import { Star } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const LoadingIndicator = () => (
  <div className="w-full flex items-center gap-2">
    <CircularProgress size={15} />
    <span>Carregando...</span>
  </div>
);

const PieChartAlt = ({ evalMethods, evaluations }) => {
  const [isDLoading, setIsDLoading] = useState(true);
  const [popupData, setPopupData] = useState(null);
  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        width: 380,
        type: 'pie',
        events: {
          dataPointSelection: (event, chartContext, { dataPointIndex }) => {
            const selectedCategory = state.options.labels[dataPointIndex];
            const selectedValue = state.series[dataPointIndex];
            const relatedData = evalMethods[dataPointIndex];

            setPopupData({
              category: selectedCategory,
              value: selectedValue,
              totalCriteria: relatedData?.attributes?.criteria?.data?.length || 0,
              description: relatedData?.attributes?.description || 'Sem descrição',
            });
          },
        },
      },
      labels: [],
      legend: { position: 'bottom' },
      responsive: [{ breakpoint: 480, options: { chart: { width: '100%' }, legend: { position: 'bottom' } } }],
    },
  });

  useEffect(() => {
    if (evalMethods?.length) {
      const aggregatedCategories = evaluations?.reduce((acc, item) => {
        const methodName = item?.attributes?.evaluation_methods?.data?.[0]?.attributes?.name;
        const methodValue = item?.attributes?.collaborator?.data ? 1 : 0;

        const existingCategory = acc.find((category) => category.method === methodName);
        if (existingCategory) existingCategory.values += methodValue;
        else acc.push({ method: methodName, values: methodValue });

        return acc;
      }, []);

      const categories = aggregatedCategories.map((item) => item.method);
      const values = aggregatedCategories.map((item) => item.values);

      setState((prevState) => ({
        ...prevState,
        series: values,
        options: { ...prevState.options, labels: categories },
      }));
      setIsDLoading(false);
    }
  }, [evalMethods, evaluations]);

  return (
    <div className="card">
      <div className="flex flex-col w-full">
        <Star sx={{ color: '#F89220' }} className='mr-auto' />
        <span className='w-full' style={{ fontWeight: '700' }}>Avaliações por Métodos</span>
      </div>
      <div id="chart" className="flex items-center justify-center">
        {
          isDLoading
            ?
            <LoadingIndicator />
            :
            <ReactApexChart
              options={state.options}
              series={state.series}
              type="pie"
              width={380}
            />
        }
      </div>
    </div>
  );
};

export default PieChartAlt;
