import { useSearchParams } from "react-router-dom";
import "./style.css";

const JobRoleItem = ({
  title,
  link,
  isActive
}) => {

  const [searchParams] = useSearchParams();
  const jobRoleFilter = searchParams.get('filter');

  return (
    <>
      <a href={link ?? '#'} className={`job-role-item ${isActive || (jobRoleFilter == title?.split(" ")[0].toLowerCase()) ? 'active' : ''}`}>{title}</a>
    </>
  );
};

export default JobRoleItem;