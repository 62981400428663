import { gql } from "@apollo/client";

export const CREATE_DISTRIBUTOR_COLLAB = gql`
  mutation AssociateMultipleCollaboratorsToDistributor(
    $id: ID!,
    $collaboratorIds: [ID!]!
  ) {
    updateDistributor(
      id: $id
      data: { collaborators: $collaboratorIds }
    ) {
      data {
        id
        attributes {
          name
          collaborators {  # Definindo um limite maior
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

