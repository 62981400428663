import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { Grid, Button, TextField, Alert, AlertTitle, Snackbar, Rating, Table, TableHead, TableCell, TableBody, TableRow, Divider } from '@mui/material';
//import { toast } from 'react-toastify';
//import { LanguageContext } from "../../../translate/languages/LanguageContext";
import { useState, useEffect } from "react";
//import i18n from "../../../translate/i18n";
import {
  UPDATE_EVAL_OPEN, CREATE_EVAL_SCORE, CREATE_PERIODS_COLLABORATOR, CREATE_OPEN_ANSWER, UPDATE_EVAL_SCORE, DELETE_EVAL_SCORE,
  DELETE_EVAL_OPEN, UPDATE_EVALUATION_COLLABORATOR, CREATE_EVALUATION_COLLABORATOR,
  CREATE_SOCRES_EVALUATION
} from '../mutation'

import { useMutation } from "@apollo/client";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function ContentStepper({
  step,
  btnState,
  showEvals,
  setShowEvals,
  company_id,
  hero,
  eval_period,
  evaluations,
  setEvaluations

}) {
  //const { selectedLanguage } = useContext(LanguageContext);
  const navigate = useNavigate()

  const [create_Evaluation] = useMutation(CREATE_EVALUATION_COLLABORATOR)
  const [create_Score] = useMutation(CREATE_SOCRES_EVALUATION)
  const [update_Evaluation] = useMutation(UPDATE_EVALUATION_COLLABORATOR)

  const [openAlertOK, setOpenAlertOK] = useState(false)
  const [successMessage, setSuccessMessage] = useState("");
  const jwt = localStorage.getItem("jwtToken");
  const [ids_closed, setIds_closed] = useState()
  const [eval__id, seteval__id] = useState(null)
  const [eval_method, setEval_method] = useState(null)

 
  

  const [value, setValue] = useState(0);

  const [scoresArray, setScoresArray] = useState([])

  const [score_, setScore_] = useState(false)
  const [evaluationId, setEvaluationId] = useState("")


  const [heroid__, setHeroid__] = useState(null)
  const [evalData, setEvalData] = useState(null);

  useEffect(() => {
    if (btnState === true) {
      setShowEvals(true)
    }

    eval_period?.map((tx) => {
      if (tx.id === hero.id) {
        setScore_(tx.id === hero.id)
        seteval__id(tx.id)
      }
    })

    hero?.companies?.map((vx) => {
      if (vx.companyID === company_id) {
        setHeroid__(vx)
      }
    }

    )

    hero?.evaluations?.map((ev) => {
      setEvaluationId(ev.id)
    })

  }, [btnState, heroid__])



  const handleCloseAlert = () => {
    setOpenAlertOK(false);
  };
  const [checkedActive, setCheckedActive] = useState(null)

  const runchanges = (i, idData, e, idn) => {
    setCheckedActive(0);
    const idData_ = idData.attributes.evaluation_answer_closeds.data
    if (i > 0) {
      setCheckedActive((prev) => (prev === i ? null : i));
    }

    setIds_closed(idData_[i].id)

  }

  //console.log(ids_closed)
  const [ans_Val, setAns_Val] = useState([])
  const [comments, setComments] = useState("")


  function handleChange(stepIndex, questionIndex, event) {
    const updatedEvalData = [...evalData];
    updatedEvalData[stepIndex][questionIndex].answer = event.target.value;
    setEvalData([...updatedEvalData]);
  }


  const itemSubmit = async () => {

    const evaluationPeriod = eval_period?.find(period =>
      period.collaborators.some(collaborator => collaborator.id === hero?.id)
    );
    const evaluationMethodsIds = evaluations.id;

    // if (heroid__ && heroid__.companyID) {
    if (true) {
      try {
        const evaluationData = {
          star: totalScore, // Nota da avaliação
          Comment: comments, // Comentário
          collaborator: hero?.id, // ID do colaborador
          companies: hero.companies[0].companyID, // IDs das empresas (ajuste conforme necessário)
          evaluation_period: evaluationPeriod?.id ?? "158", // ID do período de avaliação
          evaluation_methods: [evaluationMethodsIds], // IDs dos métodos de avaliação
        };

        // Chamar a mutação CREATE_EVALUATION_COLLABORATOR
        const evaluationResponse = await create_Evaluation({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`, // JWT de autenticação
            },
          },
          variables: {
            data: evaluationData,
          },
        });

        // Obter o ID da avaliação criada
        const evaluationId = evaluationResponse.data.createEvaluation.data.id;
       // console.log("evaluationResponse", evaluationResponse);
        // Preparar os dados dos scores para a mutação CREATE_SOCRES_EVALUATION
        const scoresArray =
          evaluations.criteria.map((criterion) => ({
            Score: criterion.score, // Valor do score
            criterionId: criterion.id, // ID do critério avaliado
          }))
     
        // Chamar a mutação CREATE_SOCRES_EVALUATION para cada score
        const scoresResponse = await Promise.all(
          scoresArray.map((scoreItem) =>
            create_Score({
              context: {
                headers: {
                  authorization: `Bearer ${jwt}`,
                },
              },
              variables: {
                data: {
                  Score: scoreItem.score, // Valor do score
                  criterion: scoreItem.criterionId, // ID do critério
                  evaluation: evaluationId, // ID da avaliação associada
                },
              },
            })
          )
        );
/*         console.log("evaluationData", evaluationData);
        console.log("scoresArray", scoresArray);
        console.log("evaluationId", evaluationId);
      console.log("ja salvou"); */
        setValue(0)
        setComments("")
        setEvaluations([])
        toast.success("Colaborador avaliado com sucesso")
        navigate(`/company/${hero.companies[0].companyID}/collaborators`)
      } catch (error) {
        toast.error("Ocorreu um erro ao avaliar o colaborador. Tente novamente!")
        console.error("Erro ao criar avaliação e scores:", error);
      }
 } else {
 toast.warning("Há um erro com a associação da empresa ao colaborador.");
    }
    /*  } else {
 
       update_Evaluation({
         context: {
           headers: {
             authorization: `Bearer ${jwt}`,
           },
         },
         variables: {
           id: evaluationId,
           data: {
             star: value,
             Comment: comments,
             collaborator: hero.id,
             companies: [heroid__.companyID],
             evaluation_period: eval_period.id,
 
           },
         },
       }).then((data) => {
 
         setOpenAlertOK(true)
         setSuccessMessage("Atualizada");
         setValue(0)
         setComments("")
       }).catch((error) => {
         console.log(error);
       })
     }
  */

    /*  update_Eval({
              context: {
                headers: {
                  authorization: `Bearer ${jwt}`,
                },
              },
              variables: {
                id: score_Id[0],
                data: {
                  company: company_id,
                  collaborator: hero.id,
                  evaluation_period: eval__id,//evalID_data[0][0].id,
                  evaluation_question_opens: question_Id[0],
                  evaluation_question_closeds: qIDC,
                  evaluation_answer_opens: answer_Id.concat(answers__),//get ids from state
                  evaluation_answer_closeds: ids_closed,
    
                },
              },
            }).then((data) => {
              console.log(data.data)
              setOpenAlertOK(true)
              setSuccessMessage("Atualizado");
            }).catch((error) => {
              console.log(error);
            }) */




    /* 


    const updateAns__ = []
    //Iterate over unique qIds
    const answers__ = []

    try {

      if (score_ === true) {
        console.log("score_", score_)
        for (let i = 0; i < answer_Id?.length || i < question_Id[0]?.length || ids_closed; i++) {
          await updateOpenEval({
            context: {
              headers: {
                authorization: `Bearer ${jwt}`,
              },
            },
            variables: {
              id: answer_Id[i],
              data: {
                evaluation_question_open: question_Id[0][i],
                answer: ans_Val[i]
              },
            },

          }).then(async (data) => {
            console.log(data.data, "dados")
            console.log("atualizou evaluate score")
            const ansid = data?.data?.updateEvaluationAnswerOpen?.data?.id
            updateAns__.push(ansid)
            setOpenAlertOK(true)
            setSuccessMessage("Atualizado");
            //setEvalData([])

            //setEvalData([])
          }).catch((error) => {
            console.log("ocorreu um erro", error);
          })

        }
      } else {
        for (let i = 0; i < qIDO?.length || i < ans_Val?.length; i++) {
          await createOpenEval({
            context: {
              headers: {
                authorization: `Bearer ${jwt}`,
              },
            },
            variables: {
              data: {
                evaluation_question_open: qIDO[i],
                answer: ans_Val[i],
              },
            },
          }).then(async (data) => {

            const ansid = await data?.data?.createEvaluationAnswerOpen?.data?.id
            answers__.push(ansid)
            console.log(ansid, "dados criou")

            setOpenAlertOK(true)
            setSuccessMessage("Atualizado");

            //setAns_id(prev => [...prev, data?.data?.createEvaluationAnswerOpen?.data?.id])
            //setEvalData([])
          }).catch((error) => {
            console.log("ocorreu um erro", error);
          })

        }

      }


      if (score_ === false) {
        create_Eval({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },

          variables: {

            data: {
              company: company_id,
              collaborator: hero.id,
              evaluation_period: i_,//eval_prdID[0],evalID_data[0][0].id,,
              evaluation_question_opens: question_Id[0],
              evaluation_question_closeds: qIDC,
              evaluation_answer_opens: answers__,
              evaluation_answer_closeds: ids_closed,
            },
          },
        }).then((data) => {
          console.log(data.data)
          console.log(answers__)
          setOpenAlertOK(true)
          setSuccessMessage("Atualizado");
        }).catch((error) => {
          console.log(error);
        })

      } else {
        update_Eval({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
          variables: {
            id: score_Id[0],
            data: {
              company: company_id,
              collaborator: hero.id,
              evaluation_period: eval__id,//evalID_data[0][0].id,
              evaluation_question_opens: question_Id[0],
              evaluation_question_closeds: qIDC,
              evaluation_answer_opens: answer_Id.concat(answers__),//get ids from state
              evaluation_answer_closeds: ids_closed,

            },
          },
        }).then((data) => {
          console.log(data.data)
          setOpenAlertOK(true)
          setSuccessMessage("Atualizado");
        }).catch((error) => {
          console.log(error);
        })

      }


    } catch (err) {
      console.log(err)
    }
 
   */
  }
  const calculateMethodTotal = (evaluation) => {
    if (!evaluation?.criteria || !Array.isArray(evaluation.criteria)) return 0;
    const maxScore = 5; 
    // Itera sobre os critérios e calcula o total ponderado
    const total = evaluation.criteria.reduce((sum, criterion) => {
      // Pontuação máxima (5 estrelas)
      const weightedScore = (criterion.score || 0) * (criterion.percentage / 100); // Pontuação ponderada
      return sum + weightedScore;
    }, 0);
  
    // Normaliza o total para uma escala de 0 a 100%
    return Math.min(total / maxScore * 100, 100);
  };

// Cálculo da pontuação total para todos os métodos
const totalScore = calculateMethodTotal(evaluations);



  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="30px"
      p={4}
      sx={{
        maxWidth: 800,
        margin: "auto",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      }}
    >
      {/* Título */}
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Revisar Avaliação
      </Typography>

      {/* Métodos e Critérios */}
      <Box
        sx={{
          width: "100%",
          border: "1px solid #ddd",
          borderRadius: "8px",
          padding: "16px",
          backgroundColor: "#f9f9f9",
        }}
      >
       
          <Box  mb={3}>
            {/* Nome do Método */}
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              {evaluations?.name || ""}
            </Typography>

            {/* Critérios Avaliados */}
            {(evaluations?.criteria && Array.isArray(evaluations.criteria)) ? (
              evaluations.criteria.map((criterion, criterionIndex) => (
                <Box
                  key={criterionIndex}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                  }}
                >
                  {/* Nome e Peso */}
                  <Box>
                    <Typography variant="body1" fontWeight="bold">
                      {criterion?.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Peso: {criterion?.percentage}%
                    </Typography>
                  </Box>

                  {/* Avaliação em Estrelas */}
                  <Rating
                    size="large"
                    readOnly
                    value={criterion?.score || 0}
                    precision={0.5}
                  />
                </Box>
              ))) : null}

         
          </Box>

       
      </Box>


      {/* Comentário Adicional */}
      <Box sx={{ width: "100%" }}>
        <Typography component="h3" variant="h6" gutterBottom>
          Adicione um comentário (opcional)
        </Typography>
        <TextField
          type="text"
          fullWidth
          name="answer"
          id="answer"
          size="medium"
          placeholder="Escreva seu comentário aqui..."
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          multiline
          rows={4}
          variant="outlined"
        />
      </Box>
      {/* Exibe a pontuação total da avaliação */}
      <Box sx={{ width: "100%", textAlign: "center", mt: 3 }}>
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          {!isNaN(totalScore) ? `Pontuação Total: ${totalScore}%` : ""}

        </Typography>
      </Box>

      {/* Botão de Submissão */}
      <Box sx={{ textAlign: "center", mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={itemSubmit}
          sx={{ px: 5 }}
        >
          Enviar Avaliação
        </Button>
      </Box>

      {/* Snackbar de Sucesso */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAlertOK}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
      >
        <Alert severity="success" sx={{ border: 1, width: "100%" }}>
          <AlertTitle sx={{ display: "flex", justifyContent: "space-between" }}>
            Sucesso
            <CloseIcon
              color="secondary"
              onClick={handleCloseAlert}
              sx={{ cursor: "pointer" }}
            />
          </AlertTitle>
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );





  /*   return (
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} gap={"30px"}>
  
  
        <Grid
          item
          container
          alignItems="left"
          justifyContent="center"
        >
          {showEvals &&
            <Grid item style={{}}>
              <Box >
                <Typography component='h3' variant='h3' style={{ fontSize: 16, marginLeft: 9 }}>
                  Adicione um comentário(opcional)
                </Typography>
  
                <Grid container margin={2}>
                  <TextField
                    type="text"
                    fullWidth
                    name={'answer'}
                    id={'answer'}
                    size="medium"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  />
                </Grid>
              </Box>
  
            </Grid>}
     
  
          {!showEvals && (
            <Grid>
              <Typography component="h3" variant="h3" style={{ fontSize: 16, marginLeft: 9 }}>
                Dados da avaliação:
              </Typography>
              {hero?.evaluations?.length > 0 ? (
                <Table style={{ marginTop: 22, marginLeft: 9, width: "100%" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>Nº</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Comentário adicional</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Nome da empresa</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Avaliação</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {hero?.evaluations?.map((evals, index) => (
                      <TableRow key={index}>
                        <TableCell>{evals?.eval_id || "Não disponível"}</TableCell>
                        <TableCell>{evals?.comment || "Não informado"}</TableCell>
                        <TableCell>{evals?.companies?.data[0]?.attributes?.name || "Não informado"}</TableCell>
                        <TableCell>   <Rating
                          value={evals.star}
                          precision={0.5}
                          readOnly
                        /></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Typography component="p" style={{ fontSize: 14, marginLeft: 9, marginTop: 20 }}>
                  Nenhuma avaliação a ser mostrada.
                </Typography>
              )}
  
  
            </Grid>
          )}
  
  
  
        </Grid>
  
        <Grid
          item
          container
          spacing={-20}
          alignItems="center"
          direction="column"
          justifyContent="center"
        >
       
          <Button variant="contained"
            type="submit"
            color="primary"
            onClick={itemSubmit}
          >
            {'Evaluate'}
          </Button>
        </Grid>
        <Snackbar
          style={{ backgroundColor: 'teal', color: 'coral' }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openAlertOK}
          autoHideDuration={5000}
          onClose={handleCloseAlert}
        >
          <Alert severity="success" sx={{ border: 1 }}>
            <AlertTitle sx={{ width: 500, display: 'flex', justifyContent: 'space-between' }}>
              Successo
              <CloseIcon color='secondary' onClick={handleCloseAlert} sx={{ cursor: 'pointer' }} />
            </AlertTitle>
            {successMessage}
          </Alert>
        </Snackbar>
  
      </Box>
    ); */
}

