import { useMutation, useQuery } from "@apollo/client";
import { GET_DISTRIBUTOR_BY_COMPANY_ID, GET_DISTRIBUTOR_COLLABORATORS, GET_DISTRIBUTOR_COLLAB_IDs, GET_DISTRIBUTOR_COMPANIES } from "./gql/query";
import { CREATE_DISTRIBUTOR_COLLAB } from "./gql/mutation";
import { useEffect, useState } from "react";

const CreateCollaborator = ({ userId, collaboratorsId }) => {
  const jwtToken = localStorage.getItem("jwtToken");
  const [isSuccess, setIsSuccess] = useState(false);

  const [associateCollaborators, { loading: loadingMutation }] = useMutation(
    CREATE_DISTRIBUTOR_COLLAB
  );

  useEffect(() => {
    if (collaboratorsId && jwtToken && userId) {
      associateCollaborators({
        context: {
          headers: {
            authorization: `Bearer ${jwtToken}`,
          },
        },
        variables: {
          id: userId,
          collaboratorIds: collaboratorsId,
        },
      }).then((res) => {
        if (res) {
          setIsSuccess(true);
        }
      }).catch(() => {
        setIsSuccess(false);
      });
    }
  }, [collaboratorsId, userId, jwtToken]);

  return isSuccess;
};

const GetAll = ({ userId, jwtToken }) => {
  return useQuery(GET_DISTRIBUTOR_COLLABORATORS, {
    context: {
      headers: {
        authorization: `Bearer ${jwtToken}`,
      },
    },
    variables: { userId },
  });
};

const GetCompanies = ({ userId, jwtToken }) => {
  return useQuery(GET_DISTRIBUTOR_COMPANIES, {
    context: {
      headers: {
        authorization: `Bearer ${jwtToken}`,
      },
    },
    variables: { userId },
  });
};

const GetCollaboratorsId = ({ userId }) => {
  const jwtToken = localStorage.getItem("jwtToken");
  return useQuery(GET_DISTRIBUTOR_COLLAB_IDs, {
    context: {
      headers: {
        authorization: `Bearer ${jwtToken}`,
      },
    },
    variables: { userId }
  });
};

const GetDistByCompanyId = ({ companyId }) => {
  const jwtToken = localStorage.getItem("jwtToken");

  return useQuery(GET_DISTRIBUTOR_BY_COMPANY_ID, {
    skip: !companyId,
    context: {
      headers: {
        authorization: `Bearer ${jwtToken}`,
      },
    },
    variables: { companyId }
  });

};

export const DistributorService = {
  GetAll,
  GetCollaboratorsId,
  CreateCollaborator,
  GetCompanies,
  GetDistByCompanyId,
};

