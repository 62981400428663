export const StylesInput = {
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'primary.main',
  },
  '& .Mui-focused .MuiInputLabel-root': {
    color: 'primary.main',
  },
  '& .MuiOutlinedInput-root': {
    padding: 0,
  },
  '& .MuiInputLabel-root': {
    top: '-7px',
  },
  '& .MuiInputLabel-shrink': {
    top: '0px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '20px',
  },

  // Ajustes específicos para Autocomplete
  '& .MuiAutocomplete-root': {
    padding: 0, // Remove espaçamento extra ao redor do Autocomplete
  },
  '& .MuiAutocomplete-inputRoot': {
    padding: 0, // Remove padding extra na raiz do input
  },
  '& .MuiAutocomplete-input': {
    padding: '19.5px !important', // Garante que o padding seja aplicado corretamente
  },
  '& .MuiAutocomplete-popupIndicator': {
    color: 'primary.main', // Ajusta a cor do ícone de dropdown
  },
};
