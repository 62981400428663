import { gql } from "@apollo/client";

export const GET_EMPLOYEER_BY_EMAIL = gql`
  query UsersPermissionsUsers($filters: UsersPermissionsUserFiltersInput) {
    usersPermissionsUsers(filters: $filters) {
      data {
        id
        attributes {
          email
          isResetPassword
          userRole{data{id attributes{role description}}}
        }
      }
    }
  }
`;

export const GET_EMPLOYEER_BY_EM = gql`
  query UsersPermissionsUsers {
    usersPermissionsUsers {
      data {
        id
        attributes {
          email
          isResetPassword
          userRole{data{id attributes{role description}}}
        }
      }
    }
  }
`;

export const GET_USERNAMES = gql`
query UsersPermissionsUsers {
  usersPermissionsUsers(filters:{role:{name:{contains:"Company"}}}) {
    data {
      id
      attributes {
        username
        name
      }
    }
  }
}
`;

export const GET_COUNTRY_STATE_CITY = gql`
query Data ($pagination: PaginationArg) {
  countries(pagination: $pagination) {
    data {
      id
      attributes {
        areaCode
        name
cities{data{id attributes{name}}}
        states {data {id attributes {name cities { data {id attributes { name}}}}}}}}}}
`;

export const GET_CITIES = gql`
query Data ($pagination: PaginationArg) {
  cities(pagination: $pagination) {
    data {id attributes{name}}}}
`;
