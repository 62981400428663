import { gql } from '@apollo/client';

export const GET_COMPANY_BY_EMAIL = gql`
  query UsersPermissionsUsers($filters: UsersPermissionsUserFiltersInput) {
    usersPermissionsUsers(filters: $filters) {
      data {
        id
        attributes {
          email
          isResetPassword
        }
      }
    }
  }
`;

export const GET_COUNTRY_STATE_CITY = gql`
  query Data($pagination: PaginationArg) {
    countries(pagination: $pagination) {
      data {
        id
        attributes {
          name
          cities {
            data {
              id
              attributes {
                name
              }
            }
          }
          states {
            data {
              id
              attributes {
                name
                cities {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_CITIES = gql`
  query Data($pagination: PaginationArg) {
    cities(pagination: $pagination) {
      data {
        id
        attributes {
          name
          country {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_DISTRIBUTORS = gql`
  query Distributors {
    distributors(pagination: { pageSize: 1000 }) {
      data {
        id
        attributes {
          status
          name
          users_permissions_user {
            data {
              id
              attributes {
                username
                confirmed
              }
            }
          }
          city {
            data {
              id
              attributes {
                name
              }
            }
          }
          country {
            data {
              id
              attributes {
                name
              }
            }
          }
          email
          phone
          image {
            data {
              id
              attributes {
                url
              }
            }
          }
          collaborators {
            data {
              id
              attributes {
                name
                email
              }
            }
          }
        }
      }
    }
  }
`;
export const QUERY_STATUS_ENUM = gql`
  query ENUM_COMPANY_STATUS {
    __type(name: "ENUM_COMPANY_STATUS") {
      enumValues {
        name
      }
    }
  }
`;
export const QUERY_IMGS = gql`
  query uploadFiles {
    data {
      id
      attributes {
        name
        url
      }
    }
  }
`;
export const GET_USER_DATA = gql`
  query UsersPermissionsUser($id: ID) {
    usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          email
          name
          postRole {
            data {
              id
              attributes {
                postRole
                description
              }
            }
          }
          userRole {
            data {
              id
              attributes {
                role
                description
              }
            }
          }
          image {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_COLLABORATORS = gql`
  query GetCollaborators {
    collaborators(pagination: { pageSize: 1000 }) {
      data {
        id
        attributes {
          name
          email
          phone
          web
          intro
          address
          postRole {
            data {
              id
              attributes {
                postRole
                description
              }
            }
          }
          city {
            data {
              id
              attributes {
                name
              }
            }
          }
          country {
            data {
              id
              attributes {
                name
              }
            }
          }
          image {
            data {
              id
              attributes {
                url
              }
            }
          }
          experiences(pagination: { pageSize: 20 }) {
            data {
              id
              attributes {
                description
                startDate
                endDate
                postRole {
                  data {
                    id
                    attributes {
                      postRole
                      description
                    }
                  }
                }
                expEntity {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                country {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          educations(pagination: { pageSize: 20 }) {
            data {
              id
              attributes {
                description
                startDate
                endDate
                course {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                faculty {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                country {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          expertise(pagination: { pageSize: 20 }) {
            data {
              id
              attributes {
                description
                expertise {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          skills(pagination: { pageSize: 20 }) {
            data {
              id
              attributes {
                percentual
                itTool {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          profiles(pagination: { pageSize: 20 }) {
            data {
              id
              attributes {
                urlProfile
                description
                platform {
                  data {
                    id
                    attributes {
                      name
                      icon {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          awards(pagination: { pageSize: 20 }) {
            data {
              id
              attributes {
                name
                description
              }
            }
          }
          portfolios(pagination: { pageSize: 20 }) {
            data {
              id
              attributes {
                name
                description
                image {
                  data {
                    id
                    attributes {
                      url
                      previewUrl
                    }
                  }
                }
              }
            }
          }
          client {
            data {
              id
              attributes {
                companies {
                  data {
                    id
                    attributes {
                      name
                      image {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          users_permissions_user {
            data {
              id
              attributes {
                username
              }
            }
          }
          company {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_DIST_EMPLOYERS = gql`
  query GetEmploymentColabDistributors($distributorId: ID!) {
    employmentcolabdistributors(
      filters: { distributors: { id: { eq: $distributorId } } }
    ) {
      data {
        id
        attributes {
          status
          payment
          # distributors(pagination: { pageSize: 1000}) {
          #   data {
          #     id
          #   }
          # }
          collaborators(pagination: { pageSize: 1000000 }) {
            data {
              id
              attributes {
                furnishers {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                employmentcolabdistributors {
                  data {
                    id
                    attributes {
                      distributors {
                        data {
                          id
                        }
                      }
                    }
                  }
                }
                distributors {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                jobrole_colabs {
                  data {
                    id
                    attributes {
                      job_roles {
                        data {
                          id
                          attributes {
                            category
                          }
                        }
                      }
                    }
                  }
                }
                users_permissions_user {
                  data {
                    id
                    attributes {
                      username
                      confirmed
                    }
                  }
                }
                name
                email
                phone
                web
                intro
                address
                payment
                rating
                speciality
                language_collaborators {
                  data {
                    id
                    attributes {
                      language {
                        data {
                          id
                          attributes {
                            idioma
                          }
                        }
                      }
                      level
                      collaborator {
                        data {
                          id
                          attributes {
                            name
                          }
                        }
                      }
                    }
                  }
                }
                evaluations {
                  data {
                    id
                    attributes {
                      star
                      Comment
                      companies {
                        data {
                          id
                          attributes {
                            name
                          }
                        }
                      }

                      evaluation_period {
                        data {
                          id
                          attributes {
                            start
                            end
                            description
                          }
                        }
                      }
                    }
                  }
                }
                postRole {
                  data {
                    id
                    attributes {
                      postRole
                      description
                    }
                  }
                }
                city {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                country {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                experiences(pagination: { pageSize: 20 }) {
                  data {
                    attributes {
                      description
                      startDate
                      endDate
                      postRole {
                        data {
                          attributes {
                            postRole
                            description
                          }
                        }
                      }
                      expEntity {
                        data {
                          attributes {
                            name
                          }
                        }
                      }
                      country {
                        data {
                          attributes {
                            name
                          }
                        }
                      }
                    }
                  }
                }
                educations(pagination: { pageSize: 20 }) {
                  data {
                    attributes {
                      description
                      startDate
                      endDate
                      course {
                        data {
                          attributes {
                            name
                          }
                        }
                      }
                      faculty {
                        data {
                          attributes {
                            name
                          }
                        }
                      }
                      country {
                        data {
                          attributes {
                            name
                          }
                        }
                      }
                    }
                  }
                }
                expertise {
                  data {
                    attributes {
                      description
                      expertise {
                        data {
                          attributes {
                            name
                          }
                        }
                      }
                    }
                  }
                }
                skills {
                  data {
                    attributes {
                      percentual
                      itTool {
                        data {
                          attributes {
                            name
                          }
                        }
                      }
                    }
                  }
                }
                profiles {
                  data {
                    attributes {
                      urlProfile
                      description
                      platform {
                        data {
                          attributes {
                            name
                            icon {
                              data {
                                attributes {
                                  url
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                awards {
                  data {
                    attributes {
                      name
                      description
                    }
                  }
                }
                portfolios {
                  data {
                    attributes {
                      name
                      description
                      image {
                        data {
                          attributes {
                            url
                            previewUrl
                          }
                        }
                      }
                    }
                  }
                }
                client {
                  data {
                    attributes {
                      companies {
                        data {
                          id
                          attributes {
                            name
                            image {
                              data {
                                attributes {
                                  url
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                users_permissions_user {
                  data {
                    id
                    attributes {
                      username
                    }
                  }
                }
                companies {
                  data {
                    id
                    attributes {
                      name
                      email
                      users_permissions_user {
                        data {
                          id
                          attributes {
                            username
                          }
                        }
                      }
                    }
                  }
                }
                employment {
                  data {
                    id
                    attributes {
                      collaborators {
                        data {
                          id
                          attributes {
                            name
                          }
                        }
                      }
                    }
                  }
                }
                isActive
              }
            }
          }
        }
      }
    }
  }
`;