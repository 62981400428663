import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { Card, CardActions, Typography } from '@mui/material';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import DoneIcon from '@mui/icons-material/Done';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Pagination,
} from '@mui/material';
import React, { useEffect, useState, useRef, useContext } from 'react';
import './company.css';
import CompanyDelete from './Company_delete';
import CreateCompany from './create_distributor';
import i18n from '../../../translate/i18n';
import { LanguageContext } from '../../../translate/languages/LanguageContext';
import { UserRoleContext } from '../../../contexts/userRole-context';
import { CheckCircleOutline, CloudCircleOutlined, KeyboardArrowLeft, KeyboardArrowRight, NetworkCell, School } from '@mui/icons-material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 0,
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default function DistributorList({
  setCompanies,
  companies,
  company_,
  setCompany_,
  setCompany_Edit,
  currentID,
  setCurrentID,
  company_Edit,
  companies_,
  setOpenAlertOK,
  setSuccessMessage,
  updater,
  setUpdater,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [activeOption, setActiveOption] = useState(false);
  const [companyUser, setCompanyUser] = useState('');

  const { selectedLanguage } = useContext(LanguageContext);
  const { uRoleContext } = useContext(UserRoleContext);
  const itemsPerPage = 8;

  // Calculate the indexes of the first and last items to display on the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  // Extract the items to display on the current page
  const currentItems = companies_?.slice(firstIndex, lastIndex);

  // Calculate the total number of pages
  const totalPages = Math.ceil(companies_?.length / itemsPerPage);

  // Handle the page change event
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [companies_, totalPages]);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const [companyID, setCompanyID] = useState(false);
  const [company, setCompany] = useState('');
  const [open, setOpen] = useState(false);

  function handleClickDelete(companyID, companyData) {
    console.log('companyData', companyData);

    setCompanyID(companyID);
    setCompanyUser(companyData?.attributes?.users_permissions_user?.data);

    setOpen(true);
    setActiveOption(false);
    let data = companies_.find((d) => d.id === companyID);
    setCompany(data);
  }

  const clickDisable = (companyID, companyData) => {
    setActiveOption(true);
    setOpen(true);
    setCompanyID(companyID);

    let data = companies_.find((d) => d.id === companyID);
    setCompany(data);
    setCompanyUser(data);
  };

  function handleClickOpenView(companyID, companyData) {
    setCompany_Edit(false);
    let data = companies_.find((d) => d.id === companyID);

    setCompany_((i) => {
      return {
        id: data.id,
        userID: data?.attributes?.users_permissions_user?.data?.id,
        name: data?.attributes?.name,
        country: data?.attributes?.country?.data?.attributes?.name,
        city: data?.attributes?.city?.data?.attributes?.name,
        email: data?.attributes?.email,
        phone: data?.attributes?.phone,
        status: data?.attributes?.status,
        image: data?.attributes?.image?.data?.attributes.url,
        imageID: data?.attributes?.image?.data?.id,
        findDevs: data?.attributes?.findDevs?.data,
        countryID: data?.attributes?.country?.data?.id,
        cityID: data?.attributes?.city?.data?.id,
        confirmed:
          data?.attributes?.users_permissions_user?.data?.attributes?.confirmed,
      };
    });
  }

  function handleClose() {
    setOpen(false);
    setOpenModal(false);
    // setCompanies(companies)
  }
  function handleEdit(companyID, companyData) {
    //setCompany_(companyData);
    setCompany_Edit(true);
    setOpenModal(true);
    setCurrentID(companyID);
    let data = companies_.find((d) => d.id === companyID);

    setCompany_((i) => {
      return {
        id: data.id,
        userID: data?.attributes?.users_permissions_user?.data?.id,
        name: data?.attributes?.name,
        country: data?.attributes?.country?.data?.attributes?.name,
        city: data?.attributes?.city?.data?.attributes?.name,
        email: data?.attributes?.email,
        phone: data?.attributes?.phone,
        status: data?.attributes?.status,
        image: data?.attributes?.image?.data?.attributes.url,
        imageID: data?.attributes?.image?.data?.id,
        findDevs: data?.attributes?.findDevs?.data,
        countryID: data?.attributes?.country?.data?.id,
        cityID: data?.attributes?.city?.data?.id,
        confirmed:
          data?.attributes?.users_permissions_user?.data?.attributes?.confirmed,
      };
    });
  }

  const [openTarget, setOpenTarget] = useState(false);
  const menuRef = useRef(null);
  const [display, setDisplay] = useState(-1);
  const toggleElement = (currentIndex) => {
    setOpenTarget(!openTarget);
    setDisplay(currentIndex);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenTarget(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  console.log('distributors', companies);

  return (
    <>
      <div className="flex items-center w-full mb-10 px-6 border-t border-b py-2.5">
        <div className="flex flex-col flex-1">
          <span style={{ fontSize: '1.45rem', textTransform: 'capitalize' }} className="font-bold font-onest self-start">Distribuidores</span>
        </div>
        <a href="/" className='py-[0.75rem] border border-[#F89220] px-6 rounded-[0.25rem] text-[#F89220] hover:bg-[#F89220] hover:text-[#fff]'>Voltar</a>
      </div>

      <div className="w-full px-6 -mt-6 pb-6 relative grid md:grid-cols-2 lg:grid-cols-4 gap-6">
        {
          currentItems?.map((row, index) => (
            <div
              key={index}
              className="bg-white rounded-[12px] border border-gray-200 mt-6 shadow-md relative py-6 px-6"
            >
              <div className="absolute top-4 right-4">
                <LongMenu
                  collaboratorID={row.id}
                  collaboratorData={row}
                  rowId={row.id}
                  row={row}
                />
              </div>
              <div className="flex">
                <div className='flex self-start items-center jusitfy-center'>
                  {
                    row?.avatar ? (
                      <img
                        // src={`${REACT_APP_API_URL}${row?.avatar}`}
                        className='w-[50px] h-[50px] aspect-square rounded-full border object-top'
                      />
                    ) : (

                      <span className="flex items-center justify-center w-[50px] h-[50px] aspect-square rounded-full bg-[#EA1967] text-white text-xl lg:text-2xl">{row?.attributes?.name?.[0]}</span>
                    )
                  }
                </div>
                <div className="flex flex-col ml-2">
                  <h4 className="font-bold self-start" style={{ fontSize: '0.85rem' }}>
                    {row?.attributes?.name}
                  </h4>
                  <small style={{ fontSize: '0.85rem' }} className="text-[rgba(0,0,0,0.65)] self-start -mt-1">{row?.attributes?.country?.data?.attributes?.name}</small>
                </div>
              </div>
              <div className="flex flex-col w-full mt-4">
                <span className='font-bold block w-full text-[#F89220]'>
                  <NetworkCell sx={{ fontSize: 18 }} /> {" "}
                  {
                    row?.attributes?.status ? 'Active' : 'Inactive' || 'N/A'
                  }
                </span>
                <div className="flex items-center gap-2 w-full">
                  {
                    row?.attributes?.email
                  }
                </div>
              </div>
            </div>
          ))
        }
      </div>

      <div className="mt-8 flex space-x-3 items-center justify-center">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="outline-none border inline-block text-center py-[0.45rem] px-[0.45rem] bg-white cursor-pointer shadow-sm text-black hover:bg-[#F9AE00] hover:text-[#fff] roudned-[8px]"
        >
          <KeyboardArrowLeft />
        </button>
        <div className="flex items-center gap-3">
          <span
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}
            className={`w-[28px] h-[28px] flex items-center justify-center text-[0.875rem] font-bold ${currentPage === totalPages ? 'bg-white text-black' : 'bg-[#F9AE00] text-white'}`}
          >
            {currentPage}
          </span>
          <span>
            {i18n.t('de', { lng: selectedLanguage })}
          </span>
          <span
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}
            className={`w-[28px] h-[28px] items-center justify-center text-[0.875rem] font-bold ${currentPage === totalPages ? 'bg-[#F9AE00] text-white' : 'bg-white text-black'}`}
          >
            {totalPages}
          </span>
        </div>
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className={`outline-none border inline-block text-center py-[0.45rem] px-[0.45rem] bg-white cursor-pointer shadow-sm text-black hover:bg-[#F9AE00] hover:text-[#fff] roudned-[8px] `}
        >
          <KeyboardArrowRight />
        </button>
      </div>

      <div className="hidden">
        <Paper>
          <Paper component={Paper} sx={{ padding: 2, marginBottom: 10 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 900, overflow: 'hidden' }}
                aria-label='customized table'
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell component='th' sx={{ padding: 3 }}>
                      {i18n.t('table.name', { lng: selectedLanguage })}
                    </StyledTableCell>
                    <StyledTableCell component='th' sx={{ padding: 3 }}>
                      {i18n.t('table.location', { lng: selectedLanguage })}
                    </StyledTableCell>
                    <StyledTableCell component='th' sx={{ padding: 3 }}>
                      {i18n.t('table.state', { lng: selectedLanguage })}
                    </StyledTableCell>
                    <StyledTableCell component='th' sx={{ padding: 3 }}>
                      {i18n.t('table.email', { lng: selectedLanguage })}
                    </StyledTableCell>
                    <StyledTableCell component='th' sx={{ padding: 3 }}>
                      {i18n.t('table.phone', { lng: selectedLanguage })}
                    </StyledTableCell>
                    <StyledTableCell component='th' sx={{ padding: 3 }}>
                      {i18n.t('table.status', { lng: selectedLanguage })}
                    </StyledTableCell>
                    <StyledTableCell component='th' sx={{ padding: 3 }}>
                      {i18n.t('table.action', { lng: selectedLanguage })}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {currentItems?.map((row, index) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell scope='row' sx={{}}>
                        {row?.attributes?.name || 'N/A'}
                      </StyledTableCell>

                      <StyledTableCell scope='row' sx={{}}>
                        {row?.attributes?.country?.data?.attributes?.name || 'N/A'}
                      </StyledTableCell>
                      <StyledTableCell scope='row' sx={{}}>
                        {row?.attributes?.city?.data?.attributes?.name || 'N/A'}
                      </StyledTableCell>
                      <StyledTableCell
                        scope='row'
                        sx={{ padding: 2, wordBreak: 'break-all' }}
                      >
                        {row?.attributes?.email || 'N/A'}
                      </StyledTableCell>
                      <StyledTableCell scope='row' sx={{}}>
                        {row?.attributes?.phone || 'N/A'}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.attributes?.status ? 'Active' : 'Inactive' || 'N/A'}
                      </StyledTableCell>

                      <StyledTableCell sx={{}}>
                        <IconButton
                          aria-label='view'
                          color='secondary'
                          onClick={() => toggleElement(index)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </StyledTableCell>

                      <StyledTableCell>
                        <>
                          {openTarget && display === index ? (
                            <Card
                              ref={menuRef}
                              variant='outlined'
                              sx={{ position: 'absolute', right: 170, width: 150 }}
                            >
                              <CardActions disableSpacing>
                                <IconButton
                                  aria-label='view'
                                  onClick={() => handleClickOpenView(row.id, row)}
                                >
                                  <VisibilityOutlinedIcon color='primary' />

                                  <Typography sx={{ paddingLeft: 1 }} id={row.id}>
                                    {' '}
                                    {i18n.t('options.view', {
                                      lng: selectedLanguage,
                                    })}{' '}
                                  </Typography>
                                </IconButton>
                              </CardActions>
                              {row?.attributes?.status === 'Active' ||
                                uRoleContext === 'administrator' ? (
                                <CardActions disableSpacing>
                                  <IconButton
                                    aria-label='view'
                                    onClick={() => handleEdit(row.id, row)}
                                  >
                                    <EditOutlinedIcon color='primary' />
                                    <Typography sx={{ paddingLeft: 1 }} id={row.id}>
                                      {i18n.t('options.edit', {
                                        lng: selectedLanguage,
                                      })}
                                    </Typography>
                                  </IconButton>
                                </CardActions>
                              ) : (
                                ''
                              )}

                              <CardActions disableSpacing>
                                <IconButton
                                  aria-label='view'
                                  onClick={() => handleClickDelete(row.id, row)}
                                >
                                  <ClearOutlinedIcon color='primary' />
                                  <Typography sx={{ paddingLeft: 1 }} id={row.id}>
                                    {' '}
                                    {i18n.t('options.delete', {
                                      lng: selectedLanguage,
                                    })}{' '}
                                  </Typography>
                                </IconButton>
                              </CardActions>

                              <CardActions disableSpacing>
                                <IconButton
                                  aria-label='view'
                                  onClick={() => clickDisable(row.id, row)}
                                >
                                  {row?.attributes?.status === 'Active' ? (
                                    <DoNotDisturbAltIcon
                                      color={
                                        row?.attributes?.status === 'Active'
                                          ? 'disabled'
                                          : 'primary'
                                      }
                                    />
                                  ) : (
                                    <DoneIcon color='primary' />
                                  )}

                                  <Typography sx={{ paddingLeft: 1 }} id={row.id}>
                                    {row?.attributes?.status === 'Active'
                                      ? i18n.t('options.disable', {
                                        lng: selectedLanguage,
                                      })
                                      : i18n.t('options.enable', {
                                        lng: selectedLanguage,
                                      })}{' '}
                                  </Typography>
                                </IconButton>
                              </CardActions>
                            </Card>
                          ) : (
                            <></>
                          )}
                        </>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Pagination
                shape='rounded'
                size='small'
                color='primary'
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  paddingTop: 13,
                  paddingBottom: 3,
                  position: 'relative',
                  float: 'right',
                }}
              />
            </Box>
          </Paper>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            maxWidth='md'
          >
            <DialogTitle
              id='alert-dialog-title'
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              {activeOption === false ? (
                <p>Delete {company.attributes?.name}?</p>
              ) : (
                <p>
                  {company?.attributes?.status === 'Active' ? 'Disable' : 'Enable'}{' '}
                  {company?.attributes?.name}?
                </p>
              )}
            </DialogTitle>
            <DialogContent>
              <CompanyDelete
                setOpenAlertOK={setOpenAlertOK}
                setSuccessMessage={setSuccessMessage}
                companyID={companyID}
                companies={companies_}
                setCompanies={setCompanies}
                handleClose={handleClose}
                companyUserID={companyUser?.id}
                companyUser={companyUser}
                currentID={currentID}
                activeOption={activeOption}
                company={company}
                setCompany={setCompany}
              />
            </DialogContent>
          </Dialog>

          {company_Edit === true && (
            <Dialog
              open={openModal}
              onClose={handleClose}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
              maxWidth='md'
            >
              <DialogContent>
                <CreateCompany
                  updater={updater}
                  setUpdater={setUpdater}
                  companies={companies_}
                  setCompanies={setCompanies}
                  setCompany_={setCompany_}
                  company_={company_}
                  setCompany_Edit={setCompany_Edit}
                  company_Edit={company_Edit}
                  currentID={currentID}
                  setCurrentID={setCurrentID}
                  handleClose={handleClose}
                />
              </DialogContent>
            </Dialog>
          )}
        </Paper>
      </div>
    </>
  );

  function LongMenu({ collaboratorID, collaboratorData, contractText, collaboratorStep, rowId, row }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const booleanStatus = collaboratorData.isActive

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    // const handleClose = () => {
    //   setAnchorEl(null);
    // };

    // const handleOpenView = () => {
    //   //console.log(collaboratorID);
    //   setColaborador(collaboratorData);
    //   handleClose();
    // };

    // const handleEdit = () => {
    //   setColaborador(collaboratorData);
    //   setColaboradorEdit(true);
    //   handleClose();
    // };

    // const handleClickDelete = (step) => {
    //   //console.log(collaboratorID);
    //   setCollaboratorID(collaboratorID);
    //   setCollaboradorUser(collaboratorData);
    //   setCollaboratorStep(step);
    //   setOpen(true);
    //   handleClose();
    // };
    // const handleClickDisable = (step) => {
    //   //console.log(collaboratorID);
    //   setCollaboratorID(collaboratorID);
    //   setCollaboradorUser(collaboratorData);
    //   setCollaboratorStep(step);
    //   setOpen(true);
    //   handleClose();
    //   setBooleanStatus(collaboratorData.isActive)
    // };




    return (
      <>
        <IconButton aria-label="more" onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        {/* <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={() => handlePreviewCollab(rowId, row)}>
            <VisibilityOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> {i18n.t('options.view', { lng: selectedLanguage })}
          </MenuItem>
          {
            isAdmin ? (
              [
                <MenuItem key="edit" onClick={handleEdit}>
                  <EditOutlinedIcon color="primary" style={{ marginRight: '5px' }} />
                  {i18n.t('options.edit', { lng: selectedLanguage })}
                </MenuItem>,
                <MenuItem key="toggle" onClick={() => handleClickDisable(true)}>
                  {booleanStatus ? (
                    <ClearOutlinedIcon color="primary" style={{ marginRight: '5px' }} />
                  ) : (
                    <CheckCircleOutline color="primary" style={{ marginRight: '5px' }} />
                  )}
                  {booleanStatus
                    ? i18n.t('options.disable', { lng: selectedLanguage })
                    : i18n.t('options.enable', { lng: selectedLanguage })}
                </MenuItem>,
                <MenuItem key="delete" onClick={() => handleClickDelete(false)}>
                  <DeleteIcon color="primary" style={{ marginRight: '5px' }} />
                  {i18n.t('options.delete', { lng: selectedLanguage })}
                </MenuItem>,
              ]
            ) : null
          }


          <MenuItem onClick={() => handleClickAssociate(contractText, rowId)}>
            <CloudCircleOutlined color='primary' style={{ marginRight: '5px' }} />
            {contractText}
          </MenuItem>
        </Menu> */}
      </>
    );
  }
}
