import {
    Autocomplete,
    Button, FormControl, Grid, InputLabel, TextField, Typography, createFilterOptions
} from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_COUNTRY_STATE_CITY, GET_EDUCATION_COURSES, GET_EDUCATION_FACULTIES, GET_POSTROLE } from "../query.gql";
import { CREATE_EDUCATION, CREATE_EDUCATION_COURSES, CREATE_EDUCATION_FACULTIES, UPDATE_EDUCATION } from "../mutation.gql";
import i18n from "../../../../translate/i18n";
import { LanguageContext } from "../../../../translate/languages/LanguageContext";
import { useContext } from "react";

const EducationStep = ({
    handleClose,
    collaboratorID,
    collaborator,
    setCollaborator,
    collaborators,
    setCollaborators,
    ObjectList,
    setObjectList,
    step,
    stepID,
    setEducation,
    setIsGridVisible

}) => {
    const { selectedLanguage } = useContext(LanguageContext);
    const addStep = step?.includes("Add");
    const editStep = step?.includes("Edit");
    const createStep = step?.includes("Create");

    const filter = createFilterOptions();
    const jwt = localStorage.getItem("jwtToken");
    const [getEduFacultiesName] = useLazyQuery(GET_EDUCATION_FACULTIES);
    const [getEduCoursesName] = useLazyQuery(GET_EDUCATION_COURSES);
    const [new_education] = useMutation(CREATE_EDUCATION);
    const [update_edu] = useMutation(UPDATE_EDUCATION);
    const [new_eduFaculty] = useMutation(CREATE_EDUCATION_FACULTIES);
    const [new_eduCourse] = useMutation(CREATE_EDUCATION_COURSES);
    //const [education, setEducation] = useState([])
    const [eduCourses, setEduCourses] = useState([]);
    const [newEduCourses, setNewEduCourses] = useState([]);
    const [eduCourseValue, setEduCourseValue] = useState(null);
    const [eduFaculties, setEduFaculties] = useState([]);
    const [newEduFaculties, setNewEduFaculties] = useState([]);
    const [eduFacultyValue, setEduFacultyValue] = useState(null);
    const [facultyError, setFacultyError] = useState(null);
    const [courseError, setCourseError] = useState(null);
    const [countryError, setCountryError] = useState(null);
    const [postRoleError, setRoleError] = useState(null);
    const [getCountries] = useLazyQuery((GET_COUNTRY_STATE_CITY));
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState(null);
    const [getPostRole] = useLazyQuery(GET_POSTROLE);
    const [postRole, setPostRole] = useState(null);
    const [postRoles, setPostRoles] = useState([]);
    const [startDate, setStartDate] = useState("");
    const handleStartChangeDate = e => { setStartDate(e.target.value) }
    const [endDate, setEndDate] = useState("");
    const handleEndChangeDate = e => { setEndDate(e.target.value) }

    const [description, setDescription] = useState('');
    useEffect(() => {
        setEduCourses([]);
        setEduFaculties([]);
        getEduFacultiesName({
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
            //fetchPolicy: "network-only",
        }).then((data) => {
            const eduFacultiesData = data.data.educationFaculties.data.map((item) => {
                return {
                    id: item.id,
                    name: item.attributes.name,
                };
            });

            setEduFaculties(eduFacultiesData);

        });
        getEduCoursesName({
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
            //fetchPolicy: "network-only",
        }).then((data) => {
            const eduCoursesData = data.data.educationCourses.data.map((item) => {
                return {
                    id: item.id,
                    name: item.attributes.name,
                };
            });

            setEduCourses(eduCoursesData);

        });
        const fetchData = async () => {
            try {
                const countriesData = await getCountries({
                    fetchPolicy: "cache-first", variables: {
                        pagination: {
                            limit: 1000,
                        },
                    },
                });
                setCountries(countriesData?.data?.countries?.data?.map((item) => {
                    return {
                        id: item.id,
                        name: item.attributes?.name,
                        cities: item.attributes?.cities?.data?.map((item) => {
                            return {
                                id: item.id,
                                name: item.attributes?.name,
                            };
                        })
                    };
                }));
                const postRoleData = await getPostRole({
                    variables: {
                        pagination: {
                            limit: 300,
                        },
                    },
                    context: {
                        headers: {
                            authorization: `Bearer ${jwt}`,
                        },
                    },
                    fetchPolicy: "cache-first",
                })
                setPostRoles(postRoleData.data.postRoles.data);
            } catch (error) {
                toast.error("Ocorreu um erro ao carregar a lista de Países");
            }
        };

        fetchData();
        if (step?.includes("Edit")) {
            const foundEducation = ObjectList.find((object) => object.id === stepID);
            setEduFacultyValue({ id: foundEducation.facultyID, name: foundEducation.faculty })
            setEduCourseValue({ id: foundEducation.courseID, name: foundEducation.course })
            setCountry({ id: foundEducation.countryID, name: foundEducation.country })
            setDescription(foundEducation.description)
            setStartDate(foundEducation.startDate)
            setEndDate(foundEducation.endDate)
        }
    }, []);
    const handleInputChange = (event, setVariable) => {
        setVariable(event.target.value);
    };
    const handleAdd = () => {
        if (eduFacultyValue === null) {
            setFacultyError(i18n.t('collaboratorStep.error.facultySelect', { lng: selectedLanguage }));
        }
        if (country === null) {
            setCountryError(i18n.t('collaboratorStep.error.countrySelect', { lng: selectedLanguage }));
        }
        if (eduCourseValue === null) {
            setCourseError(i18n.t('collaboratorStep.error.courseSelect', { lng: selectedLanguage }));
        }
        else {
            new_education({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    data: {
                        faculty: eduFacultyValue.id,
                        course: eduCourseValue.id,
                        description: description,
                        startDate: startDate,
                        endDate: endDate,
                        country: country.id,
                        collaborator: collaboratorID,
                    },
                },
            }).then((data) => {
                const newEducation = data.data.createEducation.data
                const updatedData = {
                    id: newEducation.id,
                    description: newEducation.attributes?.description,
                    startDate: newEducation.attributes?.startDate,
                    endDate: newEducation.attributes?.endDate,
                    course: newEducation.attributes?.course?.data?.attributes?.name,
                    courseID: newEducation.attributes?.course?.data?.id,
                    faculty: newEducation.attributes?.faculty?.data?.attributes?.name,
                    facultyID: newEducation.attributes?.faculty?.data?.id,
                    country: newEducation.attributes?.country?.data?.attributes?.name,
                    countryID: newEducation.attributes?.country?.data?.id
                }
                const updatedMainData = collaborators.map(obj => {
                    if (obj.id === collaborator.id) {
                        return { ...obj, educations: [...obj.educations, updatedData] }; // Add the updatedData value to the expertise array
                    }
                    return obj; // Keep the original object
                });
                const newList = [...ObjectList, updatedData]
                setObjectList(newList)
                setCollaborators(updatedMainData)
                handleClose()
                toast.success("Education Done!!")
            })


        }

    };
    const handleEdit = () => {
        if (eduFacultyValue === null) {
            setFacultyError(i18n.t('collaboratorStep.error.facultySelect', { lng: selectedLanguage }));
        }
        if (country === null) {
            setCountryError(i18n.t('collaboratorStep.error.countrySelect', { lng: selectedLanguage }));
        }
        if (eduCourseValue === null) {
            setCourseError(i18n.t('collaboratorStep.error.courseSelect', { lng: selectedLanguage }));
        }
        else {
            update_edu({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: stepID,
                    data: {
                        description: description,
                        startDate: startDate,
                        endDate: endDate,
                        faculty: eduFacultyValue.id,
                        course: eduCourseValue.id,
                        country: country.id,
                        collaborator: collaboratorID,
                    },
                },
            }).then((data) => {

                const foundExpertise =
                {
                    id: stepID,
                    description: description,
                    startDate: startDate,
                    endDate: endDate,
                    faculty: eduFacultyValue.name,
                    facultyID: eduFacultyValue.id,
                    course: eduCourseValue.name,
                    courseID: eduCourseValue.id,
                    country: country.name,
                    countryID: country.id
                }
                const foundExpertiseIndex = ObjectList.findIndex(expertise => expertise.id === stepID);

                if (foundExpertiseIndex !== -1) {
                    ObjectList[foundExpertiseIndex] = {
                        ...foundExpertise,
                    };
                }
                handleClose()
                setEduFacultyValue(null)
                setEduCourseValue(null)
                setStartDate(null)
                setEndDate(null)
                setDescription("")
                toast.success("Education Updated!!")
            })


        }

    };
    const handleCreate = () => {
        if (eduFacultyValue === null) {
            setFacultyError(i18n.t('collaboratorStep.error.facultySelect', { lng: selectedLanguage }));
        }
        if (country === null) {
            setCountryError(i18n.t('collaboratorStep.error.countrySelect', { lng: selectedLanguage }));
        }
        if (eduCourseValue === null) {
            setCourseError(i18n.t('collaboratorStep.error.courseSelect', { lng: selectedLanguage }));
        }
        else {
            const newEducationItem = {
                faculty: eduFacultyValue.name,
                facultyID: eduFacultyValue.id,
                course: eduCourseValue.name,
                courseID: eduCourseValue.id,
                description: description,
                startDate: startDate,
                endDate: endDate,
                countryName: country.name,
                countryID: country.id
            };
            setEducation(prevState => [...prevState, newEducationItem]);
            setIsGridVisible(false);

        }

    };



    return (


        <>

            <Grid container item xs={12} spacing={3} id="expGrid">
                <Grid item xs={12} md={6}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.nameFaculty', { lng: selectedLanguage })}</InputLabel>
                    <Autocomplete
                        value={eduFacultyValue}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                const filteredEduFaculty = eduFaculties.filter(item =>
                                    item.name?.toLowerCase() === newValue?.toLowerCase()
                                );

                                if (filteredEduFaculty?.length > 0) {
                                    setEduFacultyValue({
                                        name: filteredEduFaculty[0].name,
                                        id: filteredEduFaculty[0].id
                                    });
                                } else {
                                    new_eduFaculty({
                                        context: {
                                            headers: {
                                                authorization: `Bearer ${jwt}`,
                                            },
                                        },
                                        //fetchPolicy: "network-only",
                                        variables: {
                                            data: {
                                                name: newValue.inputValue
                                            },
                                        },
                                    }).then((data) => {
                                        const eduFacultyId = data.data.createEducationFaculty.data.id;
                                        const eduFacultyName = data.data.createEducationFaculty.data.attributes.name;

                                        setEduFacultyValue({
                                            name: eduFacultyName, id: eduFacultyId
                                        });
                                        const newOption = { name: eduFacultyName, id: eduFacultyId }

                                        setNewEduFaculties([...newEduFaculties, newOption]);
                                    })
                                }

                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                new_eduFaculty({
                                    context: {
                                        headers: {
                                            authorization: `Bearer ${jwt}`,
                                        },
                                    },
                                    //fetchPolicy: "network-only",
                                    variables: {
                                        data: {
                                            name: newValue.inputValue
                                        },
                                    },
                                }).then((data) => {
                                    const eduFacultyId = data.data.createEducationFaculty.data.id;
                                    const eduFacultyName = data.data.createEducationFaculty.data.attributes.name;

                                    setEduFacultyValue({
                                        name: eduFacultyName, id: eduFacultyId
                                    });
                                    const newOption = { name: eduFacultyName, id: eduFacultyId }

                                    setNewEduFaculties([...newEduFaculties, newOption]);
                                })
                            } else {
                                setEduFacultyValue(newValue);
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.name);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    name: `Add "${inputValue}"`,
                                });
                            }

                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="eduFaculty"
                        options={[...eduFaculties, ...newEduFaculties]}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.name;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        size="small"
                        freeSolo
                        renderInput={(params) => (
                            <TextField {...params} />
                        )}
                    />
                    {facultyError && <Typography
                        variant="body2"
                        color="gray"
                        style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                        {facultyError}
                    </Typography>}
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.course', { lng: selectedLanguage })}</InputLabel>
                    <Autocomplete
                        value={eduCourseValue}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                const filteredExpEntity = eduCourses.filter(item =>
                                    item.name?.toLowerCase() === newValue?.toLowerCase()
                                );

                                if (filteredExpEntity?.length > 0) {
                                    setEduCourseValue({
                                        name: filteredExpEntity[0].name,
                                        id: filteredExpEntity[0].id
                                    });
                                } else {
                                    new_eduCourse({
                                        context: {
                                            headers: {
                                                authorization: `Bearer ${jwt}`,
                                            },
                                        },
                                        // fetchPolicy: "network-only",
                                        variables: {
                                            data: {
                                                name: newValue.inputValue
                                            },
                                        },
                                    }).then((data) => {
                                        const expEntityId = data.data.createEducationCourse.data.id;
                                        const expEntityName = data.data.createEducationCourse.data.attributes.name;

                                        setEduCourseValue({
                                            name: expEntityName, id: expEntityId
                                        });
                                        const newOption = { name: expEntityName, id: expEntityId }

                                        setNewEduCourses([...newEduFaculties, newOption]);
                                    })
                                }

                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                new_eduCourse({
                                    context: {
                                        headers: {
                                            authorization: `Bearer ${jwt}`,
                                        },
                                    },
                                    // fetchPolicy: "network-only",
                                    variables: {
                                        data: {
                                            name: newValue.inputValue
                                        },
                                    },
                                }).then((data) => {
                                    const expEntityId = data.data.createEducationCourse.data.id;
                                    const expEntityName = data.data.createEducationCourse.data.attributes.name;

                                    setEduCourseValue({
                                        name: expEntityName, id: expEntityId
                                    });
                                    const newOption = { name: expEntityName, id: expEntityId }

                                    setNewEduCourses([...newEduCourses, newOption]);
                                })
                            } else {
                                setEduCourseValue(newValue);
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.name);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    name: `Add "${inputValue}"`,
                                });
                            }

                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="expEntity"
                        options={[...eduCourses, ...newEduCourses]}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.name;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        size="small"
                        freeSolo
                        renderInput={(params) => (
                            <TextField {...params} />
                        )}
                    />{courseError && <Typography
                        variant="body2"
                        color="gray"
                        style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                        {courseError}
                    </Typography>}
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel id="select-countries">{i18n.t('collaboratorStep.collaborator.country', { lng: selectedLanguage })}</InputLabel>
                    <FormControl fullWidth>
                        <Autocomplete
                            size="small"
                            options={countries}
                            value={country}
                            getOptionLabel={(country) => country.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                                setCountry(newValue)
                                setCountryError(null);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Ex: Cabo Verde"
                                    required
                                />
                            )}
                        />
                        {countryError && <Typography
                            variant="body2"
                            color="gray"
                            style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                            {countryError}
                        </Typography>}
                    </FormControl>

                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.description', { lng: selectedLanguage })}</InputLabel>
                    <TextField size="small"
                        required
                        autoFocus
                        multiline
                        type="text"
                        value={description}
                        onChange={(event) => handleInputChange(event, setDescription)}
                        fullWidth
                        name="description"
                        id="description"
                        placeholder={i18n.t('collaboratorStep.collaborator.descriptionBody', { lng: selectedLanguage })}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.start', { lng: selectedLanguage })}</InputLabel>
                    <TextField
                        required
                        fullWidth
                        type="date"
                        value={startDate}
                        onChange={handleStartChangeDate}
                        name="start_date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.end', { lng: selectedLanguage })}</InputLabel>
                    <TextField
                        required
                        fullWidth
                        type="date"
                        value={endDate}
                        onChange={handleEndChangeDate}
                        name="start_date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

            </Grid>
            <Grid container spacing={2} marginTop={1}>
                <Grid container item xs={12} spacing={3} marginLeft={0.5} >
                    {createStep &&
                        <Grid item>
                            <Button
                                type="button"
                                color="primary"
                                onClick={handleCreate}
                            >
                               {i18n.t('collaboratorStep.button.education', { lng: selectedLanguage })}
                            </Button>
                        </Grid>
                    }
                </Grid>
                <Grid container spacing={3} item xs={12} display="flex" justifyContent="space-around" alignItems="center">
                    {!createStep  && <Grid item>
                        <Button variant="outlined" size="small" onClick={handleClose}>{i18n.t('collaboratorStep.button.cancel', { lng: selectedLanguage })}</Button>
                    </Grid>}
                    <Grid item>

                        {editStep && <Button
                            variant="contained"
                            size="small"
                            onClick={handleEdit}
                        >{i18n.t('collaboratorStep.button.edit', { lng: selectedLanguage })}</Button>}
                        {addStep && <Button
                            variant="contained"
                            size="small"
                            onClick={handleAdd}
                        >{i18n.t('collaboratorStep.button.create', { lng: selectedLanguage })}</Button>}
                    </Grid>
                </Grid>
            </Grid>

        </>
    );

};

export default EducationStep;