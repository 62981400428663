import { Grid, Button } from "@mui/material";
import { GET_COLLABORATORS, GET_INDICATORS, EVAL_PERIODS, EVAL_PERIODS_BY_ID, EVAL_SCORES_COLLABORATOR, EVAL_OPEN_ANSWERS, EVAL_SCORES_COMPANY } from "../query";
import { useState, useEffect, useContext } from "react";
import { useLazyQuery } from "@apollo/client";
import { AuthContext } from "../../../contexts/auth-context";
import AvaliarList from "./AvaliarList";
import Avaliar from "../Rating_View/Avaliar";
import Pathway from "../../../components/TopBar/pathway";
import { UserRoleContext } from "../../../contexts/userRole-context";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { GET_COLLABORATOR_BY_ID } from "../../Collaborator/Collaborator_View_Page/query.gql";

const RatingsMain = () => {
  const { collabId } = useParams();

  const jwt = localStorage.getItem("jwtToken");
  const [getCollaborators] = useLazyQuery(GET_COLLABORATORS)
  const { user } = useContext(AuthContext);
  const [getEvals] = useLazyQuery(EVAL_PERIODS_BY_ID)
  const [getEvals_] = useLazyQuery(EVAL_PERIODS)
  const [getCollab] = useLazyQuery(GET_COLLABORATOR_BY_ID)
  //const [eval_ScoresbyCollabs] = useLazyQuery(EVAL_SCORES_COLLABORATOR)
  // const [openAns_] = useLazyQuery(EVAL_OPEN_ANSWERS)
  const [i_, setI_] = useState(null)
  const [avaliars, setAvaliars] = useState([])
  const [viewCollab, setViewCollab] = useState(null)
  const [pathView, setPathView] = useState('')
  const [indicators, setIndicators] = useState([]);
  const [filteredList, setFilteredList] = useState("");
  const [eval_period, setEval_period] = useState([])
  const [answer_Id, set_Answer_Id] = useState([])
  const [answer_, set_answer_] = useState([])
  const [score_Id, set_Score_Id] = useState([])
  const [question_Id, set_Question_Id] = useState([])
  const [company_id, setCompany_id] = useState(null)
  const [openAnsIdx, setOpenAnsIdx] = useState()
  const [step, setStep] = useState(0);
  const [collabID, setCollabID] = useState(null)
  const [btnState, setBtnState] = useState(false)
  const [showEvals, setShowEvals] = useState(null);
  const [companyEditEval, setCompanyEditEval] = useState(null);
  const [scoreFilter, setScoreFilter] = useState(null)
  const { uRoleContext } = useContext(UserRoleContext);
  const [adminViews, setAdminViews] = useState([])
  const companyId = 1238;
  const navigate = useNavigate()



  useEffect(() => {


    getCollab({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      variables: {
        id: collabId

      },
      //fetchPolicy: "no-cache",
    }).then((data) => {
     
      // Acessando a estrutura correta de dados
      const collaboratorData = data?.data?.collaborator?.data ? [data.data.collaborator.data] : [];

      const mappedData = collaboratorData.map((item) => {
        return {
          id: item.id,
          userID: item.attributes?.users_permissions_user?.data?.id,
          name: item.attributes?.name,
          email: item.attributes?.email,
          phone: item.attributes?.phone,
          address: item.attributes?.address,
          web: item.attributes?.web,
          intro: item.attributes?.intro,
          country: item.attributes?.country?.data?.attributes?.name,
          countryID: item.attributes?.country?.data?.id,
          city: item.attributes?.city?.data?.attributes?.name,
          cityID: item.attributes?.city?.data?.id,
          postRole: item.attributes?.postRole?.data?.attributes?.postRole,
          postRoleID: item.attributes?.postRole?.data?.id,
          postRoleDescription: item.attributes?.postRole?.data?.attributes?.description,
          rating: item.attributes?.rating,
          companies: item.attributes?.companies?.data?.map((company) => {
            return {
              companyID: company.id,
              company: company.attributes?.name,
              userID: company.attributes?.users_permissions_user?.data?.id,
              email: company?.attributes?.email,
            }
          }),
          evaluations: item.attributes?.evaluations?.data?.map((evals) => {
            return {
              eval_id: evals.id,
              companies: evals.attributes?.companies,
              comment: evals.attributes?.Comment,
              star: evals.attributes?.star,
            }
          }),
          isActive: item.attributes?.isActive,
          avatar: item.attributes?.image?.data?.attributes?.url,
          avatarID: item.attributes?.image?.data?.id,
          profiles: item.attributes?.profiles?.data?.map((profiles) => {
            return {
              id: profiles.id,
              urlProfile: profiles.attributes?.urlProfile,
              description: profiles.attributes?.description,
              platform: profiles.attributes?.platform?.data?.attributes?.name,
              platformID: profiles.attributes?.platform?.data?.id,
              icon: profiles.attributes?.platform?.data?.attributes?.icon?.data?.attributes?.url
            };
          }),
          clientID: item.attributes?.client?.data?.id,
          client: item.attributes?.client?.data?.attributes?.companies?.data?.map((client) => {
            return {
              id: client.id,
              name: client.attributes?.name,
              image: client.attributes?.image?.data?.attributes?.url,
            };
          }),
          portfolios: item.attributes?.portfolios?.data?.map((portfolios) => {
            return {
              id: portfolios.id,
              name: portfolios.attributes?.name,
              description: portfolios.attributes?.description,
              image: portfolios.attributes?.image?.data?.attributes?.url,
              imageID: portfolios.attributes?.image?.data?.id
            };
          }),
          awards: item.attributes?.awards?.data?.map((profiles) => {
            return {
              id: profiles.id,
              name: profiles.attributes?.name,
              description: profiles.attributes?.description
            };
          }),
          skills: item.attributes?.skills?.data?.map((profiles) => {
            return {
              id: profiles.id,
              percentual: profiles.attributes?.percentual,
              name: profiles.attributes?.itTool?.data?.attributes?.name,
              itToolID: profiles.attributes?.itTool?.data?.id
            };
          }),
          expertise: item.attributes?.expertise?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              expertise: profiles.attributes?.expertise?.data?.attributes?.name,
              expertiseID: profiles.attributes?.expertise?.data?.id
            };
          }),
          educations: item.attributes?.educations?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              startDate: profiles.attributes?.startDate,
              endDate: profiles.attributes?.endDate,
              course: profiles.attributes?.course?.data?.attributes?.name,
              courseID: profiles.attributes?.course?.data?.id,
              faculty: profiles.attributes?.faculty?.data?.attributes?.name,
              facultyID: profiles.attributes?.faculty?.data?.id,
              country: profiles.attributes?.country?.data?.attributes?.name,
              countryID: profiles.attributes?.country?.data?.id
            };
          }),
          experiences: item.attributes?.experiences?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              startDate: profiles.attributes?.startDate,
              endDate: profiles.attributes?.endDate,
              postRole: profiles.attributes?.postRole?.data?.attributes?.postRole,
              postRoleDescription: profiles.attributes?.postRole?.data?.attributes?.description,
              postRoleID: profiles.attributes?.postRole?.data?.id,
              expEntity: profiles.attributes?.expEntity?.data?.attributes?.name,
              expEntityID: profiles.attributes?.expEntity?.data?.id,
              country: profiles.attributes?.country?.data?.attributes?.name,
              countryID: profiles.attributes?.country?.data?.id
            };
          }),
        };
      });

      // Ordenar os dados
      const sortedData = mappedData?.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });

      setViewCollab(sortedData[0]);
      setShowEvals(true)

      const evalPeriod = adminViews?.find(ep =>
        ep.attributes.collaborators.data.some(collab => collab.id === collabId)
      );
      
      alert(evalPeriod.id)

      if (evalPeriod.id) {
        setI_(evalPeriod.id)
        setBtnState(true)
      } else {

      }

    }).catch((error) => {

      console.log("deu erro ao pegar colaborador pelo id", error);




    })
  }, [])

  const handleReturn = () => {
    setViewCollab(null)
    navigate(`/company/${companyId}/collaborators`)
  };
  const listCollaborator = viewCollab === null
  const returnButton = !listCollaborator && viewCollab && (<Button color="primary" onClick={handleReturn}>Return</Button>);

  /*  useEffect(() => {
     openAns_({
       context: {
         headers: {
           authorization: `Bearer ${jwt}`,
         },
       },
       //fetchPolicy: "no-cache",
     }).then((data) => {
       setOpenAnsIdx(data.data.evaluationAnswerOpens.data)
 
     })
   }, [openAns_]) */

  useEffect(async () => {
    await getCollaborators({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      //fetchPolicy: "no-cache",
    }).then((data) => {


      const collaboratorData = data?.data?.collaborators?.data?.map((item) => {

        return {
          id: item.id,
          userID: item.attributes?.users_permissions_user?.data?.id,
          name: item.attributes?.name,
          email: item.attributes?.email,
          phone: item.attributes?.phone,
          address: item.attributes?.address,
          web: item.attributes?.web,
          intro: item.attributes?.intro,
          country: item.attributes?.country?.data?.attributes?.name,
          countryID: item.attributes?.country?.data?.id,
          city: item.attributes?.city?.data?.attributes?.name,
          cityID: item.attributes?.city?.data?.id,
          postRole: item.attributes?.postRole?.data?.attributes?.postRole,
          postRoleID: item.attributes?.postRole?.data?.id,
          postRoleDescription: item.attributes?.postRole?.data?.attributes?.description,
          rating: item.attributes?.rating,

          /*  evaluation_period: item.attributes?.evaluation_period?.data?.map((evals) => {
             return {
               id: evals.id,
               start: evals.attributes?.start,
               end: evals.attributes?.start,
               description: evals.attributes?.start
                }
 
           }), */

          companies: item.attributes?.companies?.data?.map((company) => {
            return {
              companyID: company.id,
              company: company.attributes?.name,
              userID: company.attributes?.users_permissions_user?.data?.id,
              evalsData: company.attributes?.evaluation_period?.data,
              email: company?.attributes?.email,
              // avatar: item.attributes?.image?.data?.attributes?.url,
            }

          }),

          evaluations: item.attributes?.evaluations?.data?.map((evals) => {
            return {
              eval_id: evals.id,
              companies: evals.attributes?.companies,
              comment: evals.attributes?.Comment,
              star: evals.attributes?.star,
            }

          }),
          isActive: item.attributes?.isActive,
          avatar: item.attributes?.image?.data?.attributes?.url,
          avatarID: item.attributes?.image?.data?.id,

          profiles: item.attributes?.profiles?.data?.map((profiles) => {
            return {
              id: profiles.id,
              urlProfile: profiles.attributes?.urlProfile,
              description: profiles.attributes?.description,
              platform: profiles.attributes?.platform?.data?.attributes?.name,
              platformID: profiles.attributes?.platform?.data?.id,
              icon: profiles.attributes?.platform?.data?.attributes?.icon?.data?.attributes?.url

            };
          }),
          clientID: item.attributes?.client?.data?.id,
          client: item.attributes?.client?.data?.attributes?.companies?.data?.map((client) => {
            /*    const matchingScores = item.attributes?.evaluation_scores?.data?.filter((score) => score.attributes?.company?.data?.id === client.id);
               const rateValue = matchingScores.flatMap((matchingScore) =>
                 matchingScore?.attributes?.evaluation_answer_closeds?.data.map((score) => score.attributes.rate)
               );
               const rateLength = rateValue?.length;
               const rateTotal = rateValue.reduce((accumulator, currentScore) => accumulator + currentScore, 0);
    */
            return {
              id: client.id,
              name: client.attributes?.name,
              image: client.attributes?.image?.data?.attributes?.url,
              /*          rateTotal: rateTotal,
                       rateLength: rateLength,
                       rateValue: rateValue, */
            };
          }),


          portfolios: item.attributes?.portfolios?.data?.map((portfolios) => {
            return {
              id: portfolios.id,
              name: portfolios.attributes?.name,
              description: portfolios.attributes?.description,
              image: portfolios.attributes?.image?.data?.attributes?.url,
              imageID: portfolios.attributes?.image?.data?.id
            };
          }),
          awards: item.attributes?.awards?.data?.map((profiles) => {
            return {
              id: profiles.id,
              name: profiles.attributes?.name,
              description: profiles.attributes?.description
            };
          }),
          skills: item.attributes?.skills?.data?.map((profiles) => {
            return {
              id: profiles.id,
              percentual: profiles.attributes?.percentual,
              name: profiles.attributes?.itTool?.data?.attributes?.name,
              itToolID: profiles.attributes?.itTool?.data?.id
            };
          }),
          expertise: item.attributes?.expertise?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              expertise: profiles.attributes?.expertise?.data?.attributes?.name,
              expertiseID: profiles.attributes?.expertise?.data?.id
            };
          }),
          educations: item.attributes?.educations?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              startDate: profiles.attributes?.startDate,
              endDate: profiles.attributes?.endDate,
              course: profiles.attributes?.course?.data?.attributes?.name,
              courseID: profiles.attributes?.course?.data?.id,
              faculty: profiles.attributes?.faculty?.data?.attributes?.name,
              facultyID: profiles.attributes?.faculty?.data?.id,
              country: profiles.attributes?.country?.data?.attributes?.name,
              countryID: profiles.attributes?.country?.data?.id
            };
          }),
          experiences: item.attributes?.experiences?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              startDate: profiles.attributes?.startDate,
              endDate: profiles.attributes?.endDate,
              postRole: profiles.attributes?.postRole?.data?.attributes?.postRole,
              postRoleDescription: profiles.attributes?.postRole?.data?.attributes?.description,
              postRoleID: profiles.attributes?.postRole?.data?.id,
              expEntity: profiles.attributes?.expEntity?.data?.attributes?.name,
              expEntityID: profiles.attributes?.expEntity?.data?.id,
              country: profiles.attributes?.country?.data?.attributes?.name,
              countryID: profiles.attributes?.country?.data?.id
            };
          }),

        };
      });
      console.log("colaborador dados", collaboratorData);

      const sortedData = collaboratorData?.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      setAvaliars(sortedData);
    }).catch((error) => {
      console.log("erro", error);

    })
  }, [getCollaborators]);

  /*   useEffect(() => {
      if (viewCollab) {
        setPathView(` / Collaborator`)
      } else {
        setPathView(``)
      }
    }, []) */


  console.log("viewcollab", viewCollab);

  /* const [getIndicators, { data }] = useLazyQuery(GET_INDICATORS, {
    context: {
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getIndicators();
  }, [getIndicators]); */

  /*  useEffect(() => {
     if (data) {
       const { indicators } = data;
 
       setIndicators(indicators.data);
     }
   }, [data]); */

  const filteredSearched = indicators.filter((item) => {
    return item.attributes.indicator.toLowerCase().includes(filteredList.toLowerCase())
  });



  useEffect(() => {
    //compare first with user id to extract companyid

    console.log("avaliars", avaliars);
    console.log("user?.id", user?.id);
    set_Score_Id([])
    avaliars?.map((tx) => {
      return tx?.companies?.map((ix) => {
        if (tx?.userID  === Number(ix?.userID)) {

          return setCompany_id(ix.companyID)

        }
      })
    }
    )



    if (company_id || viewCollab) {
        console.log("veio");
        
      get_Eval()
    }
    if (uRoleContext === 'administrator') {
      get_Eval_()
    }
    if (company_id && viewCollab) {
      //eval_score_by_id()
      collab_score_by_id()

    }

  }, [avaliars, company_id, user?.id, viewCollab])

  const get_Eval = async () => {
    console.log("company_id", company_id);


    await getEvals({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      variables: {
        id: company_id,
        filters: {
          isActive: {
            eq: true,
          },
          company: {
            id: {
              eq: company_id,
            },
          },
        },
      },

    }).then((data) => {

        console.log("perido de avaliacao", data);
      //  const duplicatCollabs =item?.attributes?.collaborators?.data.filter((item, index) => eval_item?.indexOf(item.map((xx)=> xx.id )) === index)
      const evalData = data?.data?.evaluationPeriods?.data?.map((item) => {
        return {
          id: item?.id,
          collaborators: item?.attributes?.collaborators?.data,
          company: item?.attributes?.company?.data
        };
      });
      setEval_period(evalData)

    });


  };
  const get_Eval_ = async () => {
    await getEvals_({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },


    }).then((data) => {

      setAdminViews(data.data.evaluationPeriods.data)



    });
  };


  const collab_score_by_id = async () => {
    /*   console.log(collabID, "collabID");
  
      await eval_ScoresbyCollabs({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: {
          id: collabID,
          filters: {
            isActive: {
              eq: true,
            },
            collaborator: {
              id: {
                eq: collabID,
              },
            },
          },
        },
  
      }).then((data) => {
     
  
        const eval_data_1id = []
        const evalData_ = data?.data?.evaluationScores?.data.map((xy, index) => xy.id)
        const eval_data_1 = data?.data?.evaluationScores?.data.map((xy, idx) => {
          if (i_ === xy?.attributes?.evaluation_period.data.id) {
            xy?.attributes?.evaluation_answer_opens?.data.map((xx, i) => eval_data_1id.push(xx.id))
          }
        }
        )
  
        const evalData_2 = data?.data?.evaluationScores?.data.map((xy, i) =>
          xy?.attributes?.evaluation_answer_opens?.data.map((vx) => {
            return vx.attributes.evaluation_question_open.data.id
          }
  
          )
        )
        const evalData_3 = data?.data?.evaluationScores?.data.map((xy, i) => xy?.attributes?.evaluation_answer_opens?.data.map((vx) => {
          return vx.attributes.answer
        }
  
        )
        )
  
     
  
        set_answer_(evalData_3)
        set_Answer_Id(eval_data_1id)
        set_Score_Id(evalData_)
        set_Question_Id(evalData_2)
      }); */

  };
  const emptyQuestions_ = indicators.filter((tx) => tx.attributes.evaluation_question_opens.data?.length !== 0)
  //   console.log( adminViews )

  // alert(btnState)

  return (
    <>
      <Grid container >
        <Grid item xs={12}>
          <div className="pt-6 px-8">
          </div>
          {returnButton}
          <>
            {viewCollab ? (
              <Avaliar
                setAvaliars={setAvaliars}
                eval_period={eval_period}
                avaliars={avaliars}
                company_id={company_id}
                viewCollab={viewCollab}
                setViewCollab={setViewCollab}
                indicators={filteredSearched}
                setIndicators={setIndicators}
                openAnsIdx={openAnsIdx}
                step={step}
                setStep={setStep}
                btnState={btnState}
                setBtnState={setBtnState}
                showEvals={showEvals}
                setShowEvals={setShowEvals}
                companyEditEval={companyEditEval}
                setCompanyEditEval={setCompanyEditEval}
                answer_Id={answer_Id}
                score_Id={score_Id}
                question_Id={question_Id}
                set_Answer_Id={set_Answer_Id}
                set_Score_Id={set_Score_Id}
                set_Question_Id={set_Question_Id}
                answer_={answer_}
                set_answer_={set_answer_}
                scoreFilter={scoreFilter}
                setScoreFilter={setScoreFilter}
                i_={i_}
                setI_={setI_}
              />
            ) : (
              <p>Carregando dados do colaborador...</p>
            )}
          </>

        </Grid>
      </Grid>

    </>
  );
}

export default RatingsMain;