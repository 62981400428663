// CollaboratorToCompany.jsx
import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Autocomplete,
  Typography,
  Box,
  TextField,
  Grid,
  Switch,
  FormControlLabel
} from '@mui/material';
import Stack from '@mui/material/Stack';

import { useMutation, useQuery } from '@apollo/client';
import { CREATE_EMPLOYMENT_COLLAB, CREATE_AVAL_PERIOD, ASSOC_CTOC, TESTE_2, UPDATE_COMPANY_C, DELETE_EMPLOYMENT_BY_COLLABORATOR, DELETE_NOTIFICATION_MUTATION, UPDATE_EMPLOYMENT_TO_NULL, ASSOC_COLLAB_TO_DIST, ASSOC_COL_TO_DIST } from './mutation.gql';
import { GET_COLLABORATORS, GET_COMP_GET } from './query.gql';
import { GET_COMPANIES, GET_USER_DATA } from "../../CompanyPage/Company_Creation_Page/query.gql";
import { toast } from 'react-toastify';
import jwtDecode from "jwt-decode";
import { useLazyQuery } from "@apollo/client";
import { GET_COLLABORATOR_BY_ID_TO_COMPANY } from '../Collaborator_View_Page/query.gql';
import { StylesInput } from '../../../theme-alt';
import { useEmploymentDistributorId, useGetAssocDistCollab, useGetDistByUserId } from '../../CompanyPage/Company_Creation_Page/helpers';
import { DistributorService } from '../../../services/distributors';
import { Money, Warning } from '@mui/icons-material';

const CollaboratorToCompany = ({
  open,
  handleClose,
  idCollaborador,
  action,
  idCompany = null,
  notificationId = null,
  nameCollaborador = null,
  collabRole = null,
  isDistEmployer = false,
  paymentCollaborador = null,
}) => {

  const jwt = localStorage.getItem("jwtToken");
  const userRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  const { id: employeerID } = jwtDecode(jwt);

  const [isAlreadyAssociated, setIsAlreadyAssociated] = useState(false);
  const [loadingCheck, setLoadingCheck] = useState(true);
  const [currentCompanyId, setCurrentCompanyId] = useState("");
  const [currentOption, setCurrentOption] = useState(-1);
  const [salary, setSalary] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [updateEmploymentToNull] = useMutation(UPDATE_EMPLOYMENT_TO_NULL);

  const [isDistCollaborator, setIsDistCollaborator] = useState(false);

  const [assocToDist, setAssocToDist] = useState(false);
  const uIdDist = useGetDistByUserId();

  const { data: distData, loading: carregamento, fetchMore } = DistributorService.GetAll({
    userId,
    jwtToken: jwt,
  });


  // const { collaborators, distributors } = useGetAssocDistCollab();

  const { collaborators, distributors } = useEmploymentDistributorId();

  const handleAssocToDist = (target) => {
    setAssocToDist(target.checked);
  };

  useEffect(() => {
    if (paymentCollaborador) {
      setSalary(paymentCollaborador);
    }
  }, [paymentCollaborador]);

  useEffect(() => {
    if (open) {
      if (action == 0) {
        setIsAlreadyAssociated(true);
      }
      setLoadingCheck(false);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      if (userRole == 'distributor') {

        const collaborators = distData?.distributors?.data?.[0]?.attributes?.employmentcolabdistributors?.data;
        const isDistributor_Associate = Array.isArray(collaborators)
          ? collaborators.filter((item) => item?.attributes?.collaborators?.data?.[0]?.id === idCollaborador)
          : [];
        const isDistCollaborator = distData?.distributors?.data?.[0]?.id == isDistEmployer;

        if (isDistributor_Associate?.length > 0 || isDistCollaborator) {
          setAssocToDist(true);
          setIsDistCollaborator(true);
        } else {
          setAssocToDist(false);
          setIsDistCollaborator(false);
        }
      }
      console.log(`Associando colaborador com ID: ${idCollaborador}`);
    }
    const role = localStorage.getItem('userRole') ?? '';
    if (role == 'administrator' || role == 'distributor') {
      setIsAdmin(!isAdmin);
    }
  }, [idCollaborador, open, distData]);

  const [get_companies] = useLazyQuery(GET_COMP_GET);
  const [get_collaborator] = useLazyQuery(GET_COLLABORATOR_BY_ID_TO_COMPANY);
  const [assocCollabo] = useMutation(CREATE_EMPLOYMENT_COLLAB);
  // const [assocCollabToDist] = useMutation(ASSOC_COLLAB_TO_DIST);
  const [assocCollabToDist] = useMutation(ASSOC_COL_TO_DIST);
  const [initEvaluation] = useMutation(CREATE_AVAL_PERIOD);
  const [fireCollab] = useMutation(DELETE_EMPLOYMENT_BY_COLLABORATOR);
  const [delNotification] = useMutation(DELETE_NOTIFICATION_MUTATION);

  const loadCollabData = () => {
    get_companies({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      variables: {
        userId: employeerID.toString()
      },
    }).then((data) => {
      const lastId = data?.data?.companies?.data?.map(companyItem => {
        return companyItem.id;
      });
      setCurrentCompanyId(lastId.toString());
    });
  };

  console.log("CURREN NOT ID", notificationId);
  console.log(currentCompanyId);

  const proceedAssoc = async ({ companyId, salary, startDate, endDate, positionCollaborator }) => {
    setLoadingCheck(true);
    try {

      if (userRole == 'distributor' && !isDistCollaborator) {
        if (!salary) {
          toast.warning('Valor Pagamento obrigatório');
          return;
        }
        const currentIds = collaborators?.map((colab) => colab.id.toString()) || [];
        const currentDistIds = distributors?.map((dist) => dist.id.toString()) || [];

        const updatedIds = Array.from(new Set([...currentIds, idCollaborador.toString()]));
        const updatedDist = Array.from(new Set([...currentDistIds, uIdDist.toString()]));

        assocCollabToDist({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
          variables: {
            collaborators: [idCollaborador],
            distributors: [uIdDist],
            // collaborators: collaborators.map((c) => c.toString()).concat(idCollaborador.toString()),
            // distributors: distributors.map((d) => d.toString()).concat(uIdDist.toString()),
            payment: parseFloat(salary),
            status: true
          }
        })
          .then((res) => {
            toast.success('Operação feita com sucesso');
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          })
          .catch((err) => {
            console.log("O ERRO DIST COLL", err);
            toast.error('Algo deu errado ao tentar associar o colaborador');
          });

      } else {
        // Primeiro, associar o colaborador
        const assocResult = await assocCollabo({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
          variables: {
            companyId: [companyId.toString()],
            collaboratorId: [idCollaborador.toString()],
            position: collabRole ?? 'N/A',
            startDate: startDate,
            endDate: endDate,
            status: 'active',
            Salary: parseFloat(salary),
          },
        });

        // Verificar se o assocCollabo teve sucesso
        if (assocResult?.data) {
          // Criar o período de avaliação
          const evalResult = await initEvaluation({
            context: {
              headers: {
                authorization: `Bearer ${jwt}`,
              },
            },
            variables: {
              companies: [companyId.toString()],
              collaborators: [idCollaborador.toString()],
              start: startDate,
              end: endDate,
              description: 'Novo período com contratação',
              isActive: true,
            },
          });
          const resultado = await associarColaborador();

          if (resultado && evalResult?.data) {
            if (notificationId != null) {
              delNotification({
                context: {
                  headers: {
                    authorization: `Bearer ${jwt}`,
                  },
                },
                variables: {
                  id: notificationId.toString()
                },
              }).then((endResult) => {
                if (endResult) {
                  toast.success("Operação feita com Sucesso!");
                } else {
                  toast.warning("Não foi possivel actualizar o estado da notificação!!!");
                }
              })
            } else {
              toast.success("Colaborador contratado e avaliação inicial criada com sucesso!");
            }
            setTimeout(() => {
              window.location.reload();
            }, 150);
          } else {
            console.warn("Associação concluída, mas a criação da avaliação falhou.");
            toast.warning("Colaborador contratado, mas houve um problema ao criar a avaliação ou associando a empresa ao colaborador.");
          }
        } else {
          toast.error("Falha na associação do colaborador.");
          throw new Error("Falha na associação do colaborador.");
        }
      }
      handleClose();
    } catch (error) {
      console.error("Erro ao processar a associação:", error);
      toast.error("Erro ao tentar contratar o colaborador.");
    } finally {
      setLoadingCheck(false);
    }
  };



  const [updateCollaborator] = useMutation(UPDATE_COMPANY_C);

  const associarColaborador = async () => {


    setLoadingCheck(true);
    try {
      const { data } = await get_collaborator({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: {
          id: idCollaborador.toString(),
        },
      });

      const currentCompanies = data?.collaborator?.data?.attributes?.companies.data


      const newCompany = {
        id: currentOption || '',
      }

      const updatedCompanies = [
        ...new Set([...currentCompanies, newCompany].map(company => company.id)),
      ];


      await updateCollaborator({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: {
          id: idCollaborador,
          data: {
            companies: updatedCompanies,
          },
        },
      });


      //toast.success(`Company associada com sucesso ao colaborador!`);
      handleClose();
      return true
      /*   setTimeout(() => {
          window.location.reload();
        }, 1500); */
    } catch (error) {

      console.error("Erro ao associar company ao colaborador:", error);
      toast.error("Erro ao associar company ao colaborador.");
      return false
    } finally {
      setLoadingCheck(false);
    }
  };


  const desassociarColaborador = async () => {
    setLoadingCheck(true); // Inicia o carregamento
    try {
      const { data } = await get_collaborator({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: {
          id: idCollaborador,
        },
      });

      // Obtém a lista de companies existentes
      const currentCompanies = data?.data?.collaborator?.attributes?.companies?.data.map(comp => comp.id) || [];

      // Remove a company da lista
      const updatedCompanies = currentCompanies.filter(companyId => companyId !== currentCompanyId.toString());

      // Chama a mutação para atualizar o colaborador
      await updateCollaborator({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: {
          id: idCollaborador.toString(),
          data: {
            companies: updatedCompanies,
          },
        },
      });



      toast.success(`Company desassociada com sucesso do colaborador!`);
      handleClose();
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Erro ao desassociar company do colaborador:", error);
      toast.error("Erro ao desassociar company do colaborador.");
    } finally {
      setLoadingCheck(false); // Finaliza o carregamento
    }
  };


  const [companies, setCompanies] = useState([]);
  const [companiesItems, setCompaniesItems] = useState([]);

  const [getCompanys_, { loading, data }] = useLazyQuery(GET_COMPANIES, {
    context: {
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      const { companies } = data;
      setCompanies(companies.data);

      companies.data.map((items) => {
        //console.log("AAAAAAADDDDDDDDEUS", items.id);
      })
      // Processar os dados diretamente aqui
      const items = companies.data.map((item) => ({
        value: item?.id,
        label: item?.attributes?.name,
      }));
      setCompaniesItems(items);
    }
  }, [data]);

  const fireCollaborator = () => {
    if (idCollaborador) {
      handleUpdateEmployment(idCollaborador);
      // return;
      /*alert(idCollaborador)
      try {
        fireCollab({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
          variables: {
            id: idCollaborador.toString()
          },
        }).then((response) => {
          if (response) {
            console.log("RRRRRRRR", response);
            toast.success("Feito com successo");
            return;
            setTimeout(() => {
              window.location.reload();
            }, 150);
          } else {
            toast.error("Algo deu errado, tente mais tarde");
          }
        });
      } catch (error) {
        toast.error("Algo deu errado, tente mais tarde");
      }*/
    }
  };

  const handleUpdateEmployment = async (collaboratorId) => {
    try {
      const response = await updateEmploymentToNull({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: { collaboratorId },
      });
      if (notificationId != null) {
        delNotification({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
          variables: {
            id: notificationId.toString()
          },
        }).then((endResult) => {
          if (endResult) {
            toast.success("Feito com successo");
            //toast.success("Operação feita com Sucesso!");
          } else {
            toast.warning("Não foi possivel actualizar o estado da notificação!!!");
          }
        })
      } else {
        toast.success("Feito com sucesso!");
      }
      // console.log("Mutation Response:", response);
      setTimeout(() => {
        window.location.reload();
      }, 250);
    } catch (err) {
      console.error("Error updating employment:", err);
      toast.error("Algo deu errado, tente mais tarde");
    }
    handleClose();
  };

  useEffect(() => {
    getCompanys_();
    loadCollabData();
  }, []);

  useEffect(() => {
    if (idCompany) {
      setCurrentOption(idCompany);
    }
  }, [idCompany]);


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="associate-dialog-title"
      maxWidth="md"
    >
      <DialogTitle id="associate-dialog-title" style={{ textAlign: 'center' }}>
        {loadingCheck ? 'Verificando associação...' : (
          action == 0
            ?
            `Deseja desassociar este colaborador da sua empresa?`
            : userRole == 'distributor' && isDistCollaborator ? '' : userRole == 'distributor' && !isDistCollaborator ? 'Associar a minha Rede' : `Deseja mesmo associar este colaborador ${nameCollaborador != null ? nameCollaborador : ''} à sua empresa?`
        )}
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        {loadingCheck ? (
          <CircularProgress />
        ) : isAlreadyAssociated ? (
          <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={fireCollaborator}
                style={{ marginRight: '10px' }}
              // disabled={loadingAssoc}
              >
                Descontratar
                {/* {loadingAssoc ? 'Associando...' : 'Sim, Associar'}  */}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}></Grid>
              {
                userRole == 'distributor' && isDistCollaborator && !idCompany ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div className="flex border-b pb-10 mb-4 items-start w-full gap-2" style={{ alignItems: 'flex-start' }}>
                          <span className='block mt-5 pl-5'>
                            <Warning sx={{ color: "#F7931E" }} />
                          </span>
                          <span className='block mt-4 -mb-3 text-left'>Este collaborador já faz parte da sua rede distribuidora, veja mais detalhes na sua lista de colaboradores.</span>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ) :
                  userRole == 'administrator' || userRole == 'distributor' && isDistCollaborator && idCompany ? (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <span className='w-full block -mb-3 pl-5 text-left'>Associar Colaborador a Empresa</span>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <span className='flex my-3'></span>
                          {
                            idCompany != null ? (

                              <Autocomplete
                                options={companiesItems}
                                getOptionLabel={(option) => option.label}
                                value={companiesItems.find((item) => item.value === idCompany) || null}
                                onChange={(event, newValue) => {
                                  if (!idCompany) {
                                    setCurrentOption(newValue?.value);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Selecione uma empresa"
                                    variant="outlined"
                                    fullWidth
                                    sx={StylesInput}
                                    InputProps={{
                                      ...params.InputProps,
                                      readOnly: !!idCompany,
                                    }}
                                  />
                                )}
                                disabled={!!idCompany}
                              />
                            ) : (
                              <Autocomplete
                                disabled={assocToDist}
                                options={companiesItems}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, newValue) => {
                                  setCurrentOption(newValue?.value);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Selecione uma empresa"
                                    variant="outlined"
                                    fullWidth
                                    sx={StylesInput}
                                  />
                                )}
                              />
                            )
                          }

                          <span className='flex my-3'></span>

                          {/* Remuneração */}
                          <Grid item xs={12}>
                            <TextField
                              label="Remuneração"
                              type="number"
                              fullWidth
                              value={salary}
                              disabled={paymentCollaborador ? true : false}
                              onChange={(e) => setSalary(e.target.value)}
                              sx={StylesInput}
                            />
                          </Grid>

                          <span className='flex my-3'></span>

                          <Grid container spacing={2}>
                            <Grid item xs={12}></Grid>

                            {/* Outro Campo de Texto */}
                            <Grid item lg={6} xs={12}>
                              <TextField
                                label="Data Inicio"
                                type="date"
                                fullWidth
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                sx={StylesInput}
                              />
                            </Grid>

                            {/* Campo de Data */}
                            <Grid item lg={6} xs={12}>
                              <TextField
                                label="Data Fim"
                                type="date"
                                fullWidth
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                sx={StylesInput}
                              />
                            </Grid>
                          </Grid>

                        </Box>

                      </Grid>
                    </>
                  ) :
                    null
              }
              {
                userRole == 'distributor' && !isDistCollaborator && (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div className="flex border-b pb-10 items-start w-full gap-2" style={{ alignItems: 'flex-start' }}>
                          <span className='block mt-5 pl-5'>
                            <Warning sx={{ color: "#F7931E" }} />
                          </span>
                          <span className='block mt-4 -mb-3 text-left'>Este collaborador não faz parte da sua rede distribuidora, não será possível distribuir o mesmo para as demais empresas, deverás Associar ele a sua rede.</span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="flex items-start">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={!isDistCollaborator}
                              onChange={(e) => handleAssocToDist(e.target)}
                            />
                          }
                          label="Associar colaborador a minha rede"
                        />
                      </div>
                    </Grid>
                    {/* Remuneração */}
                    <Grid item xs={12}>
                      <div className="flex border-b pb-10 items-start w-full gap-2" style={{ alignItems: 'flex-start' }}>
                        <span className='block mt-4 -mb-5 text-left'>Definir novo valor de Pagamento para este Colaborador.</span>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Remuneração"
                        type="number"
                        fullWidth
                        onChange={(e) => setSalary(e.target.value)}
                        sx={StylesInput}
                      />
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}></Grid>
                  </>
                )
              }

              {
                userRole == 'distributor' && isDistCollaborator ? (
                  <>
                  </>
                ) : userRole == 'administrator' ? (
                  <>
                    {/* Remuneração */}
                    <Grid item xs={12}>
                      <TextField
                        label="Remuneração"
                        type="number"
                        fullWidth
                        onChange={(e) => setSalary(e.target.value)}
                        sx={StylesInput}
                      />
                    </Grid>

                    <Grid item xs={12}></Grid>

                    {/* Outro Campo de Texto */}
                    <Grid item lg={6} xs={12}>
                      <TextField
                        label="Data Inicio"
                        type="date"
                        fullWidth
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={StylesInput}
                      />
                    </Grid>

                    {/* Campo de Data */}
                    <Grid item lg={6} xs={12}>
                      <TextField
                        label="Data Fim"
                        type="date"
                        fullWidth
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={StylesInput}
                      />
                    </Grid>

                    <Grid item xs={12}></Grid>
                  </>
                ) : <></>
              }

              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>

              <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                {
                  userRole == 'distributor' && isDistCollaborator && !idCompany ? (
                    <></>
                  ) : (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          if (action === 1) {
                            if (userRole == 'distributor' && !isDistCollaborator) {
                              setTimeout(() => {
                                proceedAssoc({ companyId: currentOption, salary, endDate, endDate });
                              }, 100);
                            }
                            else if (currentOption && salary && startDate && endDate) {
                              setTimeout(() => {
                                proceedAssoc({
                                  companyId: currentOption,
                                  salary,
                                  startDate,
                                  endDate,
                                });
                              }, 100);
                            } else {
                              toast.warn('Preencha todos os campos obrigatórios!');
                            }
                            // associarColaborador();
                          } else {
                            // desassociarColaborador();
                            // alert(action)
                            // alert(currentOption && salary && startDate && endDate)
                          }
                        }}
                        style={{ marginRight: '10px' }}
                      // disabled={loadingAssoc}
                      >
                        Associar
                        {/* {loadingAssoc ? 'Associando...' : 'Sim, Associar'}  */}
                      </Button>
                    </Grid>
                  )
                }
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleClose}
                  >
                    {userRole == 'distributor' && isDistCollaborator && !idCompany ? 'Sair' : 'Cancelar'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>


            {/* <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (action == 1) {
                 associarColaborador(); 
                } else {
                 desassociarColaborador();
                } 
              }}
              style={{ marginRight: '10px' }}
            // disabled={loadingAssoc}
            >
              Sim, Associar
               {loadingAssoc ? 'Associando...' : 'Sim, Associar'} 
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            // disabled={loadingAssoc}
            >
              Cancelar
            </Button> */}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CollaboratorToCompany;
