import {
  Alert,
  AlertTitle,
  Autocomplete,
  Avatar,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { LoginContext } from "../../contexts/login-context";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { CREATE_COMPANY, LOGIN, CREATE_USER, CREATE_FIND_DEV } from "./mutation.gql";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../contexts/auth-context";
import { ToastContainer, toast } from "react-toastify";
import { Box } from "@mui/system";
import { GET_CITIES, GET_COUNTRY_STATE_CITY, GET_USERNAMES } from "./query.gql";
import { GET_EMPLOYEER_BY_EMAIL } from "./query.gql";
import i18n from "../../translate/i18n";
import { LanguageContext } from "../../translate/languages/LanguageContext";
import LoadingCircle from "../../components/Loading/loading_circle";
import { UserRoleContext } from "../../contexts/userRole-context";


const Register = () => {

  const filter = createFilterOptions();
  const [cE, setCE] = useState([]);
  const { selectedLanguage } = useContext(LanguageContext);
  const { setURoleContext } = useContext(UserRoleContext);
  const validationEmailForm = yup
    .object({
      password: yup
        .string()
        .min(6, "The Password must have more than 6 characters!")
        .required("Password is Mandatory"),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Password does not match!"),
      email: yup
        .string()
        .notOneOf(cE, "Email in use")
        .email("Insert a valid Email Address")
        .required("Email is Mandatory"),
      confirmEmail: yup
        .string()
        .oneOf([yup.ref("email"), null], "Email does not match!"),
      name: yup
        .string()
        .min(3, "The name need to have more than 3 caracters")
        .required("The Name is required"),
      branchOfActivity: yup
        .string()
        .min(2, "There's no activity named with a single character")
        .required("The branch of activity is required"),
      phone: yup
        .string()
        .min(7, "Phone must have more than 7 numbers")
        .required("Phone Number is required"),

    })
    .required();

  let navigate = useNavigate();
  const { loginData, setLoginData } = useContext(LoginContext);
  const context = useContext(AuthContext);
  const [getEmployeerByEmail] = useLazyQuery(GET_EMPLOYEER_BY_EMAIL);
  const [getUsernames] = useLazyQuery(GET_USERNAMES);
  const [openAlertOK, setOpenAlertOK] = useState(false)
  const [successMessage, setSuccessMessage] = useState("");
  const [severity, setSeverity] = useState('info')
  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState("");
  const [userError, setUserError] = useState(null);
  const [getCountries] = useLazyQuery((GET_COUNTRY_STATE_CITY));
  const [getCities] = useLazyQuery((GET_CITIES));


  const [register_login] = useMutation(CREATE_USER);
  const [created_user_login] = useMutation(LOGIN);
  const [register_company] = useMutation(CREATE_COMPANY);
  const [createFindDev] = useMutation(CREATE_FIND_DEV);

  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [countryError, setCountryError] = useState(null);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState(null);
  const [cityError, setCityError] = useState(null);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const countriesData = await getCountries({
          variables: {
            pagination: {
              limit: 300,
            },
          },
        });
        setCountries(countriesData?.data?.countries?.data?.map((item) => {
          return {
            id: item.id,
            name: item.attributes?.name,
            areaCode: item.attributes?.areaCode,
            cities: item.attributes?.cities?.data?.map((item) => {
              return {
                id: item.id,
                name: item.attributes?.name,
              };
            })
          };
        }));
        setCities(countriesData?.data?.countries?.data?.flatMap((country) => {
          return country.attributes?.cities?.data?.map((city) => {
            return {
              id: city.id,
              name: city.attributes?.name,
              country: country.attributes?.name
            };
          });
        }));

      } catch (error) {
        setSuccessMessage(i18n.t('login.error.countryFetch', { lng: selectedLanguage }));
        setSeverity("error")
        setOpenAlertOK(true)

      }
      try {
        const userData = await getUsernames({
          variables: {
            pagination: {
              limit: 1000,
            },
          },
        });
        setUsers(userData?.data?.usersPermissionsUsers?.data?.map((item) => {
          return {
            id: item.id,
            username: item.attributes?.username,
            name: item.attributes?.name
          };
        }));

      } catch (error) {
        setSuccessMessage(i18n.t('login.error.userFetch', { lng: selectedLanguage }));
        setSeverity("error")
        setOpenAlertOK(true)

      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (country === null) {
      setCity(null);
      setCountryCode(null);
    }
  }, [country]);



  const [step, setStep] = useState(0);

  const filteredCity = cities?.filter(item =>
    item.country?.toLowerCase().includes(country?.name?.toLowerCase())
  )

  const {
    trigger,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationEmailForm),
  });

  const toLogin = () => navigate("/login");
  const registerForm = async (dataForm) => {


    try {
      const data = await getEmployeerByEmail({
        variables: {
          filters: {
            email: {
              eq: dataForm.email,
            },
            blocked: {
              eq: false,
            },
          },
        },
        //fetchPolicy: "network-only",
      });

      if (data.data.usersPermissionsUsers.data?.length === 0) {
        const userData = await register_login({
          variables: {
            data: {
              name: dataForm.name,
              username: username,
              password: dataForm.password,
              email: dataForm.email,
              confirmed: true,
              role: 1,
              userRole: 2
            },
          },
        });

        //console.log("createCompany");
        //console.log(userData.data.createUsersPermissionsUser.data.id);
        const userID = userData.data.createUsersPermissionsUser.data.id;
        setURoleContext("company")
        const companyData = await register_company({
          variables: {
            data: {
              country: country.id,
              city: city.id,
              name: dataForm.name,
              email: dataForm.email,
              phone: countryCode + ' ' + dataForm.phone,
              status: "Active",
              branchOfActivity: dataForm.branchOfActivity,
              users_permissions_user: userID
            },
          },
        });

        await createFindDev({
          variables: {
            data: {
              description: "Registro de " + dataForm.name,
              company: companyData.data.createCompany.data.id,
            },
          },
        });

        const userLoginData = await created_user_login({
          variables: {
            input: {
              identifier: dataForm.email,
              password: dataForm.password,
            },
          },
        });

        navigate("/login");
        /*    context.login(userLoginData.data.login); */
      } else {

        setSuccessMessage(i18n.t('login.error.emailSelect', { lng: selectedLanguage }));
        setSeverity("error");
        setOpenAlertOK(true);
      }
    } catch (error) {
      console.log(error);
      //toast.error("Verifica os dados enseridos e tente novamente!!");
      setSuccessMessage(i18n.t('login.error.usernameBody', { lng: selectedLanguage }))
      setSuccessMessage(i18n.t('login.error.userCreation', { lng: selectedLanguage }));
      setSeverity("error");
      setOpenAlertOK(true);
    }
  };

  /* 
    const handleUsernameChange = (event) => {
      setUsername(event.target.value);
      let userNameCheck = users.some(user => user.username === event.target.value)
      if (userNameCheck) {
        setSuccessMessage(i18n.t('login.error.username', { lng: selectedLanguage }));
        setSeverity("error")
        setOpenAlertOK(true)
        setUserError(i18n.t('login.error.usernameBody', { lng: selectedLanguage }))
      } else { setUserError(null) }
    }; */


  const handleUsernameChange = (event) => {
    const value = event.target.value;
    setUsername(value);


    // Verificar se o campo username está vazio ou tem menos de 3 caracteres
    if (value.trim() === "") {
      setUserError(i18n.t('login.error.usernameError', { lng: selectedLanguage })); // Mensagem de erro para campo vazio
      return; // Para evitar fazer mais verificações, já que o campo é inválido
    }

    if (value?.length < 3) {
      setUserError(i18n.t('login.error.usernameMinLength', { lng: selectedLanguage })); // Mensagem de erro para nome de usuário com menos de 3 caracteres
      return;
    }

    // Verificar se o nome de usuário já existe na lista de usuários
    let userNameCheck = users.some(user => user.username === value);
    if (userNameCheck) {
      setSuccessMessage(i18n.t('login.error.username', { lng: selectedLanguage }));
      setSeverity("error");
      setOpenAlertOK(true);
      setUserError(i18n.t('login.error.usernameBody', { lng: selectedLanguage })); // Mensagem de erro para nome de usuário duplicado
    } else {
      setUserError(null); // Limpar erro caso não haja problema
    }
  };


  const userNameCheck = users?.some(user => user.username === username)


  const handleCloseAlert = () => {
    setOpenAlertOK(false);
  };


  if (countries) return (
    <>
      <form onSubmit={handleSubmit(registerForm)} noValidate>
        <ToastContainer />
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{ marginBottom: "0px", marginTop: "30px" }}
          >
            <Typography
              variant="h3"
            >
              {i18n.t('login.register.title', { lng: selectedLanguage })}
            </Typography>
          </Grid>
          <Grid item container spacing={1} sx={{ display: step > 0 ? "none" : "" }}>

            <Grid item xs={12}>
              <InputLabel>
                {i18n.t('login.register.userName', { lng: selectedLanguage })}
              </InputLabel>
              <TextField

                required
                autoFocus
                type="text"
                defaultValue=""
                fullWidth
                multiline
                name="username"
                id="username"
                onChange={(e) => handleUsernameChange(e)}

              />
              {userError && <Typography
                variant="body2"
                color="gray"
                style={{ fontSize: '0.75rem', marginTop: '0.344rem', marginLeft: '0.875rem' }}>
                {userError}
              </Typography>}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>
                {i18n.t('login.register.companyName', { lng: selectedLanguage })}
              </InputLabel>
              <TextField
                required
                autoFocus
                type="text"
                defaultValue=""
                fullWidth
                name="name"
                id="name"
                {...register("name")}
                helperText={errors.name?.message}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel>
                {i18n.t('collaboratorStep.collaborator.country', { lng: selectedLanguage })}
              </InputLabel>
              <FormControl fullWidth>
                <Autocomplete
                  size="small"
                  options={countries}
                  value={country}
                  getOptionLabel={(country) => country.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(event, newValue) => {
                    setCountry(newValue)
                    setCity(null)
                    setCountryCode(newValue.areaCode)
                    setCountryError(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={i18n.t('collaboratorStep.collaborator.countryBody', { lng: selectedLanguage })}
                      required
                    />
                  )}
                />
                {countryError && <Typography
                  variant="body2"
                  color="gray"
                  style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                  {countryError}
                </Typography>}
              </FormControl>
            </Grid>

            {country != null && <Grid item xs={12}>
              <InputLabel>
                {i18n.t('collaboratorStep.collaborator.city', { lng: selectedLanguage })}
              </InputLabel>
              <FormControl fullWidth>
                <Autocomplete
                  size="small"
                  options={filteredCity}
                  value={city}
                  getOptionLabel={(city) => city.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(event, newValue) => {
                    setCity(newValue)
                    setCityError(null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={i18n.t('collaboratorStep.collaborator.cityBody', { lng: selectedLanguage })}
                      required
                    />
                  )}
                />
                {cityError && <Typography
                  variant="body2"
                  color="gray"
                  style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                  {cityError}
                </Typography>}
              </FormControl>
            </Grid>}

            <Grid item xs={12}>
              <InputLabel>
                {i18n.t('login.register.branchActivity', { lng: selectedLanguage })}
              </InputLabel>
              <TextField
                required
                autoFocus
                type="text"
                defaultValue=""
                fullWidth
                name="branchOfActivity"
                id="branchOfActivity"
                {...register("branchOfActivity")}
                helperText={errors.branchOfActivity?.message}
              />
            </Grid>

            {countryCode && <Grid item xs={12} >
              <InputLabel>
                {i18n.t('collaboratorStep.collaborator.phone', { lng: selectedLanguage })}
              </InputLabel>
              <div style={{ display: "flex", gap: "0.2rem" }}>
                <TextField
                  style={{ minWidth: "6rem", maxWidth: "7rem" }}
                  size="small"
                  required
                  autoFocus
                  type="text"
                  value={countryCode}
                  fullWidth
                  name="areaCode"
                  id="areaCode"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField size="small"
                  required
                  autoFocus
                  type="number"
                  defaultValue=""
                  fullWidth
                  name="phone"
                  id="phone"
                  {...register("phone")}
                  helperText={errors.phone?.message}
                />
              </div>
            </Grid>}

            <Grid item xs={12}>
              <Button type="button" size="medium" variant="contained" onClick={() => {
                if (country === null) {
                  setCountryError(i18n.t('login.error.countrySelect', { lng: selectedLanguage }));
                }
                if (city === null) {
                  setCityError(i18n.t('login.error.citySelect', { lng: selectedLanguage }));
                }
                if (username === null) {
                  setUserError(i18n.t('login.error.usernameError', { lng: selectedLanguage }));
                }
                trigger(["phone", "name", "branchOfActivity"]).then((valid) => {
                  if (valid && !userNameCheck)
                    setStep(1);
                })
              }} fullWidth>
                {i18n.t('button.next', { lng: selectedLanguage })}
              </Button>
            </Grid>
          </Grid>
          <Grid item container spacing={3} sx={{ display: step <= 0 ? "none" : "" }}>

            <Grid item xs={12}>
              <InputLabel>
                {i18n.t('login.register.email', { lng: selectedLanguage })}
              </InputLabel>
              <TextField
                required
                autoFocus
                type="email"
                defaultValue=""
                fullWidth
                name="email"
                id="email"
                {...register("email")}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>
                {i18n.t('login.register.emailConfirm', { lng: selectedLanguage })}
              </InputLabel>
              <TextField
                required
                autoFocus
                type="email"
                defaultValue=""
                fullWidth
                name="confirmEmail"
                id="confirmEmail"
                {...register("confirmEmail")}
                helperText={errors.confirmEmail?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>
                {i18n.t('login.register.password', { lng: selectedLanguage })}
              </InputLabel>
              <TextField
                required
                autoFocus
                type="password"
                defaultValue=""
                fullWidth
                name="password"
                id="password"
                {...register("password")}
                helperText={errors.password?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>
                {i18n.t('login.register.passwordConfirm', { lng: selectedLanguage })}
              </InputLabel>
              <TextField
                required
                autoFocus
                type="Password"
                defaultValue=""
                fullWidth
                name="confirmPassword"
                id="confirmPassword"
                {...register("confirmPassword")}
                helperText={errors.confirmPassword?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="button" size="medium" variant="text" onClick={() => setStep(0)}>
                {i18n.t('login.button.back', { lng: selectedLanguage })}
              </Button>
              <Button type="submit" size="medium" variant="contained" fullWidth>
                {i18n.t('login.button.register', { lng: selectedLanguage })}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button type="button" size="small" variant="text" fullWidth onClick={toLogin}>
              {i18n.t('login.button.login', { lng: selectedLanguage })}
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openAlertOK}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
      >
        <Alert severity={severity} onClose={handleCloseAlert}>
          <AlertTitle>{
            severity === 'success' ? i18n.t('alert.success', { lng: selectedLanguage }) :
              severity === 'warning' ? i18n.t('alert.warning', { lng: selectedLanguage }) :
                severity === 'info' ? i18n.t('alert.info', { lng: selectedLanguage }) :
                  i18n.t('alert.error', { lng: selectedLanguage })}
          </AlertTitle>
          {successMessage}
        </Alert>
      </Snackbar>
    </>
  ); else return <><LoadingCircle message={i18n.t('loading.loading', { lng: selectedLanguage })} /></>
};

export default Register;
