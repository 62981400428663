import React, { useEffect, useState} from "react";
import { Button, Box, IconButton, Grid,Paper ,Typography,CardActions,Dialog, DialogContent } from "@mui/material";
import { toast } from 'react-toastify';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import BusinessIcon from '@mui/icons-material/Business';  
import { UPDATE_COMPANY, DELETE_COMPANY } from "./mutation.gql";
import { useMutation} from "@apollo/client";
import { styled } from '@mui/material/styles';
import { Hero } from "./sections/hero";
import { Trans } from 'react-i18next'
import { LanguageContext } from '../../../translate/languages/LanguageContext';
import i18n from '../../../translate/i18n';
import { DELETE_FUNISHER, UPDATE_FUNISHER } from "../Furnisher_Creation_Page/mutation.gql";

/* import {

  makeStyles,
  Modal,
  Backdrop,
  Fade
} from "@material-ui/core"; */
 
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
}));
const { REACT_APP_API_URL } = process.env;
 
const FurnisherView = ({
  furnishers,
  setFurnishers,
  setFurnishers_, 
  furnishers_, 
  setOpenAlertOK,
  setSuccessMessage,
   }) => {


  const [open, setOpen] = useState(false);
  const jwt = localStorage.getItem("jwtToken");  
  const [updateFurnisher] = useMutation(UPDATE_FUNISHER); 
  const [active, setActive] = useState(false); 
  const [deleteFurnisher] = useMutation(DELETE_FUNISHER)
  const [activeStatus, setActiveStatus] = useState(furnishers_.status); 
 const { selectedLanguage } = React.useContext(LanguageContext); 

  const imageUrl = furnishers_?.image ? `${REACT_APP_API_URL}${furnishers_?.image}`: '/avatar.jpg'
const handleClose = () => {
    setOpen(false);
   
};
const handleOpen = () => {
  setOpen(true);
 
};

 const handleReturn = () => {
  setFurnishers(null)
   // setCompany_Edit(false)
  }
  
const updateStatus=()=>{
 
  if(activeStatus !== 'Active'){
 
    updateFurnisher({
      context: {
          headers: {
              authorization: `Bearer ${jwt}`,
          },
      },
      //fetchPolicy: "network-only",
      variables: {
           id:furnishers_.id,
          data: {           
           status: 'Active',
           
          },
      },
   
  }).then((data) => {
  
    setOpenAlertOK(true)
    setSuccessMessage("Fornecedor Atuazido");
  })

  setActiveStatus(furnishers_.status = 'Active')
  }

}


 
const toggleEdit = () => {
     
      setActive(false);
  };
  const toggleQA = () => {
  setActive(true);
 
    }
 
  const removeRequest=()=>{
    deleteFurnisher({
        context: {
            headers: {
                authorization: `Bearer ${jwt}`,
            },
        },
        //fetchPolicy: "network-only",
        variables: {
            id: furnishers_.id
          },
    }).then((data) => {
        handleClose()
        // console.log( company_.id)
        setOpenAlertOK(true)
        setSuccessMessage("Fornecedor Removido");
        const updatedData = furnishers.filter(obj => obj.id !== furnishers_.id);
        setFurnishers(updatedData);
        handleReturn()
     
    }).catch((error) => {
        console.log(error)
        toast.error("Something went wrong!");
    });


  }
 
 

  return (
    <>
    
    <Button sx={{p:2}} color="primary" onClick={handleReturn} id='return'>
        Return 
      </Button> 
     <Paper sx={{ flexGrow: 1, }}> 

      <Grid container spacing={0}>
      <Grid item xs={2.5} sx={{ backgroundColor: "#FAFAFA",padding: 2 }}>
       <Button variant={ !active?"contained":"outlined"} onClick={toggleEdit} sx={{ margin: 1,minWidth: 190,minHeight:65}} >
       
     <BusinessIcon/> {i18n.t('button.companyProfile', { lng: selectedLanguage })}</Button>  

       <Button variant={ active? "contained": "outlined"} onClick={toggleQA} autoFocus sx={{ margin: 1,minWidth: 190,minHeight:65}}>
      <LibraryBooksIcon/>{i18n.t('button.questionsandanswers', { lng: selectedLanguage })}
      </Button> 
      </Grid>

      <Paper sx={{ flexGrow: 1 ,p:3}}> 
     
      <Box component="div" sx={{display: "flex" ,justifyContent:'space-between' , flexGrow: 1 }}  >
       
{!active
       ? <>
    <div onClick={handleOpen} style={{height: 50}}> 
   <Hero furnishers_={furnishers_ }/> 
   </div>
<Box sx={{ml:-15}}>

<Typography variant="h2" component="h1" sx={{mt:7}}> {furnishers_.name}</Typography>
<Grid container spacing={2} marginTop={1} >
      
            <Grid item > 
<Typography variant="h3" component="h3" sx={{pt:2.2}}> {i18n.t('table.name', { lng: selectedLanguage })} </Typography>
<Typography variant="h3" component="h3"sx={{mt:3}} > {i18n.t('table.email', { lng: selectedLanguage })} </Typography>
<Typography variant="h3" component="h3" sx={{mt:3}}> {i18n.t('table.location', { lng: selectedLanguage })}</Typography>
<Typography variant="h3" component="h3"sx={{mt:3}}>{i18n.t('table.state', { lng: selectedLanguage })}   </Typography>
<Typography variant="h3" component="h3" sx={{mt:3}}>{i18n.t('table.branch', { lng: selectedLanguage })}  </Typography>
<Typography variant="h3" component="h3" sx={{mt:3}}> {i18n.t('table.phone', { lng: selectedLanguage })} </Typography>
<Typography variant="h3" component="h3" sx={{mt:3}}>Status </Typography>
           </Grid> 
           <Grid item sx={{mt:1.5,  ml: 5}}>
    
             <Typography paragraph={true}sx={{pt:1,  ml:4}} >{furnishers_.name} </Typography>
             <Typography paragraph={true}sx={{pt:1, ml: 4}}>{furnishers_.email} </Typography>
           <Typography paragraph={true} sx={{pt:1,ml: 4}}>{furnishers_.country} </Typography>
               <Typography paragraph={true} sx={{pt:1, ml: 4}}>{furnishers_.city} </Typography> 
             <Typography paragraph={true} sx={{pt:1, ml:4}}>{furnishers_.branchOfActivity} </Typography> 
    <Typography paragraph={true} sx={{pt:1,  ml: 4}}>{furnishers_.phone} </Typography> 
    <Typography paragraph={true} sx={{pt:1, ml: 4}}>{furnishers_.status} </Typography>
 </Grid>
  
</Grid>
</Box>

       </>:
       <>
 
 <Grid>
   {furnishers_?.findDevs?.map((itx,index)=>
            <Typography size="small" sx={{pt: 3}} key={index}> {itx.attributes?.description}</Typography> 
            )}   
   <Box> 
   { furnishers_?.findDevs?.map(itx => 
  itx.attributes?.furnishers_).map((ito,i)=> 
    <div key={i}>
    
    
    
    </div>)}  
 
  
   </Box> 
     </Grid>
       </>
       
       }

 <Box component="div" sx={{
        display: "flex", 
       
       }}> 
   
          <CardActions 
         sx={{display:"block"}} >
          { active?
           <Box sx={{display:"flex"}} > 
            <Button size="small"variant={ furnishers_.status === 'Active'? "contained": "outlined"} sx={{ m:0.5}}onClick={removeRequest}> 
           <Trans i18nKey="en.reject">Reject</Trans>
            </Button> 
             
           <Button size="small"variant={furnishers_.status !== 'Active'? "contained": "outlined" }autoFocus sx={{ m:0.5}} onClick={updateStatus} >
           
           <Trans i18nKey="en.accept">Accept</Trans>
            </Button>
            </Box>
        
          :'' }
      
       </CardActions>
     </Box>

</Box>

     </Paper>
      </Grid>

    </Paper>
  <Dialog
    open={open}
    onClose={handleClose}
    hasCloseButton
   
  > 

 <img src={imageUrl} alt="avatar"/>
  </Dialog> 
    </>
  );
};

export default FurnisherView;
 