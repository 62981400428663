import { gql } from "@apollo/client";

export const GET_USER_DATA = gql`
query UsersPermissionsUser($id: ID) {
  usersPermissionsUser(id:$id) {
    data {
      id
      attributes {
        email
        name
        username
        postRole{data{id attributes{postRole description}}}
        userRole{data{id attributes{role description}}}
        image{data{id attributes{url}}}
      }}}}
`;