import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, Pagination, Paper, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from "@mui/material";
import i18n from '../../../translate/i18n';
import { LanguageContext } from '../../../translate/languages/LanguageContext';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { EVAL_PERIODS, GET_COLLABORATORS } from '../query';
import jwtDecode from 'jwt-decode';
import { GET_ALL_EVALUATIONS_COLLABORATORS } from '../mutation';
import { Visibility } from '@mui/icons-material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 0,
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  }
}));

const EvaluationAll = () => {

  const jwt = localStorage.getItem("jwtToken");
  const { id: employeerID } = jwtDecode(jwt);
  const [getEvaluation] = useLazyQuery(GET_COLLABORATORS);
  const [evalCollab] = useLazyQuery(GET_ALL_EVALUATIONS_COLLABORATORS);

  const location = useLocation();
  const { selectedLanguage } = useContext(LanguageContext);

  const { evaluations } = location.state || {};
  const [evaluatedCollaborators, setEvaluatedCollaborators] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    evalCollab({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
    }).then(data => {
      setEvaluatedCollaborators(data?.data?.evaluations?.data)
    })
  }, []);

  const totalPages = Math.ceil(evaluatedCollaborators?.length / itemsPerPage);
  const currentData = evaluatedCollaborators.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  console.log('AVALIADOss:', evaluatedCollaborators);

  return (
    <>

      <div className="p-6">
        <div className="controls-bar">
          <div className="flex items-center ml-auto">
            <a
              href="/evaluation"
              className='border border-[#F89220] text-[#F89220] py-3.5 px-6 hover:opacity-60 rounded-[0.25rem] mr-2'>
              Voltar
            </a>
          </div>
        </div>

        <Paper component={Paper} sx={{ padding: 2, marginBottom: 10 }}>
          <TableContainer>
            <Table sx={{ minWidth: 900, overflow: 'hidden' }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell component="th" sx={{ padding: 3 }}>
                    {i18n.t('table.name', { lng: selectedLanguage })}
                  </StyledTableCell>
                  <StyledTableCell component="th" sx={{ padding: 3 }}>
                    {i18n.t('table.speciality', { lng: selectedLanguage })}
                  </StyledTableCell>
                  <StyledTableCell component="th" sx={{ padding: 3 }}>
                    {i18n.t('table.evaluation', { lng: selectedLanguage })}
                  </StyledTableCell>
                  <StyledTableCell component="th" sx={{ padding: 3 }}>
                    {i18n.t('table.action', { lng: selectedLanguage })}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  evaluatedCollaborators?.map((row) => (
                    <StyledTableRow key={row.id}>
                      {/* Nome do colaborador */}
                      <StyledTableCell scope="row">
                        {row?.attributes?.collaborator?.data?.attributes?.name || "N/A"}
                      </StyledTableCell>

                      {/* Especialidade do colaborador */}
                      <StyledTableCell>
                        {row?.attributes?.collaborator?.data?.attributes?.postRole?.data.attributes.postRole || "N/A"}
                      </StyledTableCell>

                      {/* Avaliação (pontos ou estrelas) */}
                      <StyledTableCell>
                        {row?.attributes?.star || "N/A"}
                      </StyledTableCell>

                      {/* Ação */}
                      <StyledTableCell>
                        <a href={`/ratings/${row?.attributes?.collaborator?.data?.id}`} className="absoulte top-48 right-10 text-[#F89220] hover:opacity-80">
                          <Visibility />
                        </a>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: "flex", justifyContent: "flex-end", width: '100%' }}>
            <Pagination
              shape="rounded"
              size="small"
              color="primary"
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              sx={{
                paddingTop: 1,
                paddingBottom: 2,
                position: "relative",
                width: '100%',
                borderTop: '1px solid rgba(0,0,0,0.1)',
                justifyContent: 'flex-end',
                display: 'flex !important',
                alignItems: 'center',
              }}
            />
          </Box>
        </Paper>

      </div>


      {/* <Paper component={Paper} sx={{ padding: 2, marginBottom: 10 }}
        >
          <TableContainer >
            <Table sx={{ minWidth: 900, overflow: 'hidden' }} aria-label="customized table">

              <TableBody>
                {currentItems?.map((row, index) => (
                  <StyledTableRow key={row.id} >
                    <StyledTableCell scope="row" sx={{}}>
                      {row?.attributes?.name || "N/A"}
                    </StyledTableCell>

                    <StyledTableCell scope="row" sx={{}}>
                      {row?.attributes?.country?.data?.attributes?.name || "N/A"}
                    </StyledTableCell>
                    <StyledTableCell scope="row" sx={{}}>
                      {row?.attributes?.city?.data?.attributes?.name || "N/A"}
                    </StyledTableCell>
                    <StyledTableCell scope="row" sx={{ padding: 2, wordBreak: "break-all" }}>
                      {row?.attributes?.email || "N/A"}
                    </StyledTableCell>
                    <StyledTableCell scope="row" sx={{}}>
                      {row?.attributes?.phone || "N/A"}</StyledTableCell>
                    <StyledTableCell >
                      {row?.attributes?.status || "N/A"}
                    </StyledTableCell>
                    <StyledTableCell scope="row" sx={{}} >
                      {row?.attributes?.branchOfActivity || "N/A"}
                    </StyledTableCell>

                    <StyledTableCell sx={{}}>
                      <IconButton
                        aria-label="view"
                        color="secondary"
                        onClick={() => toggleElement(index)} >
                        <MoreVertIcon />
                      </IconButton>

                    </StyledTableCell>

                    <StyledTableCell>
                      <>
                        {openTarget && display === index ?
                          <Card ref={menuRef} variant="outlined" sx={{ position: 'absolute', right: 170, width: 150 }} >

                            <CardActions
                              disableSpacing>

                              <IconButton aria-label="view"

                                onClick={() => handleClickOpenView(row.id, row)}
                              >

                                <VisibilityOutlinedIcon color="primary" />

                                <Typography sx={{ paddingLeft: 1 }} id={row.id}> {i18n.t('options.view', { lng: selectedLanguage })} </Typography>
                              </IconButton>

                            </CardActions>
                            {row?.attributes?.status === 'Active' || uRoleContext === 'administrator' ? <CardActions
                              disableSpacing  >
                              <IconButton aria-label="view" onClick={() => handleEdit(row.id, row)} >
                                <EditOutlinedIcon color="primary" />
                                <Typography sx={{ paddingLeft: 1 }} id={row.id}>{i18n.t('options.edit', { lng: selectedLanguage })}</Typography>
                              </IconButton>

                            </CardActions> : ''}

                            <CardActions
                              disableSpacing >
                              <IconButton aria-label="view"
                                onClick={() => handleClickDelete(row.id, row)}
                              >

                                <ClearOutlinedIcon color="primary" />
                                <Typography sx={{ paddingLeft: 1 }} id={row.id}> {i18n.t('options.delete', { lng: selectedLanguage })} </Typography>
                              </IconButton>

                            </CardActions>

                            <CardActions

                              disableSpacing >
                              <IconButton aria-label="view"
                                onClick={() => clickDisable(row.id, row)}

                              >
                                {row?.attributes?.status === 'Active' ? (
                                  < DoNotDisturbAltIcon color={row?.attributes?.status === 'Active' ? 'disabled' : 'primary'} />
                                ) : (
                                  <DoneIcon color="primary" />
                                )}


                                <Typography sx={{ paddingLeft: 1 }} id={row.id} >{row?.attributes?.status === 'Active' ? i18n.t('options.disable', { lng: selectedLanguage }) : i18n.t('options.enable', { lng: selectedLanguage })} </Typography>
                              </IconButton>

                            </CardActions>
                          </Card> : (
                            <></>
                          )}
                      </>
                    </StyledTableCell>

                  </StyledTableRow>

                ))}
              </TableBody>

            </Table>
          </TableContainer>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Pagination
              shape="rounded"
              size="small"
              color="primary"
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              sx={{
                paddingTop: 13,
                paddingBottom: 3,
                position: "relative",
                float: "right"
              }}
            />
          </Box>
        </Paper> */}
    </>
  );
};

export default EvaluationAll;