import React, { useState, useEffect, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import i18n from "../../../translate/i18n";
import { LanguageContext } from "../../../translate/languages/LanguageContext";
import { CircularProgress } from "@mui/material";

const MixedChart = ({ maxStar }) => {
  const { selectedLanguage } = useContext(LanguageContext);

  const meses = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];

  const gerarColaboradores = (dadosColaboradores, key) => {
    return meses.map((_, index) => {
      const monthName = meses[index].substring(0, 3);
      return dadosColaboradores?.find((item) => item.month === monthName)?.[key] || 0;
    });
  };

  const [chartData, setChartData] = useState({
    series: [
      { name: "Pontuação Máxima", type: "column", data: Array(12).fill(0) },
      { name: "Colaboradores Avaliados", type: "column", data: Array(12).fill(0) },
    ],
    options: {
      chart: { height: 350, type: "line", stacked: false, toolbar: { show: false } },
      stroke: { width: [0, 3], curve: "smooth" },
      plotOptions: { bar: { columnWidth: "50%" } },
      fill: { opacity: [0.85, 1] },
      markers: { size: 5 },
      xaxis: { categories: meses, title: { text: i18n.t("tittles.collaborator", { lng: selectedLanguage }) } },
      yaxis: { min: 0, max: 100, tickAmount: 5, title: { text: "" } },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (y, { seriesIndex }) =>
            y !== undefined ? (seriesIndex === 0 ? `${y.toFixed(0)} pontos` : `${(y + 1).toFixed(0)} colab`) : y,
        },
      },
      colors: ["#FFA500", "#00C3FF"],
    },
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (maxStar?.length) {
      setIsLoading(true);

      const maxStarData = gerarColaboradores(maxStar, "maxStar");
      const qtdCollaboratorsData = gerarColaboradores(maxStar, "qtdCollaboratorEvaluated");

      console.log("maximumStarData atualizado:", maxStarData);
      console.log("qtdCollaboratorsData atualizado:", qtdCollaboratorsData);

      setChartData((prevData) => ({
        ...prevData,
        series: [
          { ...prevData.series[0], data: maxStarData },
          { ...prevData.series[1], data: qtdCollaboratorsData },
        ],
      }));

      setIsLoading(false);
    }
  }, [maxStar]);

  return (
    <div className="card">
      {isLoading ? (
        <div className="flex items-center gap-2">
          <CircularProgress />
          <span>Carregando...</span>
        </div>
      ) : (
        <div id="chart">
          <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={350} />
        </div>
      )}
    </div>
  );
};

export default MixedChart;
