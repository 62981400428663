import React, { useContext, useEffect, useRef, useState } from "react";
import { LanguageContext } from "../../../translate/languages/LanguageContext";
import { UserRoleContext } from "../../../contexts/userRole-context";
import { DistributorService } from "../../../services/distributors";
import { AlternateEmail, ClearOutlined, ContactPhone, Done, DoNotDisturbAlt, EditOutlined, Groups, KeyboardArrowLeft, KeyboardArrowRight, MoreVert, Place, VisibilityOutlined } from "@mui/icons-material";
import i18n from "../../../translate/i18n";
import { Card, CardActions, Dialog, DialogContent, DialogTitle, IconButton, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CompanyDelete from "../../CompanyPage/Company_Creation_Page/Company_delete";
import CreateCompany from "../../CompanyPage/Company_Creation_Page/create_company";
import SearchAddBar from "../../../components/TopBar/search-add-bar.component";
import Pathway from "../../../components/TopBar/pathway";

export default function DistCompanies(
  // setCompanies,
  // company_ =============>>>> ok,
  // setCompany_,
  // setCompany_Edit,
  // currentID,
  // setCurrentID,
  // company_Edit,
  // companies_ =============>>>> ok,
  // setOpenAlertOK,
  // setSuccessMessage,
  // updater,
  // setUpdater
) {

  const itemsPerPage = 6;

  const navigate = useNavigate();

  const jwtToken = localStorage.getItem("jwtToken");
  const userId = localStorage.getItem("userId");

  const { selectedLanguage } = useContext(LanguageContext);
  const { uRoleContext } = useContext(UserRoleContext);

  const [currentPage, setCurrentPage] = useState(1);

  const [companies, setCompanies] = useState([]);

  const [companies_, setCompanies_] = useState([]);
  const [company_, setCompany_] = useState(null);
  const [company_Edit, setCompany_Edit] = useState(false);
  const [openTarget, setOpenTarget] = useState(false);
  const [display, setDisplay] = useState(-1);
  const [currentCompanyId, setCurrentCompanyId] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [currentID, setCurrentID] = useState(null);

  const [companyID, setCompanyID] = useState(false);
  const [companyUser, setCompanyUser] = useState("");
  const [company, setCompany] = useState("");
  const [open, setOpen] = useState(false);
  const [activeOption, setActiveOption] = useState(false);

  const [openAlertOK, setOpenAlertOK] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [updater, setUpdater] = useState([]);

  const [companyFilter, setCompanyFilter] = useState(null);
  const [filteredList, setFilteredList] = useState('');


  const { data, loading, fetchMore } = DistributorService.GetCompanies({
    userId,
    jwtToken,
  });

  const menuRef = useRef(null);

  useEffect(() => {

    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenTarget(false)
      }

    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (data) {
      setCompanies_(data?.distributors?.data?.[0]?.attributes?.companies?.data);
      console.log("ASLSLL", companies_);
    }
  }, [data]);


  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  const currentItems = companies_?.slice(firstIndex, lastIndex);

  // Calculate the total number of pages
  const totalPages = Math.ceil(companies_?.length / itemsPerPage);

  function handleClose() {
    setOpen(false);
    setOpenModal(false);
    // setCompanies(companies)
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    setCurrentPage(1);

  }, [companies_, totalPages]);


  const toggleElement = (currentIndex, id) => {
    setOpenTarget(!openTarget)
    setDisplay(currentIndex);
    setCurrentCompanyId(id);
  };

  return (
    <>
      <Pathway />
      {<SearchAddBar
        updater={updater}
        setUpdater={setUpdater}
        setData={setCompanies}
        searched={filteredList}
        setCompanyFilter={setCompanyFilter}
        setSearched={setFilteredList} />}

      <div className="flex flex-col px-6 mb-10 py-2.5 border-t border-b">
        <span style={{ fontSize: '1rem' }} className="font-onest self-start">
          Listando
        </span>
        <span style={{ fontSize: '1.45rem', textTransform: 'capitalize' }} className="font-bold font-onest self-start">Minhas Empresas</span>
      </div>

      <div className="w-full px-6 -mt-6 pb-6 relative z-50 grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        {
          currentItems?.map((row, index) => (
            <div
              key={row.id}
              className="bg-white rounded-[12px] border border-gray-200 mt-6 shadow-md relative py-6 px-6"
            >
              <div className="absolute top-4 right-4">
                <IconButton
                  aria-label="view"
                  color="secondary"
                  onClick={() => toggleElement(index, row.id)} >
                  <MoreVert />
                </IconButton>
              </div>

              <div className="flex">
                <div className='flex self-start items-center jusitfy-center'>
                  <span className="flex items-center justify-center w-[50px] h-[50px] aspect-square rounded-full bg-[#EA1967] text-white text-xl lg:text-xl">{row?.attributes?.name[0] || "-"}</span>
                </div>
                <div className="flex flex-col ml-2">
                  <h4 className="font-bold self-start" style={{ fontSize: '0.85rem' }}>
                    {row?.attributes?.name || "N/A"}
                  </h4>
                  <small style={{ fontSize: '0.85rem' }} className="text-[rgba(0,0,0,0.65)] self-start -mt-1">
                    <Place sx={{ fontSize: '16px' }} />
                    {row?.attributes?.city?.data?.attributes?.name || "N/A"} {" - "} {row?.attributes?.country?.data?.attributes?.name || "N/A"}
                  </small>
                  <div className="flex flex-col w-full">
                    <small className='w-full flex items-center gap-2 text-[#F89220]' style={{ fontWeight: 'bold' }}>
                      Branch: {" "}
                      <span className='font-bold text-black'>
                        {row?.attributes?.branchOfActivity || "N/A"}
                      </span>
                    </small>
                  </div>
                </div>
              </div>

              <div className="border-t flex flex-col w-full gap-1 mt-2 pt-2">
                <div className="flex items-center w-full gap-2" style={{ fontSize: '13px' }}>
                  <div className="flex items-center text-[#F89220] gap-2" style={{ fontWeight: 'bold' }}>
                    <ContactPhone sx={{ fontSize: '18px' }} />
                    Contactos:
                  </div>
                  <span className='whitespace-nowrap block text-ellipsis overflow-hidden line-clamp-1' style={{ fontWeight: 'bold' }}>{row?.attributes?.phone || "N/A"}</span>
                </div>
                <div className="flex items-center w-full gap-2" style={{ fontSize: '13px' }}>
                  <div className="flex whitespace-nowrap items-center text-[#F89220] gap-2" style={{ fontWeight: 'bold' }}>
                    <AlternateEmail sx={{ fontSize: '18px' }} />
                    E-mail:
                  </div>
                  <span
                    className='whitespace-nowrap block text-ellipsis overflow-hidden line-clamp-2'
                    style={{
                      fontWeight: 'bold'
                    }}>
                    {row?.attributes?.email || "N/A"}
                  </span>
                </div>
              </div>

              <>
                {
                  openTarget && display === index ? (
                    <Card ref={menuRef} variant="outlined" sx={{ position: 'absolute', right: -25, width: 240, top: 56, zIndex: '9999999999' }} >
                      <CardActions
                        disableSpacing>

                        <IconButton aria-label="view"

                          onClick={() => handleClickOpenView(row.id, row)}
                        >

                          <VisibilityOutlined color="primary" />

                          <Typography sx={{ paddingLeft: 1 }} id={row.id}> {i18n.t('options.view', { lng: selectedLanguage })} </Typography>
                        </IconButton>

                      </CardActions>

                      {
                        row?.attributes?.status === 'Active' || uRoleContext === 'administrator' ? <CardActions
                          disableSpacing  >
                          <IconButton aria-label="view" onClick={() => handleEdit(row.id, row)} >
                            <EditOutlined color="primary" />
                            <Typography sx={{ paddingLeft: 1 }} id={row.id}>{i18n.t('options.edit', { lng: selectedLanguage })}</Typography>
                          </IconButton>

                        </CardActions> : ''
                      }

                      <CardActions
                        disableSpacing >
                        <IconButton aria-label="view"
                          onClick={() => handleClickDelete(row.id, row)}
                        >

                          <ClearOutlined color="primary" />
                          <Typography sx={{ paddingLeft: 1 }} id={row.id}> {i18n.t('options.delete', { lng: selectedLanguage })} </Typography>
                        </IconButton>

                      </CardActions>

                      <CardActions
                        disableSpacing >
                        <IconButton aria-label="view"
                          onClick={() => {
                            navigate(`/company/${row.id}/collaborators`, { state: { comp: row } })
                          }}
                        >

                          <Groups color="primary" />
                          <Typography sx={{ paddingLeft: 1 }} id={row.id}> {i18n.t('tittles.company_col', { lng: selectedLanguage })} </Typography>
                        </IconButton>

                      </CardActions>

                      <CardActions

                        disableSpacing >
                        <IconButton aria-label="view"
                          onClick={() => clickDisable(row.id, row)}

                        >
                          {row?.attributes?.status === 'Active' ? (
                            < DoNotDisturbAlt color={row?.attributes?.status === 'Active' ? 'disabled' : 'primary'} />
                          ) : (
                            <Done color="primary" />
                          )}


                          <Typography sx={{ paddingLeft: 1 }} id={row.id} >{row?.attributes?.status === 'Active' ? i18n.t('options.disable', { lng: selectedLanguage }) : i18n.t('options.enable', { lng: selectedLanguage })} </Typography>
                        </IconButton>

                      </CardActions>

                    </Card>
                  ) : (
                    <></>
                  )
                }
              </>
            </div>
          ))
        }
      </div>

      <div className="mt-8 flex space-x-3 items-center justify-center">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="outline-none border inline-block text-center py-[0.45rem] px-[0.45rem] bg-white cursor-pointer shadow-sm text-black hover:bg-[#F9AE00] hover:text-[#fff] roudned-[8px]"
        >
          <KeyboardArrowLeft />
        </button>
        <div className="flex items-center gap-3">
          <span
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}
            className={`w-[28px] h-[28px] flex items-center justify-center text-[0.875rem] font-bold ${currentPage === totalPages ? 'bg-white text-black' : 'bg-[#F9AE00] text-white'}`}
          >
            {currentPage}
          </span>
          <span>
            {i18n.t('de', { lng: selectedLanguage })}
          </span>
          <span
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}
            className={`w-[28px] h-[28px] items-center justify-center text-[0.875rem] font-bold ${currentPage === totalPages ? 'bg-[#F9AE00] text-white' : 'bg-white text-black'}`}
          >
            {totalPages}
          </span>
        </div>
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className={`outline-none border inline-block text-center py-[0.45rem] px-[0.45rem] bg-white cursor-pointer shadow-sm text-black hover:bg-[#F9AE00] hover:text-[#fff] roudned-[8px] `}
        >
          <KeyboardArrowRight />
        </button>
      </div>

      <Paper>
        <Dialog

          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='md'
        >
          <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" alignItems="center">
            {
              activeOption === false ? (
                <p>Delete {company.attributes?.name}?</p>
              ) : (
                <p>{company?.attributes?.status === 'Active' ? 'Disable' : 'Enable'} {company?.attributes?.name}?</p>
              )
            }
          </DialogTitle>
          <DialogContent>
            <CompanyDelete
              setOpenAlertOK={setOpenAlertOK}
              setSuccessMessage={setSuccessMessage}
              companyID={companyID}
              companies={companies_}
              setCompanies={setCompanies}
              handleClose={handleClose}
              companyUserID={companyUser?.id}
              companyUser={companyUser}
              currentID={currentID}
              activeOption={activeOption}
              company={company}
              setCompany={setCompany}
            />

          </DialogContent>
        </Dialog>

        {company_Edit === true
          && <Dialog
            open={openModal}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='md'
          >
            <DialogContent>
              <CreateCompany
                updater={updater}
                setUpdater={setUpdater}
                companies={companies_}
                setCompanies={setCompanies}
                setCompany_={setCompany_}
                company_={company_}
                setCompany_Edit={setCompany_Edit}
                company_Edit={company_Edit}
                currentID={currentID}
                setCurrentID={setCurrentID}
                handleClose={handleClose}

              />
            </DialogContent>
          </Dialog>
        }
      </Paper>
    </>
  );

  function handleClickOpenView(companyID, companyData) {

    setCompany_Edit(false)
    let data = companies_.find(d => d.id === companyID)

    setCompany_(i => {
      return {
        id: data.id,
        userID: data?.attributes?.users_permissions_user?.data?.id,
        name: data?.attributes?.name,
        country: data?.attributes?.country?.data?.attributes?.name,
        city: data?.attributes?.city?.data?.attributes?.name,
        email: data?.attributes?.email,
        phone: data?.attributes?.phone,
        status: data?.attributes?.status,
        branchOfActivity: data?.attributes.branchOfActivity,
        image: data?.attributes?.image?.data?.attributes.url,
        imageID: data?.attributes?.image?.data?.id,
        findDevs: data?.attributes?.findDevs?.data,
        countryID: data?.attributes?.country?.data?.id,
        cityID: data?.attributes?.city?.data?.id,
        confirmed: data?.attributes?.users_permissions_user?.data?.attributes?.confirmed,
      }
    })

  };

  function handleEdit(companyID, companyData) {
    //setCompany_(companyData);
    setCompany_Edit(true);
    setOpenModal(true)
    setCurrentID(companyID)
    let data = companies_.find(d => d.id === companyID)

    setCompany_(i => {
      return {
        id: data.id,
        userID: data?.attributes?.users_permissions_user?.data?.id,
        name: data?.attributes?.name,
        country: data?.attributes?.country?.data?.attributes?.name,
        city: data?.attributes?.city?.data?.attributes?.name,
        email: data?.attributes?.email,
        phone: data?.attributes?.phone,
        status: data?.attributes?.status,
        branchOfActivity: data?.attributes.branchOfActivity,
        image: data?.attributes?.image?.data?.attributes.url,
        imageID: data?.attributes?.image?.data?.id,
        findDevs: data?.attributes?.findDevs?.data,
        countryID: data?.attributes?.country?.data?.id,
        cityID: data?.attributes?.city?.data?.id,
        confirmed: data?.attributes?.users_permissions_user?.data?.attributes?.confirmed,
      }
    })

  };

  function handleClickDelete(companyID, companyData) {
    console.log("companyData", companyData);

    setCompanyID(companyID)
    setCompanyUser(companyData?.attributes?.users_permissions_user?.data);

    setOpen(true)
    setActiveOption(false)
    let data = companies_.find(d => d.id === companyID)
    setCompany(data)

  };

  function clickDisable(companyID, companyData) {
    setActiveOption(true)
    setOpen(true)
    setCompanyID(companyID)

    let data = companies_.find(d => d.id === companyID)
    setCompany(data)
    setCompanyUser(data);
  };

};