import { Timeline } from "@mui/icons-material";
import AnalysisCardItem from "./AnalysisCardItem";
import { GET_COMPANIES } from "../../CompanyPage/Company_Creation_Page/query.gql";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_COLLABORATORS, GET_EMPLOYMENT_COLLAB, GET_FURNISHERS_ALL } from "../../Collaborator/Collaborator_Creation_Page/query.gql";
import { GET_DISTRIBUTORS } from "../../Distributor/Distributor_Creation_Page/query.gql";
import { useDistCollabsCount, useFurnisherCollaborators } from "../../FurnisherPage/Furnisher_Creation_Page/helpers";
import { useDistByUserId, useDistCollabCount, useFurnisherCollabCount, useFurnisherIdByUserId } from "../../../components/TopBar/helpers";
import { useGetDistByUserId } from "../../CompanyPage/Company_Creation_Page/helpers";
import { DistributorService } from "../../../services/distributors";
import { GET_DISTRIBUTOR_COLLABORATORS } from "../../../services/distributors/gql/query";

const AnalysisCardClone = ({ props: data, evaluations, eval_periodHistory, companyId }) => {
  const [companies, setCompanies] = useState([]);
  const jwt = localStorage.getItem("jwtToken");
  const [collaborators, setCollaborators] = useState([]);
  const [hiredCollaborators, setHiredCollaborators] = useState(0);
  const [getCollaborators] = useLazyQuery(GET_COLLABORATORS);
  const [getComp] = useLazyQuery(GET_COMPANIES);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentCompanyId, setCurrentCompanyId] = useState(1238);
  const loggedId = localStorage.getItem('userId');
  const companyCurrentID = localStorage.getItem('companyCurrentID');
  const userRole = localStorage.getItem('userRole') ?? null;
  const [collabContracted, setCollabContracted] = useState([]);
  const [furnishersCount, setFurnishersCount] = useState(0);
  const [distributores, setDistributores] = useState(0);
  const [getContracted] = useLazyQuery(GET_EMPLOYMENT_COLLAB);
  const [getFurn_] = useLazyQuery(GET_FURNISHERS_ALL);
  const [getDist] = useLazyQuery(GET_DISTRIBUTORS);
  const [uIdFurnisher, setUIdFurnisher] = useState(null);

  const [distTrib_Collab, setDisTirb_Collab] = useState(0);
  const [comppCollabs, setComppCollabs] = useState(0);

  const [currentDistId, setCurrentDistId] = useState(null);

  const [gggddd] = useLazyQuery(GET_DISTRIBUTOR_COLLABORATORS);

  const { data: dist_ccL, loading: cc, fetchMore } = DistributorService.GetAll({
    userId: loggedId,
    jwtToken: jwt,
  });

  useEffect(() => {
    if (dist_ccL) {
      console.log("dddd", dist_ccL);
      setDisTirb_Collab(dist_ccL?.distributors?.data?.[0]?.attributes?.employmentcolabdistributors?.data?.length)
    }
  }, [dist_ccL]);

  useEffect(() => {
    console.log("userRole", userRole);

    if (userRole) {
      setIsAdmin(userRole == 'administrator');
    }

  }, [userRole]);

  const furniCollab = useFurnisherCollabCount();
  const distCollab = useDistCollabCount();
  const iii = useGetDistByUserId();
  const distCount = useDistCollabsCount(iii);

  useEffect(() => {
    getComp({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      fetchPolicy: 'network-only',
    }).then((response) => {
      setCompanies(response?.data?.companies?.data);

      const companyCollabs = response?.data?.companies?.data?.filter((item) => (item?.id == companyCurrentID))
      setCollabContracted(companyCollabs?.[0]?.attributes?.collaborators?.data ?? []);

      console.log("DDDDD111sss", companyCollabs?.[0]?.attributes?.distributors?.data?.[0]?.id)
      setCurrentDistId(companyCollabs?.[0]?.attributes?.distributors?.data?.[0]?.id);
    })
  }, [companyId]);

  useEffect(() => {
    getCollaborators({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      //fetchPolicy: "no-cache",
    }).then((data) => {
      console.log("colabord", data);

      const collaboratorsData = data?.data?.collaborators?.data || [];


      const ddd = collaboratorsData.filter(item => item?.attributes?.employmentcolabdistributors?.data?.[0]?.attributes?.distributors?.data?.[0]?.id == currentDistId);

      setComppCollabs(ddd?.length || 0);

      setCollaborators(collaboratorsData);

      if (collaboratorsData?.length > 0) {
        const companies = collaboratorsData.flatMap((item) => {
          return item?.attributes?.companies?.data?.filter((comp) => {
            const iii = comp?.attributes?.users_permissions_user?.data?.id;
            if (iii && iii === loggedId) {
              setCurrentCompanyId(comp?.id);
              return true;
            }
            return false;
          });
        });
        setHiredCollaborators(companies?.length);
      } else {
        setHiredCollaborators(0);
      }
    });

    getFurn_({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
    }).then(data => {
      if (data) {
        setFurnishersCount(data?.data?.furnishers?.data?.length ?? 0);
      }
    })

    getDist({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
    }).then(data => {
      if (data) {
        setDistributores(data?.data?.distributors?.data?.length ?? 0);
      }
    })

  }, []);

  /*  useEffect(() => {
    
     
     getContracted({
       variables: {
         filters: {
           companies: {
             id: {
               eq: currentCompanyId, // Filtro pelo ID da empresa
             },
           },
         },
         pagination: {
           limit: 10, // Você pode ajustar o limite, se necessário
         },
         sort: ["startDate:desc"], // Ordenação, opcional
       },
       context: {
         headers: {
           authorization: `Bearer ${jwt}`,
         },
       },
       fetchPolicy: "no-cache",
     })
       .then((result) => {
      
         setCollabContracted(result?.data?.employments?.data || []);
       })
       .catch((err) => {
         console.error("Erro ao buscar os dados:", err);
       });
   }, [currentCompanyId]); */

  const uIdFurn = useFurnisherIdByUserId();
  const uIdDist = useDistByUserId();
  const [getCompanys_, { loading, data: myData }] = useLazyQuery(GET_COMPANIES, {
    context: {
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (myData) {
      const { companies } = data;
      console.log("COMPA: ", companies)

      setCompanies(companies.data);
    }

  }, [myData]);

  let filter = [];
  if (userRole == 'distributor') {
    filter = companies?.filter((item) =>
      item?.attributes?.distributors?.data?.[0]?.id == uIdDist
    )
  }

  return (
    <>
      <div className="bg-white rounded-[10px] amazing-shadow pt-4 pb-6 px-8 w-full">
        <span style={{ fontWeight: 'bold' }} className="flex flex-col w-full">
          <span className="w-full">
            <Timeline sx={{ color: '#FF9213' }} />
          </span>
          <span className="w-full">Análises</span>
        </span>
        <div className="params-analysis mt-4 grid lg:grid-cols-3 items-center gap-3">
          {
            userRole !== 'collaborator' && (
              <AnalysisCardItem
                title="Colaboradores"
                count={
                  userRole === 'furnishers' ? furniCollab :
                    userRole === 'distributor' ? distTrib_Collab :
                      userRole === 'company' ? comppCollabs :
                        collaborators?.length
                }
                link={`${userRole === 'furnishers' ? `/furnisher/${uIdFurn}/collaborators` : userRole === 'distributor' ? `/dist/${uIdDist}/collaborators` : '/collaborator/main'}`}
              />
            )
          }
          {
            userRole !== 'company' && userRole !== 'collaborator' && userRole !== 'distributor' && userRole !== 'furnishers' && (
              <AnalysisCardItem title="Fornecedores" count={furnishersCount} link="/furnishers" />
            )
          }
          {
            userRole !== 'distributor' && userRole !== 'collaborator' && userRole !== 'company' && userRole !== 'furnishers' && (
              <AnalysisCardItem title="Distribuidores" count={distributores} link="/distributor" />
            )
          }

          <AnalysisCardItem
            title="Total Avaliações"
            count={eval_periodHistory?.length ?? 0}
            link="/evaluation/all"
            state={evaluations}
          />
          {
            userRole !== 'collaborator' && (
              isAdmin || userRole == 'distributor' ? (
                <AnalysisCardItem
                  title="Empresas"
                  count={userRole == 'distributor' ? filter?.length : companies?.length}
                  link="/companies"
                />
              ) : (
                userRole !== 'furnishers' && userRole !== 'distributor' && (
                  <AnalysisCardItem
                    title="Contratados"
                    count={collabContracted?.length ?? 0}
                    link={`/company/${currentCompanyId}/collaborators`}
                  />
                )
              )
            )
          }
        </div>
      </div>
    </>
  );
};

export default AnalysisCardClone;