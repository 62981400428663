import { Alert, AlertTitle, Autocomplete, Button, FormControl, FormControlLabel, Grid, InputLabel, Snackbar, Switch, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_COMPANIES_AND_COLLABORATORS } from './query.gql';
import { CREATE_EVALUATION_PERIOD, UPDATE_EVALUATION_PERIOD } from './mutation.gql';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { LanguageContext } from '../../translate/languages/LanguageContext';
import i18n from '../../translate/i18n';
import { useSnackbar } from '../../contexts/snackbar-context';

const PeriodFormTest = ({
    evaluationPeriod,
    titleAction,
    handleClose,
    setData }) => {
    const { selectedLanguage } = useContext(LanguageContext);
    const { setOpenAlertOK, setSuccessMessage, setSeverity } = useSnackbar()
    const viewData = titleAction === i18n.t('title.viewPeriod', { lng: selectedLanguage });
    const editData = titleAction === i18n.t('title.editPeriod', { lng: selectedLanguage });
    const createData = titleAction === i18n.t('title.period', { lng: selectedLanguage });

    const jwt = localStorage.getItem("jwtToken");
    const [title, setTitle] = useState(i18n.t('button.save', { lng: selectedLanguage }));
    const [evaluationPeriods, setEvaluationPeriods] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [collaborators, setCollaborators] = useState([]);
    const [getCompanies_Collaborators] = useLazyQuery(GET_COMPANIES_AND_COLLABORATORS);
    const [companyValueData, setCompanyValueData] = useState(null);
    const [collaboratorValueData, setCollaboratorValueData] = useState([]);
    useEffect(() => {
        getCompanies_Collaborators({
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
            fetchPolicy: 'cache-and-network'
        }).then(data => {
            setCompanies(data.data?.companies?.data?.map(pr => ({
                id: pr.id,
                name: pr.attributes.name,
                collaborators: pr.attributes?.collaborators?.data?.map((item) => {
                    return {
                        id: item.id,
                        name: item.attributes?.name,
                    };
                })
            })));

            setCollaborators(data?.data?.companies?.data?.flatMap((country) => {
                return country.attributes?.collaborators?.data?.map((city) => {
                    return {
                        id: city.id,
                        name: city.attributes?.name,
                        company: country.attributes.name
                    };
                });
            }));
        }).catch(error => {
            console.log(error);
        });

        if (!createData) {
            setCompanyValueData({
                id: evaluationPeriod.companyID,
                name: evaluationPeriod.companyName
            });
            setCollaboratorValueData(evaluationPeriod.collaborator
            );
        }
    }, []);

    console.log("evaluationPeriod.collaborator", evaluationPeriod);
    
    const filteredCollaborators = collaborators?.filter(item =>
        item.company?.toLowerCase().includes(companyValueData?.name?.toLowerCase())
    )

    console.log("filtrados", filteredCollaborators);
    
    const [formData, setFormData] = useState({
        id: evaluationPeriod.id,
        start: evaluationPeriod.start,
        end: evaluationPeriod.end,
        description: evaluationPeriod.description,
        isActive: evaluationPeriod.isActive,
        company: evaluationPeriod.companyID,
        collaborator: evaluationPeriod.collaborator,
    });

    const [checked, setChecked] = useState(formData.isActive);
    const handleChangeState = (e) => {
        setChecked(e.target.checked)
        setFormData({ ...formData, isActive: e.target.checked })
    };
    const [newEvaluationPeriod, { loading, error }] = useMutation(CREATE_EVALUATION_PERIOD);
    const [updateEvaluationPeriod, { loading2, error2 }] = useMutation(UPDATE_EVALUATION_PERIOD);


    const handleSubmit = (e) => {
        e.preventDefault();
        let collaboratorArray = formData.collaborator.map(obj => parseInt(obj.id));

        if (evaluationPeriod.id === undefined) {
            newEvaluationPeriod({
                variables: {
                    data: {
                        start: formData.start,
                        end: formData.end,
                        description: formData.description,
                        company: companyValueData.id,
                        collaborators: collaboratorArray
                    },
                },
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
            }).then((response) => {
                console.log(response.data.createEvaluationPeriod.data, "createdData")
                const newEvaluationPeriod = response.data.createEvaluationPeriod.data;
                setData((prevEvaluationPeriod) => [newEvaluationPeriod, ...prevEvaluationPeriod]);
                setOpenAlertOK(true)
                setSuccessMessage(i18n.t('success.createPeriod', { lng: selectedLanguage }));
                setSeverity("success")
                handleClose();
            })
                .catch(error => {
                    console.log(error);
                });
        } else {
            let collaboratorArray = formData.collaborator.map(obj => parseInt(obj.id));
            updateEvaluationPeriod(

                {
                    variables: {
                        id: evaluationPeriod.id,
                        data: {
                            start: formData.start,
                            end: formData.end,
                            description: formData.description,
                            isActive: formData.isActive,
                            company: companyValueData.id,
                            collaborators: collaboratorArray
                        }
                    },
                    context: {
                        headers: {
                            authorization: `Bearer ${jwt}`,
                        },
                    },
                }).then(data => {
                    if (formData.start !== evaluationPeriod.start ||
                        formData.end !== evaluationPeriod.end ||
                        formData.description !== evaluationPeriod.description ||
                        companyValueData.id !== evaluationPeriod.companyID ||
                        formData.collaborator !== evaluationPeriod.collaborator ||
                        formData.isActive !== evaluationPeriod.isActive) {
                        let obj = evaluationPeriods.filter(i => i.id === evaluationPeriod.id);
                        obj.push(data.data.updateEvaluationPeriod.data);
                        setEvaluationPeriods(obj);
                        setOpenAlertOK(true)
                        setSuccessMessage(i18n.t('success.updatePeriod', { lng: selectedLanguage }));
                        setSeverity("success")
                        handleClose();
                    }
                }).catch(error2 => {
                    console.log(error2)
                    setOpenAlertOK(true)
                    setSuccessMessage(i18n.t('error.updatePeriod', { lng: selectedLanguage }));
                    setSeverity("error")
                });
        }

    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2} marginTop={1}>
                <Grid container spacing={2} margin={1} marginLeft={3}>
                    <InputLabel required >{i18n.t('table.description', { lng: selectedLanguage })}</InputLabel>
                    <TextField
                        id="filled-basic"
                        variant="outlined"
                        fullWidth
                        required
                        InputProps={{
                            readOnly: viewData ? true : false,
                        }}
                        defaultValue={viewData ? evaluationPeriod.description :
                            editData ? evaluationPeriod.description : formData.description}
                        onChange={(e) =>
                            setFormData({ ...formData, description: e.target.value })}
                    />
                </Grid>
                <Grid container spacing={2} margin={1}>
                    <Grid item xs={2} md={3}>
                        <InputLabel required >{i18n.t('table.startDate', { lng: selectedLanguage })}</InputLabel>
                        <TextField
                            autoFocus
                            required
                            /*label="Data início avaliação"*/
                            fullWidth
                            type="date"
                            InputProps={{
                                readOnly: viewData ? true : false,
                            }}
                            defaultValue={viewData ? evaluationPeriod.start :
                                editData ? evaluationPeriod.start : formData.start}
                            onChange={(e) =>
                                setFormData({ ...formData, start: e.target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} md={3}>
                        <InputLabel required >{i18n.t('table.endDate', { lng: selectedLanguage })}</InputLabel>
                        <TextField
                            autoFocus
                            required
                            /* label="Data fim avaliação"*/
                            fullWidth
                            type="date"
                            InputProps={{
                                readOnly: viewData ? true : false,
                            }}
                            defaultValue={viewData ? evaluationPeriod.end :
                                editData ? evaluationPeriod.end : formData.end}
                            onChange={(e) =>
                                setFormData({ ...formData, end: e.target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel required >{i18n.t('table.company', { lng: selectedLanguage })}</InputLabel>
                        <FormControl fullWidth size='small'>
                            <Autocomplete
                                readOnly={viewData ? true : false}
                                value={companyValueData}
                                onChange={(e, value) => {
                                    setCompanyValueData(value);
                                    setFormData({ ...formData, company: value });
                                }}
                                options={companies}
                                getOptionLabel={(option) => (option ? option.name : '')}
                                isOptionEqualToValue={(option, value) => option && option.id === value.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />

                        </FormControl>
                    </Grid>
                </Grid>
                {filteredCollaborators?.length > 0 && <Grid container spacing={2} margin={1}>
                    <Grid item xs={6}>
                        <InputLabel required >{i18n.t('collaborator.title.collaborator', { lng: selectedLanguage })}</InputLabel>
                        <FormControl fullWidth size='small'>
                            <Autocomplete
                                multiple
                                readOnly={viewData ? true : false}
                                value={collaboratorValueData}
                                onChange={(e, value) => {
                                    setCollaboratorValueData(value)
                                    setFormData({ ...formData, collaborator: value })
                                }
                                }
                                options={filteredCollaborators}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"

                                        fullWidth

                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>

                </Grid>}
                {!createData && (
                    <Grid item xs={6}>
                        <FormControlLabel
                            disabled={!editData ? true : false}
                            sx={{ marginLeft: 0 }}
                            value="start"
                            control={
                                <Switch
                                    color="primary"
                                    checked={checked}
                                    onChange={handleChangeState}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label={i18n.t('table.state', { lng: selectedLanguage })}
                            labelPlacement="start"
                        />
                    </Grid>
                )}
                <Grid container spacing={3} item xs={12} display="flex" justifyContent="center" alignItems="center" marginTop={15}>
                    <Grid item xs={3}>
                        <Button variant="outlined" size="large" fullWidth onClick={handleClose}>{i18n.t('button.cancel', { lng: selectedLanguage })}</Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            disabled={viewData ? true : false}
                            type="submit"
                            variant="contained"
                            size="large"
                            fullWidth

                        >
                            {title}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export default PeriodFormTest;