import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem, Pagination } from '@mui/material';
import { GET_INDICATORS } from './query.gql';
import { React, useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import IndicatorForm from './indicator-form';
import IndicatorDelete from './delete-indicator.component';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomPagination from '../../components/TableComponents/CustomPagination';
import { LanguageContext } from '../../translate/languages/LanguageContext';
import i18n from '../../translate/i18n';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: 0,
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    }
}));

export default function IndicatorList({ filteredSearched, indicators, setIndicators }) {
    const { selectedLanguage } = useContext(LanguageContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortedPage, setSortedPage] = useState(filteredSearched);

    const itemsPerPage = 10;
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentItems = sortedPage.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(sortedPage?.length / itemsPerPage);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        setCurrentPage(1);
        setSortedPage(filteredSearched)
    }, [filteredSearched]);

    const [open, setOpen] = useState(false);

    const [indicator, setIndicator] = useState({
        id: '',
        indicator: '',
        dimension: '',
        isActive: '',
    })

    const currentLocation = useLocation()

    const [title, setTitle] = useState(i18n.t('title.indicator', { lng: selectedLanguage }))

    function LongMenu({ actionID }) {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        function handleClickOpenView(indicatorID) {
            setTitle(i18n.t('title.viewIndicator', { lng: selectedLanguage }))
            setOpen(true);
            let data = indicators.find(d => d.id === indicatorID)
            setIndicator(i => {
                return {
                    id: data.id,
                    indicator: data.attributes.indicator,
                    dimensionID: data.attributes.dimension.data.id,
                    dimensionName: data.attributes.dimension.data.attributes.dimension,
                    isActive: data.attributes.isActive
                }
            })
        };

        function handleClickOpenEdit(indicatorID) {
            setTitle(i18n.t('title.editIndicator', { lng: selectedLanguage }))
            setOpen(true);
            let data = indicators.find(d => d.id === indicatorID)
            setIndicator(i => {
                return {
                    id: data.id,
                    indicator: data.attributes.indicator,
                    dimensionID: data.attributes.dimension.data.id,
                    dimensionName: data.attributes.dimension.data.attributes.dimension,
                    isActive: data.attributes.isActive
                }
            })
        };

        function handleClickDelete(indicatorID) {
            setTitle(i18n.t('title.confirm', { lng: selectedLanguage }))
            setOpen(true)
            let data = indicators.find(d => d.id === indicatorID)
            setIndicator(data)
        }

        return (
            <>
                <IconButton aria-label="more" onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem onClick={() => handleClickOpenView(actionID)}>
                        <VisibilityOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> {i18n.t('options.view', { lng: selectedLanguage })}
                    </MenuItem>
                    <MenuItem onClick={() => handleClickOpenEdit(actionID)}>
                        <EditOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> {i18n.t('options.edit', { lng: selectedLanguage })}
                    </MenuItem>
                    <MenuItem onClick={() => handleClickDelete(actionID)}>
                        <ClearOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> {i18n.t('options.delete', { lng: selectedLanguage })}
                    </MenuItem>

                </Menu>
            </>
        );
    }

    function handleClose() {
        setOpen(false);
    };

    return (
        <Paper component={Paper} sx={{ padding: 2, marginBottom: 10 }}>
            <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{i18n.t('table.name', { lng: selectedLanguage })}</StyledTableCell>
                            <StyledTableCell>{i18n.t('table.state', { lng: selectedLanguage })}</StyledTableCell>
                            <StyledTableCell>{i18n.t('table.dimension', { lng: selectedLanguage })}</StyledTableCell>
                            <StyledTableCell>{i18n.t('table.action', { lng: selectedLanguage })}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentItems?.map(row => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">
                                    {row?.attributes?.indicator || "N/A"}
                                </StyledTableCell>
                                <StyledTableCell>{
                                    row?.attributes?.isActive === true ? i18n.t('options.active', { lng: selectedLanguage }) : i18n.t('options.inactive', { lng: selectedLanguage }) || "N/A"
                                }</StyledTableCell>
                                <StyledTableCell>{row?.attributes?.dimension?.data?.attributes?.dimension || "N/A"}</StyledTableCell>
                                <StyledTableCell>
                                    <div style={{ marginRight: "35px" }}>
                                        <LongMenu actionID={row.id} />
                                    </div>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='md'
                sx={{ border: 2, borderColor: "#F7931E" }}
            >
                <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" alignItems="center">
                    {title}
                </DialogTitle>
                <DialogContent>
                    {title === i18n.t('title.confirm', { lng: selectedLanguage }) ? <IndicatorDelete indicator={indicator} handleClose={handleClose} setList={setIndicators} list={indicators} /> :
                        currentLocation.pathname === '/indicator' ? <IndicatorForm indicador={indicator} setList={setIndicators} list={indicators} handleClose={handleClose} titleAction={title} /> : ''}

                </DialogContent>
            </Dialog>
        </Paper>
    );
}
