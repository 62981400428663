import { useMutation } from '@apollo/client';
import { Button, Grid } from '@mui/material';
import { toast } from 'react-toastify';
import { DELETE_AWARD, DELETE_EDUCATION, DELETE_EXPERIENCE, DELETE_EXPERTISE_CATEGORY, DELETE_PORTFOLIO, DELETE_PROFILE, DELETE_SKILLS, UPDATE_CLIENTS } from '../mutation.gql';

const DeleteStep = (
    {
        step,
        portfolio,
        setPortfolio,
        profiles,
        setProfiles,
        awards,
        setAwards,
        clients,
        setClients,
        educations,
        setEducations,
        experiences,
        setExperiences,
        expertises,
        setExpertises,
        setSkills,
        skills,
        stepID,
        collaborator,
        setCollaborator,
        collaborators,
        setCollaborators,
        handleClose,
    }) => {
    const [deleteExpertise] = useMutation(DELETE_EXPERTISE_CATEGORY)
    const [deleteSkill] = useMutation(DELETE_SKILLS)
    const [deleteExperience] = useMutation(DELETE_EXPERIENCE)
    const [deleteEducation] = useMutation(DELETE_EDUCATION)
    const [deleteProfile] = useMutation(DELETE_PROFILE)
    const [deleteAward] = useMutation(DELETE_AWARD)
    const [deletePortfolio] = useMutation(DELETE_PORTFOLIO)
    const [updateClients] = useMutation(UPDATE_CLIENTS)
    const jwt = localStorage.getItem("jwtToken")


    const handleDelete = () => {
        /*  
  */
        if (step === "Clear Expertise") {

            if (expertises?.length > 0) {
                expertises.forEach((expertise, index) => {
                    deleteExpertise({
                        context: {
                            headers: {
                                authorization: `Bearer ${jwt}`,
                            },
                        },
                        variables: {
                            id: expertise.id,
                        },
                    })
                        .then((data) => {
                            toast.success("Expertise Deleted!");
                            /* const updatedData = expertises.filter((obj) => obj.id !== expertise.id);
                            setExpertises(updatedData);
                    
                            const updatedMainData = collaborators.map((obj) => {
                              if (obj.id === collaborator.id) {
                                return { ...obj, expertise: updatedData };
                              }
                              return obj;
                            });
                    
                            setCollaborators(updatedMainData); */

                            if (index === expertises?.length - 1) {
                                const updatedData = [];
                                setExpertises(updatedData);

                                const updatedMainData = collaborators.map((obj) => {
                                    if (obj.id === collaborator.id) {
                                        return { ...obj, expertise: updatedData };
                                    }
                                    return obj;
                                });

                                setCollaborators(updatedMainData);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            toast.error("Something Went Wrong! Please Refresh");
                        });
                });
            }


        }
        if (step === "Delete Expertise") {
            deleteExpertise({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: stepID
                },
            }).then((data) => {
                toast.success("Expertise Deleted!");
                const updatedData = expertises.filter(obj => obj.id !== stepID);
                setExpertises(updatedData);
                const updatedMainData = collaborators.map(obj => {
                    if (obj.id === collaborator.id) {
                        return { ...obj, expertise: updatedData };; // Replace the object with the updated collaborator
                    }
                    return obj; // Keep the original object
                });

                setCollaborators(updatedMainData);

            }).catch((error) => {
                console.log(error)
                toast.error("Something went wrong!");
            });

        }


        if (step === "Clear Skill") {

            if (skills?.length > 0) {
                skills.forEach((skill, index) => {
                    deleteSkill({
                        context: {
                            headers: {
                                authorization: `Bearer ${jwt}`,
                            },
                        },
                        variables: {
                            id: skill.id,
                        },
                    })
                        .then((data) => {
                            toast.success("Skill Deleted!");

                            if (index === skills?.length - 1) {
                                const updatedData = [];
                                setSkills(updatedData);

                                const updatedMainData = collaborators.map((obj) => {
                                    if (obj.id === collaborator.id) {
                                        return { ...obj, skills: updatedData };
                                    }
                                    return obj;
                                });

                                setCollaborators(updatedMainData);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            toast.error("Something Went Wrong! Please Refresh");
                        });
                });
            }


        }
        if (step === "Delete Skill") {
            deleteSkill({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: stepID
                },
            }).then((data) => {
                toast.success("Skill Deleted!");
                const updatedData = skills.filter(obj => obj.id !== stepID);
                setSkills(updatedData);
                const updatedMainData = collaborators.map(obj => {
                    if (obj.id === collaborator.id) {
                        return { ...obj, skills: updatedData };; // Replace the object with the updated collaborator
                    }
                    return obj; // Keep the original object
                });

                setCollaborators(updatedMainData);

            }).catch((error) => {
                console.log(error)
                toast.error("Something went wrong!");
            });

        }

        if (step === "Clear Portfolio") {

            if (portfolio?.length > 0) {
                portfolio.forEach((port, index) => {
                    deletePortfolio({
                        context: {
                            headers: {
                                authorization: `Bearer ${jwt}`,
                            },
                        },
                        variables: {
                            id: port.id,
                        },
                    })
                        .then((data) => {
                            toast.success("Portfolio Deleted!");
                            fetch(`http://localhost:1337/api/upload/files/${port.imageID}`, {
                                method: "DELETE",
                                headers: {
                                    Authorization: `Bearer ${jwt}`,
                                },
                            })
                            if (index === portfolio?.length - 1) {
                                const updatedData = [];
                                setPortfolio(updatedData);

                                const updatedMainData = collaborators.map((obj) => {
                                    if (obj.id === collaborator.id) {
                                        return { ...obj, skills: updatedData };
                                    }
                                    return obj;
                                });

                                setCollaborators(updatedMainData);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            toast.error("Something Went Wrong! Please Refresh");
                        });
                });
            }


        }
        if (step === "Delete Portfolio") {
            deletePortfolio({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: stepID
                },
            }).then((data) => {
                console.log(data.data)
                const foundExpertise = portfolio.find((expertise) => expertise.id === stepID);
                fetch(`http://localhost:1337/api/upload/files/${foundExpertise.imageID}`, {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                })
                toast.success("Portfolio Eliminated!!");
                const updatedData = portfolio.filter(obj => obj.id !== stepID);
                setPortfolio(updatedData);
                const updatedMainData = collaborators.map(obj => {
                    if (obj.id === collaborator.id) {
                        return { ...obj, portfolios: updatedData };; // Replace the object with the updated collaborator
                    }
                    return obj; // Keep the original object
                });

                setCollaborators(updatedMainData);
            }).catch((error) => {
                console.log(error)
                toast.error("Something went wrong!");
            });

        }


        if (step === "Delete Award") {
            deleteAward({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: stepID
                },
            }).then((data) => {
                toast.success("Profile Award!");
                const updatedData = awards.filter(obj => obj.id !== stepID);
                setAwards(updatedData);
                const updatedMainData = collaborators.map(obj => {
                    if (obj.id === collaborator.id) {
                        return { ...obj, awards: updatedData };; // Replace the object with the updated collaborator
                    }
                    return obj; // Keep the original object
                });

                setCollaborators(updatedMainData);
            }).catch((error) => {
                console.log(error)
                toast.error("Something went wrong!");
            });

        }
        if (step === "Clear Award") {
            if (awards?.length > 0) {
                awards.forEach((award, index) => {
                    deleteAward({
                        context: {
                            headers: {
                                authorization: `Bearer ${jwt}`,
                            },
                        },
                        variables: {
                            id: award.id,
                        },
                    })
                        .then((data) => {
                            toast.success("Awards Deleted!");

                            if (index === awards?.length - 1) {
                                const updatedData = [];
                                setAwards(updatedData);

                                const updatedMainData = collaborators.map((obj) => {
                                    if (obj.id === collaborator.id) {
                                        return { ...obj, awards: updatedData };
                                    }
                                    return obj;
                                });

                                setCollaborators(updatedMainData);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            toast.error("Something Went Wrong! Please Refresh");
                        });
                });
            }


        }


        if (step === "Delete Profile") {
            deleteProfile({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: stepID
                },
            }).then((data) => {
                toast.success("Profile Deleted!");
                const updatedData = profiles.filter(obj => obj.id !== stepID);
                setProfiles(updatedData);
                const updatedMainData = collaborators.map(obj => {
                    if (obj.id === collaborator.id) {
                        return { ...obj, profiles: updatedData };; // Replace the object with the updated collaborator
                    }
                    return obj; // Keep the original object
                });

                setCollaborators(updatedMainData);
            }).catch((error) => {
                console.log(error)
                toast.error("Something went wrong!");
            });
        }
        if (step === "Clear Profile") {
            if (profiles?.length > 0) {
                profiles.forEach((profile, index) => {
                    deleteProfile({
                        context: {
                            headers: {
                                authorization: `Bearer ${jwt}`,
                            },
                        },
                        variables: {
                            id: profile.id,
                        },
                    })
                        .then((data) => {
                            toast.success("Profiles Deleted!");

                            if (index === profiles?.length - 1) {
                                const updatedData = [];
                                setProfiles(updatedData);

                                const updatedMainData = collaborators.map((obj) => {
                                    if (obj.id === collaborator.id) {
                                        return { ...obj, profiles: updatedData };
                                    }
                                    return obj;
                                });

                                setCollaborators(updatedMainData);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            toast.error("Something Went Wrong! Please Refresh");
                        });
                });
            }


        }


        if (step === "Delete Client") {
            const updatedData = clients.filter(obj => obj.id !== stepID);
            const updatedDataIDS = updatedData.map(obj => obj.id);

            updateClients({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                variables: {
                    id: collaborator.clientID, data: { collaborator: collaborator.id, companies: updatedDataIDS }
                },
            }).then((data) => {
                toast.success("Client Updated!");
                setClients(updatedData);
                const updatedMainData = collaborators.map(obj => {
                    if (obj.id === collaborator.id) {
                        return { ...obj, client: updatedData };; // Replace the object with the updated collaborator
                    }
                    return obj; // Keep the original object
                });

                setCollaborators(updatedMainData);
            })
                .catch((error) => {
                    console.log(error);
                    toast.error("Something Went Wrong! Please Refresh");
                });

        }
        if (step === "Clear Client") {
            updateClients({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                variables: {
                    id: collaborator.clientID, data: { collaborator: collaborator.id, companies: [] }
                },
            }).then((data) => {
                toast.success("Education Deleted!");
                const updatedData = [];
                setClients(updatedData);
                const updatedMainData = collaborators.map(obj => {
                    if (obj.id === collaborator.id) {
                        return { ...obj, client: updatedData };; // Replace the object with the updated collaborator
                    }
                    return obj; // Keep the original object
                });

                setCollaborators(updatedMainData);
            })
                .catch((error) => {
                    console.log(error);
                    toast.error("Something Went Wrong! Please Refresh");
                });
        }

        if (step === "Clear Education") {

            if (educations?.length > 0) {
                educations.forEach((education, index) => {
                    deleteEducation({
                        context: {
                            headers: {
                                authorization: `Bearer ${jwt}`,
                            },
                        },
                        variables: {
                            id: education.id,
                        },
                    })
                        .then((data) => {
                            toast.success("Education Deleted!");

                            if (index === educations?.length - 1) {
                                const updatedData = [];
                                setEducations(updatedData);

                                const updatedMainData = collaborators.map((obj) => {
                                    if (obj.id === collaborator.id) {
                                        return { ...obj, educations: updatedData };
                                    }
                                    return obj;
                                });

                                setCollaborators(updatedMainData);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            toast.error("Something Went Wrong! Please Refresh");
                        });
                });
            }


        }
        if (step === "Delete Education") {
            deleteEducation({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: stepID
                },
            }).then((data) => {
                toast.success("Education Deleted!");
                const updatedData = educations.filter(obj => obj.id !== stepID);
                setEducations(updatedData);
                const updatedMainData = collaborators.map(obj => {
                    if (obj.id === collaborator.id) {
                        return { ...obj, educations: updatedData };; // Replace the object with the updated collaborator
                    }
                    return obj; // Keep the original object
                });
                setCollaborators(updatedMainData);
            }).catch((error) => {
                console.log(error)
                toast.error("Something went wrong!");
            });


        }


        if (step === "Delete Experience") {

            deleteExperience({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: stepID
                },
            }).then((data) => {
                toast.success("Experience Deleted!");
                const updatedData = experiences.filter(obj => obj.id !== stepID);
                setExperiences(updatedData);
                const updatedMainData = collaborators.map(obj => {
                    if (obj.id === collaborator.id) {
                        return { ...obj, experiences: updatedData };; // Replace the object with the updated collaborator
                    }
                    return obj; // Keep the original object
                });

                setCollaborators(updatedMainData);

            }).catch((error) => {
                console.log(error)
                toast.error("Something went wrong!");
            });

        }
        if (step === "Clear Experience") {

            if (experiences?.length > 0) {
                experiences.forEach((experience, index) => {
                    deleteExperience({
                        context: {
                            headers: {
                                authorization: `Bearer ${jwt}`,
                            },
                        },
                        variables: {
                            id: experience.id,
                        },
                    })
                        .then((data) => {
                            toast.success("Experience Deleted!");

                            if (index === experiences?.length - 1) {
                                const updatedData = [];
                                setExperiences(updatedData);

                                const updatedMainData = collaborators.map((obj) => {
                                    if (obj.id === collaborator.id) {
                                        return { ...obj, experiences: updatedData };
                                    }
                                    return obj;
                                });

                                setCollaborators(updatedMainData);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            toast.error("Something Went Wrong! Please Refresh");
                        });
                });
            }


        }

        handleClose(false)
    };


    return (

        <form onSubmit={handleDelete}>
            <Grid container spacing={2} marginTop={1}>
                <Grid container spacing={3} item xs={12} display="flex" justifyContent="space-around" alignItems="center">
                    <Grid item>
                        <Button variant="outlined" size="small" onClick={handleClose}>Cancelar</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={
                                handleDelete

                            }
                        >Eliminar</Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );

};

export default DeleteStep;
