
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Dialog, DialogContent, DialogTitle, IconButton, Menu, MenuItem } from '@mui/material';
import { useLocation } from 'react-router-dom';
import PostRoleDelete from './post-role-delete';
import PostRoleForm from './post-role-form';
import { useContext, useEffect, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomPagination from '../../components/TableComponents/CustomPagination';
import i18n from '../../translate/i18n';
import { LanguageContext } from '../../translate/languages/LanguageContext';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: 0,
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    }
}));

export default function PostRoleList({ filteredSearched, postRoles, setPostRoles }) {
    const { selectedLanguage } = useContext(LanguageContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortedPage, setSortedPage] = useState(filteredSearched);

    const itemsPerPage = 10;
    // Calculate the indexes of the first and last items to display on the current page
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;

    // Extract the items to display on the current page
    const currentItems = sortedPage.slice(firstIndex, lastIndex);

    // Calculate the total number of pages
    const totalPages = Math.ceil(sortedPage?.length / itemsPerPage);

    // Handle the page change event
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        // Set the current page to 1 whenever the evaluation prop changes
        setCurrentPage(1);
        setSortedPage(filteredSearched)
    }, [filteredSearched]);

    const [open, setOpen] = useState(false);

    const [postRole, setPostRole] = useState({
        id: '',
        postRole: '',
        description: "",
        isActive: ''
    });


    const currentLocation = useLocation()

    const [title, setTitle] = useState("Novo Cargo");
    function LongMenu({ actionID }) {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        function handleClickOpenView(postRoleID) {
            //console.log("postRoleViewID", postRoleID)
            setOpen(true);
            setTitle(i18n.t('title.viewRole', { lng: selectedLanguage }))
            let data = postRoles.find(d => d.id === postRoleID)
            setPostRole(i => {
                return {
                    id: data.id,
                    postRole: data.attributes.postRole,
                    description: data.attributes.description,
                    isActive: data.attributes.isActive
                }
            })
        };


        function handleClickOpenEdit(postRoleID) {

            //console.log("postRoleEditID", postRoleID)
            setOpen(true);
            setTitle(i18n.t('title.editRole', { lng: selectedLanguage }))
            let data = postRoles.find(d => d.id === postRoleID)
            setPostRole(i => {
                return {
                    id: data.id,
                    postRole: data.attributes.postRole,
                    description: data.attributes.description,
                    isActive: data.attributes.isActive
                }
            })
        };

        function handleClickDelete(postRoleID) {

            //console.log("postRoleDeleteID", postRoleID)
            setTitle(i18n.t('title.confirm', { lng: selectedLanguage }))
            setOpen(true)
            let data = postRoles.find(d => d.id === postRoleID)
            setPostRole(i => {
                return {
                    id: data.id,
                    postRole: data.attributes.postRole,
                    description: data.attributes.description,
                    isActive: data.attributes.isActive
                }
            })
        };

        return (
            <>
                <IconButton aria-label="more" onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem onClick={() => handleClickOpenView(actionID)}>
                        <VisibilityOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> {i18n.t('options.view', { lng: selectedLanguage })}
                    </MenuItem>
                    <MenuItem onClick={() => handleClickOpenEdit(actionID)}>
                        <EditOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> {i18n.t('options.edit', { lng: selectedLanguage })}
                    </MenuItem>
                    <MenuItem onClick={() => handleClickDelete(actionID)}>
                        <ClearOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> {i18n.t('options.delete', { lng: selectedLanguage })}
                    </MenuItem>

                </Menu>
            </>
        );
    }


    function handleClose() {
        setOpen(false);
    };

    return (
        <Paper component={Paper} sx={{ padding: 1, marginBottom: 10 }}>
            <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell ><IconButton style={{ visibility: "hidden" }} /> {i18n.t('table.name', { lng: selectedLanguage })}</StyledTableCell>
                            <StyledTableCell style={{ width: "25rem" }} align="left">{i18n.t('table.description', { lng: selectedLanguage })}</StyledTableCell>
                            <StyledTableCell align="left">{i18n.t('table.state', { lng: selectedLanguage })}</StyledTableCell>
                            <StyledTableCell align="right">
                                <div style={{ marginRight: "35px" }}>{i18n.t('table.action', { lng: selectedLanguage })}</div>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentItems.map(row => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">
                                    <IconButton style={{ visibility: "hidden" }} />
                                    {row.attributes.postRole || "N/A"}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {row.attributes.description || "N/A"}
                                </StyledTableCell>
                                <StyledTableCell>{row.attributes.isActive === true ? i18n.t('options.active', { lng: selectedLanguage }) : i18n.t('options.inactive', { lng: selectedLanguage }) || "N/A"}</StyledTableCell>
                                <StyledTableCell align="right">
                                    <div style={{ marginRight: "35px" }}>
                                        <LongMenu actionID={row.id} />
                                    </div>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='md'
                sx={{ border: 2, borderColor: "#F7931E" }}
            >
                <DialogTitle display="flex" justifyContent="center" alignItems="center">
                    {title}
                </DialogTitle>
                <DialogContent>

                    {title === i18n.t('title.confirm', { lng: selectedLanguage }) ? <PostRoleDelete handleClose={handleClose} postRole={postRole} setList={setPostRoles} list={postRoles} /> :
                        currentLocation.pathname === '/collaborator/role' ? <PostRoleForm cargo={postRole} setList={setPostRoles} list={postRoles} handleClose={handleClose} titleAction={title} /> : ''}
                </DialogContent>
            </Dialog>
        </Paper>
    );
}
