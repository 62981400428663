import { Translate } from "@mui/icons-material";
import i18n from "../../../translate/i18n";

const Languages = ({ language, lng }) => {
  return (
    <>
      <div className="mt-10">
        <h4 className="curv-title mb-4 flex items-center gap-2">
          <span className="augmented-top"><Translate /></span>
          {i18n.t('cv.language', { lng: lng })}
        </h4>
        <div className="flex flex-wrap w-full gap-2">
          {
            language?.map((item, index) => (
              <div key={index} className="flex flex-col text-white flex-wrap w-full justify-around gap-16">
                <div className="w-full curv-lang flex gap-10 item-center justify-between">
                  <div className="flex items-center self-start w-full flex-1">
                    <small className="w-full block self-start">{item.name}</small>
                  </div>
                  {/* <div className="flex flex-col mt-2">
                    <div className="curv-bubbles flex items-center gap-1">
                      <span className="bubble active"></span>
                      <span className="bubble active"></span>
                      <span className="bubble active"></span>
                      {
                        Array.from({ length: 2 }).map((_, index) => (
                          <span key={index} className="bubble"></span>
                        ))
                      }
                    </div> */}
                  <span className="rounded-[50px] self-start ml-auto block text-center augmented-top-alt text-[#F89220] font-black px-[0.5rem]" style={{ fontSize: '0.75rem' }}>
                    {
                      item?.level ?
                        i18n.t(`cv.level.${item?.level?.toLowerCase()}`, { lng: lng })
                        : 'native'
                    }
                  </span>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </>
  );
};

export default Languages;