import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_DIST_COMPANY } from "./query.gql";
import { GET_ASSOC_DIST_TO_COLLAB, GET_DISTRIBUTTOR_BY_USER_PERMISSION, GET_EMP_DIST_DATA } from "../../Collaborator/Collaborator_Creation_Page/query.gql";
import { GET_DISTRIBUTOR_COMPANIES_IDs } from "../../../services/distributors/gql/query";


export const useGetDistCompanies = (uIdDist) => {

  const jwt = localStorage.getItem("jwtToken");
  const userId = localStorage.getItem("userId");
  const [retrieveData, setRetrieveData] = useState([]);

  const [getDistCompanies] = useLazyQuery(GET_DISTRIBUTOR_COMPANIES_IDs);

  useEffect(() => {
    if (!uIdDist) return;

    getDistCompanies({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      fetchPolicy: "no-cache",
      variables: {
        userId,
      },
    }).then((res) => {
      console.log("RESULTADOSCOMPPP", res?.data);
      setRetrieveData(res?.data?.distributors?.data?.[0]?.attributes?.companies?.data?.map((item) => item.id) || []);
    });

  }, [uIdDist]);

  return retrieveData;

};

export const useGetDistByUserId = () => {
  const jwt = localStorage.getItem("jwtToken");
  const userId = localStorage.getItem('userId');
  const [uIdDistributor, setUIdDistributor] = useState("");
  const [getD] = useLazyQuery(GET_DISTRIBUTTOR_BY_USER_PERMISSION);

  useEffect(() => {
    if (userId) {
      getD({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          }
        },
        variables: {
          userId
        },
      }).then((res) => {
        if (res?.data) {
          const currentDistributor = res?.data?.distributors?.data
          setUIdDistributor(currentDistributor?.[0]?.id);
        }
      });
    }
  }, []);
  return uIdDistributor;
};

export const useGetAssocDistCollab = () => {
  const jwt = localStorage.getItem("jwtToken");
  const userId = localStorage.getItem('userId');
  const [ids, setIds] = useState({ collaborators: [], distributors: [] });
  const [getAssocDistCollabs] = useLazyQuery(GET_ASSOC_DIST_TO_COLLAB);

  useEffect(() => {
    if (userId) {
      getAssocDistCollabs({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
      }).then((res) => {
        const collaboratorsId =
          res?.data?.employmentColabDistributors?.data?.attributes?.collaborators?.data?.map((c) => c.id) || [];

        const distId =
          res?.data?.employmentColabDistributors?.data?.attributes?.distributors?.data?.map((d) => d.id) || [];

        setIds({ collaborators: collaboratorsId, distributors: distId });
      });
    }
  }, []);

  return ids;
};

export const useGetAssocDistCollabData = () => {
  const jwt = localStorage.getItem("jwtToken");
  const userId = localStorage.getItem('userId');
  const [data, setData] = useState({ collaborators: [], distributors: [] });
  const [getAssocDistCollabs] = useLazyQuery(GET_ASSOC_DIST_TO_COLLAB);

  useEffect(() => {
    if (userId) {
      getAssocDistCollabs({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
      }).then((res) => {
        const contractedbyDist = res?.data?.employmentcolabdistributors?.data;
        setData(contractedbyDist);
      });
    }
  }, []);

  return data;
};


export const useEmploymentDistributorId = () => {
  const jwt = localStorage.getItem("jwtToken");
  const [data, setData] = useState({ collaborators: [], distributors: [] });

  const [getEmpDist] = useLazyQuery(GET_EMP_DIST_DATA);

  useEffect(() => {

    if (jwt) {
      getEmpDist({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
      }).then((res) => {
        const dist = res?.data?.employmentcolabdistributors?.data?.map(item => ({
          id: item?.attributes?.distributors?.data?.[0]?.id
        }))

        const cols = res?.data?.employmentcolabdistributors?.data?.map(item => ({
          id: item?.attributes?.collaborators?.data?.[0]?.id
        }))

        setData({ collaborators: cols, distributors: dist });
      });
    }

  }, [jwt]);

  return data;
};