
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { Card, CardActions, CircularProgress, Typography } from '@mui/material';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import DoneIcon from '@mui/icons-material/Done';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Pagination } from '@mui/material';
import React, { useEffect, useState, useRef, useContext } from 'react';
import './company.css';
import CompanyDelete from './Company_delete';
import CreateCompany from '../Company_Creation_Page/create_company'
import i18n from '../../../translate/i18n';
import { LanguageContext } from '../../../translate/languages/LanguageContext';
import { UserRoleContext } from "../../../contexts/userRole-context";
import { AlternateEmail, ContactPhone, Groups, KeyboardArrowLeft, KeyboardArrowRight, Place, RingVolume } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 0,
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  }
}));


export default function CompanyList({
  setCompanies,
  company_,
  setCompany_,
  setCompany_Edit,
  currentID,
  setCurrentID,
  company_Edit,
  companies_,
  setOpenAlertOK,
  setSuccessMessage,
  updater,
  setUpdater
}) {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [activeOption, setActiveOption] = useState(false)
  const [companyUser, setCompanyUser] = useState("");


  const { selectedLanguage } = useContext(LanguageContext);
  const { uRoleContext } = useContext(UserRoleContext);
  const itemsPerPage = 6;


  // Calculate the indexes of the first and last items to display on the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  // Extract the items to display on the current page
  const currentItems = companies_?.slice(firstIndex, lastIndex);

  // Calculate the total number of pages
  const totalPages = Math.ceil(companies_?.length / itemsPerPage);

  // Handle the page change event
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const { REACT_APP_API_URL } = process.env;

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    setCurrentPage(1);

  }, [companies_, totalPages]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 4500);
  }, [companies_]);


  const [companyID, setCompanyID] = useState(false);
  const [company, setCompany] = useState("");
  const [open, setOpen] = useState(false);


  function handleClickDelete(companyID, companyData) {
    console.log("companyData", companyData);

    setCompanyID(companyID)
    setCompanyUser(companyData?.attributes?.users_permissions_user?.data);

    setOpen(true)
    setActiveOption(false)
    let data = companies_.find(d => d.id === companyID)
    setCompany(data)

  };


  const clickDisable = (companyID, companyData) => {
    setActiveOption(true)
    setOpen(true)
    setCompanyID(companyID)
    setOpenTarget(!openTarget)
    setDisplay(false);
    setCurrentCompanyId(null);

    let data = companies_.find(d => d.id === companyID)
    setCompany(data)
    setCompanyUser(data);

  }

  function handleClickOpenView(companyID, companyData) {

    setCompany_Edit(false)
    let data = companies_.find(d => d.id === companyID)

    setCompany_(i => {
      return {
        id: data.id,
        userID: data?.attributes?.users_permissions_user?.data?.id,
        name: data?.attributes?.name,
        country: data?.attributes?.country?.data?.attributes?.name,
        city: data?.attributes?.city?.data?.attributes?.name,
        email: data?.attributes?.email,
        phone: data?.attributes?.phone,
        status: data?.attributes?.status,
        branchOfActivity: data?.attributes.branchOfActivity,
        image: data?.attributes?.image?.data?.attributes.url,
        imageID: data?.attributes?.image?.data?.id,
        findDevs: data?.attributes?.findDevs?.data,
        countryID: data?.attributes?.country?.data?.id,
        cityID: data?.attributes?.city?.data?.id,
        confirmed: data?.attributes?.users_permissions_user?.data?.attributes?.confirmed,
      }
    })

  };

  function handleClose() {
    setOpen(false);
    setOpenModal(false);
    // setCompanies(companies)
  };
  function handleEdit(companyID, companyData) {
    //setCompany_(companyData);
    setCompany_Edit(true);
    setOpenModal(true)
    setCurrentID(companyID)
    let data = companies_.find(d => d.id === companyID)

    setCompany_(i => {
      return {
        id: data.id,
        userID: data?.attributes?.users_permissions_user?.data?.id,
        name: data?.attributes?.name,
        country: data?.attributes?.country?.data?.attributes?.name,
        city: data?.attributes?.city?.data?.attributes?.name,
        email: data?.attributes?.email,
        phone: data?.attributes?.phone,
        status: data?.attributes?.status,
        branchOfActivity: data?.attributes.branchOfActivity,
        image: data?.attributes?.image?.data?.attributes.url,
        imageID: data?.attributes?.image?.data?.id,
        findDevs: data?.attributes?.findDevs?.data,
        countryID: data?.attributes?.country?.data?.id,
        cityID: data?.attributes?.city?.data?.id,
        confirmed: data?.attributes?.users_permissions_user?.data?.attributes?.confirmed,
      }
    })

  };

  const [openTarget, setOpenTarget] = useState(false);
  const menuRef = useRef(null);
  const [display, setDisplay] = useState(-1);
  const [currentCompanyId, setCurrentCompanyId] = useState(-1);
  const toggleElement = (currentIndex, id) => {
    setOpenTarget(!openTarget)
    setDisplay(currentIndex);
    setCurrentCompanyId(id);
  };


  useEffect(() => {

    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenTarget(false)
      }

    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="flex w-full items-center gap-2">
          <CircularProgress size={15} />
          <div>Carregando empresas...</div>
        </div>
      </>
    )
  }

  console.log(currentItems)


  if (!currentItems || currentItems?.length == 0) {
    return (
      <>
        <div>Nenhum empresa cadastrada...</div>
      </>
    );
  }


  return (
    <>
      <div className="flex flex-col px-6 mb-10 py-2.5 border-t border-b">
        <span style={{ fontSize: '1rem' }} className="font-onest self-start">
          Listando
        </span>
        <span style={{ fontSize: '1.45rem', textTransform: 'capitalize' }} className="font-bold font-onest self-start">Empresas</span>
      </div>

      <div className="w-full px-6 -mt-6 pb-6 relative grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        {
          currentItems?.map((row, index) => (
            <div
              key={row.id}
              className="bg-white rounded-[12px] border border-gray-200 mt-6 shadow-md relative py-6 px-6"
            >
              <div className="absolute top-4 right-4">
                <IconButton
                  aria-label="view"
                  color="secondary"
                  onClick={() => toggleElement(index, row.id)} >
                  <MoreVertIcon />
                </IconButton>
              </div>
              <div className="flex">
                <div className='flex self-start items-center jusitfy-center'>
                  {
                    row?.attributes?.image ? (
                      <img
                        src={`${REACT_APP_API_URL}${row?.attributes?.image?.data?.attributes?.url}`}
                        className='w-[50px] h-[50px] aspect-square rounded-full border object-top'
                      />
                    ) : (
                      <span className="flex items-center justify-center w-[50px] h-[50px] aspect-square rounded-full bg-[#EA1967] text-white text-xl lg:text-xl">{row?.attributes?.name[0] || "-"}</span>
                    )
                  }
                </div>
                <div className="flex flex-col ml-2">
                  <h4 className="font-bold self-start" style={{ fontSize: '0.85rem' }}>
                    {row?.attributes?.name || "N/A"}
                  </h4>
                  <small style={{ fontSize: '0.85rem' }} className="text-[rgba(0,0,0,0.65)] self-start -mt-1">
                    <Place sx={{ fontSize: '16px' }} />
                    {row?.attributes?.city?.data?.attributes?.name || "N/A"} {" "} {row?.attributes?.country?.data?.attributes?.name || "N/A"}
                  </small>
                  <div className="flex flex-col w-full">
                    <small className='w-full flex items-center gap-2 text-[#F89220]' style={{ fontWeight: 'bold' }}>
                      Branch: {" "}
                      <span className='font-bold text-black'>
                        {row?.attributes?.branchOfActivity || "N/A"}
                      </span>
                    </small>
                  </div>
                </div>
              </div>
              <div className="border-t flex flex-col w-full gap-1 mt-2 pt-2">
                <div className="flex items-center w-full gap-2" style={{ fontSize: '13px' }}>
                  <div className="flex items-center text-[#F89220] gap-2" style={{ fontWeight: 'bold' }}>
                    <ContactPhone sx={{ fontSize: '18px' }} />
                    Contactos:
                  </div>
                  <span className='whitespace-nowrap block text-ellipsis overflow-hidden line-clamp-1' style={{ fontWeight: 'bold' }}>{row?.attributes?.phone || "N/A"}</span>
                </div>
                <div className="flex items-center w-full gap-2" style={{ fontSize: '13px' }}>
                  <div className="flex whitespace-nowrap items-center text-[#F89220] gap-2" style={{ fontWeight: 'bold' }}>
                    <AlternateEmail sx={{ fontSize: '18px' }} />
                    E-mail:
                  </div>
                  <span
                    className='whitespace-nowrap block text-ellipsis overflow-hidden line-clamp-2'
                    style={{
                      fontWeight: 'bold'
                    }}>
                    {row?.attributes?.email || "N/A"}
                  </span>
                </div>
              </div>
              <>
                {openTarget && display === index ?
                  <Card ref={menuRef} variant="outlined" sx={{ position: 'absolute', right: -25, width: 240, top: 56, zIndex: 999999999 }} >

                    <CardActions
                      disableSpacing>

                      <IconButton aria-label="view"

                        onClick={() => handleClickOpenView(row.id, row)}
                      >

                        <VisibilityOutlinedIcon color="primary" />

                        <Typography sx={{ paddingLeft: 1 }} id={row.id}> {i18n.t('options.view', { lng: selectedLanguage })} </Typography>
                      </IconButton>

                    </CardActions>
                    {
                      row?.attributes?.status === 'Active' || uRoleContext === 'administrator' ? <CardActions
                        disableSpacing  >
                        <IconButton aria-label="view" onClick={() => handleEdit(row.id, row)} >
                          <EditOutlinedIcon color="primary" />
                          <Typography sx={{ paddingLeft: 1 }} id={row.id}>{i18n.t('options.edit', { lng: selectedLanguage })}</Typography>
                        </IconButton>

                      </CardActions> : ''}

                    <CardActions
                      disableSpacing >
                      <IconButton aria-label="view"
                        onClick={() => handleClickDelete(row.id, row)}
                      >

                        <ClearOutlinedIcon color="primary" />
                        <Typography sx={{ paddingLeft: 1 }} id={row.id}> {i18n.t('options.delete', { lng: selectedLanguage })} </Typography>
                      </IconButton>

                    </CardActions>

                    <CardActions
                      disableSpacing >
                      <IconButton aria-label="view"
                        onClick={() => {
                          navigate(`/company/${row.id}/collaborators`, { state: { comp: row } })
                        }}
                      >

                        <Groups color="primary" />
                        <Typography sx={{ paddingLeft: 1 }} id={row.id}> {i18n.t('tittles.company_col', { lng: selectedLanguage })} </Typography>
                      </IconButton>

                    </CardActions>


                    <CardActions

                      disableSpacing >
                      <IconButton aria-label="view"
                        onClick={() => clickDisable(row.id, row)}

                      >
                        {row?.attributes?.status === 'Active' ? (
                          <DoNotDisturbAltIcon
                            color={row?.attributes?.status === 'Active' ? 'disabled' : 'primary'}
                          />
                        ) : (
                          <DoneIcon color="primary" />
                        )}

                        <Typography sx={{ paddingLeft: 1 }} id={row.id} >
                          {
                            row?.attributes?.status === 'Active'
                              ? i18n.t('options.disable', { lng: selectedLanguage })
                              : i18n.t('options.enable', { lng: selectedLanguage })
                          }
                        </Typography>
                      </IconButton>

                    </CardActions>
                  </Card> : (
                    <></>
                  )}
              </>
            </div>
          ))
        }
      </div>


      <div className="mt-8 flex space-x-3 items-center justify-center">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="outline-none border inline-block text-center py-[0.45rem] px-[0.45rem] bg-white cursor-pointer shadow-sm text-black hover:bg-[#F9AE00] hover:text-[#fff] roudned-[8px]"
        >
          <KeyboardArrowLeft />
        </button>
        <div className="flex items-center gap-3">
          <span
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}
            className={`w-[28px] h-[28px] flex items-center justify-center text-[0.875rem] font-bold ${currentPage === totalPages ? 'bg-white text-black' : 'bg-[#F9AE00] text-white'}`}
          >
            {currentPage}
          </span>
          <span>
            {i18n.t('de', { lng: selectedLanguage })}
          </span>
          <span
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}
            className={`w-[28px] h-[28px] items-center justify-center text-[0.875rem] font-bold ${currentPage === totalPages ? 'bg-[#F9AE00] text-white' : 'bg-white text-black'}`}
          >
            {totalPages}
          </span>
        </div>
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className={`outline-none border inline-block text-center py-[0.45rem] px-[0.45rem] bg-white cursor-pointer shadow-sm text-black hover:bg-[#F9AE00] hover:text-[#fff] roudned-[8px] `}
        >
          <KeyboardArrowRight />
        </button>
      </div>

      <Paper>

        <Dialog

          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='md'
        >

          <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" alignItems="center">
            {
              activeOption === false ? (
                <p>Delete {company.attributes?.name}?</p>
              ) : (
                <p>{company?.attributes?.status === 'Active' ? 'Disable' : 'Enable'} {company?.attributes?.name}?</p>
              )
            }
          </DialogTitle>
          <DialogContent>
            <CompanyDelete
              setOpenAlertOK={setOpenAlertOK}
              setSuccessMessage={setSuccessMessage}
              companyID={companyID}
              companies={companies_}
              setCompanies={setCompanies}
              handleClose={handleClose}
              companyUserID={companyUser?.id}
              companyUser={companyUser}
              currentID={currentID}
              activeOption={activeOption}
              company={company}
              setCompany={setCompany}
            />

          </DialogContent>
        </Dialog>

        {company_Edit === true
          && <Dialog
            open={openModal}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='md'
          >
            <DialogContent>
              <CreateCompany
                updater={updater}
                setUpdater={setUpdater}
                companies={companies_}
                setCompanies={setCompanies}
                setCompany_={setCompany_}
                company_={company_}
                setCompany_Edit={setCompany_Edit}
                company_Edit={company_Edit}
                currentID={currentID}
                setCurrentID={setCurrentID}
                handleClose={handleClose}

              />
            </DialogContent>
          </Dialog>}


      </Paper>
    </>
  );

}