import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import LockResetIcon from '@mui/icons-material/LockReset';
import ConstructionIcon from '@mui/icons-material/Construction';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import QuizIcon from '@mui/icons-material/Quiz';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import {
	Box,
	Collapse,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	SwipeableDrawer,
	Tooltip,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
	AutoFixHigh,
	AutoGraph,
	DrawOutlined,
	EditNoteOutlined,
	ExpandLess,
	ExpandMore,
	FactCheckOutlined,
	Groups,
	PersonPinCircle,
	Quiz,
	StartOutlined,
} from '@mui/icons-material';
import i18n from '../../translate/i18n';
import LanguageSelector from '../../translate/languages/languageSelector';
import { LanguageContext } from '../../translate/languages/LanguageContext';
import { UserRoleContext } from '../../contexts/userRole-context';
import jwtDecode from 'jwt-decode';
import { GET_COMPANIES_ALL, GET_DISTRIBUTTOR_BY_USER_PERMISSION, GET_FURNISHERS_ALL, GET_FURNISHERS_BY_USER_PERMISSION } from '../../pages/Collaborator/Collaborator_Creation_Page/query.gql';
import { useLazyQuery } from '@apollo/client';

const SideBar = () => {
	const { uRoleContext } = useContext(UserRoleContext);
	const { selectedLanguage } = useContext(LanguageContext);
	const [open, setOpen] = useState(false);
	const [uId, setUId] = useState(null);
	const [uIdFurnisher, setUIdFurnisher] = useState(null);
	const [distID, setDistID] = useState(null);
	let navigate = useNavigate();


	const userId = localStorage.getItem('userId');
	const [getC] = useLazyQuery(GET_COMPANIES_ALL);
	const [getF] = useLazyQuery(GET_FURNISHERS_BY_USER_PERMISSION);
	const [getD] = useLazyQuery(GET_DISTRIBUTTOR_BY_USER_PERMISSION);
	const jwt = localStorage.getItem('jwtToken');
	const userMail = localStorage.getItem('userMail');
	const { id: employeerID } = jwtDecode(jwt);


	useEffect(() => {
		getC({
			context: {
				headers: {
					authorization: `Bearer ${jwt}`,
				},
			},
		}).then((res) => {
			if (res?.data) {
				const currentCompany = res?.data?.companies?.data?.filter(
					(item) => item?.attributes?.email == userMail
				);
				setUId(currentCompany?.[0]?.id);
				localStorage.setItem('companyCurrentID', currentCompany?.[0]?.id);
			}
		});
	}, [userId]);


	useEffect(() => {

		const userRole = localStorage.getItem('userRole') ?? null;

		if (userId) {

			if (userRole === 'furnishers') {
				getF({
					context: {
						headers: {
							authorization: `Bearer ${jwt}`,
						}
					},
					variables: {
						userId
					},
				}).then((res) => {
					if (res?.data) {
						const currentFurnisher = res?.data?.furnishers?.data
						setUIdFurnisher(currentFurnisher?.[0]?.id);
					}
				});
			} else {
				getD({
					context: {
						headers: {
							authorization: `Bearer ${jwt}`,
						}
					},
					variables: {
						userId
					},
				}).then((res) => {
					const dist = res?.data?.distributors?.data;
					console.log("MYCOMP", dist);
					setDistID(dist?.[0]?.id);
					localStorage.setItem("distId", dist?.[0]?.id);
				})
			}
		}
	}, [userId]);





	const getMenuForRole = (uRoleContext, i18n, selectedLanguage) => {

		switch (uRoleContext) {
			case 'administrator':
				return [
					{ Icon: HomeOutlinedIcon, title: i18n.t('tittles.dashboard', { lng: selectedLanguage }), url: "/" },

					{ Icon: CorporateFareRoundedIcon, title: i18n.t('tittles.company', { lng: selectedLanguage }), url: "/companies" },
					{ Icon: ReceiptRoundedIcon, title: i18n.t('tittles.furnisher', { lng: selectedLanguage }), url: "/furnishers" },

					{ Icon: PeopleAltOutlinedIcon, title: i18n.t('tittles.collaborator', { lng: selectedLanguage }), url: "/collaborator" },


					{
						Icon: PeopleAltOutlinedIcon,
						title: i18n.t('tittles.distributor', { lng: selectedLanguage }),
						url: '/distributor',
					},
					{
						Icon: StarOutlineIcon,
						title: i18n.t('tittles.evaluation', { lng: selectedLanguage }),
						url: '/evaluation',
					},
					{
						Icon: SettingsOutlinedIcon,
						title: i18n.t('tittles.parameterization', {
							lng: selectedLanguage,
						}),
						submenu: [
							{
								Icon: GroupWorkIcon,
								title: i18n.t('tittles.role', { lng: selectedLanguage }),
								url: '/collaborator/role',
							},
							{
								Icon: AccountTreeIcon,
								title: i18n.t('tittles.dimension', { lng: selectedLanguage }),
								url: '/dimension',
							},
							{
								Icon: CalendarMonthIcon,
								title: i18n.t('tittles.priod', { lng: selectedLanguage }),
								url: '/period',
							},
							{
								Icon: PushPinIcon,
								title: i18n.t('tittles.indicator', { lng: selectedLanguage }),
								url: '/indicator',
							},
							{
								Icon: QuizIcon,
								title: i18n.t('tittles.questao', { lng: selectedLanguage }),
								url: '/question',
							},
							{
								Icon: QuizIcon,
								title: i18n.t('tittles.quiz', { lng: selectedLanguage }),
								url: '/quiz',
							},
						],
					},
				];
			case 'distributor':
				return [
					{ Icon: HomeOutlinedIcon, title: i18n.t('tittles.dashboard', { lng: selectedLanguage }), url: "/" },

					{
						Icon: CorporateFareRoundedIcon,
						title: i18n.t('tittles.company', { lng: selectedLanguage }),
						url: `companies`,
						// url: `/dist/${distID}/companies`,
					},


					// { Icon: PeopleAltOutlinedIcon, title: i18n.t('tittles.collaborator', { lng: selectedLanguage }), url: "/collaborator" },
					{
						// Icon: PeopleAltIcon,
						Icon: PeopleAltOutlinedIcon,
						//url: '/collaborator',
						title: i18n.t('tittles.collaborator', { lng: selectedLanguage }),
						submenu: [
							{
								Icon: Groups,
								title: i18n.t('tittles.company_col', {
									lng: selectedLanguage
								}),
								url: "/collaborator"
							},
							{
								Icon: Groups,
								title: i18n.t('tittles.company_my_cols', {
									lng: selectedLanguage
								}),
								url: `/dist/${distID}/collaborators`
							},

						],
					},

					{ Icon: StarOutlineIcon, title: i18n.t('tittles.evaluation', { lng: selectedLanguage }), url: "/evaluation" },
				];

			case 'collaborator':
				return [
					{
						Icon: HomeOutlinedIcon,
						title: i18n.t('tittles.dashboard', { lng: selectedLanguage }),
						url: '/',
					},
					{
						Icon: CorporateFareRoundedIcon,
						title: i18n.t('tittles.company', { lng: selectedLanguage }),
						url: '/companies',
					},
					{
						// Icon: PeopleAltIcon,
						Icon: Quiz,
						url: '#',
						title: i18n.t('tittles.quiz', { lng: selectedLanguage }),
						submenu: [
							{
								Icon: EditNoteOutlined,
								title: i18n.t('tittles.quizTeste', {
									lng: selectedLanguage
								}),
								url: "#"
							},
							{
								Icon: FactCheckOutlined,
								title: i18n.t('tittles.quizResult', {
									lng: selectedLanguage
								}),
								url: `#`
							},

						],
					},
					// {
					// 	Icon: PeopleAltOutlinedIcon,
					// 	title: i18n.t('tittles.collaborator', { lng: selectedLanguage }),
					// 	url: '/collaborator',
					// },
					{
						Icon: StarOutlineIcon,
						title: i18n.t('tittles.evaluation', { lng: selectedLanguage }),
						url: '/evaluation',
					},
				];
			case 'company':
				return [
					// { Icon: HomeOutlinedIcon, title: i18n.t('tittles.dashboard', { lng: selectedLanguage }), url: "/" },
					{
						Icon: HomeOutlinedIcon,
						title: i18n.t('tittles.dashboard', { lng: selectedLanguage }),
						url: '/',
					},
					// { Icon: CorporateFareRoundedIcon, title: i18n.t('tittles.company', { lng: selectedLanguage }), url: "/companies" },
					//{ Icon: PeopleAltOutlinedIcon, title: i18n.t('tittles.collaborator', { lng: selectedLanguage }), url: "/collaborator" },
					{
						Icon: PeopleAltIcon,
						Icon: PeopleAltOutlinedIcon,
						title: i18n.t('tittles.collaborator', { lng: selectedLanguage }),
						submenu: [
							{ Icon: Groups, title: i18n.t('tittles.company_col', { lng: selectedLanguage }), url: "/collaborator/main" },
							{ Icon: Groups, title: i18n.t('tittles.company_my_cols', { lng: selectedLanguage }), url: `/company/${uId}/collaborators` },

						],
						url: '/collaborator',
					},
					{
						Icon: StarOutlineIcon,
						title: i18n.t('tittles.evaluation', { lgn: selectedLanguage }),
						submenu: [
							{
								Icon: AutoGraph,
								title: i18n.t('tittles.evaluationManager', { lng: selectedLanguage }),
								url: "/evaluation"
							},
							{
								Icon: AutoFixHigh,
								title: i18n.t('tittles.criterionEval', { lng: selectedLanguage }),
								url: "/evaluations"
							},
							{ Icon: AutoFixHigh, title: i18n.t('tittles.company_my_criterys', { lng: selectedLanguage }), url: `/company/${uId}/criterys` },
						],
						title: i18n.t('tittles.evaluationManager', {
							lng: selectedLanguage,
						}),
						url: '/evaluation',
					},

				];

			case "furnishers":
				return [
					{ Icon: HomeOutlinedIcon, title: i18n.t('tittles.dashboard', { lng: selectedLanguage }), url: "/" },
					{ Icon: Groups, title: i18n.t('tittles.company_my_cols', { lng: selectedLanguage }), url: `/furnisher/${uIdFurnisher}/collaborators` },
					// {
					// 	Icon: PeopleAltIcon,
					// 	title: i18n.t('tittles.collaborator', { lng: selectedLanguage }),
					// 	submenu: [
					// 		{ Icon: Groups, title: i18n.t('tittles.company_col', { lng: selectedLanguage }), url: "/collaborator" },
					// 		{ Icon: Groups, title: i18n.t('tittles.company_my_cols', { lng: selectedLanguage }), url: `/furnisher/${uIdFurnisher}/collaborators` },
					// 	]
					// },
				];
			default:
				return [];
		}
	};

	const Item = ({ Icon, title, url, submenu }) => {
		const [openSubmenu, setOpenSubmenu] = useState(false);
		const location = useLocation();
		const isSelected = location.pathname === url;
		return (
			<div>
				<ListItem disablePadding>
					<ListItemButton
						onClick={() => {
							if (submenu) {
								setOpenSubmenu(!openSubmenu);
							} else {
								setOpen(false);
								navigate(url);
							}
						}}
						sx={{
							backgroundColor: isSelected
								? 'rgba(247, 147, 30, 0.2)'
								: 'transparent',
							marginLeft: '0.5rem',
							marginRight: '0.5rem',
							borderRadius: 1,
						}}
					>
						<ListItemIcon>
							<Icon
								fontSize='small'
								sx={
									title === i18n.t('tittles.role', { lng: selectedLanguage }) ||
										title ===
										i18n.t('tittles.dimension', { lng: selectedLanguage }) ||
										title ===
										i18n.t('tittles.priod', { lng: selectedLanguage }) ||
										title ===
										i18n.t('tittles.indicator', { lng: selectedLanguage }) ||
										title ===
										i18n.t('tittles.questao', { lng: selectedLanguage }) ||
										title === i18n.t('tittles.quiz', { lng: selectedLanguage })
										? { display: 'none' }
										: { color: 'white' }
								}
							/>
						</ListItemIcon>
						<ListItemText
							primaryTypographyProps={{
								variant: 'body1',
								fontSize: '0.875rem',
							}}
							primary={title}
							sx={{ mr: 1, ml: -3.5 }}
						/>
						{submenu && (openSubmenu ? <ExpandLess /> : <ExpandMore />)}
					</ListItemButton>
				</ListItem>
				{submenu && (
					<List sx={{ display: openSubmenu ? 'block' : 'none' }}>
						{submenu.map((m, i) => (
							<Item
								key={i}
								title={m.title}
								Icon={m.Icon}
								url={m.url}
								submenu={m.submenu}
							/>
						))}
					</List>
				)}
			</div>
		);
	};
	const menu = getMenuForRole(uRoleContext, i18n, selectedLanguage);
	return (
		<div>
			<IconButton
				edge='start'
				color='inherit'
				sx={{ mr: 2 }}
				aria-label='open drawer'
				onClick={() => setOpen(true)}
			>
				<MenuIcon />
			</IconButton>

			<SwipeableDrawer
				variant='permanent'
				anchor='left'
				open={open}
				onClose={() => setOpen(false)}
				onOpen={() => { }}
				sx={{ position: 'relative' }}
			>
				<div
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						background: '#2F272D',
						color: 'white',
						height: '100%',
						width: '14.938rem',
					}}
				>
					<Box
						sx={{ paddingTop: 4, paddingLeft: 2.5, paddingBottom: 4 }}
						textAlign='left'
					>
						<img src='/logo2.svg' />
					</Box>

					<List>
						{menu.map((m, i) => (
							<Item
								key={i}
								title={m.title}
								Icon={m.Icon}
								url={m.url}
								submenu={m.submenu}
							/>
						))}
					</List>
				</div>
			</SwipeableDrawer>
		</div>
	);
};

export default SideBar;
