import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Grid, Stack } from "@mui/material";
import jwtDecode from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HistoryChart from "../../components/Chart/area-chart.component";
import DripChart from "../../components/Chart/drip-chart.component";
import MainCard from "../../components/MainCard/main-card.component";
import { setTitle } from "../../helpers";
import Analytics from "./analytics.component";
import { GET_EMPLOYEER_DATA, GET_EVALUATION_METHODS } from "./query.gql";
import i18n from "../../translate/i18n";
import { LanguageContext } from "../../translate/languages/LanguageContext";
import "./style.css";
import { EVAL_PERIODS_BY_ID, GET_COLLABORATORS } from "../Ratings/query";
import { GET_COMPANY_BY_ID } from "../CompanyPage/Company_View_Page/query.gql";
import { AuthContext } from "../../contexts/auth-context";
import { GET_COLLABORATORS_BY_COMPANY_ID, GET_COLLABORATORS_EVALUATIONS_BY_COMPANY_ID } from "../Collaborator/Collaborator_View_Page/query.gql";
import { AirplaneTicket, AppShortcut, DoubleArrow, Margin, PermMedia, Search, VideoLibraryOutlined } from "@mui/icons-material";
import CardItem from "../dashboard/cards/CardItem";
import CardService from "../dashboard/cards/CardService";
import VideoEmbed from "../dashboard/embed/VideoEmbed";
import WelcomeCard from "./cards/WelcomeCard";
import AnalisysCard from "./cards/AnalysisCard";
import AnalysisCard from "./cards/AnalysisCard";
import EvaluationHistoricCard from "./cards/EvaluationHistoricCard";
import RadarChartExample from "./charts/RadarChart";
import RadarChartWithColors from "./charts/RadarChart";
import LineChartExample from "./charts/LineChart";
import MixedChart from "./charts/MixedChart";
import PolarArea from "./charts/PolarArea";
import LineMixedChart from "./charts/LineMixedChart";
import PieChartAlt from "./charts/PieChart";
import AnalysisCardClone from "./cards/AnalysisCardClone";
import RadarChart from "./charts/RadarChartAlt";
import { CCCCCCCCCC, GET_ALL_EVALUATIONS_COLLABORATORS } from "../Ratings/mutation";
import { GET_EVALUATION_CRITERY } from "../evaluation/mutation.gql";
import { GET_FURNISHERS } from "../FurnisherPage/Furnisher_Creation_Page/query.gql";

const ProfileMenu = () => {

  let navigate = useNavigate();
  return (
    <Stack direction="row" spacing={2}>
      {/* <Button
        color="warning"
        variant="contained"
        size="small"
        style={{ borderRadius: "15px" }}
        onClick={() => navigate("profile")}
      >
        Ver Perfil
      </Button>
      <Button
        variant="text"
        size="small"
        onClick={() => navigate("password/change")}
      >
        Alterar Senha
      </Button> */}
    </Stack>
  );
};

const HomePage = () => {

  const location = useLocation();

  const { selectedLanguage } = useContext(LanguageContext);
  const jwt = localStorage.getItem("jwtToken");
  const role = localStorage.getItem('userRole')
  const { id: userID } = jwtDecode(jwt);
  const [employeerData, setEmployeerData] = useState({
    name: '',
    postRole: ''
  })

  const [getEmployeerData] = useLazyQuery(GET_EMPLOYEER_DATA)


  const [getEvals_] = useLazyQuery(GET_COLLABORATORS_BY_COMPANY_ID)
  const [getEvaluations] = useLazyQuery(GET_ALL_EVALUATIONS_COLLABORATORS);
  const [getEvaluationMethods] = useLazyQuery(GET_EVALUATION_METHODS);
  const [getEvaluationCriterium] = useLazyQuery(GET_EVALUATION_CRITERY);

  const [eval_period, setEval_period] = useState([])
  const [getCollaborators] = useLazyQuery(GET_COLLABORATORS)
  const [getCompany, { data, loading, error }] = useLazyQuery(GET_COMPANY_BY_ID);
  const { user } = useContext(AuthContext);
  const [avaliars, setAvaliars] = useState([]);
  const [getEvalsByID_] = useLazyQuery(GET_COLLABORATORS_EVALUATIONS_BY_COMPANY_ID)
  const [eval_periodHistory, setEval_periodHistory] = useState([])
  const [companyId, setCompanyId] = useState(1238)

  const [evals, setEvals] = useState([]);

  const [evaluationsCollaborators, setEvaluationsCollaborators] = useState([]);
  const [evaluationMethods, setEvaluationMethods] = useState([]);

  useEffect(() => {
    if (role == 'administrator') {
      get_All_COllaborators()
    }

    if (user?.id) {
      get_Company();
    }
  }, [user?.id]);

  const get_Company = async () => {
    try {
      await getCompany({
        variables: {
          userId: user?.id,
        },
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
      });

    } catch (error) {
      console.error("Erro ao buscar dados da empresa:", error);
    }
  };


  const [maxStar, setMaxStar] = useState([]);

  useEffect(() => {
    getEvaluationCriterium({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
    }).then(response => {
      //console.log("AAAAAAAAQQQQQQQQQQQQQQ", response);
      const evaluationsMethods = response?.data?.evaluationMethods?.data ?? [];
      setEvaluationMethods(evaluationsMethods)
    });


    getEvaluations({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
    }).then(response => {
      setEvaluationsCollaborators(response?.data?.evaluations?.data ?? []);

      console.log("AMORHELI", response);

      if (response?.data?.evaluations?.data) {
        const monthNames = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
        let monthlyMaxStars = {}; // Objeto para armazenar maxStar por mês
        let monthlyEvaluationsCount = {}; // Objeto para armazenar a contagem de avaliações por mês

        response.data.evaluations.data.forEach(item => {

          const attr = item?.attributes;

          console.log("ALLSTARTATTR", attr);
          const distribID = localStorage.getItem('distId');
          //if (attr?.collaborator?.data?.attributes?.distributors?.data?.[0]?.id == distribID) {
            if (attr.star) {
              const starValue = parseInt(attr.star);

              let date = attr.createdAt;
              if (!date) return;

              const monthIndex = parseInt(date.split("-")[1], 10) - 1;
              const month = monthNames[monthIndex];

              // Atualiza o valor máximo de estrelas para o mês
              if (!monthlyMaxStars[month] || starValue > monthlyMaxStars[month]) {
                monthlyMaxStars[month] = starValue;
              }

              // Contagem de avaliações por mês
              monthlyEvaluationsCount[month] = (monthlyEvaluationsCount[month] || 0) + 1;
            }
          //}

        });

        const result = monthNames.map(month => ({
          month,
          maxStar: monthlyMaxStars[month] || 0,
          qtdCollaboratorEvaluated: monthlyEvaluationsCount[month] || 0,
        }));

        console.log("RESULTKIBB: ", result);
        setMaxStar(result);

      }
    });
  }, []);

  useEffect(() => {

    if (data) {


      data.companies?.data?.map((tx) => {
        if (user?.id === Number(tx?.attributes.users_permissions_user.data.id)) {
          const companyId = tx?.attributes.users_permissions_user.data.id;
          get_Eval(tx.id);
          get_EvaluationByID(tx.id)
          setCompanyId(tx.id)

        }
      })
    }
    //get_Eval_()
  }, [data, loading, error])

  useEffect(() => {
    getCollaborators({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
    }).then(data => {

    });
  }, []);



  const get_EvaluationByID = async (companyId) => {

    if (!companyId) {
      console.error("ID da empresa está ausente.");
      return;
    }

    try {

      await getEvalsByID_({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: {
          companyId: companyId,
          filters: {
            isActive: {
              eq: true,
            },
            companies: {
              id: {
                eq: companyId,
              },
            },
          },
        },

      }).then((data) => {


        const evalData = data?.data?.evaluations.data
        setEval_periodHistory(evalData)
        setEvals(evalData);

      });
    } catch (error) {
      console.error("Erro ao buscar períodos de avaliação:", error);
    }

  };


  const get_All_COllaborators = async (companyId) => {
    getCollaborators({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      //fetchPolicy: "no-cache",
    }).then((data) => {


      const evalData = data?.data?.collaborators.data


      const transformedData = evalData.flatMap((collaborator) => {
        // Obter nome do colaborador
        const collaboratorName = collaborator?.attributes?.name || "N/A";

        // Iterar pelo array de avaliações dentro de cada colaborador
        return collaborator?.attributes?.evaluations?.data.map((evaluation) => {

          const companyData = evaluation?.attributes?.companies?.data?.[0]; // Obtém o primeiro objeto do array de empresas, se existir
          const companyName = companyData?.attributes?.name || "N/A";
          return {
            name: collaboratorName,
            company: companyName, // Assume o ID da primeira empresa, se houver
            evaluationId: evaluation?.id || "N/A",
            star: evaluation?.attributes?.star || "N/A",
            comment: evaluation?.attributes?.Comment || "N/A",
          };
        }) || [];
      });


      setEval_period(transformedData)




    }).catch((error) => {
      console.log("erro", error);

    })
  }

  const get_Eval = async (companyId) => {

    if (!companyId) {
      return
    } else {

      try {

        await getEvals_({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
          variables: {
            companyId: companyId,
            filters: {
              isActive: {
                eq: true,
              },
              companies: {
                id: {
                  eq: companyId,
                },
              },
            },
          },

        }).then((data) => {

          //  const duplicatCollabs =item?.attributes?.collaborators?.data.filter((item, index) => eval_item?.indexOf(item.map((xx)=> xx.id )) === index)
          const evalData = data?.data?.collaborators.data

          const transformedData = evalData.flatMap((collaborator) => {
            // Obter nome do colaborador
            const collaboratorName = collaborator?.attributes?.name || "N/A";

            // Iterar pelo array de avaliações dentro de cada colaborador
            return collaborator?.attributes?.evaluations?.data.map((evaluation) => {

              const companyData = evaluation?.attributes?.companies?.data?.[0]; // Obtém o primeiro objeto do array de empresas, se existir
              const companyName = companyData?.attributes?.name || "N/A";
              return {

                name: collaboratorName,
                company: companyName, // Assume o ID da primeira empresa, se houver
                evaluationId: evaluation?.id || "N/A",
                star: evaluation?.attributes?.star || "N/A",
                comment: evaluation?.attributes?.Comment || "N/A",
              };
            }) || [];
          });



          setEval_period(transformedData)

        });
      } catch (error) {
        console.error("Erro ao buscar períodos de avaliação:", error);
      }
    }



  };

  const [collaboratorsToSend, setCollaboratorsToSend] = useState([]);
  const [getFurnishers___] = useLazyQuery(GET_FURNISHERS);

  useEffect(() => {
    getEmployeerData({
      variables: {

        "usersPermissionsUserId": userID

      },
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
    }).then(data => {

      let employeerData = data.data.usersPermissionsUser.data;
      setEmployeerData({
        name: employeerData.attributes.name,
        postRole: employeerData?.attributes?.postRole?.data?.attributes?.description
      })
    }).catch(e => {
      console.log(e)
    })
  }, [])

  useEffect(() => {
    getCollaborators({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      //fetchPolicy: "no-cache",
    }).then((data) => {


      const collaboratorData = data?.data?.collaborators?.data?.map((item) => {

        return {
          id: item.id,
          userID: item.attributes?.users_permissions_user?.data?.id,
          name: item.attributes?.name,
          email: item.attributes?.email,
          phone: item.attributes?.phone,
          address: item.attributes?.address,
          web: item.attributes?.web,
          intro: item.attributes?.intro,
          country: item.attributes?.country?.data?.attributes?.name,
          countryID: item.attributes?.country?.data?.id,
          city: item.attributes?.city?.data?.attributes?.name,
          cityID: item.attributes?.city?.data?.id,
          postRole: item.attributes?.postRole?.data?.attributes?.postRole,
          postRoleID: item.attributes?.postRole?.data?.id,
          postRoleDescription: item.attributes?.postRole?.data?.attributes?.description,
          rating: item.attributes?.rating,

          /*  evaluation_period: item.attributes?.evaluation_period?.data?.map((evals) => {
             return {
               id: evals.id,
               start: evals.attributes?.start,
               end: evals.attributes?.start,
               description: evals.attributes?.start
                }
 
           }), */

          companies: item.attributes?.companies?.data?.map((company) => {
            return {
              companyID: company.id,
              company: company.attributes?.name,
              userID: company.attributes?.users_permissions_user?.data?.id,
              evalsData: company.attributes?.evaluation_period?.data,
              email: company?.attributes?.email,
              // avatar: item.attributes?.image?.data?.attributes?.url,
            }

          }),

          evaluations: item.attributes?.evaluations?.data?.map((evals) => {
            return {
              eval_id: evals.id,
              companies: evals.attributes?.companies,
              comment: evals.attributes?.Comment,
              star: evals.attributes?.star,
            }

          }),
          isActive: item.attributes?.isActive,
          avatar: item.attributes?.image?.data?.attributes?.url,
          avatarID: item.attributes?.image?.data?.id,

          profiles: item.attributes?.profiles?.data?.map((profiles) => {
            return {
              id: profiles.id,
              urlProfile: profiles.attributes?.urlProfile,
              description: profiles.attributes?.description,
              platform: profiles.attributes?.platform?.data?.attributes?.name,
              platformID: profiles.attributes?.platform?.data?.id,
              icon: profiles.attributes?.platform?.data?.attributes?.icon?.data?.attributes?.url

            };
          }),
          clientID: item.attributes?.client?.data?.id,
          client: item.attributes?.client?.data?.attributes?.companies?.data?.map((client) => {
            /*    const matchingScores = item.attributes?.evaluation_scores?.data?.filter((score) => score.attributes?.company?.data?.id === client.id);
               const rateValue = matchingScores.flatMap((matchingScore) =>
                 matchingScore?.attributes?.evaluation_answer_closeds?.data.map((score) => score.attributes.rate)
               );
               const rateLength = rateValue?.length;
               const rateTotal = rateValue.reduce((accumulator, currentScore) => accumulator + currentScore, 0);
    */
            return {
              id: client.id,
              name: client.attributes?.name,
              image: client.attributes?.image?.data?.attributes?.url,
              /*          rateTotal: rateTotal,
                       rateLength: rateLength,
                       rateValue: rateValue, */
            };
          }),


          portfolios: item.attributes?.portfolios?.data?.map((portfolios) => {
            return {
              id: portfolios.id,
              name: portfolios.attributes?.name,
              description: portfolios.attributes?.description,
              image: portfolios.attributes?.image?.data?.attributes?.url,
              imageID: portfolios.attributes?.image?.data?.id
            };
          }),
          awards: item.attributes?.awards?.data?.map((profiles) => {
            return {
              id: profiles.id,
              name: profiles.attributes?.name,
              description: profiles.attributes?.description
            };
          }),
          skills: item.attributes?.skills?.data?.map((profiles) => {
            return {
              id: profiles.id,
              percentual: profiles.attributes?.percentual,
              name: profiles.attributes?.itTool?.data?.attributes?.name,
              itToolID: profiles.attributes?.itTool?.data?.id
            };
          }),
          expertise: item.attributes?.expertise?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              expertise: profiles.attributes?.expertise?.data?.attributes?.name,
              expertiseID: profiles.attributes?.expertise?.data?.id
            };
          }),
          educations: item.attributes?.educations?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              startDate: profiles.attributes?.startDate,
              endDate: profiles.attributes?.endDate,
              course: profiles.attributes?.course?.data?.attributes?.name,
              courseID: profiles.attributes?.course?.data?.id,
              faculty: profiles.attributes?.faculty?.data?.attributes?.name,
              facultyID: profiles.attributes?.faculty?.data?.id,
              country: profiles.attributes?.country?.data?.attributes?.name,
              countryID: profiles.attributes?.country?.data?.id
            };
          }),
          experiences: item.attributes?.experiences?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              startDate: profiles.attributes?.startDate,
              endDate: profiles.attributes?.endDate,
              postRole: profiles.attributes?.postRole?.data?.attributes?.postRole,
              postRoleDescription: profiles.attributes?.postRole?.data?.attributes?.description,
              postRoleID: profiles.attributes?.postRole?.data?.id,
              expEntity: profiles.attributes?.expEntity?.data?.attributes?.name,
              expEntityID: profiles.attributes?.expEntity?.data?.id,
              country: profiles.attributes?.country?.data?.attributes?.name,
              countryID: profiles.attributes?.country?.data?.id
            };
          }),

        };
      });

      setCollaboratorsToSend(collaboratorData ?? []);

      const sortedData = collaboratorData?.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      setAvaliars(sortedData);
    }).catch((error) => {
      console.log("erro", error);
    })
  }, [getCollaborators]);



  return (
    <>
      <div className="p-8 w-full">
        <div className="flex items-start flex-wrap w-full mb-10 gap-4">
          <div className="w-full lg:w-[30%] self-start">
            <WelcomeCard
              title={i18n.t('dashboard.welcome', { name: employeerData.name }) + "!!"}
              subtitle={employeerData.postRole}
              action={<ProfileMenu />}
            />
            <span className="mt-6 flex"></span>
            {/* <RadarChartWithColors /> */}
            {/* <PolarArea /> */}
            {/* <span className="mt-4 flex"></span> */}
            {/* {location.pathname === '/' ? <PieChartAlt /> : <RadarChart />} */}
            <PieChartAlt
              evalMethods={evaluationMethods}
              collaborators={collaboratorsToSend}
              evaluations={evaluationsCollaborators}
            />

          </div>
          <div className="w-full lg:w-[65%] ml-auto self-start">
            <AnalysisCardClone
              AnalysisCardClone={AnalysisCardClone}
              data={[]}
              eval_periodHistory={['administrator', 'furnishers'].includes(localStorage.getItem('userRole')) ? evaluationsCollaborators : eval_periodHistory}
              companyId={companyId}
              evaluations={['administrator', 'furnishers'].includes(localStorage.getItem('userRole')) && avaliars ? avaliars : []}
            />


            <span className="mt-6 flex"></span>
            <MixedChart
              evaluationsPeriod={eval_periodHistory}
              maxStar={maxStar}
            />
          </div>

        </div>

        <div className="w-full">
          {/* <LineChartExample /> */}
          {/* <LineMixedChart /> */}
        </div>

        <div className="hidden">
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <MainCard
                    title={i18n.t('dashboard.welcome', { name: employeerData.name }) + "!!"}
                    subtitle={employeerData.postRole}
                    action={<ProfileMenu />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MainCard title={i18n.t('dashboard.graph', { lng: selectedLanguage })}>
                    <DripChart />
                  </MainCard>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MainCard title={i18n.t('dashboard.review', { lng: selectedLanguage })}>
                    <Analytics />
                  </MainCard>
                </Grid>
                <Grid item xs={12}>
                  {eval_period && eval_period?.length > 0 && (
                    <MainCard
                      title={i18n.t('dashboard.history', { lng: selectedLanguage })}
                      subtitle={i18n.t('dashboard.last', { lng: selectedLanguage })}
                    >
                      <table className="w-full table-auto border-collapse">
                        <thead>
                          <tr>
                            <th className="px-8 py-2 border-b text-left">ID</th>
                            <th className="px-8 py-2 border-b text-left">Description</th>
                            <th className="px-8 py-2 border-b text-left">Collaborator</th>
                          </tr>
                        </thead>
                        <tbody>
                          {eval_period.slice(-3).reverse().map((period) => {
                            return (
                              <tr key={period?.id}>
                                <td className="px-8 py-2 border-b">{period?.evaluationId || 'N/A'}</td>
                                <td className="px-8 py-2 border-b">{period?.comment || 'N/A'}</td>
                                <td className="px-8 py-2 border-b">{period?.name || 'N/A'}</td>

                              </tr>
                            );
                          })}
                        </tbody>
                      </table>


                    </MainCard>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <HistoryChart />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default HomePage;
