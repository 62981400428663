import {
  Autocomplete,
  Button, Grid, InputLabel, TextField,
  Typography,
  createFilterOptions
} from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import AutoCompleted from "../AutoCompleteD";
import { GET_EXPERTISES, GET_EXPERTISES_AREA, GET_JOB_ROLES } from "../query.gql";
import { CREATE_EXPERTISE, CREATE_EXPERTISE_CATEGORY, UPDATE_EXPERTISE_CATEGORY } from "../mutation.gql";
import i18n from "../../../../translate/i18n";
import { LanguageContext } from "../../../../translate/languages/LanguageContext";
import { useContext } from "react";
const ExpertiseAreaStep = ({
  handleClose,
  collaboratorID,
  collaborator,
  setCollaborator,
  collaborators,
  setCollaborators,
  expertiseList,
  setExpertiseList,
  step,
  stepID,
  setExpertise,
  setExpertArea,
  setIsGridVisible

}) => {
  const { selectedLanguage } = useContext(LanguageContext);
  const addStep = step?.includes("Add");
  const editStep = step?.includes("Edit");
  const createStep = step?.includes("Create");
  const filter = createFilterOptions();
  const jwt = localStorage.getItem("jwtToken");
  const [getExpertisesName] = useLazyQuery(GET_JOB_ROLES);
  const [new_expertiseCategory] = useMutation(CREATE_EXPERTISE_CATEGORY);
  const [update_expertiseCategory] = useMutation(UPDATE_EXPERTISE_CATEGORY);
  const [new_expertise] = useMutation(CREATE_EXPERTISE);
  const [expertises, setExpertises] = useState([]);
  const [newExpertises, setNewExpertises] = useState([]);
  const [expertiseValue, setExpertiseValue] = useState(null);
  const [expertiseError, setExpertiseError] = useState(null);
  const [description, setDescription] = useState('');
  useEffect(() => {
    setExpertises([]);
    getExpertisesName({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      fetchPolicy: "cache-and-network",
    }).then((data) => {

      console.log("EEEEEEAAAAAAAAAAAA", data);

      if (data?.data?.jobRoles?.data) {
        const expertiseData = data.data.jobRoles.data.map(item => ({
          value: item?.id || '',
          category: item?.attributes?.category || ''
        }));
        setExpertises(expertiseData);
      } else {
        console.error("Dados de jobRoles não disponíveis:", data);
      }

    });
    if (step?.includes("Edit")) {
      const foundExpertise = expertiseList.find((expertise) => expertise.id === stepID);
      setExpertiseValue({ id: foundExpertise.expertiseID, name: foundExpertise.expertise })
      setDescription(foundExpertise.description)
    }
  }, []);
  const handleInputChange = (event, setVariable) => {
    setVariable(event.target.value);
  };
  const handleAdd = () => {
    if (expertiseValue === null) {
      setExpertiseError(i18n.t('collaboratorStep.error.expertiseSelect', { lng: selectedLanguage }));

    } else {
      new_expertiseCategory({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        //fetchPolicy: "network-only",
        variables: {
          data: {
            expertise: expertiseValue.id,
            description: description,
            collaborator: collaboratorID
          },
        },
      }).then((data) => {
        const expertise = data.data.createExpertiseCategory.data
        const updatedData = {
          id: expertise.id,
          description: expertise.attributes.description,
          expertise: expertise.attributes.expertise.data.attributes.name,
          expertiseID: expertise.attributes.expertise.data.id
        }
        const updatedMainData = collaborators.map(obj => {
          if (obj.id === collaborator.id) {
            return { ...obj, expertise: [...obj.expertise, updatedData] }; // Add the updatedData value to the expertise array
          }
          return obj; // Keep the original object
        });
        const newList = [...expertiseList, updatedData]
        setExpertiseList(newList)
        setCollaborators(updatedMainData)
        handleClose()
        setExpertiseValue(null)
        setDescription("")
        toast.success("Expertise Done!!")
      })


    }

  };
  const handleEdit = () => {
    if (expertiseValue === null) {
      setExpertiseError(i18n.t('collaboratorStep.error.expertiseSelect', { lng: selectedLanguage }));

    } else {
      update_expertiseCategory({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        //fetchPolicy: "network-only",
        variables: {
          id: stepID,
          data: {
            expertise: expertiseValue.id,
            description: description,
            collaborator: collaboratorID
          },
        },
      }).then((data) => {
        const expertise = data.data.updateExpertiseCategory.data
        const foundExpertise =
        {
          id: stepID,
          description: description,
          expertiseID: expertiseValue.id,
          expertise: expertiseValue.name
        }
        const foundExpertiseIndex = expertiseList.findIndex(expertise => expertise.id === stepID);

        if (foundExpertiseIndex !== -1) {
          expertiseList[foundExpertiseIndex] = {
            ...foundExpertise,
          };
        }
        handleClose()
        setExpertiseValue(null)
        setDescription("")
        toast.success("Expertise Updated!!")
      })


    }

  };
  const handleCreate = () => {
    if (expertiseValue === null) {
      setExpertiseError(i18n.t('collaboratorStep.error.expertiseSelect', { lng: selectedLanguage }));

    } else {
      const newExpertise = {
        expertiseName: expertiseValue.name,
        expertiseID: expertiseValue.id,
        description: description
      };
      setExpertArea();
      // setExpertise(prevState => [...prevState, newExpertise]);
      setIsGridVisible(false);
    }

  };



  return (


    <>

      <Grid container item xs={12} spacing={3} id="expGrid">
        <Grid item xs={12} md={12}>
          <InputLabel >{i18n.t('collaboratorStep.collaborator.nameExpertiseArea', { lng: selectedLanguage })}</InputLabel>
          <Autocomplete
            size="small"
            required
            autoFocus
            options={expertises}
            getOptionLabel={(option) => option?.category || ''}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            value={newExpertises}
            onChange={(event, newValue) => {
              setNewExpertises(newValue);
              setExpertArea(newValue);
              //console.log("OQUE EEEEEEEE", newValue);
            }}
            fullWidth
            placeholder={i18n.t('collaboratorStep.collaborator.levelBody', { lng: selectedLanguage })}
            renderInput={(params) => (
              <TextField
                {...params}
                name="skillPercentual"
                id="skillPercentual"
                placeholder={i18n.t('collaboratorStep.collaborator.levelBody', { lng: selectedLanguage })}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={12} sx={{ display: 'none' }}>
          <InputLabel >{i18n.t('collaboratorStep.collaborator.description', { lng: selectedLanguage })}</InputLabel>
          <TextField size="medium"
            required
            autoFocus
            multiline
            type="text"
            fullWidth
            name="description"
            id="description"
            value={description}
            onChange={(event) => handleInputChange(event, setDescription)}
            placeholder={i18n.t('collaboratorStep.collaborator.descriptionExpertise', { lng: selectedLanguage })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} marginTop={1}>
        <Grid container item xs={12} spacing={3} marginLeft={0.5} sx={{ display: 'none' }} >
          {createStep &&
            <Grid item>
              <Button
                type="button"
                color="primary"
                onClick={handleCreate}
              >
                {i18n.t('collaboratorStep.button.expertise', { lng: selectedLanguage })}
              </Button>
            </Grid>
          }
        </Grid>
        <Grid container spacing={3} item xs={12} display="flex" justifyContent="space-around" alignItems="center">
          {!createStep && <Grid item>
            <Button variant="outlined" size="small" onClick={handleClose}>{i18n.t('collaboratorStep.button.cancel', { lng: selectedLanguage })}</Button>
          </Grid>}
          <Grid item>

            {editStep && <Button
              variant="contained"
              size="small"
              onClick={
                handleEdit

              }
            >{i18n.t('collaboratorStep.button.edit', { lng: selectedLanguage })}</Button>}
            {addStep && <Button
              variant="contained"
              size="small"
              onClick={
                handleAdd

              }
            >{i18n.t('collaboratorStep.button.create', { lng: selectedLanguage })}</Button>}
          </Grid>
        </Grid>
      </Grid>

    </>
  );

};

export default ExpertiseAreaStep;