import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Collapse, Dialog, DialogContent, DialogTitle, IconButton, Menu, MenuItem } from '@mui/material';
import { useLocation } from 'react-router-dom';
import DimensionFormTest from './dimension-formtest';
import DimensionDelete from './delete-dimension';
import { Fragment, useContext, useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomPagination from '../../components/TableComponents/CustomPagination';
import { LanguageContext } from '../../translate/languages/LanguageContext';
import i18n from '../../translate/i18n';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: 0,
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const BorderlessCell = styled(TableCell)(({ theme }) => ({
    border: 0,
    borderBottom: 'none', // Remove the bottom border

}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    }
}));

export default function DimensionListTest({ filteredSearched, dimensions, setDimensions }) {
    const { selectedLanguage } = useContext(LanguageContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortedPage, setSortedPage] = useState(filteredSearched);

    const itemsPerPage = 10;
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;

    // Extract the items to display on the current page
    const currentItems = sortedPage.slice(firstIndex, lastIndex);

    // Calculate the total number of pages
    const totalPages = Math.ceil(sortedPage?.length / itemsPerPage);

    // Handle the page change event
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        // Set the current page to 1 whenever the evaluation prop changes
        setCurrentPage(1);
        setSortedPage(filteredSearched)
    }, [filteredSearched]);

    const [open, setOpen] = useState(false);
    const [dimension, setDimension] = useState({
        id: '',
        dimension: '',
        postRole: '',
        isActive: '',
    });

    const currentLocation = useLocation()

    const [title, setTitle] = useState("Nova Dimensão")
    function LongMenu({ actionID }) {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        function handleClickOpenView(dimensionID) {
            setOpen(true);
            setTitle(i18n.t('title.viewDimension', { lng: selectedLanguage }))
            let data = dimensions.find(dim => dim.id === dimensionID)
            setDimension(i => {
                return {
                    id: data.id,
                    dimension: data.attributes.dimension,
                    postRole: data.attributes.postRoles.data.map(pr => ({
                        id: pr.id,
                        postRole: pr.attributes.postRole,
                        description: pr.attributes.description
                    })),
                    isActive: data.attributes.isActive
                }
            })
        };

        function handleClickOpenEdit(dimensionID) {
            setOpen(true);
            setTitle(i18n.t('title.editDimension', { lng: selectedLanguage }))
            let data = dimensions.find(d => d.id === dimensionID)
            setDimension(i => {
                return {
                    id: data.id,
                    dimension: data.attributes.dimension,
                    postRole: data.attributes.postRoles.data.map(pr => ({
                        id: pr.id,
                        postRole: pr.attributes.postRole,
                        description: pr.attributes.description
                    })),
                    isActive: data.attributes.isActive
                };
            });

        };

        function handleClickDelete(dimensionID) {
            setTitle(i18n.t('title.confirm', { lng: selectedLanguage }))
            setOpen(true)
            let data = dimensions.find(d => d.id === dimensionID)
            setDimension(i => {
                return {
                    id: data.id,
                    dimension: data.attributes.dimension,
                    postRole: data.attributes.postRoles.data.map(pr => ({
                        id: pr.id,
                        postRole: pr.attributes.postRole,
                        description: pr.attributes.description
                    })),
                    isActive: data.attributes.isActive
                }
            })
        }

        return (
            <>
                <IconButton aria-label="more" onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem onClick={() => handleClickOpenView(actionID)}>
                        <VisibilityOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> {i18n.t('options.view', { lng: selectedLanguage })}
                    </MenuItem>
                    <MenuItem onClick={() => handleClickOpenEdit(actionID)}>
                        <EditOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> {i18n.t('options.edit', { lng: selectedLanguage })}
                    </MenuItem>
                    <MenuItem onClick={() => handleClickDelete(actionID)}>
                        <ClearOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> {i18n.t('options.delete', { lng: selectedLanguage })}
                    </MenuItem>

                </Menu>
            </>
        );
    }

    function handleClose() {
        setOpen(false);
    };
    const [openActions, setOpenActions] = useState([]);

    const handleClickOpenAction = (index) => {
        setOpenActions((prevState) => {
            const updatedState = [...prevState];
            updatedState[index] = !updatedState[index];
            return updatedState;
        });
    };


    return (
        <Paper component={Paper} sx={{ padding: 1, marginBottom: 10 }}>
            <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell ><IconButton style={{ visibility: "hidden", marginLeft: "0.8rem" }} /> {i18n.t('table.name', { lng: selectedLanguage })}</StyledTableCell>
                            <StyledTableCell>{i18n.t('table.state', { lng: selectedLanguage })}</StyledTableCell>
                            <StyledTableCell align="right">
                                <div style={{ marginRight: "35px" }}>
                                    {i18n.t('table.action', { lng: selectedLanguage })}
                                </div>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentItems &&
                            currentItems.map((row, index) => (
                                <Fragment key={row.id}>
                                    <StyledTableRow>
                                        <StyledTableCell style={{ width: "30rem" }} align="left">
                                            <IconButton size="small" onClick={() => handleClickOpenAction(index)}                                            >
                                                {openActions[index] ? (
                                                    <KeyboardArrowUpIcon />
                                                ) : (
                                                    <KeyboardArrowDownIcon />
                                                )}
                                            </IconButton>
                                            {row?.attributes?.dimension || 'N/A'}
                                        </StyledTableCell>
                                        <StyledTableCell>{row?.attributes?.isActive === true ? i18n.t('options.active', { lng: selectedLanguage }) : i18n.t('options.inactive', { lng: selectedLanguage }) || 'N/A'}</StyledTableCell>
                                        <StyledTableCell align="right">
                                            <div style={{ marginRight: "35px" }}>
                                                <LongMenu actionID={row.id} />
                                            </div>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    {openActions[index] && <StyledTableRow key={row.id}>
                                        <StyledTableCell colSpan={6}>
                                            <Collapse in={openActions[index]} timeout="auto" unmountOnExit>
                                                <Table size="small" aria-label="purchases">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={{ width: "29rem" }} ><IconButton style={{ visibility: "hidden", marginLeft: "0.2rem" }} />{i18n.t('table.role', { lng: selectedLanguage })}</TableCell>
                                                            <TableCell>{i18n.t('table.state', { lng: selectedLanguage })}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {row?.attributes?.postRoles?.data?.map((role) => (
                                                            <TableRow key={role.id}>
                                                                <BorderlessCell component="th" scope="row">
                                                                    <IconButton style={{ visibility: "hidden", marginLeft: "0.2rem" }} />
                                                                    {role?.attributes?.postRole || "N/A"} - {role?.attributes?.description || "N/A"}
                                                                </BorderlessCell>
                                                                <BorderlessCell>{role?.attributes?.isActive === true ? i18n.t('options.active', { lng: selectedLanguage }) : i18n.t('options.inactive', { lng: selectedLanguage }) || 'N/A'}</BorderlessCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Collapse>
                                        </StyledTableCell>
                                    </StyledTableRow>}
                                </Fragment>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='md'
                sx={{ border: 2, borderColor: "#F7931E" }}
            >
                <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" alignItems="center">
                    {title}
                </DialogTitle>
                <DialogContent>
                    {title === i18n.t('title.confirm', { lng: selectedLanguage }) ? <DimensionDelete handleClose={handleClose} dimension={dimension} setList={setDimensions} list={dimensions} /> :
                        currentLocation.pathname === '/dimension' ? <DimensionFormTest dimension={dimension} setList={setDimensions} list={dimensions}  titleAction={title} handleClose={handleClose} /> : ''}
                </DialogContent>
            </Dialog>
        </Paper>
    );
}
