import { Grid } from "@mui/material";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import MainCard from "../../components/MainCard/main-card.component";
import MainContainer from "../../components/MainContainer/main-container.component";
import LoginCarousel from "./carousel.component";
import LoginHead from "./head.component";

const LoginPage = () => {
  return (
    <Grid container>
      <Grid item xs={12} md={5}>
        <MainContainer maxWidth="xs">
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <Grid item xs={12}><LoginHead /></Grid>
            <Outlet />
          </div>
        </MainContainer>
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        style={{
          minHeight: "100vh",
          background: '#2F272D',
          display: "flex",
          flexDirection: "column",
          padding: "0 10px 10px 0"
        }}>
        <div style={{ flexGrow: 2 }}></div>
        <LoginCarousel />
      </Grid>
    </Grid>
  );
};

export default LoginPage;
