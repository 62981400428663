import { Shower, Visibility } from "@mui/icons-material";

const CollabItem = ({ collab }) => {

  const evaluationGeneralStar = collab.evaluations.data?.length
    ? collab.evaluations.data.reduce((acc, evaluation) => acc + evaluation.attributes.star, 0) / collab.evaluations.data?.length
    : 0;

  const { REACT_APP_API_URL } = process.env;

  return (
    <>
      <div className="flex items-center rounded-[0.25rem] px-4 py-2.5 w-full border border-[rgba(0,0,0,0.05)] shadow-sm  cursor-pointer bg-white">
        {collab.avatar ? (
          <img className="w-[45px] h-[45px] rounded-full" src={`${REACT_APP_API_URL}${collab.avatar}`} alt="Avatar" />
        ) : (
          <span className="flex items-center justify-center w-[45px] h-[45px] aspect-square mr-2 rounded-[0.25rem] bg-[#FF9213] text-white">
            {collab.name[0]}
          </span>
        )}


        {/*        <img src={collab.avatar ?? ''} />
        <span className="flex items-center justify-center w-[45px] h-[45px] aspect-square mr-2 rounded-[0.25rem] bg-[#FF9213] text-white">{collab.name[0]}</span> */}
        <div className="flex flex-col w-full">
          <span className="font-bold w-full text-black text-[1.15rem]">{collab.name ?? 'Fulano de Tal'}</span>
          <small className="text-gray-300 w-full text-[0.85rem] overflow-hidden text-ellipsis ">{collab.postRoleDescription ?? 'N/A'}</small>
        </div>
        <span>({evaluationGeneralStar.toFixed(1)}%)</span>
        <a href={`/ratings/${collab.id}`} className="absoulte top-48 right-10 text-[#F89220] hover:opacity-80">
          <Visibility />
        </a>
      </div>
    </>
  );
};

export default CollabItem;