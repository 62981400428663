import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, CircularProgress } from "@mui/material";
import { CREATE_NOTIFICATION } from "./mutation.gql";
import { useLazyQuery, useMutation } from "@apollo/client";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { LanguageContext } from "../../../translate/languages/LanguageContext";
import i18n from "../../../translate/i18n";
import { GET_COMPANIES, GET_COMPANIES_ALL, GET_COMPANY_BY_EMAIL, GET_COMPANY_BY_USER, GET_COMPANY_BY_USERID } from "./query.gql";

const ChoosenCollaborator = ({ open, handleClose, collaboratorID }) => {
  const [loadingCheck, setLoadingCheck] = useState(false);
  const [createNotification] = useMutation(CREATE_NOTIFICATION);
  //const [getCompany] = useLazyQuery(GET_COMPANY_BY_USER);
  const [uId, setUId] = useState(null);
  const { selectedLanguage } = React.useContext(LanguageContext);

  const jwt = localStorage.getItem("jwtToken");
  const { id: employeerID } = jwtDecode(jwt);

  const userId = localStorage.getItem("userId");
  const userMail = localStorage.getItem("userMail");
  const [getC] = useLazyQuery(GET_COMPANIES_ALL);
  const [getCompany] = useLazyQuery(GET_COMPANY_BY_EMAIL);

  useEffect(() => {
    getCompany({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      variables: {
        email: userMail
      }
    }).then((res) => {
      // console.log("DO UUUUSAIII", res);
      // res?.data?.companies?.data?.[0]?.id;
      if (res?.data) {
        // const currentCompany = res?.data?.companies?.data?.filter((item) => item?.attributes?.users_permissions_user?.data?.id == userId);
        setUId(res?.data?.companies?.data?.[0]?.id);
      }
    })
  }, [userId]);

  const sendNotification = () => {
    try {

      //alert(uId);
      //console.log("ID: ", userId, " COLLABID: ", collaboratorId);
      if (uId && collaboratorID) {
        createNotification({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
          // fetchPolicy: "no-cache",
          variables: {
            action: 'hire', // ou: fire
            status: false,
            companyId: uId.toString(),//uId.toString(), //userId.toString(),
            collaboratorId: collaboratorID.toString(),
          },
        }).then((response) => {
          // console.log("RESUSLT NOT: ", result);
          if (response?.data) {
            toast.success(i18n.t("Solicitação enviada com sucesso!", { lng: selectedLanguage }));
            setTimeout(() => {
              window.location.reload();
            }, 150);
          } else {
            console.warn("Associação concluída, mas a criação da avaliação falhou.");
            toast.warning("Colaborador contratado, mas houve um problema ao criar a avaliação.");
          }
        })
      } else {
        toast.warning("Id Collaborador Inválido");
      }
    } catch (error) {
      console.error("Erro ao processar a associação:", error);
      toast.error("Erro ao tentar contratar o colaborador.");
    } finally {
      // setLoadingCheck(false);
    }
  };

  const enviarSolicitacao = () => {
    //alert('Enviando a solicitacao');
    sendNotification();
    setLoadingCheck(!loadingCheck);
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="associate-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle id="associate-dialog-title" style={{ textAlign: 'center' }}>
          {loadingCheck ? 'Enviando solicitação...' : 'Enviar solicitação'}
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          {loadingCheck ? (
            <CircularProgress />
          ) : (
            <>
              <button
                type="button"
                onClick={enviarSolicitacao}
                className="outline-none bg-[#F9AE00] hover:opacity-70 cursor-pointer rounded-[0.25rem] text-white py-[0.5rem] px-[0.75rem] text-center inline-block w-[60%] mx-auto"
              >
                Solicitar contratação
              </button>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChoosenCollaborator;