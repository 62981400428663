import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GET_DISTRIBUTTOR_BY_USER_PERMISSION, GET_FURNISHERS_BY_USER_PERMISSION } from "../../pages/Collaborator/Collaborator_Creation_Page/query.gql";

export const useFurnisherIdByUserId = () => {

  const [uIdFurnisher, setUIdFurnisher] = useState(null);
  const [getF] = useLazyQuery(GET_FURNISHERS_BY_USER_PERMISSION);
  const jwt = localStorage.getItem('jwtToken');
  const userId = localStorage.getItem('userId');
  useEffect(() => {
    getF({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        }
      },
      variables: {
        userId
      },
    }).then((res) => {
      if (res?.data) {
        const currentFurnisher = res?.data?.furnishers?.data
        setUIdFurnisher(currentFurnisher?.[0]?.id);
      }
    });
  }, [userId]);

  return uIdFurnisher;
};

export const useFurnisherCollabCount = () => {

  const [count, setCount] = useState(0);
  const [getF] = useLazyQuery(GET_FURNISHERS_BY_USER_PERMISSION);
  const jwt = localStorage.getItem('jwtToken');
  const userId = localStorage.getItem('userId');
  useEffect(() => {
    getF({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        }
      },
      variables: {
        userId
      },
    }).then((res) => {
      if (res?.data) {
        const currentFurnisher = res?.data?.furnishers?.data
        setCount(currentFurnisher?.[0]?.attributes?.collaborators?.data?.length);
      }
    });
  }, [userId]);

  return count;
};

export const useDistByUserId = () => {

  const [uIdDistributor, setUIdDistributor] = useState(null);
  const [getD] = useLazyQuery(GET_DISTRIBUTTOR_BY_USER_PERMISSION);
  const jwt = localStorage.getItem('jwtToken');
  const userId = localStorage.getItem('userId');
  useEffect(() => {
    getD({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        }
      },
      variables: {
        userId
      },
    }).then((res) => {
      if (res?.data) {
        const currentDistributor = res?.data?.distributors?.data
        setUIdDistributor(currentDistributor?.[0]?.id);
      }
    });
  }, [userId]);

  return uIdDistributor;
};

export const useDistCollabCount = () => {

  const [count, setCount] = useState(0);
  const [getF] = useLazyQuery(GET_DISTRIBUTTOR_BY_USER_PERMISSION);
  const jwt = localStorage.getItem('jwtToken');
  const userId = localStorage.getItem('userId');
  useEffect(() => {
    getF({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        }
      },
      variables: {
        userId
      },
    }).then((res) => {
      if (res?.data) {
        const currentFurnisher = res?.data?.distributors?.data
        setCount(currentFurnisher?.[0]?.attributes?.collaborators?.data?.length);
      }
    });
  }, [userId]);

  return count;
};