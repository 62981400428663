import {
    Autocomplete,
    Button, Grid, InputLabel, TextField, Typography, createFilterOptions
} from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_IT_TOOLS } from "../query.gql";
import { CREATE_ITTOOL, CREATE_SKILLS, UPDATE_SKILLS } from "../mutation.gql";
import i18n from "../../../../translate/i18n";
import { LanguageContext } from "../../../../translate/languages/LanguageContext";
import { useContext } from "react";

const SkillStep = ({
    handleClose,
    collaboratorID,
    collaborator,
    setCollaborator,
    collaborators,
    setCollaborators,
    ObjectList,
    setObjectList,
    step,
    stepID,
    setSkill,
    setIsGridVisible

}) => {
    const { selectedLanguage } = useContext(LanguageContext);
    const addStep = step?.includes("Add");
    const editStep = step?.includes("Edit");
    const createStep = step?.includes("Create");
    const filter = createFilterOptions();
    const jwt = localStorage.getItem("jwtToken");
    const [getItToolsName] = useLazyQuery(GET_IT_TOOLS);
    const [update_Skill] = useMutation(UPDATE_SKILLS);
    const [new_itTool] = useMutation(CREATE_ITTOOL);
    const [new_skills] = useMutation(CREATE_SKILLS);
    //const [skill, setSkill] = useState([])
    const [itTools, setItTools] = useState([]);
    const [newItTools, setNewItTools] = useState([]);
    const [itToolValue, setItToolValue] = useState(null);
    const [itToolError, setItToolError] = useState(null);
    const [percent, setPercent] = useState('');
    useEffect(() => {
        setItTools([]);
        getItToolsName({
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
            //fetchPolicy: "network-only",
        }).then((data) => {
            const itToolsData = data.data.itTools.data.map((item) => {
                return {
                    id: item.id,
                    name: item.attributes.name,
                };
            });

            setItTools(itToolsData);

        });
        if (step?.includes("Edit")) {
            const foundItTool = ObjectList.find((object) => object.id === stepID);
            setItToolValue({ id: foundItTool.itToolID, name: foundItTool.name })
            setPercent(foundItTool.percentual)
        }
    }, []);
    const handleInputChange = (event, setVariable) => {
        setVariable(event.target.value);
    };
    const handleAdd = () => {
        if (itToolValue === null) {
            setItToolError(i18n.t('collaboratorStep.error.skillSelect', { lng: selectedLanguage }));

        } else {
            const clampedPercentual = Math.min(Math.max(percent, 0), 100);
            new_skills({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    data: {
                        itTool: itToolValue.id,
                        percentual: clampedPercentual,
                        collaborator: collaboratorID
                    },
                }
            }).then((data) => {
                const newSkill = data.data.createSkill.data
                const updatedData = {
                    id: newSkill.id,
                    percentual: newSkill.attributes.percentual,
                    name: newSkill.attributes.itTool.data.attributes.name,
                    itToolID: newSkill.attributes.itTool.data.id
                }
                const updatedMainData = collaborators.map(obj => {
                    if (obj.id === collaborator.id) {
                        return { ...obj, skills: [...obj.skills, updatedData] }; // Add the updatedData value to the expertise array
                    }
                    return obj; // Keep the original object
                });
                const newList = [...ObjectList, updatedData]
                setObjectList(newList)
                setCollaborators(updatedMainData)
                handleClose()
                setItToolValue(null)
                setItToolError(null)
                setPercent("")
                toast.success("Skill Done!!")
            })


        }

    };
    const handleEdit = () => {
        if (itToolValue === null) {
            setItToolError(i18n.t('collaboratorStep.error.skillSelect', { lng: selectedLanguage }));

        } else {
            const clampedPercentual = Math.min(Math.max(percent, 0), 100);
            update_Skill({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: stepID,
                    data: {
                        itTool: itToolValue.id,
                        percentual: clampedPercentual,
                        collaborator: collaboratorID
                    },
                },
            }).then((data) => {
                const foundExpertise =
                {
                    id: stepID,
                    percentual: clampedPercentual,
                    itToolID: itToolValue.itToolID,
                    name: itToolValue.name
                }
                const foundExpertiseIndex = ObjectList.findIndex(expertise => expertise.id === stepID);

                if (foundExpertiseIndex !== -1) {
                    ObjectList[foundExpertiseIndex] = {
                        ...foundExpertise,
                    };
                }
                handleClose()
                setItToolValue(null)
                setItToolError(null)
                setPercent("")
                toast.success("Skill Updated!!")
            })


        }

    };
    const handleCreate = () => {
        if (itToolValue === null) {
            setItToolError(i18n.t('collaboratorStep.error.skillSelect', { lng: selectedLanguage }));

        } else {
            const clampedPercentual = Math.min(Math.max(percent, 0), 100);
            const newSkills = {
                itToolName: itToolValue.name,
                itToolID: itToolValue.id,
                percentual: clampedPercentual
            };
            setSkill(prevState => [...prevState, newSkills]);
            setIsGridVisible(false);

        }

    };



    return (


        <>

            <Grid container item xs={12} spacing={3} id="expGrid">
                <Grid item xs={12} md={6}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.nameSkill', { lng: selectedLanguage })}</InputLabel>
                    <Autocomplete
                        value={itToolValue}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                const filteredItTools = itTools.filter(item =>
                                    item.name?.toLowerCase() === newValue?.toLowerCase()
                                );

                                if (filteredItTools?.length > 0) {
                                    setItToolValue({
                                        name: filteredItTools[0].name,
                                        id: filteredItTools[0].id
                                    });
                                    setItToolError(null)
                                } else {
                                    new_itTool({
                                        context: {
                                            headers: {
                                                authorization: `Bearer ${jwt}`,
                                            },
                                        },
                                        //fetchPolicy: "network-only",
                                        variables: {
                                            data: {
                                                name: newValue.inputValue
                                            },
                                        },
                                    }).then((data) => {
                                        const itToolId = data.data.createItTool.data.id;
                                        const itToolName = data.data.createItTool.data.attributes.name;

                                        setItToolValue({
                                            name: itToolName, id: itToolId
                                        });
                                        setItToolError(null)
                                        const newOption = { name: itToolName, id: itToolId }

                                        setNewItTools([...newItTools, newOption]);
                                    })
                                }

                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                new_itTool({
                                    context: {
                                        headers: {
                                            authorization: `Bearer ${jwt}`,
                                        },
                                    },
                                    //fetchPolicy: "network-only",
                                    variables: {
                                        data: {
                                            name: newValue.inputValue
                                        },
                                    },
                                }).then((data) => {
                                    const itToolId = data.data.createItTool.data.id;
                                    const itToolName = data.data.createItTool.data.attributes.name;

                                    setItToolValue({
                                        name: itToolName, id: itToolId
                                    });
                                    setItToolError(null)
                                    const newOption = { name: itToolName, id: itToolId }

                                    setNewItTools([...newItTools, newOption]);
                                })
                            } else {
                                setItToolValue(newValue);
                                setItToolError(null)
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.name);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    name: `Add "${inputValue}"`,
                                });
                            }

                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="eduFaculty"
                        options={[...itTools, ...newItTools]}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.name;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        size="small"
                        freeSolo
                        renderInput={(params) => (
                            <TextField {...params} />
                        )}
                    />
                    {itToolError && <Typography
                        variant="body2"
                        color="gray"
                        style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                        {itToolError}
                    </Typography>}
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.percentage', { lng: selectedLanguage })}</InputLabel>
                    <TextField size="small"
                        required
                        autoFocus
                        type="number"
                        value={percent}
                        onChange={(event) => handleInputChange(event, setPercent)}
                        fullWidth
                        placeholder={i18n.t('collaboratorStep.collaborator.percentageBody', { lng: selectedLanguage })}
                        name="skillPercentual"
                        id="skillPercentual"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} marginTop={1}>
                <Grid container item xs={12} spacing={3} marginLeft={0.5} >
                    {createStep &&
                        <Grid item>
                            <Button
                                type="button"
                                color="primary"
                                onClick={handleCreate}
                            >
                               {i18n.t('collaboratorStep.button.skill', { lng: selectedLanguage })}
                            </Button>
                        </Grid>
                    }
                </Grid>
                <Grid container spacing={3} item xs={12} display="flex" justifyContent="space-around" alignItems="center">
                    {!createStep  && <Grid item>
                        <Button variant="outlined" size="small" onClick={handleClose}>{i18n.t('collaboratorStep.button.cancel', { lng: selectedLanguage })}</Button>
                    </Grid>}
                    <Grid item>

                        {editStep && <Button
                            variant="contained"
                            size="small"
                            onClick={
                                handleEdit

                            }
                        >{i18n.t('collaboratorStep.button.edit', { lng: selectedLanguage })}</Button>}
                        {addStep && <Button
                            variant="contained"
                            size="small"
                            onClick={
                                handleAdd

                            }
                        >{i18n.t('collaboratorStep.button.create', { lng: selectedLanguage })}</Button>}
                    </Grid>
                </Grid>
            </Grid>

        </>
    );

};

export default SkillStep;