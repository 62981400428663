import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  CircularProgress,
  Autocomplete,
  Typography,
  IconButton, Menu, MenuItem, Pagination,
  Box,
  TextField,
  Grid,
  Rating
} from '@mui/material';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import ShareIcon from '@mui/icons-material/Share';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/* import { FaEllipsisV } from 'react-icons/fa'; */
// import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useState } from 'react'; import { useEffect } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { toast } from 'react-toastify';
import ColaboradorDelete from './Collaborator_delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import i18n from '../../../translate/i18n';
import { useLazyQuery, useMutation } from "@apollo/client";
import { LanguageContext } from '../../../translate/languages/LanguageContext';
import CustomPagination from '../../../components/TableComponents/CustomPagination';
import { CalendarMonth, Category, Check, CloudCircleOutlined, CurrencyBitcoin, CurrencyExchange, FaxSharp, HowToReg, Psychology, School, WbIncandescent } from '@mui/icons-material';
import CollaboratorToCompany from "./CollaboratorToCompany";
import { ASSOC_CTOC, CREATE_NOTIFICATION, TESTE_2, UPDATE_COMPANY_C } from './mutation.gql';
import { GET_COLLABORATORS, GET_NOTIFICATIONS, GET_EMPLOYMENT_COLLAB, GET_COMP_GET, GET_JROLE_COLLAB } from './query.gql';
import jwtDecode from "jwt-decode";

import "./style.css";
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ChoosenCollaborator from './ChoosenCollaborator';
import FireCollaborator from './FireCollaborator';
import { useGetAssocDistCollabData, useGetDistByUserId } from '../../CompanyPage/Company_Creation_Page/helpers';
import { DistributorService } from '../../../services/distributors';
import JobRoleItem from './partial/JobRoleItem';
import { GET_COMPANIES } from '../../CompanyPage/Company_Creation_Page/query.gql';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 0,
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 13,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  }
}));


export default function CollaboratorList({ collaborators, setCollaborators, setColaborador, colaborador, setColaboradorEdit }) {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const jobRoleFilter = searchParams.get('filter') || null;
  const { furnisherId: furnId } = useParams();

  const location = useLocation();
  const pathDist = location?.pathname?.split("/");
  const distPath = pathDist[1];

  const jwt = localStorage.getItem("jwtToken");
  const { id: employeerID } = jwtDecode(jwt);

  const roleUser = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId") ?? null;
  const userMail = localStorage.getItem("userMail") ?? null;
  const companyId = localStorage.getItem("companyCurrentID") ?? null;
  const [createNotification] = useMutation(CREATE_NOTIFICATION);

  const { selectedLanguage } = React.useContext(LanguageContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedPage, setSortedPage] = useState(collaborators);

  const [openDialog, setOpenDialog] = useState(false);
  const [collaboratorId_, setCollaboratorId_] = useState(0);

  const [getContracted] = useLazyQuery(GET_EMPLOYMENT_COLLAB);
  const [retrieveNotification] = useLazyQuery(GET_NOTIFICATIONS);
  const [getJobRoles] = useLazyQuery(GET_JROLE_COLLAB);

  const [notifiactions, setNotifications] = useState([]);
  const [collabContracted, setCollabContracted] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDistributorAccount, setIsDistributorAccount] = useState(false);

  const [isDistEmployer, setIsDistEmployer] = useState(false);

  const [allNotifications, setAllNotifications] = useState([]);
  const [myDistID, setMyDistID] = useState(null);

  const [jobRoles, setJobRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [companyDistId, setCompanyDistId] = useState(null);

  const [getCompanys_, { data: allCompanies }] = useLazyQuery(GET_COMPANIES, {
    context: {
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
  });
  useEffect(() => {
    getCompanys_()
      .then(res => {
        console.log("RETRIEVED: ", res);
        const currentCompany = res?.data?.companies?.data?.filter(item => (item?.attributes?.email == userMail));

        setCompanyDistId(currentCompany?.[0]?.attributes?.distributors?.data?.[0]?.id);
        console.log("MYCOMP", currentCompany);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  }, []);

  const uIdDist = useGetDistByUserId();

  const sendNotification = (collaboratorId) => {
    try {
      const userId = localStorage.getItem("userId");
      console.log("ID: ", userId, " COLLABID: ", collaboratorId);
      if (userId && collaboratorId) {
        createNotification({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
          // fetchPolicy: "no-cache",
          variables: {
            action: 'hire', // ou: fire
            status: false,
            companyId: userId.toString(),
            collaboratorId: collaboratorId.toString(),
          },
        }).then((response) => {
          // console.log("RESUSLT NOT: ", result);
          if (response?.data) {
            toast.success(i18n.t("Solicitação enviada com sucesso!", { lng: selectedLanguage }));
            setTimeout(() => {
              window.location.reload();
            }, 150);
          } else {
            console.warn("Associação concluída, mas a criação da avaliação falhou.");
            toast.warning("Colaborador contratado, mas houve um problema ao criar a avaliação.");
          }
        })
      } else {
        alert(1);
      }
    } catch (error) {
      console.error("Erro ao processar a associação:", error);
      toast.error("Erro ao tentar contratar o colaborador.");
    } finally {
      // setLoadingCheck(false);
    }
    alert('enviei')
  };

  useEffect(() => {

    setIsLoading(true);
    getJobRoles({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
    }).then(response => {
      if (response?.data) {
        const all = response?.data?.jobRoles?.data ?? []
        setJobRoles(all);
      }
    }).catch((err) => { })
      .finally(() => {
        setIsLoading(false);
      })

    getContracted({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      fetchPolicy: "no-cache",
    }).then((result) => {
      console.log("Resultado bruto da query:", result?.data?.employments.data);
      setCollabContracted(result?.data?.employments.data);
      /*  const contract = result?.data?.employments?.data?.map(({ id, attributes }) => {
         const companyName = attributes?.companies?.data?.[0]?.attributes?.name || "Unknown Company";
 
         return {
           id,
           company: companyName,
         };
       }); */



      //console.log("CONTRATOS: ", contract);
    });

    const role = localStorage.getItem('userRole') ?? '';
    if (role == 'administrator' || role == 'distributor' || role == 'furnishers') {
      setIsAdmin(true);
    }
    if (role == 'distributor') {
      setIsDistributorAccount(true);
    }

    let notificationss = localStorage.getItem("notifications") ?? [];
    notificationss = JSON.parse(notificationss);
    if (notificationss?.length > 0) {
      setAllNotifications(notificationss);
    }

    retrieveNotification({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      fetchPolicy: "no-cache",
    }).then((response) => {
      if (response?.data) {
      }
    });

  }, []);

  const { data: myDist } = DistributorService.GetDistByCompanyId({ companyId });

  const { REACT_APP_API_URL } = process.env;


  const handleOpenDialog = (id) => {
    setOpenDialog(!openDialog);
    setCollaboratorId_(id);
  };

  useEffect(() => {
    if (myDist) {
      setMyDistID(myDist?.distributors?.data?.[0]?.id);
    }
  }, [myDist]);

  const [get_companies] = useLazyQuery(GET_COMP_GET);


  const [openFireDialog, setOpenFireDialog] = useState(false);
  const [openAssociateDialog, setOpenAssociateDialog] = useState(false);
  const [associateId, setAssociateId] = useState(0);

  const [collaboratorID, setCollaboratorID] = useState(false);

  const [collaboratorUser, setCollaboradorUser] = useState("");
  const [collaboratorStep, setCollaboratorStep] = useState(true);
  const [actionValor, setActionValor] = useState(1);

  const distId = localStorage.getItem("distId") ?? null;

  const handleOpenAssociateDialog = (id) => {
    setAssociateId(id);
    setOpenAssociateDialog(true);
  };

  const handleOpenFireDialog = () => {
    setOpenFireDialog(true);
  };

  const handleCloseAssociateDialog = () => {
    setOpenAssociateDialog(false);
    setAssociateId(0);
  };

  const itemsPerPage = 8;


  const [currentCompanyId, setCurrentCompanyId] = useState("");
  const [textToShow, setTextToShow] = useState("Contratar");

  // Calculate the indexes of the first and last items to display on the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;



  // Extract the items to display on the current page
  let collabItems =
    isAdmin ? collaborators :
      roleUser == 'company' ?
        collaborators.filter((item) => (item.employmentcolabdistributors?.[0]?.attributes?.distributors?.data?.[0]?.id == companyDistId))
        : collaborators?.filter((item, index) => (item.employmentcolabdistributors?.[0]?.attributes?.distributors?.data?.[0]?.id == myDistID));

  if (jobRoleFilter) {
    collabItems = collabItems?.filter((item) => jobRoleFilter.toUpperCase() === item?.jobRoles?.split('_')[0]?.toUpperCase());
  }

  const sollicitados = allNotifications.filter((item) => item.attributes?.collaborator?.data?.id == item.id);
  let currentItems = collabItems?.slice(firstIndex, lastIndex);

  const loadCollabData = () => {
    get_companies({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      variables: {
        userId: employeerID.toString()
      },
    }).then((data) => {
      const lastId = data?.data?.companies?.data?.map(companyItem => {
        // console.log("NO MUSEKE: ", companyItem.id)
        return companyItem.id;
      });
      //alert(lastId);
      setCurrentCompanyId(lastId?.toString());

    });
  };

  const handlePreviewCollab = (id, currentItem) => {
    const currentData = currentItems.find((item) => item.id = id);
    const url = '/collaborator/preview?id=';
    navigate('/curriculum/details', {
      state: {
        data: currentItem,
        currentItems: currentItems
      }
    });


  };

  const [collabPosition, setCollabPosition] = useState("N/A");


  const handleClickAssociate = (contractText, id) => {
    if (contractText == "Descontratar") {
      setActionValor(0);
    } else {
      setActionValor(1);
    }
    handleOpenAssociateDialog(id);
    currentItems.map((item, i) => {
      if (item.id == id) {
        console.log("item1jdjj", item);
        const val = item?.employmentcolabdistributors?.some(
          emp => emp?.attributes?.distributors?.data?.[0]?.id === distId
        ) ? distId : false;

        setIsDistEmployer(val);

        handleClose();
        setCollabPosition(item.speciality);
      }
    });
  };

  const [solicitId, setSolicitId] = useState(-1);

  const handleClickSolicit = (id) => {
    handleOpenAssociateDialog(id);
  };

  useEffect(() => {
    loadCollabData();
    //alert(currentCompanyId);
  }, []);



  // Calculate the total number of pages
  const totalPages = Math.ceil(collabItems?.length / itemsPerPage);

  // Handle the page change event
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const [open, setOpen] = useState(false);
  const [booleanStatus, setBooleanStatus] = useState(false);
  const [collaboratorRole, setCollaboratorRole] = useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [ratingValue, setRatingValue] = useState(3);

  function handleClose() {
    setOpen(false);
  };


  function LongMenu({ collaboratorID, collaboratorData, contractText, collaboratorStep, rowId, row }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const booleanStatus = collaboratorData.isActive

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleOpenView = () => {
      //console.log(collaboratorID);
      setColaborador(collaboratorData);
      handleClose();
    };

    const handleEdit = () => {
      setColaborador(collaboratorData);
      setColaboradorEdit(true);
      handleClose();
    };

    const handleClickDelete = (step) => {
      //console.log(collaboratorID);
      setCollaboratorID(collaboratorID);
      setCollaboradorUser(collaboratorData);
      setCollaboratorStep(step);
      setOpen(true);
      handleClose();
    };
    const handleClickDisable = (step) => {
      //console.log(collaboratorID);
      setCollaboratorID(collaboratorID);
      setCollaboradorUser(collaboratorData);
      setCollaboratorStep(step);
      setOpen(true);
      handleClose();
      setBooleanStatus(collaboratorData.isActive)
    };

    console.log("IN DOCS, ", roleUser);

    return (
      <div>
        {
          roleUser == 'administrator' ? (
            <IconButton aria-label="more" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          ) :
            distPath == 'dist' || distPath == 'furnisher' ? (
              <IconButton aria-label="more" onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
            ) :
              (row?.employmentcolabdistributors?.[0]?.attributes?.distributors?.data?.[0]?.id == distId) || (row?.furnishers.length > 0 && row?.furnishers?.[0]?.attributes?.id == furnId) ? (
                <IconButton aria-label="more" sx={{ pointerEvents: 'none' }}>
                  <span className='flex items-center justify-center w-[20px] h-[20px] bg-[#25AD52] rounded-full'>
                    <CheckIcon sx={{ fontSize: 12, color: '#fff' }} />
                  </span>
                </IconButton>
              ) : (
                <IconButton aria-label="more" onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              )
        }


        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {
            roleUser == 'distributor' && distPath == 'dist' && row?.employmentcolabdistributors?.[0]?.attributes?.distributors?.data?.[0]?.id == distId && (
              <MenuItem onClick={() => handlePreviewCollab(rowId, row)}>
                <VisibilityOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> {i18n.t('options.view', { lng: selectedLanguage })}
              </MenuItem>

            )
          }
          {
            roleUser == 'furnishers' && distPath == 'furnisher' && row?.furnishers?.[0]?.id === furnId && (
              <MenuItem onClick={() => handlePreviewCollab(rowId, row)}>
                <VisibilityOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> {i18n.t('options.view', { lng: selectedLanguage })}
              </MenuItem>
            )
          }
          {
            (roleUser == 'administrator' || roleUser == 'company') && (
              <MenuItem onClick={() => handlePreviewCollab(rowId, row)}>
                <VisibilityOutlinedIcon color='primary' style={{ marginRight: '5px' }} /> {i18n.t('options.view', { lng: selectedLanguage })}
              </MenuItem>
            )
          }
          {
            roleUser == 'administrator' || (row?.employmentcolabdistributors?.[0]?.attributes?.distributors?.data?.[0]?.id == distId && distPath == 'dist') || (roleUser == 'furnishers' && distPath == 'furnisher' && row?.furnishers?.[0]?.id === furnId) ? (
              [
                <MenuItem key="edit" onClick={handleEdit}>
                  <EditOutlinedIcon color="primary" style={{ marginRight: '5px' }} />
                  {i18n.t('options.edit', { lng: selectedLanguage })}
                </MenuItem>,

                <MenuItem key="toggle" onClick={() => handleClickDisable(true)}>
                  {booleanStatus ? (
                    <ClearOutlinedIcon color="primary" style={{ marginRight: '5px' }} />
                  ) : (
                    <CheckIcon color="primary" style={{ marginRight: '5px' }} />
                  )}
                  {booleanStatus
                    ? i18n.t('options.disable', { lng: selectedLanguage })
                    : i18n.t('options.enable', { lng: selectedLanguage })}
                </MenuItem>,
                <MenuItem key="delete" onClick={() => handleClickDelete(false)}>
                  <DeleteIcon color="primary" style={{ marginRight: '5px' }} />
                  {i18n.t('options.delete', { lng: selectedLanguage })}
                </MenuItem>
              ]

            ) : null
          }

          {
            'Descontratar' == contractText && roleUser == 'company' ? (
              null
            ) :
              'Descontratar' == contractText && ['distributor', 'furnishers'].includes(roleUser) ? (
                distPath == 'dist' ? (
                  null
                ) : null
              ) : (
                ['distributor', 'furnishers'].includes(userRole) ? (
                  distPath !== 'dist' && distPath !== 'furnisher' && (
                    <MenuItem onClick={() => handleClickAssociate(contractText, rowId)}>
                      <CloudCircleOutlined color='primary' style={{ marginRight: '5px' }} />
                      Associar {distPath}
                    </MenuItem>
                  )
                ) : (
                  <MenuItem onClick={() => handleClickAssociate(contractText, rowId)}>
                    <CloudCircleOutlined color='primary' style={{ marginRight: '5px' }} />
                    {contractText}
                  </MenuItem>
                )
              )
          }
        </Menu>
      </div>
    );
  }


  const [userRole, setUserRole] = useState('Company');

  useEffect(() => {
    const role = localStorage.getItem("userRole");
    if (role) {
      setUserRole(role.toLowerCase())
    }
  }, [userRole])

  const calculateExperienceYears = (experiences) => {
    let totalYears = 0;

    experiences?.forEach((experience) => {
      const startDate = new Date(experience.startDate);
      const endDate = new Date(experience.endDate);
      const years = endDate.getFullYear() - startDate.getFullYear();

      // Ajusta se o mês e dia do endDate ainda não ocorreram este ano
      if (
        endDate.getMonth() < startDate.getMonth() ||
        (endDate.getMonth() === startDate.getMonth() && endDate.getDate() < startDate.getDate())
      ) {
        totalYears -= 1;
      }

      totalYears += years;
    });


    return totalYears;
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const btnActionClick = (id, role) => {
    setCollaboratorRole(role ?? null);
    if (id) {
      isAdmin ? handleClickAssociate('Contratar', id) : sendNotification(id);
    } else {
      toast.error('ID indefinido');
    }
  };

  const fireCollaborator = (id) => {
    if (id) {
      const employmentId = collaborators.find(item => item?.id == id)?.employmentCollab?.id;
      handleClickAssociate('Descontratar', employmentId);
    } else {
      toast.error('ID indefinido');
    }
  };

  console.log("ITEM CURRENTS", currentItems);

  // const handleActionClick = (row) => {
  //   setCollaboratorUser(row); // Passa os dados do colaborador
  //   setCollaboratorID(row.id);
  //   setCollaboratorStep(false); // Passa para a etapa de "descontratar"
  //   setOpen(true); // Abre o diálogo
  // };

  // const handleContractClick = (row) => {
  //   setCollaboratorUser(row); // Passa os dados do colaborador
  //   setCollaboratorID(row.id);
  //   setCollaboratorStep(true); // Passa para a etapa de "contratar"
  //   setOpenAssociateDialog(true); // Abre o diálogo de contratação
  // };


  return (
    <>
      {
        distPath != 'dist' && distPath !== 'furnisher' && (

          <div className="flex flex-col flex-1 px-6">
            <span style={{ fontSize: '1rem' }} className="font-onest self-start">
              Filtragem por Categorias
            </span>
            <div className="flex flex-wrap w-full items-center gap-2 mb-4 mt-2">
              {

                isLoading ? (
                  <>
                    <div className="flex items-center gap-2">
                      <CircularProgress size={15} />
                      <p className="">Carregando JobRoles...</p>
                    </div>
                  </>
                ) :
                  jobRoles?.length === 0 ? (
                    <p className="mt-5">Nenhum JobRole Encontrado</p>
                  ) : (
                    <>
                      <JobRoleItem
                        title="Todas"
                        link={`/collaborator/main`}
                        isActive={!jobRoleFilter ? 'active' : ''}
                      />
                      {
                        jobRoles?.map((item, index) => (
                          <JobRoleItem
                            key={index}
                            title={i18n.t(`collaborator.dashboard.tittles.${item?.attributes?.category?.split('_')[0]}`, { lng: selectedLanguage })}
                            link={`/collaborator/main?filter=${item?.attributes?.category?.split('_')[0]?.toLowerCase()}`}
                          />
                        ))
                      }
                    </>
                  )

              }
            </div>

          </div>
        )
      }
      <div className="flex items-center w-full mb-10 px-6 border-t border-b py-2.5">
        <div className="flex flex-col flex-1">
          <span style={{ fontSize: '1rem' }} className="font-onest self-start">
            Resultados para
          </span>
          <span style={{ fontSize: '1.45rem', fontWeight: 'bold' }} className="font-onest self-start">
            {
              jobRoleFilter ? '' : distPath != 'dist' && distPath != 'furnisher' ? 'Todos as Categorias' : 'Meus Colaboradores'
            }
          </span>
        </div>
        {jobRoleFilter && (<a href={roleUser == 'furnisher' ? '#' : '/collaborator'} className='py-[0.75rem] border border-[#F89220] px-6 rounded-[0.25rem] text-[#F89220] hover:bg-[#F89220] hover:text-[#fff]'>Voltar</a>)}
      </div>

      <span className='px-6'>{jobRoleFilter && currentItems?.length == 0 ? 'Nenhum Colaborador Associado a essa Categoria' : ''}</span>

      {
        currentItems?.length == 0 && userRole == 'company' && (
          <div className='w-full px-6'>
            <span>Nenuhm colaborador associado a sua Distribuidora</span>
          </div>
        )
      }

      <div className="w-full px-6 -mt-6 pb-6 relative grid md:grid-cols-2 lg:grid-cols-4 gap-6">
        {
          currentItems?.map((row, index) => (
            <>
              <div
                key={index}
                className="bg-white rounded-[12px] border border-gray-200 mt-6 shadow-md relative py-6 px-6"
              >
                <div className="absolute top-4 right-4">
                  <LongMenu
                    collaboratorID={row.id}
                    collaboratorData={row}
                    rowId={row.id}
                    row={row}
                    contractText={
                      isAdmin ? row.employmentCollab != null ? 'Descontratar' : 'Contratar' : 'Contratar'
                    }
                  />
                </div>
                <div className="flex">
                  <div className='flex self-start items-center jusitfy-center'>
                    {
                      row?.avatar ? (
                        <img
                          src={`${REACT_APP_API_URL}${row?.avatar}`}
                          className='w-[50px] h-[50px] aspect-square rounded-full border object-top'
                        />
                      ) : (

                        <span className="flex items-center justify-center w-[50px] h-[50px] aspect-square rounded-full bg-[#EA1967] text-white text-xl lg:text-2xl">{row?.name[0]}</span>
                      )
                    }
                  </div>
                  <div className="flex flex-col ml-2">
                    <h4 className="font-bold self-start" style={{ fontSize: '0.85rem' }}>
                      {row?.name?.split(" ")[0]} {row?.name && row?.name?.split(" ").pop()}
                    </h4>
                    <small style={{ fontSize: '0.85rem' }} className="text-[rgba(0,0,0,0.65)] self-start -mt-1">{row.speciality ?? 'N/A'}</small>
                    <div className="flex self-start items-center mb-2" style={{ fontSize: 16 }}>
                      {
                        row.evaluations?.length > 0 ? (
                          Array.from({ length: 5 }).map((_, index) => (
                            <span
                              key={index}
                              className={`${index + 1 <= (parseInt(row.evaluations[0]?.attributes?.star, 10) || 0) ? 'text-[#F9AE00]' : 'text-black'}`}
                            >
                              {index + 1 <= (parseInt(row.evaluations[0]?.attributes?.star, 10) || 0) ? "★" : "☆"}
                            </span>
                          ))
                        ) : (
                          Array.from({ length: 5 }).map((_, index) => (
                            <span
                              key={index}
                              className="text-black"
                            >
                              ☆
                            </span>
                          ))
                        )
                      }
                    </div>
                  </div>
                </div>

                <div className="flex flex-col">
                  <div className="flex flex-col w-full">
                    <span className='font-bold flex w-full text-[#F89220] items-center'>
                      <Psychology sx={{ fontSize: 18 }} /> {" "}
                      {i18n.t('collaborator.skills', { lng: selectedLanguage })}
                    </span>
                    <div className="flex flex-col items-center gap-2 w-full mt-2 pl-[2px]">
                      {

                        row?.skills?.length > 0 ? row?.skills?.map((skill, index) => (
                          index <= 2 ? (
                            <small key={index} className='w-full flex' style={{ alignItems: 'flex-start' }}>
                              <Check sx={{ fontSize: 14 }} /> <span className='txttrn ml-1'>{skill.name}</span>
                              <span className='font-semibold'>({skill.percentual}%)</span>
                            </small>
                          ) : null
                        )) : (
                          <small className='text-gray-400 w-full'>Nenhum...</small>
                        )
                      }
                    </div>
                  </div>
                  <div className="flex flex-col w-full mt-4">
                    <span className='font-bold flex w-full text-[#F89220] items-center'>
                      <CurrencyExchange sx={{ fontSize: 18 }} /> {" "}
                      {i18n.t('collaborator.paymentPerHour', { lng: selectedLanguage })}
                    </span>
                    <div className="flex items-center gap-2 w-full mt-2 pl-[2px]">
                      ${" "}{
                        userRole == 'distributor' && distPath == 'dist'
                          ? row?.employmentcolabdistributors?.[0]?.attributes?.payment
                          : row?.payment ?? '0'
                      }
                    </div>
                  </div>

                  <div className="flex flex-col w-full mt-4">
                    <span className='font-bold block w-full text-[#F89220]'>
                      <School sx={{ fontSize: 18 }} /> {" "}
                      {i18n.t('collaborator.education', { lng: selectedLanguage })}
                    </span>
                    <div className="flex items-center gap-2 w-full">
                      {
                        row?.educations?.length > 0 ? (
                          <div className='flex flex-col w-full'>
                            <small className='w-full txttrn' style={{ fontWeight: 'bold' }}>
                              {row?.educations[0]?.course}
                            </small>
                            <small className='w-full text-gray-400 txttrn1'>{row?.educations[0]?.faculty}</small>
                          </div>
                        ) : (
                          <small className='text-gray-400'>Nenhum...</small>
                        )
                      }
                    </div>
                  </div>
                </div>

                <p className='text-sm pt-2 opacity-75 hidden font-medium mt-2 border-t overflow-hidden text-ellipsis line-clamp-2 h-[50px]'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia distinctio accusamus hic commodi ut assumenda?</p>
                <span></span>
                <div className="flex flex-col w-full border-t mt-2 pt-1.5">
                  <small className='w-full flex items-center gap-1 uppercase text-[#F89220]' style={{ fontWeight: 'bold' }}>
                    <CalendarMonth sx={{ fontSize: 17 }} /> {i18n.t('collaborator.experienceYears', { lng: selectedLanguage })}: {" "}
                    <span className='font-bold text-black'>
                      {calculateExperienceYears(row?.experiences)}
                    </span>
                  </small>
                  <small className='w-full flex items-center gap-2 text-[#F89220]'>
                    <span className="flex items-center gap-1 uppercase" style={{ fontWeight: 'bold' }}>
                      <HowToReg sx={{ fontSize: 19 }} /> {i18n.t('collaborator.role', { lng: selectedLanguage })}: {" "}
                    </span>
                    <span className='font-bold text-black whitespace-nowrap text-ellipsis line-clamp-1 overflow-hidden'>
                      {row?.postRoleDescription ?? 'N/A'}
                    </span>
                  </small>
                </div>
              </div>
            </>
          ))
        }
      </div>


      <div className={`mt-8 space-x-3 items-center justify-center ${currentItems?.length == 0 ? 'hidden' : 'flex'}`}>
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="outline-none border inline-block text-center py-[0.45rem] px-[0.45rem] bg-white cursor-pointer shadow-sm text-black hover:bg-[#F9AE00] hover:text-[#fff] roudned-[8px]"
        >
          <KeyboardArrowLeftIcon />
        </button>
        <div className="flex items-center gap-3">
          <span
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}
            className={`w-[28px] h-[28px] flex items-center justify-center text-[0.875rem] font-bold ${currentPage === totalPages ? 'bg-white text-black' : 'bg-[#F9AE00] text-white'}`}
          >
            {
              currentItems?.length == 0 ? 0 : currentPage
            }

          </span>
          <span>
            {i18n.t('de', { lng: selectedLanguage })}
          </span>
          <span
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}
            className={`w-[28px] h-[28px] items-center justify-center text-[0.875rem] font-bold ${currentPage === totalPages ? 'bg-[#F9AE00] text-white' : 'bg-white text-black'}`}
          >
            {totalPages}
          </span>
        </div>
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className={`outline-none border inline-block text-center py-[0.45rem] px-[0.45rem] bg-white cursor-pointer shadow-sm text-black hover:bg-[#F9AE00] hover:text-[#fff] roudned-[8px] `}
        >
          <KeyboardArrowRightIcon />
        </button>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" alignItems="center">
          {
            collaboratorStep === false ? (
              <p>Delete {collaboratorUser.name}?</p>
            ) : (
              <p>{booleanStatus ? 'Disable' : 'Enable'} {collaboratorUser.name}?</p>
            )
          }
        </DialogTitle>
        <DialogContent>
          <ColaboradorDelete
            collaboratorID={collaboratorID}
            collaborators={collaborators}
            setCollaborators={setCollaborators}
            collaboratorUserID={collaboratorUser.userID}
            collaboratorUser={collaboratorUser}
            handleClose={handleClose}
            collaboratorStep={collaboratorStep}
          />
        </DialogContent>
      </Dialog>
      {
        isAdmin ? (
          <>
            <CollaboratorToCompany
              open={openAssociateDialog}
              handleClose={handleCloseAssociateDialog}
              idCollaborador={associateId}
              action={actionValor}
              positionCollaborator={collabPosition}
              collabRole={collaboratorRole}
              isDistEmployer={isDistEmployer}
            />

          </>

        ) : (
          <ChoosenCollaborator
            open={openAssociateDialog}
            handleClose={handleCloseAssociateDialog}
            collaboratorID={associateId}
          />
        )
      }
    </>

  );
}
