import css from "../css/portfolio.module.css";
import { Section } from "../section";
import { PopupGallery } from "../popup.gallery";
import { useState } from "react"; import
EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card, CardContent, IconButton } from "@mui/material";
const { REACT_APP_API_URL } = process.env;


const Header = ({ handleOpen, setStepID, colaboradorEdit }) => {
  return (
    <>
      <h2>{colaboradorEdit === true && <>
        <IconButton color="primary" onClick={() => {
          handleOpen("Add Portfolio");
          setStepID("ID");
        }}>
          <PlusOneIcon />
        </IconButton>
      </>}Portfolio</h2>
      <p>Here it gets interesting.</p>
      {colaboradorEdit === true &&
        <>
          <IconButton color="primary" onClick={() => {
            handleOpen("Clear Portfolio");
            setStepID("ID");
          }}>
            <DeleteIcon />
          </IconButton>
        </>}
    </>
  );
};

const Content = ({ setStepID, handleOpen, portfolio, colaboradorEdit }) => {
  const data = portfolio;

  const photoList = portfolio.map((project) => ({
    src: `${process.env.REACT_APP_API_URL}${project.image}`,
    caption: project.name
  }));

  const [index, setIndex] = useState(0);
  const [popup, setPopup] = useState(false);

  const showPopup = (i) => {
    setIndex(i);
    setPopup(true);
  };


  const setPosition = (i) => {
    if (i < 0 || i > photoList?.length - 1) return;
    console.log("index", i)
    setIndex(i);
  };

  return (
    <div>
      {colaboradorEdit === true ? (
        <>
          <ul className={css.list}>
            {data.map((project, index) => (
              <Card key={project.id}>
                <li
                  className={css.project}

                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_API_URL}${project.image})`,
                  }}
                  role="button"
                  onClick={() => showPopup(index)}
                >
                  <div className={css.wrapper}>
                    <div className={css.overlay}></div>
                    <h3 className={css.title}>{project.name}</h3>
                    <hr className={css.separator} />
                    <p className={css.description}>{project.description}</p>
                  </div>
                </li>
                <IconButton color="primary" onClick={() => {
                  handleOpen("Edit Portfolio");
                  setStepID(project.id);
                }}>
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton color="primary" onClick={() => {
                  handleOpen("Delete Portfolio");
                  setStepID(project.id);
                }}>
                  <ClearOutlinedIcon />
                </IconButton>
              </Card>
            ))}
          </ul>

        </>
      ) : (
        <>
          <ul className={css.list}>
            {data.map((project, index) => (
              <li
                className={css.project}
                key={project.id}
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_API_URL}${project.image})`,
                }}
                role="button"
                onClick={() => showPopup(index)}
              >
                <div className={css.wrapper}>
                  <div className={css.overlay}></div>
                  <h3 className={css.title}>{project.name}</h3>
                  <hr className={css.separator} />
                  <p className={css.description}>{project.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}

      {photoList?.length > 0 && <PopupGallery
        photoList={photoList}
        index={index}
        setIndex={setPosition}
        visible={popup}
        setVisibility={setPopup}
      />}
    </div>
  );
};

export const Portfolio = ({ handleOpen, setStepID, portfolio, colaboradorEdit }) => {
  return <Section header={<Header handleOpen={handleOpen} setStepID={setStepID} colaboradorEdit={colaboradorEdit} />} content={<Content setStepID={setStepID} handleOpen={handleOpen} portfolio={portfolio} colaboradorEdit={colaboradorEdit} />} />;
};
