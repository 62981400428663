import { Button, Grid, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import CollaboratorList from "./Collaborator_list";
import { GET_COLLABORATORS } from "./query.gql";
import { ToastContainer } from "react-toastify";
import CollaboratorView from "../Collaborator_View_Page/collaborator_detail_main";
import SearchAddBar from "../../../components/TopBar/search-add-bar.component";
import Pathway from "../../../components/TopBar/pathway";
import LoadingCircle from "../../../components/Loading/loading_circle";
import { LanguageContext } from "../../../translate/languages/LanguageContext";
import i18n from "../../../translate/i18n";
import "./style.css";
import { useLocation, useParams } from "react-router-dom";
import { GET_COMPANIES } from "../../CompanyPage/Company_Creation_Page/query.gql";

const CreateCollaboratorMain = () => {

  const location = useLocation();
  const pathDist = location?.pathname?.split("/");
  const distPath = pathDist[1];

  const { furnisherId } = useParams();

  const { selectedLanguage } = useContext(LanguageContext);
  const jwt = localStorage.getItem("jwtToken");
  const userRole = localStorage.getItem('userRole');
  const distId = localStorage.getItem('distId');
  const userId = localStorage.getItem('userId');
  const userMail = localStorage.getItem('userMail');
  const [getCollaborators] = useLazyQuery(GET_COLLABORATORS)
  const [loading, setLoading] = useState(true);
  const [collaborators, setCollaborators] = useState([])
  const [colaborador, setColaborador] = useState(null);

  const [colaboradorEdit, setColaboradorEdit] = useState(false);
  const [sortedCollaborators, setSortedCollaborators] = useState("")
  const [filteredList, setFilteredList] = useState('');
  const [collaboratorFilter, setCollaboratorFilter] = useState(null);

  const handleReturn = () => {
    setColaborador(null)
    setColaboradorEdit(false)
  };
  const listCollaborator = colaborador === null
  const returnButton = !listCollaborator && (<Button color="primary" onClick={handleReturn}>Return</Button>);


  useEffect(() => {
    getCollaborators({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      //fetchPolicy: "no-cache",
    }).then((data) => {
      const collaboratorData = data?.data?.collaborators?.data?.map((item) => {
        return {
          id: item.id,
          userID: item.attributes?.users_permissions_user?.data?.id,
          name: item.attributes?.name,
          email: item.attributes?.email,
          phone: item.attributes?.phone,
          address: item.attributes?.address,
          payment: item?.attributes?.payment ?? 0,
          web: item.attributes?.web,
          intro: item.attributes?.intro,
          country: item.attributes?.country?.data?.attributes?.name,
          countryID: item.attributes?.country?.data?.id,
          city: item.attributes?.city?.data?.attributes?.name,
          yearsofExperience: item.attributes?.yearsofExperience,
          speciality: item.attributes?.speciality,
          cityID: item.attributes?.city?.data?.id,
          postRole: item.attributes?.postRole?.data?.attributes?.postRole,
          postRoleID: item.attributes?.postRole?.data?.id,
          postRoleDescription: item.attributes?.postRole?.data?.attributes?.description,
          evaluations: item.attributes?.evaluations?.data,
          employmentCollab: item.attributes?.employment?.data,
          companies: item.attributes?.companies?.data,
          isActive: item.attributes?.isActive,
          avatar: item.attributes?.image?.data?.attributes?.url,
          avatarID: item.attributes?.image?.data?.id,
          profiles: item.attributes?.profiles?.data?.map((profiles) => {
            return {
              id: profiles.id,
              urlProfile: profiles.attributes?.urlProfile,
              description: profiles.attributes?.description,
              platform: profiles.attributes?.platform?.data?.attributes?.name,
              platformID: profiles.attributes?.platform?.data?.id,
              icon: profiles.attributes?.platform?.data?.attributes?.icon?.data?.attributes?.url

            };
          }),
          jobRoles: item.attributes?.jobrole_colabs?.data?.[0]?.attributes?.job_roles?.data?.[0]?.attributes?.category,
          clientID: item.attributes?.client?.data?.id,
          client: item.attributes?.client?.data?.attributes?.companies?.data?.map((client) => {
            return {
              id: client.id,
              name: client.attributes?.name,
              image: client.attributes?.image?.data?.attributes?.url,
            };
          }),

          furnishers: item?.attributes?.furnishers?.data,
          distributors: item?.attributes?.distributors?.data,
          employmentcolabdistributors: item?.attributes?.employmentcolabdistributors?.data,

          portfolios: item.attributes?.portfolios?.data?.map((portfolios) => {
            return {
              id: portfolios.id,
              name: portfolios.attributes?.name,
              description: portfolios.attributes?.description,
              image: portfolios.attributes?.image?.data?.attributes?.url,
              imageID: portfolios.attributes?.image?.data?.id
            };
          }),
          awards: item.attributes?.awards?.data?.map((profiles) => {
            return {
              id: profiles.id,
              name: profiles.attributes?.name,
              description: profiles.attributes?.description
            };
          }),
          skills: item.attributes?.skills?.data?.map((profiles) => {
            return {
              id: profiles.id,
              percentual: profiles.attributes?.percentual,
              name: profiles.attributes?.itTool?.data?.attributes?.name,
              itToolID: profiles.attributes?.itTool?.data?.id
            };
          }),
          expertise: item.attributes?.expertise?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              expertise: profiles.attributes?.expertise?.data?.attributes?.name,
              expertiseID: profiles.attributes?.expertise?.data?.id
            };
          }),
          languages: item.attributes?.language_collaborators?.data?.map((lang) => {
            return {
              level: lang?.attributes?.level,
              name: lang?.attributes?.language.data.attributes.idioma,
              languageID: lang?.attributes?.language?.data?.id
            };
          }),
          educations: item.attributes?.educations?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              startDate: profiles.attributes?.startDate,
              endDate: profiles.attributes?.endDate,
              course: profiles.attributes?.course?.data?.attributes?.name,
              courseID: profiles.attributes?.course?.data?.id,
              faculty: profiles.attributes?.faculty?.data?.attributes?.name,
              facultyID: profiles.attributes?.faculty?.data?.id,
              country: profiles.attributes?.country?.data?.attributes?.name,
              countryID: profiles.attributes?.country?.data?.id
            };
          }),
          experiences: item.attributes?.experiences?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              startDate: profiles.attributes?.startDate,
              endDate: profiles.attributes?.endDate,
              postRole: profiles.attributes?.postRole?.data?.attributes?.postRole,
              postRoleDescription: profiles.attributes?.postRole?.data?.attributes?.description,
              postRoleID: profiles.attributes?.postRole?.data?.id,
              expEntity: profiles.attributes?.expEntity?.data?.attributes?.name,
              expEntityID: profiles.attributes?.expEntity?.data?.id,
              country: profiles.attributes?.country?.data?.attributes?.name,
              countryID: profiles.attributes?.country?.data?.id
            };
          }),

        };
      });

      console.log("MYDADACOMPANY", collaboratorData)

      const sortedData = collaboratorData?.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      //alert(distPath)
      if (userRole === "distributor" && distPath === "dist") {
        const filtro = sortedData?.filter(item =>
          item?.employmentcolabdistributors?.some(emp =>
            emp?.attributes?.distributors?.data?.some(dist => dist?.id === distId)
          )
        );
        //console.log("ISADMIN")
        setCollaborators(filtro);
      } else if (userRole === 'furnishers' && distPath === 'furnisher') {
        const filtro = sortedData?.filter(item => item?.furnishers?.[0]?.id == furnisherId);
        setCollaborators(filtro);
      } else {
        //console.log("ISADMIN22", collaboratorData);
        setCollaborators(sortedData);
      }


      setLoading(false);

    }).catch((error) => {
      console.log("Ocorreu um erro ao pegar colaborador", error);

    })
  }, [collaboratorFilter]);

  const calculateExperienceYears = (experiences) => {


    let totalYears = 0;

    experiences.forEach((experience) => {
      const startDate = new Date(experience.startDate);
      const endDate = new Date(experience.endDate);
      const years = endDate.getFullYear() - startDate.getFullYear();

      // Ajusta se o mês e dia do endDate ainda não ocorreram este ano
      if (
        endDate.getMonth() < startDate.getMonth() ||
        (endDate.getMonth() === startDate.getMonth() && endDate.getDate() < startDate.getDate())
      ) {
        totalYears -= 1;
      }

      totalYears += years;
    });

    return totalYears;
  };


  const filteredAdvanced = collaborators?.filter(item => {
    if (collaboratorFilter) {
      // Array para coletar os colaboradores que atendem ao critério de anos de experiência
      let experienceMatch = false;

      // Verifica cada chave no colaboradorFilter
      const matches = Object.entries(collaboratorFilter).every(([key, value]) => {
        if (!value || (Array.isArray(value) && value?.length === 0)) return true; // Ignora se não houver valor


        //if (!value) return true; // Ignora se não houver valor


        if (key === 'skills') {
          const itemSkills = item.skills || []; // Skills do colaborador
          // Verifica se pelo menos uma skill do filtro está presente no colaborador
          return value.some(skill =>
            itemSkills.some(itemSkill => itemSkill.name?.toLowerCase() === skill.toLowerCase())
          );
        }

        // Verifica se a chave é para anos mínimos de experiência
        if (key === 'minYearsOfExperience') {
          const totalExperienceYears = calculateExperienceYears(item.experiences);


          // Verifica se os anos de experiência são exatamente iguais ao valor fornecido
          experienceMatch = totalExperienceYears >= parseInt(value, 10);
          return experienceMatch; // Retorna true se a experiência coincidir
        }

        // Filtragem padrão para outros campos (texto, etc.)
        const itemValue = item[key]?.toString().toLowerCase();


        // Verifica se o valor do item contém o valor do filtro
        return itemValue && itemValue.includes(value.toString().toLowerCase());
      });

      // Se a experiência corresponder, inclua no resultado final
      return matches || experienceMatch; // Retorna verdadeiro se alguma das condições for verdadeira
    }
    return false; // Se não houver filtro, retorna todos os colaboradores
  });



  const filteredSearched = collaborators?.filter(item =>
    Object.values(item).some(value =>
      value?.toString().toLowerCase().includes(filteredList.toLowerCase())
    )
  );


  /*const filteredSearched = collaborators?.filter(item =>
      Object.values(item).some(value =>
        value?.toString().toLowerCase().includes(filteredList.toLowerCase())
      )
    );
  */

  if (loading) return <LoadingCircle message={i18n.t('loading.loading', { lng: selectedLanguage })} />
  return (
    <Grid container >
      <ToastContainer />
      <Grid item xs={12}>
        {/* <Pathway /> */}
        <Grid container style={{ marginBottom: '1.5rem', padding: '0 1rem' }}>
          <Grid item xs={12}>
            <div className="card banner w-full flex mt-10">
              <span>Seja Bem-vindo à plataforma</span>
              <span className="banner-colored">Afrikan Coders!</span>
            </div>
          </Grid>
        </Grid>

        <div className="px-[1rem]">
          {listCollaborator && <SearchAddBar
            searched={filteredList}
            setSearched={setFilteredList}
            setCollaboratorFilter={setCollaboratorFilter}
            setData={setCollaborators} />}
        </div>


        {returnButton}
        {listCollaborator ? (
          <CollaboratorList
            collaborators={filteredAdvanced !== undefined ? (filteredAdvanced?.length > 0 ? filteredAdvanced : filteredSearched) : filteredSearched}
            setCollaborators={setCollaborators}
            colaborador={colaborador}
            setColaborador={setColaborador}
            setColaboradorEdit={setColaboradorEdit}
          />
        ) : (
          <CollaboratorView
            colaborador={colaborador}
            setColaborador={setColaborador}
            setColaboradorEdit={setColaboradorEdit}
            colaboradorEdit={colaboradorEdit}
            setCollaborators={setCollaborators}
            collaborators={collaborators}
          />
        )}
        {returnButton}
      </Grid>
    </Grid>
  );
}

export default CreateCollaboratorMain;