import { gql } from "@apollo/client";

export const GET_FURNISHER_COLLABORATORS_IDs = gql`
  query GetFurnishersCollaborators($userId: ID!) {
    furnishers(filters: { users_permissions_user: { id: { eq: $userId } } }) {
      data {
        id
        attributes {
          name
          collaborators(pagination: {pageSize: 1000000}) {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_DISTRIBUTOR_COMPANIES_IDs = gql`
  query GetDistributorCompanies($userId: ID!) {
    distributors(filters: { users_permissions_user: { id: { eq: $userId } } }) {
      data {
        id
        attributes {
          name
          companies(pagination: {pageSize: 1000000}) {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_DISTRIBUTOR_COMPANIES = gql`
  query GetDistributorCompanies($userId: ID!) {
    distributors(filters: { users_permissions_user: { id: { eq: $userId } } }) {
      data {
        id
        attributes {
          name
          companies(pagination: {pageSize: 1000000}) {
            data {
              id
              attributes {
                name
                status
                phone
                email
                findDevs {
                  data {
                    id
                    attributes {
                      description
                    }
                  }
                }
                image {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                branchOfActivity
                city {
                  data {
                    id 
                    attributes {
                      name
                    }
                  }
                }
                country {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                users_permissions_user {
                  data {
                    id 
                    attributes {
                      username 
                      confirmed
                    }
                  }
                } 
                evaluation_periods{
                  data {
                    id 
                    attributes {
                      start 
                      end 
                      isActive 
                      collaborators {
                        data {
                          id 
                          attributes {
                            name
                          }
                        }
                      }
                    }
                  }
                }   
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_DISTRIBUTOR_COLLAB_IDs = gql`
  query GetDistributorCollaborators($userId: ID!) {
    distributors(filters: { users_permissions_user: { id: { eq: $userId } } }) {
      data {
        id
        attributes {
          name
          collaborators(pagination: {pageSize: 1000000}) {
            data {
              id
            }
          }
        }
      }
    }
  }
`;


export const GET_DISTRIBUTOR_COLLABORATORS = gql`
  query GetDistributorByUser($userId: ID!) {
  distributors(filters: { users_permissions_user: { id: { eq: $userId } } }) {
    data {
      id
      attributes {
        name
        employmentcolabdistributors {
          data {
            id
            attributes {
              payment
              status
              collaborators(pagination: { pageSize: 1000000 }) {
                data {
                  id
                  attributes {
                    furnishers {
                      data {
                        id
                        attributes {
                          name
                        }
                      }
                    }
                    distributors {
                      data {
                        id
                        attributes {
                          name
                        }
                      }
                    }
                    jobrole_colabs {
                      data {
                        id
                        attributes {
                          job_roles {
                            data {
                              id
                              attributes {
                                category
                              }
                            }
                          }
                        }
                      }
                    }
                    users_permissions_user {
                      data {
                        id
                        attributes {
                          username
                          confirmed
                        }
                      }
                    }
                    name
                    email
                    phone
                    web
                    intro
                    address
                    payment
                    rating
                    speciality
                    language_collaborators {
                      data {
                        id
                        attributes {
                          language {
                            data {
                              id
                              attributes {
                                idioma
                              }
                            }
                          }
                          level
                          collaborator {
                            data {
                              id
                              attributes {
                                name
                              }
                            }
                          }
                        }
                      }
                    }
                    evaluations {
                      data {
                        id
                        attributes {
                          star
                          Comment
                          companies {
                            data {
                              id
                              attributes {
                                name
                              }
                            }
                          }

                          evaluation_period {
                            data {
                              id
                              attributes {
                                start
                                end
                                description
                              }
                            }
                          }
                        }
                      }
                    }
                    postRole {
                      data {
                        id
                        attributes {
                          postRole
                          description
                        }
                      }
                    }
                    city {
                      data {
                        id
                        attributes {
                          name
                        }
                      }
                    }
                    country {
                      data {
                        id
                        attributes {
                          name
                        }
                      }
                    }
                    image {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                    experiences(pagination: { pageSize: 20 }) {
                      data {
                        attributes {
                          description
                          startDate
                          endDate
                          postRole {
                            data {
                              attributes {
                                postRole
                                description
                              }
                            }
                          }
                          expEntity {
                            data {
                              attributes {
                                name
                              }
                            }
                          }
                          country {
                            data {
                              attributes {
                                name
                              }
                            }
                          }
                        }
                      }
                    }
                    educations(pagination: { pageSize: 20 }) {
                      data {
                        attributes {
                          description
                          startDate
                          endDate
                          course {
                            data {
                              attributes {
                                name
                              }
                            }
                          }
                          faculty {
                            data {
                              attributes {
                                name
                              }
                            }
                          }
                          country {
                            data {
                              attributes {
                                name
                              }
                            }
                          }
                        }
                      }
                    }
                    expertise {
                      data {
                        attributes {
                          description
                          expertise {
                            data {
                              attributes {
                                name
                              }
                            }
                          }
                        }
                      }
                    }
                    skills {
                      data {
                        attributes {
                          percentual
                          itTool {
                            data {
                              attributes {
                                name
                              }
                            }
                          }
                        }
                      }
                    }
                    profiles {
                      data {
                        attributes {
                          urlProfile
                          description
                          platform {
                            data {
                              attributes {
                                name
                                icon {
                                  data {
                                    attributes {
                                      url
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    awards {
                      data {
                        attributes {
                          name
                          description
                        }
                      }
                    }
                    portfolios {
                      data {
                        attributes {
                          name
                          description
                          image {
                            data {
                              attributes {
                                url
                                previewUrl
                              }
                            }
                          }
                        }
                      }
                    }
                    client {
                      data {
                        attributes {
                          companies {
                            data {
                              id
                              attributes {
                                name
                                image {
                                  data {
                                    attributes {
                                      url
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    users_permissions_user {
                      data {
                        id
                        attributes {
                          username
                        }
                      }
                    }
                    companies {
                      data {
                        id
                        attributes {
                          name
                          email
                          users_permissions_user {
                            data {
                              id
                              attributes {
                                username
                              }
                            }
                          }
                        }
                      }
                    }
                    employment {
                      data {
                        id
                        attributes {
                          collaborators {
                            data {
                              id
                              attributes {
                                name
                              }
                            }
                          }
                        }
                      }
                    }
                    isActive
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;


export const GET_DISTRIBUTOR_BY_COMPANY_ID = gql`
  query GetDistributorByCompany($companyId: ID!) {
    distributors(filters: { companies: { id: { eq: $companyId } } }) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;