import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField
} from "@mui/material";
import { Section } from "../section";
import css from "../css/expertise.module.css";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { StylesInput } from "../../../../theme-alt";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPDATE_PAYMENT_IN_COLLAB, UPDATE_PAYMENT_IN_COLLAB_ALL } from "../query.gql";
import { toast } from "react-toastify";

const Header = ({ handleOpen, setStepID, colaboradorEdit, payment }) => {

  const jwt = localStorage.getItem("jwtToken");

  const [open, setOpen] = useState(false);
  const [salary, setSalary] = useState(0);

  const [updatePayment] = useMutation(UPDATE_PAYMENT_IN_COLLAB);
  const [updatePayment_] = useMutation(UPDATE_PAYMENT_IN_COLLAB_ALL);

  const handleClose = () => setOpen(!open);

  useEffect(() => {
    setSalary(payment?.payment || 0);
  }, [payment]);

  const proceedEdit = (collaboratorId, paymentValue, iddd) => {

    if (!collaboratorId) {
      toast.warning("ID Colaborador Inválido!");
      return;
    }
    if (paymentValue == 0) {
      toast.warning("O valor do pagamento não pode ser 0");
      return;
    }

    if (iddd) {
      updatePayment({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: {
          id: iddd,
          payment: parseFloat(paymentValue),
        }
      }).then(res => {
        if (res) {
          toast.success("Update Successful!");
          setTimeout(() => {
            window.location.reload();
          }, 1500)
        }
      }).catch(erro => {
        toast.error(erro);
      })

    } else {
      updatePayment_({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: {
          id: collaboratorId,
          payment: parseFloat(paymentValue),
        }
      }).then(res => {
        if (res) {
          toast.success("Update Successful!");
          setTimeout(() => {
            window.location.reload();
          }, 1500)
        }
      }).catch(erro => {
        toast.error(erro);
      })
    }



  }

  return (
    <>
      <h2>{colaboradorEdit === true && <>
        <IconButton color="primary" onClick={handleClose}>
          <EditOutlinedIcon />
        </IconButton>
      </>}PAYMENT</h2>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" alignItems="center">
          PAYMENT
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <span className='w-full block -mb-3 pl-5 text-left'>Insira o novo valor de Pagamento/Hora</span>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <span className='flex my-3'></span>
              <Grid item xs={12}>
                <TextField
                  label="Remuneração"
                  type="number"
                  fullWidth
                  value={salary}
                  onChange={(e) => setSalary(e.target.value)}
                  sx={StylesInput}
                />
              </Grid>

              <span className='flex my-3'></span>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleClose}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {

                      proceedEdit(payment?.id, salary, payment?.empId);

                    }}
                    style={{ marginRight: '10px' }}
                  // disabled={loadingAssoc}
                  >
                    Editar
                    {/* {loadingAssoc ? 'Associando...' : 'Sim, Associar'}  */}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Content = ({ handleOpen, setStepID, payment, colaboradorEdit }) => {

  return (
    <>
      {
        colaboradorEdit === true ? (
          <>
            <div className={css.wrapper}>
              <h2 className="font-bold" style={{ fontSize: '1.1rem' }}> Payment/Hour ${" "}{payment.payment}</h2>
            </div>
          </>
        ) : null
      }
    </>
  );
};

export const Payment = ({ handleOpen, setStepID, payment, colaboradorEdit }) => {
  return (
    <Section
      header={
        <Header
          payment={payment}
          colaboradorEdit={colaboradorEdit}
        />
      }
      content={
        <Content
          handleOpen={handleOpen}
          setStepID={setStepID}
          colaboradorEdit={colaboradorEdit}
          payment={payment}
        />
      }
    />
  )
};
