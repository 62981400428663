import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        email
        
      }
    }
  }
`;
export const REGISTER = gql`
mutation Register($input: UsersPermissionsRegisterInput!) {
  register(input: $input) {
    jwt
    user {
      id
      email
    }
  }
}
`;
export const CREATE_USER = gql`
mutation CreateUser($data: UsersPermissionsUserInput!) {
  createUsersPermissionsUser(data: $data){
    data{
      id 
      attributes{
        username
         email
         confirmed 
           userRole {
          data {
            id
            attributes {
              role
            }
          }
        }
        }}}
}
`;



export const CREATE_FUNISHER = gql`
  mutation CreateFurnisher($data: FurnisherInput!) {
    createFurnisher( data: $data ){
    data {
      id
      attributes {
        name
        email
        phone
        status
        city {
          data {
            id
            attributes {
              name
            }
          }
        }
        country {
          data {
            id
            attributes {
              name
            }
          }
        }
        users_permissions_user {
          data {
            id
            attributes {
              username
            }
          }
        }
      }
    }
  }
}
`;

export const CREATE_CITY = gql`
mutation createCity($data: CityInput!) {
  createCity(data: $data) {
    data {
      id
      attributes {
        name
      }
    }
  }
}`


export const DELETE_FUNISHER = gql`
mutation deleteFurnisher($id: ID!) {
  deleteFurnisher(id: $id) {
    data {
      id
      attributes {
        name
        status
      }
    }
  }
}
`;

export const UPDATE_FUNISHER = gql`
 mutation updateFurnisher($id: ID!, $data: FurnisherInput!) {
  updateFurnisher(id: $id, data: $data) {
    data {
      id
      attributes {
       name
      email
      phone
      status
      city {
        id
      }
      country {
        id
      }
      users_permissions_user {
        id
  }
      
       }
    }
  }
}

`;

export const DELETE_MEDIA_FILE = gql`
mutation DeleteMediaLibrary($id: ID!) {
  deleteUploadFile(id: $id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}

`;
export const DISABLE_USERS = gql`
mutation disableUser($id:ID!,$disable:Boolean!) {
  updateUsersPermissionsUser(id: $id,data:{confirmed:$disable}) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;
export const CREATE_MEDIA_FILE = gql`
mutation uploadFile(
  $file: Upload!
  $info: FileInfoInput!
  $refId: ID!
  $field: String!
  $ref: String!
) {
  upload(file: $file, refId: $refId, field: $field, ref: $ref, info: $info) {
    data {
      id
      attributes {
        name
        url
      }
    }
  }
}

`;
export const DELETE_USERS = gql`
mutation deleteUser($id:ID!) {
  deleteUsersPermissionsUser(id: $id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`;
export const CREATE_FIND_DEV = gql`
mutation FindDev($data: FindDevInput!) {
  createFindDev(data: $data) {
    data {
      id
      attributes {
        description
        company_answers{data{id attributes{name}}}
        company {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}`

export const ASSOCIATECOLLABORATOR_TO_FURNISHER = gql`
mutation createCollaboratorFromFurnicher($data: CollaboratorInput!) {
  createCollaborator(data: $data) {
    data {
      id
      attributes {
        name
        email
        phone
        isActive
        speciality
        yearsofExperience
        payment
        users_permissions_user {
          data {
            id
            attributes {
              username
              email
            }
          }
        }
city {
          data {
            id
            attributes {
              name
            }
          }
        }
        country {
          data {
            id
            attributes {
              name
            }
          }
        }
        distributors {
          data {
            id
            attributes {
              name
            }
          }
  }
   }
}
}
}
`;

export const ASSOCIATECOLLABORATOR_TO_DISTRIBUTOR = gql`
mutation AssociateCollaboratorToDistributor($id: ID!, $collaboratorId: [ID!]) {
  updateDistributor(id: $id, data: { collaborators: $collaboratorId }) {
    data {
      id
      attributes {
        name
        collaborators {
          data {
            id
            attributes {
              name
            }
          }
        }
  }
}
}
}
`;

export const ASSOCIATECOLLABORATOR_TO_FURN = gql`
mutation updateFurnisher($id: ID!, $collaboratorIds: [ID!]) {
  updateFurnisher(id: $id, data: { collaborators: $collaboratorIds }) {
    data {
      id
      attributes {
        name
        collaborators {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
`;

export const ASSOCTOCOL_TO_FURN_F = gql`
mutation AssociateMultipleCollaboratorsToFurnisher (
  $id: ID!, 
  $collaboratorIds: [ID!]
  ) {
  updateFurnisher(
    id: $id, 
    data: { collaborators: $collaboratorIds }
    ) {
    data {
      id
      attributes {
        name
        collaborators {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
`;

export const ASSOCIATECOLLABORATOR_TO_FFURN = gql`
  mutation createFurnisherCollaborator($data: FurnishercollabortorInput!) {
    createFurnishercollabortor(data: $data) {
      data {
        id
        attributes {
          furnishers {
            data {
              id
              attributes {
                name
                email
              }
            }
          }
          collaborators {
            data {
              id
              attributes {
                name
                email
              }
            }
          }
          createdAt
        }
      }
    }
  }
`;

export const ASSOCIATECOLLABORATOR_TO_DIST = gql`
  mutation AssociateMultipleCollaboratorsToDistributor(
    $id: ID!,
    $collaboratorIds: [ID!]!
  ) {
    updateDistributor(
      id: $id
      data: { collaborators: $collaboratorIds }
    ) {
      data {
        id
        attributes {
          name
          collaborators { # Definindo um limite maior
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;
// mutation updateDistributor($id: ID!, $collaboratorIds: [ID!]) {
//   updateDistributor(id: $id, data: { collaborators: $collaboratorIds }) {
//     data {
//       id
//       attributes {
//         name
//         collaborators {
//           data {
//             id
//             attributes {
//               name
//             }
//           }
//         }
//       }
//     }
//   }
// }