import { useMutation } from '@apollo/client';
import {useState } from 'react';  
 import { DELETE_COMPANY, UPDATE_COMPANY, DISABLE_USERS, DELETE_USERS, DELETE_FUNISHER, UPDATE_FUNISHER } from './mutation.gql';
import { Button, Grid } from '@mui/material';

const FunisherDelete = ({funisher,setSuccessMessage, setOpenAlertOK,activeOption, funisherUserID, funisherID ,handleClose ,funishies,setFunishies}) => {
    
    const [deleteFunisher] = useMutation(DELETE_FUNISHER)
    const [disableFunisher] = useMutation(UPDATE_FUNISHER)
    const [disableUser] = useMutation(DISABLE_USERS)
       const [deleteUser] = useMutation(DELETE_USERS)
  const deleteFunisherItem =  activeOption===false
    const disableFunisherItem = activeOption=== true
    const jwt = localStorage.getItem("jwtToken")
    const [status, setStatus] = useState(funisher.attributes.status)
   
  
    const handleDisable = async () => {

if(status=== 'Active'){
   try {
            await disableFunisher({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: funisherID,
                    data: {
                        status: 'Inactive',
                    },
                },
            }).then((data) => { 
           
            const updatedData = funishies.filter(obj => obj);
            setFunishies(updatedData)
               setOpenAlertOK(true)
                setSuccessMessage("Fornecedor atualizado");
                setStatus(funishies.attributes.status = 'Inactive') 
            });
     
            handleClose()
        } catch (error) {
            console.log(error);
            setOpenAlertOK(false)
            setSuccessMessage("Error");
        } 
       
}else{

   try {
            await disableFunisher({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: funisherID,
                    data: {
                        status: 'Active',
                    },
                },
            }).then((data) => {               
                setStatus(funishies.attributes.status = 'Active')
               const updatedData = funishies.filter(obj => obj);
            setFunishies(updatedData) 
               setOpenAlertOK(true)
            setSuccessMessage("Fornecedor atualizado");    
            });
          
            handleClose();
            
        } catch (error) {
            console.log(error);
            setOpenAlertOK(false)
            setSuccessMessage("Error");
        } 
      
}

  };
   

    
    const handleDelete =async () => {  
         handleClose()
        deleteFunisher({
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
            //fetchPolicy: "network-only",
            variables: {
                id: funisherID
            },
        }).then((data) => {
       const updatedData = funishies.filter(obj => obj.id !== funisherID);
            setFunishies(updatedData)
          
            setOpenAlertOK(true)
            setSuccessMessage("Fornecedor Removido"); 
        })
        .catch((error) => {
            console.log(error)
            setOpenAlertOK(false)
            setSuccessMessage("Error");
        });
     
        
        await deleteUser({
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
            //fetchPolicy: "network-only",
            variables: {
                id: funisherUserID,
                   
            }
        }).catch((error) => {
            console.log(error)
        
        });
           
       
    } 
 
    return (<>
        <form onSubmit={deleteFunisherItem?handleDelete:handleDisable}>
            <Grid container spacing={2} marginTop={1}>
                <Grid container spacing={3} item xs={12} display="flex" justifyContent="space-around" alignItems="center">
                    <Grid item>
                        <Button variant="outlined" size="small" onClick={handleClose}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        {deleteFunisherItem && <Button
                            variant="contained"
                            size="small"
                            onClick={
                                handleDelete
                            }
                        >Delete</Button>}
                        {disableFunisherItem && <Button
                            variant="contained"
                            size="small"
                            onClick={
                                handleDisable
                            }
                        >{status === 'Active'? 'Disable' : 'Enable'}</Button>}
                    </Grid>
                </Grid> 
            </Grid> 
        </form> 
        
        
        </>
    );

};

export default FunisherDelete;
