import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    InputLabel,
    TextField,
    Paper,
    Autocomplete,
    FormControl,
    createFilterOptions,
    Box,
    Typography,
    Select,
    Checkbox,
    ListItemText,
    OutlinedInput,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AddIcon from '@mui/icons-material/Add';
import QuizFormTeste from '../../pages/QuizPage/quiz-form.component';
import QuestionFormTeste from '../../pages/QuestionPage/question-form-teste.component';
import { useLocation, useNavigate } from 'react-router-dom';
import IndicatorFormTest from '../../pages/IndicatorPage/indicator-form';
import PeriodForm from '../../pages/filestodelete/period-form.component';
import PostRoleFormTeste from '../../pages/PostRolePage/post-role-form';
import PeriodFormTest from '../../pages/PeriodPage/period-formtest.component';
import DimensionFormTest from '../../pages/DimensionPage/dimension-formtest';
import CreateCompany from '../../pages/CompanyPage/Company_Creation_Page/create_company';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GET_POST_ROLES } from '../../pages/DimensionPage/query.gql';
import FilterListIcon from '@mui/icons-material/FilterList';
import i18n from '../../translate/i18n';
import { LanguageContext } from '../../translate/languages/LanguageContext';
import { theme } from '../Theme';
import ClearIcon from '@mui/icons-material/Clear';
import { GET_IT_TOOLS } from '../../pages/Collaborator/Collaborator_Creation_Page/query.gql';
import { useLazyQuery } from '@apollo/client';
import CreateFunisher from '../../pages/FurnisherPage/Furnisher_Creation_Page/create_furnisher';
import CreateDistributor from '../../pages/Distributor/Distributor_Creation_Page/create_distributor';
import { StylesInput } from '../../theme-alt';
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 380,
        color: 'rgb(55, 65, 81)',
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '19ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

const SearchAddBar = ({ searched, setSearched, setData, data, setCollaboratorFilter, setCompanyFilter, setFunisher, furnisher }) => {
    const [nameCollaborator, setNameCollaborator] = useState('');
    const [phoneCollaborator, setPhoneCollaborator] = useState('');
    const [yearsofExperience, setYearsofExperience] = useState('');
    const [emailCollaborator, setEmailCollaborator] = useState('');
    const [addressCollaborator, setAddressCollaborator] = useState('');
    const [specialityCollaborator, setSpecialityCollaborator] = useState(null);
    const [error, setError] = useState('');
    const [skills, setSkills] = useState([]);
    const [itTools, setItTools] = useState([]);
    const jwt = localStorage.getItem("jwtToken");
    const [nameCompany, setNameCompany] = useState('');
    const [addressCompany, setAddressCompany] = useState('');
    const [phoneCompany, setPhoneCompany] = useState('');
    const [statusCompany, setStatusCompany] = useState(null);
    const [getItToolsName] = useLazyQuery(GET_IT_TOOLS);
    const [openFilter, setOpenFilter] = useState(false);



    const [openFilterCompany, setOpenFilterCompany] = useState(false);

    const [userRole, setUserRole] = useState('Company');

    useEffect(() => {
        setItTools([]);
        getItToolsName({
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
            //fetchPolicy: "network-only",
        }).then((data) => {
            const itToolsData = data.data.itTools.data.map((item) => {
                return {
                    id: item.id,
                    name: item.attributes.name,
                };
            });

            setItTools(itToolsData);
        });
    }, []);

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSkills(typeof value === 'string' ? value.split(',') : value);
    };

    useEffect(() => {
        const role = localStorage.getItem('userRole');
        if (role) {
            setUserRole(role.toLowerCase());
        }
    }, [userRole]);

    const handleClickFilter = () => {
        if (currentLocation.pathname === '/collaborator/main') {
            setOpenFilter(true);
        } else if (currentLocation.pathname === '/companies') {
            console.log('na company');

            setOpenFilterCompany(true);
        }
    };
    const handleCloseFilter = () => {
        setNameCollaborator('');
        setPhoneCollaborator('');
        setEmailCollaborator('');
        setAddressCollaborator('');
        setYearsofExperience('');
        setSpecialityCollaborator(null);
        setOpenFilter(false);
        setOpenFilterCompany(false);
        setNameCompany('');
        setPhoneCompany('');
        setAddressCompany('');
        setStatusCompany(null);
    };

    const optionEspeciality = [
        { id: 'frontend', name: 'Front-end' },
        { id: 'backend', name: 'Back-end' },
        { id: 'fullstack', name: 'Full-Stack' },
    ];

    const optionStatus = [
        { id: 'active', name: 'Ativo' },
        { id: 'pending', name: 'Pendente' },
    ];

    const { selectedLanguage } = useContext(LanguageContext);
    const currentLocation = useLocation();
    let navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const isMobileMode = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumMode = useMediaQuery(theme.breakpoints.down('md'));
    const handleClickOpen = () => {
        if (currentLocation.pathname === '/collaborator/main' || currentLocation.pathname.split('/')[1] === 'dist' || currentLocation.pathname.split('/')[1] === 'furnisher') {
            navigate('/collaborator/create');
        } else {
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClear = () => {
        setSearched('');
    };

    const OnClickToFilter = () => {
        if (
            nameCollaborator.trim() === '' &&
            phoneCollaborator === '' &&
            emailCollaborator.trim() === '' &&
            specialityCollaborator === null &&
            addressCollaborator.trim() === '' &&
            yearsofExperience.trim() === '' &&
            skills?.length === 0
        ) {
            setError(i18n.t('collaboratorFilter.error', { lng: selectedLanguage }));
        } else {
            const collaboratorFilter = {
                name: nameCollaborator || '',
                phone: phoneCollaborator || '',
                email: emailCollaborator || '',
                country: addressCollaborator || '',
                speciality: specialityCollaborator?.id || '',
                minYearsOfExperience: yearsofExperience,
                skills: skills || [],
            };

            setCollaboratorFilter(collaboratorFilter);
            handleCloseFilter();
        }
    };

    const OnClickToFilterCompany = () => {
        if (
            nameCompany.trim() === '' &&
            phoneCompany === '' &&
            statusCompany === null &&
            addressCompany.trim() === ''
        ) {
            setError(i18n.t('collaboratorFilter.error', { lng: selectedLanguage }));
        } else {
            const companyFilter = {
                name: nameCompany || '',
                phone: phoneCompany || '',
                status: statusCompany?.id || '',
                country: addressCompany || '',
            };

            setCompanyFilter(companyFilter);
            handleCloseFilter();
        }
    };

    const [indicators] = useState([]);
    const [dimensions] = useState([]);
    const [postRoles] = useState([]);
    const [evaluationPeriod] =
        useState(/* {
        id: "",
        start: "",
        end: "",
        description: "",
        isActive: true,
        companyID: "1195",
        companyName: "",
        collaborator: [{ id: "24", name: "Heli teste" }, { id: "24", name: "Heli teste" }, { id: "28", name: "Helinete Amorim" }]
        ,
    } */);

    const [companies, setCompanies] = useState([]);
    const [quizzes] = useState([]);
    const [questions] = useState([]);
    const [updaters, setUpdaters] = useState([]);

    return (
        <Grid sx={{ marginBottom: 3 }}>
            <Grid container spacing={2} direction='row' alignItems='center'>
                <Grid item xs={3}>
                    <Paper>
                        <Search sx={{ display: 'flex', alignItems: 'center', padding: '8px 0' }}>
                            <SearchIconWrapper>
                                <SearchIcon color='disabled' />
                            </SearchIconWrapper>
                            <StyledInputBase
                                sx={StylesInput}
                                inputProps={{ 'aria-label': 'search' }}
                                placeholder='Search'
                                value={searched}
                                onChange={(e) => setSearched(e.target.value)}
                            />
                        </Search>
                    </Paper>
                </Grid>
                {searched && (
                    <Grid item xs={0.2}>
                        <ClearIcon
                            sx={{
                                cursor: 'pointer',
                                marginTop: '0.5rem',
                                marginLeft: '-0.55rem',
                            }}
                            onClick={handleClear}
                        />
                    </Grid>
                )}
                <Grid item xs={2}>
                    <Paper>
                        <Button
                            onClick={handleClickFilter}
                            variant='text'
                            sx={{ marginTop: '0.1rem', color: 'grey' }}
                            startIcon={<FilterListIcon />}
                        >
                            {isMobileMode
                                ? ''
                                : i18n.t('button.filter', { lng: selectedLanguage })}
                        </Button>
                    </Paper>
                    <StyledMenu
                        id='demo-customized-menu'
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={openFilter}
                        open={openFilter}
                        onClose={handleCloseFilter}
                    >
                        <MenuItem>
                            <Grid container item xs={10} spacing={1} id='expGrid'>
                                <Grid item xs={12} md={6}>
                                    <InputLabel>
                                        {i18n.t('collaboratorStep.collaborator.nameCollaborator', {
                                            lng: selectedLanguage,
                                        })}
                                    </InputLabel>
                                    <TextField
                                        size='small'
                                        required
                                        autoFocus
                                        type='text'
                                        value={nameCollaborator}
                                        onChange={(event) => {
                                            setNameCollaborator(event.target.value);
                                        }}
                                        name='name'
                                        id='name'
                                        placeholder='Ex: Afrikan Coders'
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <InputLabel>
                                        {i18n.t('table.location', { lng: selectedLanguage })}
                                    </InputLabel>
                                    <TextField
                                        size='small'
                                        required
                                        autoFocus
                                        type='text'
                                        name='address'
                                        value={addressCollaborator}
                                        onChange={(event) =>
                                            setAddressCollaborator(event.target.value)
                                        }
                                        id='address'
                                        placeholder='Ex: Parque Tecnologico Nº12'
                                    />
                                </Grid>

                                {!userRole.includes('company') ? (
                                    <>
                                        <Grid item xs={12} md={6}>
                                            <InputLabel>
                                                {i18n.t('collaboratorStep.collaborator.phone', {
                                                    lng: selectedLanguage,
                                                })}
                                            </InputLabel>
                                            <TextField
                                                size='small'
                                                required
                                                autoFocus
                                                type='text'
                                                value={phoneCollaborator}
                                                onChange={(event) =>
                                                    setPhoneCollaborator(event.target.value)
                                                }
                                                name='phone'
                                                id='phone'
                                                placeholder='Ex: +238 5802943'
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <InputLabel>
                                                {i18n.t('collaboratorStep.collaborator.email', {
                                                    lng: selectedLanguage,
                                                })}
                                            </InputLabel>
                                            <TextField
                                                size='small'
                                                required
                                                autoFocus
                                                type='text'
                                                value={emailCollaborator}
                                                onChange={(event) =>
                                                    setEmailCollaborator(event.target.value)
                                                }
                                                name='email'
                                                id='email'
                                                placeholder='Ex: african@koders.com'
                                            />
                                        </Grid>
                                    </>
                                ) : null}

                                <Grid item xs={12} md={6}>
                                    <InputLabel>
                                        {i18n.t('collaboratorStep.collaborator.yearsOfExperience', {
                                            lng: selectedLanguage,
                                        })}
                                    </InputLabel>
                                    <TextField
                                        size='small'
                                        required
                                        autoFocus
                                        type='number'
                                        value={yearsofExperience}
                                        onChange={(event) =>
                                            setYearsofExperience(event.target.value)
                                        }
                                        name='yearsofExperience'
                                        id='yearsofExperience'
                                        placeholder='Ex: 3'
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <InputLabel id='select-especiality'>
                                        {i18n.t('collaboratorStep.collaborator.especiality', {
                                            lng: selectedLanguage,
                                        })}
                                    </InputLabel>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            size='small'
                                            options={optionEspeciality}
                                            value={specialityCollaborator}
                                            getOptionLabel={(specialityCollaborator) =>
                                                specialityCollaborator.name
                                            }
                                            isOptionEqualToValue={(option, value) =>
                                                option.id === value.id
                                            }
                                            onChange={(event, newValue) => {
                                                setSpecialityCollaborator(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant='outlined'
                                                    placeholder={i18n.t('Ex: Front-end', {
                                                        lng: selectedLanguage,
                                                    })}
                                                    required
                                                />
                                            )}
                                        />
                                        <Grid item xs={12} md={6} style={{ padding: '12px' }}>
                                            <Box fullWidth>
                                                <Button
                                                    fullWidth
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={OnClickToFilter}
                                                >
                                                    {i18n.t('button.collaboratorFilter', {
                                                        lng: selectedLanguage,
                                                    })}
                                                </Button>
                                                {error && (
                                                    <Typography variant='body2' color='error'>
                                                        {error}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    </StyledMenu>

                    <StyledMenu
                        id='demo-customized-menu'
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={openFilterCompany}
                        open={openFilterCompany}
                        onClose={handleCloseFilter}
                    >
                        <MenuItem>
                            <Grid container item xs={10} spacing={1} id='expGrid'>
                                <Grid item xs={12} md={6}>
                                    <InputLabel>
                                        {i18n.t('companyFilter.nameComapny', {
                                            lng: selectedLanguage,
                                        })}
                                    </InputLabel>
                                    <TextField
                                        size='small'
                                        required
                                        autoFocus
                                        type='text'
                                        value={nameCompany}
                                        onChange={(event) => {
                                            setNameCompany(event.target.value);
                                        }}
                                        name='name'
                                        id='name'
                                        placeholder='Ex: Sintaxy'
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <InputLabel>
                                        {i18n.t('table.location', { lng: selectedLanguage })}
                                    </InputLabel>
                                    <TextField
                                        size='small'
                                        required
                                        autoFocus
                                        type='text'
                                        name='addresscompany'
                                        value={addressCompany}
                                        onChange={(event) => setAddressCompany(event.target.value)}
                                        id='addresscompany'
                                        placeholder='Ex: Angola'
                                    />
                                </Grid>

                                {!userRole.includes('company') ? (
                                    <>
                                        <Grid item xs={12} md={6}>
                                            <InputLabel>
                                                {i18n.t('companyFilter.phone', {
                                                    lng: selectedLanguage,
                                                })}
                                            </InputLabel>
                                            <TextField
                                                size='small'
                                                required
                                                autoFocus
                                                type='text'
                                                value={phoneCompany}
                                                onChange={(event) =>
                                                    setPhoneCompany(event.target.value)
                                                }
                                                name='phonecompany'
                                                id='phonecompany'
                                                placeholder='Ex: +238 5802943'
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <InputLabel>
                                                {i18n.t('companyFilter.status', {
                                                    lng: selectedLanguage,
                                                })}
                                            </InputLabel>

                                            <Autocomplete
                                                size='small'
                                                options={optionStatus}
                                                value={statusCompany}
                                                getOptionLabel={(statusCompany) => statusCompany.name}
                                                isOptionEqualToValue={(option, value) =>
                                                    option.id === value.id
                                                }
                                                onChange={(event, newValue) => {
                                                    setStatusCompany(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant='outlined'
                                                        placeholder={i18n.t('Ex: Pendente', {
                                                            lng: selectedLanguage,
                                                        })}
                                                        required
                                                    />
                                                )}
                                            />
                                            {/*   <TextField
                                                size="small"
                                                required
                                                autoFocus
                                                type="text"

                                                value={statusCompany}
                                                onChange={(event) => setStatusCompany(event.target.value)}
                                                name="status"
                                                id="status"
                                                placeholder="Ex: Luanda"
                                            /> */}
                                        </Grid>
                                    </>
                                ) : null}

                                <Grid item xs={12} md={6} style={{ padding: '12px' }}>
                                    <Box fullWidth>
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            color='primary'
                                            onClick={OnClickToFilterCompany}
                                        >
                                            {i18n.t('button.collaboratorFilter', {
                                                lng: selectedLanguage,
                                            })}
                                        </Button>
                                        {error && (
                                            <Typography variant='body2' color='error'>
                                                {error}
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    </StyledMenu>
                </Grid>
                {
                    userRole != 'company' && (
                        <Grid
                            item
                            xs={searched ? 5 : 5}
                            container
                            justifyContent='flex-end'
                            sx={{ marginLeft: 'auto' }}
                        >
                            <Button
                                variant='contained'
                                startIcon={<AddIcon />}
                                onClick={handleClickOpen}
                            >
                                {i18n.t('button.new', { lng: selectedLanguage })}
                            </Button>
                        </Grid>
                    )
                }
            </Grid>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='md'
            >
                <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" alignItems="center">
                    {currentLocation.pathname === '/question' ? i18n.t('title.question', { lng: selectedLanguage }) :
                        currentLocation.pathname === '/quiz' ? i18n.t('title.quiz', { lng: selectedLanguage }) :
                            currentLocation.pathname === '/collaborator/role' ? i18n.t('title.role', { lng: selectedLanguage }) :
                                currentLocation.pathname === '/dimension' ? i18n.t('title.dimension', { lng: selectedLanguage }) :
                                    currentLocation.pathname === '/period' ? i18n.t('title.period', { lng: selectedLanguage }) :
                                        currentLocation.pathname === '/collaborator' ? i18n.t('title.collaborator', { lng: selectedLanguage }) :
                                            currentLocation.pathname === '/companies' ? i18n.t('title.company', { lng: selectedLanguage }) :
                                                currentLocation.pathname === '/furnishers' ? i18n.t('title.furnishers', { lng: selectedLanguage }) :
                                                    currentLocation.pathname === '/distributor'
                                                        ? i18n.t('title.distributor', { lng: selectedLanguage }) :
                                                        i18n.t('title.indicator', { lng: selectedLanguage })}
                </DialogTitle>
                <DialogContent>

                    {
                        currentLocation.pathname === '/question'
                            ?
                            <QuestionFormTeste
                                question={questions}
                                titleAction={i18n.t('title.question', { lng: selectedLanguage })}
                                handleClose={handleClose} setData={setData}
                            />
                            :
                            currentLocation.pathname === '/quiz'
                                ?
                                <QuizFormTeste
                                    quiz={quizzes}
                                    titleAction={i18n.t('title.quiz', { lng: selectedLanguage })}
                                    handleClose={handleClose} setData={setData}
                                />
                                :
                                currentLocation.pathname === '/indicator'
                                    ?
                                    <IndicatorFormTest
                                        indicador={indicators}
                                        titleAction={i18n.t('title.indicator', { lng: selectedLanguage })}
                                        handleClose={handleClose}
                                        list={data}
                                        setData={setData}
                                    />
                                    :
                                    currentLocation.pathname === '/collaborator/role'
                                        ?
                                        <PostRoleFormTeste
                                            cargo={postRoles}
                                            titleAction={i18n.t('title.role', { lng: selectedLanguage })}
                                            handleClose={handleClose}
                                            list={data}
                                            setData={setData}
                                        />
                                        :
                                        currentLocation.pathname === '/dimension'
                                            ?
                                            <DimensionFormTest
                                                dimension={dimensions}
                                                titleAction={i18n.t('title.dimension', { lng: selectedLanguage })}
                                                handleClose={handleClose}
                                                list={data}
                                                setData={setData}
                                            />
                                            :
                                            currentLocation.pathname === '/period'
                                                ?
                                                <PeriodFormTest
                                                    evaluationPeriod={evaluationPeriod}
                                                    titleAction={i18n.t('title.period', { lng: selectedLanguage })}
                                                    handleClose={handleClose}
                                                    setData={setData}
                                                />
                                                :
                                                currentLocation.pathname === '/companies'
                                                    ?
                                                    <CreateCompany
                                                        companySet={companies}
                                                        companyItems={setCompanies}
                                                        titleAction={i18n.t('title.company', { lng: selectedLanguage })}
                                                        handleClose={handleClose}
                                                        updaters={updaters}
                                                        setUpdaters={setUpdaters} setData={setData}
                                                    />
                                                    :
                                                    currentLocation.pathname === '/furnishers'
                                                        ?
                                                        <CreateFunisher
                                                            funisher_={companies}
                                                            companyItems={setCompanies}
                                                            titleAction={i18n.t('title.furnishers', { lng: selectedLanguage })}
                                                            handleClose={handleClose}
                                                            updater={updaters}
                                                            setUpdater={setUpdaters}
                                                            setData={setFunisher}
                                                        />
                                                        :
                                                        currentLocation.pathname === '/distributor'
                                                            ? (
                                                                <CreateDistributor
                                                                    companySet={companies}
                                                                    companyItems={setCompanies}
                                                                    titleAction={i18n.t('title.distributor', {
                                                                        lng: selectedLanguage,
                                                                    })}
                                                                    handleClose={handleClose}
                                                                    updaters={updaters}
                                                                    setUpdaters={setUpdaters}
                                                                    setData={setData}
                                                                />
                                                            ) :
                                                            <PeriodForm />
                    }

                </DialogContent>
            </Dialog>
        </Grid >

    )
}

export default SearchAddBar;
