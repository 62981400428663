import React, { useContext, useEffect, useState } from "react";
import { AirplaneTicket, Apps, AppShortcut, DoubleArrow, Margin, PermMedia, Search, VideoLibraryOutlined } from "@mui/icons-material";
import Topbar from "./topbar/Topbar";
import CardItem from "./cards/CardItem";
import CardService from "./cards/CardService";
import VideoEmbed from "./embed/VideoEmbed";
import i18n from "../../translate/i18n";
import { LanguageContext } from "../../translate/languages/LanguageContext";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { GET_COLLABORATORS, GET_JROLE_COLLAB } from "../Collaborator/Collaborator_Creation_Page/query.gql";
import { useLazyQuery } from "@apollo/client";
import cardIcons from "./icons/CardIcons";
import { CircularProgress } from "@mui/material";

const defService = [
  {
    imgUrl: "ig.webp",
    service: "Front End Developer",
  },
  {
    imgUrl: "ig.webp",
    service: "Front End Developer",
  },
  {
    imgUrl: "ig.webp",
    service: "Front End Developer",
  },
  {
    imgUrl: "ig.webp",
    service: "Front End Developer",
  },
];

const Dashboard = () => {


  const navigate = useNavigate();

  const jwt = localStorage.getItem("jwtToken");
  const { id: employeerID } = jwtDecode(jwt);

  const [isLoading, setIsLoading] = useState(true);
  const [getCollaborators] = useLazyQuery(GET_COLLABORATORS);
  const [getJobRoles] = useLazyQuery(GET_JROLE_COLLAB);
  const [collaborators, setCollaborators] = useState([]);
  const [loading, setLoading] = useState(true);
  const [collaboratorFilter, setCollaboratorFilter] = useState(null);

  const { REACT_APP_API_URL } = process.env;
  const { selectedLanguage } = useContext(LanguageContext);
  const [jobRoles, setJobRoles] = useState([]);

  //const [hasJobRoles, ]

  useEffect(() => {
    setIsLoading(true);
    getJobRoles({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
    }).then(response => {
      if (response?.data) {
        const all = response?.data?.jobRoles?.data ?? []
        setJobRoles(all);
      }
      console.log("KKKEEE11EELLLL22222222222", response);
    }).catch((err) => { })
      .finally(() => {
        setIsLoading(false);
      })

    getCollaborators({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      //fetchPolicy: "no-cache",
    }).then((data) => {
      console.log("collabs", data?.data?.collaborators?.data);

      const collaboratorData = data?.data?.collaborators?.data?.map((item) => {
        console.log('VENDOOOO: ', item.attributes?.employment);
        return {
          id: item.id,
          userID: item.attributes?.users_permissions_user?.data?.id,
          name: item.attributes?.name,
          email: item.attributes?.email,
          phone: item.attributes?.phone,
          address: item.attributes?.address,
          web: item.attributes?.web,
          intro: item.attributes?.intro,
          country: item.attributes?.country?.data?.attributes?.name,
          countryID: item.attributes?.country?.data?.id,
          city: item.attributes?.city?.data?.attributes?.name,
          yearsofExperience: item.attributes?.yearsofExperience,
          speciality: item.attributes?.speciality,
          cityID: item.attributes?.city?.data?.id,
          postRole: item.attributes?.postRole?.data?.attributes?.postRole,
          postRoleID: item.attributes?.postRole?.data?.id,
          postRoleDescription: item.attributes?.postRole?.data?.attributes?.description,
          evaluations: item.attributes?.evaluations?.data,
          employmentCollab: item.attributes?.employment?.data,
          companies: item.attributes?.companies?.data/* ?.map((company) => {
              return {
                companyID: company.id,
                company: company.attributes?.name,
              };
            }) */,
          isActive: item.attributes?.isActive,
          avatar: item.attributes?.image?.data?.attributes?.url,
          avatarID: item.attributes?.image?.data?.id,
          profiles: item.attributes?.profiles?.data?.map((profiles) => {
            return {
              id: profiles.id,
              urlProfile: profiles.attributes?.urlProfile,
              description: profiles.attributes?.description,
              platform: profiles.attributes?.platform?.data?.attributes?.name,
              platformID: profiles.attributes?.platform?.data?.id,
              icon: profiles.attributes?.platform?.data?.attributes?.icon?.data?.attributes?.url

            };
          }),
          clientID: item.attributes?.client?.data?.id,
          client: item.attributes?.client?.data?.attributes?.companies?.data?.map((client) => {
            return {
              id: client.id,
              name: client.attributes?.name,
              image: client.attributes?.image?.data?.attributes?.url,
              /*   rateTotal: rateTotal,
                rateLength: rateLength,
                rateValue: rateValue, */
            };
          }),


          portfolios: item.attributes?.portfolios?.data?.map((portfolios) => {
            return {
              id: portfolios.id,
              name: portfolios.attributes?.name,
              description: portfolios.attributes?.description,
              image: portfolios.attributes?.image?.data?.attributes?.url,
              imageID: portfolios.attributes?.image?.data?.id
            };
          }),
          awards: item.attributes?.awards?.data?.map((profiles) => {
            return {
              id: profiles.id,
              name: profiles.attributes?.name,
              description: profiles.attributes?.description
            };
          }),
          skills: item.attributes?.skills?.data?.map((profiles) => {
            return {
              id: profiles.id,
              percentual: profiles.attributes?.percentual,
              name: profiles.attributes?.itTool?.data?.attributes?.name,
              itToolID: profiles.attributes?.itTool?.data?.id
            };
          }),
          expertise: item.attributes?.expertise?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              expertise: profiles.attributes?.expertise?.data?.attributes?.name,
              expertiseID: profiles.attributes?.expertise?.data?.id
            };
          }),
          languages: item.attributes?.language_collaborators?.data?.map((lang) => {
            console.log("LLLLLLLLLLLLANNNG,", lang);
            return {
              level: lang?.attributes?.level,
              name: lang?.attributes?.language.data.attributes.idioma,
              languageID: lang?.attributes?.language?.data?.id
            };
          }),
          educations: item.attributes?.educations?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              startDate: profiles.attributes?.startDate,
              endDate: profiles.attributes?.endDate,
              course: profiles.attributes?.course?.data?.attributes?.name,
              courseID: profiles.attributes?.course?.data?.id,
              faculty: profiles.attributes?.faculty?.data?.attributes?.name,
              facultyID: profiles.attributes?.faculty?.data?.id,
              country: profiles.attributes?.country?.data?.attributes?.name,
              countryID: profiles.attributes?.country?.data?.id
            };
          }),
          experiences: item.attributes?.experiences?.data?.map((profiles) => {
            return {
              id: profiles.id,
              description: profiles.attributes?.description,
              startDate: profiles.attributes?.startDate,
              endDate: profiles.attributes?.endDate,
              postRole: profiles.attributes?.postRole?.data?.attributes?.postRole,
              postRoleDescription: profiles.attributes?.postRole?.data?.attributes?.description,
              postRoleID: profiles.attributes?.postRole?.data?.id,
              expEntity: profiles.attributes?.expEntity?.data?.attributes?.name,
              expEntityID: profiles.attributes?.expEntity?.data?.id,
              country: profiles.attributes?.country?.data?.attributes?.name,
              countryID: profiles.attributes?.country?.data?.id
            };
          }),

        };
      });


      const sortedData = collaboratorData?.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setCollaborators(sortedData);
      setLoading(false);
    }).catch((error) => {
      console.log("Ocorreu um erro ao pegar colaborador", error);

    })
  }, [collaboratorFilter]);

  const defaultData = [
    {
      // icon: <Margin sx={{ fontSize: 35 }} />,
      icon: <Apps sx={{ fontSize: 25 }} />,
      // title: "Graphics & Design",
      title: i18n.t('collaborator.dashboard.tittles.all_category', { lng: selectedLanguage }),
      link: '/collaborator/main',
    },
    {
      icon: <VideoLibraryOutlined sx={{ fontSize: 25 }} />,
      title: i18n.t('collaborator.dashboard.tittles.video_anim', { lng: selectedLanguage }),
      link: '#',
    },
    {
      icon: <PermMedia sx={{ fontSize: 25 }} />,
      title: i18n.t('collaborator.dashboard.tittles.service_ai', { lng: selectedLanguage }),
      link: '#',
    },
    {
      icon: <AirplaneTicket sx={{ fontSize: 25 }} />,
      // title: "Programming & Tech",
      title: i18n.t('collaborator.dashboard.tittles.soft_dev', { lng: selectedLanguage }),
      link: '#',
    },
    {
      icon: <AppShortcut sx={{ fontSize: 25 }} />,
      title: i18n.t('collaborator.dashboard.tittles.digital_mar', { lng: selectedLanguage }),
      link: '#',
    },
  ];

  return (
    <>
      {/* <Topbar /> */}
      <div className="h-[75vh] bg-[#30262E] flex items-center justify-center">
        <div
          className="h-[60%] flex flex-col w-[90%] lg:w-[60%] mx-auto items-center justify-center">
          <div className="flex flex-col text-white text-center">
            <h1
              style={{
                fontSize: '2rem',
                lineHeight: '2.5rem'
              }}
              className="font-black mb-4">{i18n.t('collaborator.dashboard.title', { lng: selectedLanguage })}</h1>
            <span className="hidden lg:block font-light px-16">{i18n.t('collaborator.dashboard.subtitle', { lng: selectedLanguage })}</span>
          </div>
          <div className="mt-16 w-full">
            <div className="flex relative w-full">
              <input
                type="text"
                className="p-[1rem] w-full block rounded-[0.25rem]"
              />
              <span className="absolute right-2 bg-[#F89220] text-white text-center leading-[45px] w-[45px] h-[45px] rounded-[0.25rem]">
                <Search />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="px-6 pt-16 pb-16 grid lg:grid-cols-5 gap-4 mb-10">
        <CardItem
          icon={<Apps sx={{ fontSize: 25 }} />}
          title={i18n.t('collaborator.dashboard.tittles.all_category', { lng: selectedLanguage })}
          link='/collaborator/main'
        />
        {
          isLoading ? (
            <>
              <div className="flex items-center gap-2">
                <CircularProgress size={15} />
                <p className="">Carregando JobRoles...</p>
              </div>
            </>
          ) : jobRoles?.length === 0 ? (
            <p className="mt-5">Nenhum JobRole Encontrado</p>
          ) : (
            jobRoles.map((item, index) => (
              <CardItem
                key={index}
                title={i18n.t(`collaborator.dashboard.tittles.${item?.attributes?.category?.split('_')[0]}`, { lng: selectedLanguage })}
                icon={cardIcons(item?.attributes?.category?.split('_')[0])}
                link={`/collaborator/main?filter=${item?.attributes?.category?.split('_')[0]?.toLowerCase()}`}
              />
            ))
          )
        }
      </div>

      <div className="px-6 py-10">
        <div className="relative">
          <span
            style={{
              transform: "rotate(-1deg)",
              color: "#F89220",
            }}
            className="absolute -top-12 -left-24"
          >
            <DoubleArrow sx={{ fontSize: 200 }} />
          </span>
          <h1
            style={{
              fontSize: '2.6rem',
              lineHeight: '2.4rem',
              fontWeight: 'bold',
              color: '#000',
              zIndex: '111',
              position: 'relative'
            }}
          >
            Serviços mais <br /> procurados
          </h1>
        </div>
        <div className="mt-10 grid grid-cols-5 gap-5">
          <div></div>
          {
            defService.map((item, index) => (
              <CardService
                key={index}
                imgUrl={item.imgUrl}
                service={item.service}
              />
            ))
          }
        </div>
      </div>

      <div className="px-6 mt-10 bg-white py-16 grid lg:grid-cols-2 gap-10">
        <div className="flex flex-col space-y-6">
          <h1
            style={{ fontSize: '2rem', lineHeight: '2.5rem', color: '#000', textTransform: 'capitalize' }}
            className="font-bold"
          >Testimonals from those who joinded us</h1>
          <span className="text-gray-400">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempora expedita alias amet omnis placeat. Dolorem.</span>
          <a
            href="#"
            className="inline-block bg-[#F89220] text-white px-12 py-4 rounded-[0.25rem] mr-auto">Join us</a>
        </div>
        <div>
          <VideoEmbed />
        </div>
      </div>

      <div></div>
      <div className="my-[18rem]"></div>

    </>
  );

};

export default Dashboard;