import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation Login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        email
      }
    }
  }
`;
export const REGISTER = gql`
  mutation Register($input: UsersPermissionsRegisterInput!) {
    register(input: $input) {
      jwt
      user {
        id
        email
      }
    }
  }
`;
export const CREATE_USER = gql`
  mutation CreateUser($data: UsersPermissionsUserInput!) {
    createUsersPermissionsUser(data: $data) {
      data {
        id
        attributes {
          username
          email
          confirmed
        }
      }
    }
  }
`;
export const DELETE_COMPANY_ANSWER = gql`
  mutation DeleteCompanyAnswers($id: ID!) {
    deleteCompanyAnswer(id: $id) {
      data {
        id
        attributes {
          name
          findDevs {
            data {
              id
              attributes {
                description
              }
            }
          }
          company {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;
export const UPDATE_COMPANY_ANSWER = gql`
  mutation UpdateCompanyAnswer($id: ID!, $data: CompanyAnswerInput!) {
    updateCompanyAnswer(id: $id, data: $data) {
      data {
        id
        attributes {
          name
          findDevs {
            data {
              id
              attributes {
                description
              }
            }
          }
          company {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_COMPANY_QUESTION = gql`
  mutation UpdateCompanyQuestion($id: ID!, $data: CompanyQuestionInput!) {
    updateCompanyQuestion(id: $id, data: $data) {
      data {
        id
        attributes {
          name
          isActive
        }
      }
    }
  }
`;
export const CREATE_DISTRIBUTOR = gql`
  mutation CreateDistributor($data: DistributorInput!) {
    createDistributor(data: $data) {
      data {
        id
        attributes {
          status
          name
          users_permissions_user {
            data {
              id
              attributes {
                username
              }
            }
          }
          city {
            data {
              id
              attributes {
                name
              }
            }
          }
          country {
            data {
              id
              attributes {
                name
              }
            }
          }
          email
          phone
          image {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_CITY = gql`
  mutation createCity($data: CityInput!) {
    createCity(data: $data) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation deleteCompany($id: ID!) {
    deleteCompany(id: $id) {
      data {
        id
        attributes {
          name
          status
        }
      }
    }
  }
`;

export const UPDATE_DISTRIBUTOR = gql`
  mutation updateDistributor($id: ID!, $data: CompanyInput!) {
    updateDistributor(id: $id, data: $data) {
      data {
        id
        attributes {
          status
          name
          users_permissions_user {
            data {
              id
              attributes {
                username
              }
            }
          }
          city {
            data {
              id
              attributes {
                name
              }
            }
          }
          country {
            data {
              id
              attributes {
                name
              }
            }
          }
          email
          phone
          image {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_MEDIA_FILE = gql`
  mutation DeleteMediaLibrary($id: ID!) {
    deleteUploadFile(id: $id) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;
export const DISABLE_USERS = gql`
  mutation disableUser($id: ID!, $disable: Boolean!) {
    updateUsersPermissionsUser(id: $id, data: { confirmed: $disable }) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;
export const CREATE_MEDIA_FILE = gql`
  mutation uploadFile(
    $file: Upload!
    $info: FileInfoInput!
    $refId: ID!
    $field: String!
    $ref: String!
  ) {
    upload(file: $file, refId: $refId, field: $field, ref: $ref, info: $info) {
      data {
        id
        attributes {
          name
          url
        }
      }
    }
  }
`;
export const DELETE_USERS = gql`
  mutation deleteUser($id: ID!) {
    deleteUsersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;
export const CREATE_FIND_DEV = gql`
  mutation FindDev($data: FindDevInput!) {
    createFindDev(data: $data) {
      data {
        id
        attributes {
          description
          company_answers {
            data {
              id
              attributes {
                name
              }
            }
          }
          company {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;
