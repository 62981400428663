import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const validationEmailForm = yupResolver(
  yup.object({
    email: yup
      .string()
      .email("Insira um email válido")
      .required("O email é obrigatório"),
  })
);
