import { Section } from "../section";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import { IconButton } from "@mui/material";
const iconAdd = <IconButton color="primary"><PlusOneIcon /></IconButton>
const iconDel = <IconButton color="primary"><ClearOutlinedIcon /></IconButton>
const iconEdit = <IconButton color="primary"><EditOutlinedIcon /></IconButton>

const Header = ({ intro,handleOpen, setStepID, colaboradorEdit }) => {
  return (
    <>
      {colaboradorEdit === true && <IconButton color="primary" onClick={() => {
        handleOpen("Edit Intro");
        setStepID(intro.id);
        alert(intro.id)
      }}>
        <EditOutlinedIcon />
      </IconButton>}
      <h2>Intro</h2>
      <p>What I am all about.</p>
    </>
  );
};

const Content = ({ intro, colaboradorEdit }) => {
  // console.log(collaborator)
  return (
    <>
      <p>
        {intro.intro || "N/A"}
      </p>
    </>
  );
};

export const Intro = ({handleOpen, setStepID, intro, colaboradorEdit }) => {
  return <Section header={<Header intro={intro} handleOpen={handleOpen} setStepID={setStepID} colaboradorEdit={colaboradorEdit} />} content={<Content intro={intro} colaboradorEdit={colaboradorEdit} />} />;
};
