import { AirplaneTicket, AppShortcut, PermMedia, VideoLibraryOutlined } from "@mui/icons-material";

const cardIcons = (currentIcon) => {

  const arrayIcons = {
    SOTWARE: <AppShortcut sx={{ fontSize: 25 }} />,
    GRAFIC: <VideoLibraryOutlined sx={{ fontSize: 25 }} />,
    DESIGN: <AirplaneTicket sx={{ fontSize: 25 }} />,
    AI: <PermMedia sx={{ fontSize: 25 }} />
  };

  return arrayIcons[currentIcon?.toUpperCase()] || null;
};

export default cardIcons;