import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Alert,
  AlertTitle,
  Snackbar,
  Paper,
  Autocomplete,
  Typography,
  TextField,
  Button,
  Stack,
  InputLabel,
  Grid,
  FormControl,
  Box,
} from '@mui/material';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CREATE_DISTRIBUTOR,
  CREATE_CITY,
  CREATE_MEDIA_FILE,
  UPDATE_DISTRIBUTOR,
  CREATE_FIND_DEV,
  CREATE_USER,
  DELETE_MEDIA_FILE,
} from './mutation.gql';
import { GET_DISTRIBUTORS, GET_USER_DATA } from './query.gql';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { GET_COUNTRY_STATE_CITY } from './query.gql';
import { createFilterOptions } from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import i18n from '../../../translate/i18n';
import { LanguageContext } from '../../../translate/languages/LanguageContext';

const CreateDistributor = ({
  handleClose,
  currentID,
  company_,
  setCompany_,
  setCurrentID,
  setData,
  updater,
  setUpdater,
}) => {
  const [country, setCountry] = useState(null);
  const [createCompany] = useMutation(CREATE_DISTRIBUTOR);
  const [create_City] = useMutation(CREATE_CITY);
  const [cities, setCities] = useState([]);
  const [newCities, setNewCities] = useState([]);
  const [cityValue, setCityValue] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [getCountries] = useLazyQuery(GET_COUNTRY_STATE_CITY);
  const [updateCompany] = useMutation(UPDATE_DISTRIBUTOR);
  const [countries, setCountries] = useState([]);
  const filter = createFilterOptions();
  const [error, setError] = useState(null);
  const [company, setCompany] = useState([]);
  const [create_user] = useMutation(CREATE_USER);
  const [uploadImage] = useMutation(CREATE_MEDIA_FILE);
  const [createFindDev] = useMutation(CREATE_FIND_DEV);
  const [deleteImage] = useMutation(DELETE_MEDIA_FILE);
  const [openAlertOK, setOpenAlertOK] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [cE, setCE] = useState([]);
  const [companies, setCompanies] = useState([]);
  const { selectedLanguage } = useContext(LanguageContext);

  let navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: company_?.name,
    country: company_?.countryID,
    city: company_?.cityID,
    email: company_?.email,
    phone: company_?.phone,
    image: company_?.image,
    imageID: company_?.imageID,
  });

  const FILE_TYPE = 'image/jpeg';
  const jwt = localStorage.getItem('jwtToken');
  const filteredCity = cities?.filter((item, event) =>
    item.country?.toLowerCase().includes(country?.name?.toLowerCase())
  );

  const filteredCountry = countries?.filter((item) =>
    item.name?.toLowerCase().includes(cityValue?.country?.toLowerCase())
  );

  const validationEmailForm = yup
    .object({
      email: currentID
        ? yup.string()
        : yup
            .string()
            .notOneOf(cE, 'Email in use')
            .email('Please insert a valid Email address')
            .required('Email is Mandatory'),
      name: currentID
        ? yup.string()
        : yup
            .string()
            .min(3, 'Name should be longer than 3 characters')
            .required('The Name is Mandatory'),
      phone: currentID
        ? yup.string()
        : yup
            .string()
            .min(7, 'Phone should be 7 numbers long or more')
            .required('Phone Number is required'),
      country: currentID
        ? yup.string()
        : yup.string().required('Country is Mandatory'),
    })
    .required();

  const fileInputRef = useRef(null);
  const [imageFile, setImageFile] = useState(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (currentID) {
      setCompany_(company_);
      setCurrentID(currentID);
    }
  }, [currentID]);

  const [getCompanys_, { loading, data }] = useLazyQuery(GET_DISTRIBUTORS, {
    context: {
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data && data.companies) {
      setCompanies(data.companies.data);
    }
  }, [data]);

  useEffect(() => {
    getCompanys_();
  }, []);

  const [companyExist, setCompanyExist] = useState(0);

  const itemSubmit = async (e) => {
    const exists = companies?.some(
      (comp) => comp.attributes.name?.toLowerCase() === e.name.toLowerCase()
    );
    const existsEmail = companies?.some(
      (comp) => comp.attributes.name?.toLowerCase() === e.email
    );
    if (currentID) {
      await updateCompany({
        context: {
          headers: {
            authorization: `Bearer ${jwt}`,
          },
        },
        variables: {
          id: company_.id,
          data: {
            name: e.name,
            email: e.email,
            phone: e.phone,
            country: formData.country,
            city: formData.city,
            status: true,
          },
        },
      }).then(async (data) => {
        setOpenAlertOK(true);
        setSuccessMessage('Empresa atualizado');

        deleteImage({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
          //fetchPolicy: "network-only",
          variables: {
            id: company_.imageID,
          },
        });
        let newImage = [];

        try {
          const newPort = data.data.updateCompany.data;
          const imgBlob = imageFile?.slice();
          const fileName = `Avatar of ${e.name}`;
          const fileType = FILE_TYPE;
          const file_ = new File([imgBlob], fileName, { type: fileType });

          await uploadImage({
            context: {
              headers: {
                authorization: `Bearer ${jwt}`,
              },
            },
            variables: {
              file: file_,
              info: { name: fileName },
              refId: newPort.id,
              field: 'image',
              ref: 'api::company.company',
            },
          })
            .then((data) => {
              newImage.push(data.data.upload);
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (err) {
          console.log(err);
        }

        let obj = updater?.filter((i) => i.id === company_.id);

        if (
          formData.name !== company_.name ||
          formData.email !== company_.email
        ) {
          obj.push(
            data.data.updateCompany.data,
            newImage.map((itx) => {
              obj.attributes.image = itx;
            })
          );
        }
        setUpdater(obj);

        handleClose();
      });
    } else {
      // Siene Qeustions

      if (exists) {
        toast.error('Ja existe uma Empresa com o mesmo nome');
        // alert('Ja existe uma Empresa com o mesmo nome');
        return;
      } else if (existsEmail) {
        toast.error('Ja existe uma Empresa com o mesmo email');
      } else {
        await createCompany({
          context: {
            headers: {
              authorization: `Bearer ${jwt}`,
            },
          },
          variables: {
            data: {
              country: country.id,
              city: cityValue.id,
              name: e.name,
              email: e.email,
              phone: e.phone,
              status: true,
            },
          },
        })
          .then(async (data) => {
            setOpenAlertOK(true);
            setSuccessMessage('Distribuidor adicionado');

            const newPort = data.data.createDistributor.data;
            const imgBlob = imageFile?.slice();
            const fileName = `Avatar of ${e.name}`;
            const fileType = FILE_TYPE;
            const file_ = new File([imgBlob], fileName, { type: fileType });
            let newImage = [];
            let newDev = [];
            let newUser = [];
            const newComp = [];

            await create_user({
              variables: {
                data: {
                  distributor: newPort.id,
                  name: e.name,
                  email: e.email,
                  password: e.email,
                  confirmed: true,
                  username: e.name,
                  role: 5,
                  userRole: 6,
                },
              },
            }).then((data) => {
              newUser.push(data.data.createUsersPermissionsUser.data);
            });

            await uploadImage({
              context: {
                headers: {
                  authorization: `Bearer ${jwt}`,
                },
              },
              variables: {
                file: file_,
                info: { name: fileName },
                refId: newPort.id,
                field: 'image',
                ref: 'api::company.company',
              },
            })
              .then((data) => {
                newImage.push(data.data.upload);
              })
              .catch((error) => {
                console.log(error);
              });

            const incomingData = data.data.createCompany.data;

            newComp.push(
              incomingData,
              newImage.map((itx) => {
                incomingData.attributes.image = itx;
              }),
              (incomingData.attributes.users_permissions_user.data =
                newUser[0]),
              (incomingData.attributes.findDevs = newDev)
            );
            setData((prev) => [...prev, ...newComp]);
            navigate('/login');
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    handleClose();
  };

  useEffect(() => {
    const fetchData = async (e) => {
      try {
        const countriesData = await getCountries({
          fetchPolicy: 'cache-first',
          variables: {
            pagination: {
              limit: 1000,
            },
          },
        });
        setCountries(
          countriesData?.data?.countries?.data?.map((item) => {
            return {
              id: item.id,
              name: item.attributes?.name,
              cities: item.attributes?.cities?.data?.map((item) => {
                return {
                  id: item.id,
                  name: item.attributes?.name,
                };
              }),
            };
          })
        );

        setCities(
          countriesData?.data?.countries?.data?.flatMap((country) => {
            return country.attributes?.cities?.data?.map((city) => {
              return {
                id: city.id,
                name: city.attributes?.name,
                country: country.attributes?.name,
              };
            });
          })
        );
      } catch (error) {
        toast.error('Ocorreu um erro ao carregar a lista de Países');
      }
    };

    fetchData();
  }, []);

  const handleCloseAlert = () => {
    setOpenAlertOK(false);
  };
  const {
    setValue,
    trigger,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationEmailForm),
  });

  return (
    <form onSubmit={handleSubmit(itemSubmit)}>
      <Paper>
        <ToastContainer />
        <Stack direction='row'>
          <Grid container margin={2}>
            <InputLabel id='demo-simple-select-label'>Name</InputLabel>
            <TextField
              autoFocus
              type='text'
              defaultValue={company_?.name}
              fullWidth
              name='name'
              id='name'
              placeholder='Ex: Afrikan Coders'
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              {...register('name')}
              helperText={errors.name?.message}
            />{' '}
          </Grid>
          <Grid container margin={2}>
            <InputLabel id='demo-simple-select-label'>Email</InputLabel>
            <TextField
              autoFocus
              type='email'
              defaultValue={company_?.email}
              fullWidth
              name='email'
              id='email'
              placeholder='Ex: afrikanCoders@mail.com'
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              {...register('email')}
              helperText={errors.email?.message}
            />{' '}
          </Grid>
        </Stack>

        <Stack direction='row'>
          <Grid container margin={2}>
            <InputLabel id='demo-simple-select-label'>Country</InputLabel>
            <FormControl fullWidth>
              <Autocomplete
                size='small'
                options={
                  filteredCountry?.length > 0 ? filteredCountry : countries
                }
                defaultValue={company_?.country || null}
                getOptionLabel={(country) => country.name || company_?.country}
                id='country'
                onChange={(e, newValue) => {
                  setCountry(newValue);
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )}
                renderInput={(params, option) => (
                  <TextField
                    {...params}
                    value={company_?.country || country}
                    variant='outlined'
                    placeholder='Ex: Cabo Verde'
                    {...register('country')}
                    helperText={errors.country?.message}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid container margin={2}>
            <InputLabel id='demo-simple-select-label'>City</InputLabel>
            <FormControl fullWidth>
              <Autocomplete
                defaultValue={company_?.city || null}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    const filteredItem = cities.filter(
                      (item) =>
                        item.name?.toLowerCase() === newValue?.toLowerCase()
                    );
                    if (filteredItem?.length > 0) {
                      setCityValue({
                        name: filteredItem[0].name,
                        id: filteredItem[0].id,
                      });
                    } else {
                      create_City({
                        context: {
                          headers: {
                            authorization: `Bearer ${jwt}`,
                          },
                        },
                        //fetchPolicy: "network-only",
                        variables: {
                          data: {
                            name: newValue.inputValue,
                            country: country.id,
                          },
                        },
                      }).then((data) => {
                        const city_Id = data.data.createCity.data.id;
                        const city_Name =
                          data.data.createCity.data.attributes.name;

                        setCityValue({
                          name: city_Name,
                          id: city_Id,
                        });
                        const newOption = { name: city_Name, id: city_Id };

                        setNewCities([...newCities, newOption]);
                      });
                    }
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    create_City({
                      context: {
                        headers: {
                          authorization: `Bearer ${jwt}`,
                        },
                      },
                      //fetchPolicy: "network-only",
                      variables: {
                        data: {
                          name: newValue.inputValue,
                          country: country.id,
                        },
                      },
                    }).then((data) => {
                      const city_Id = data.data.createCity.data.id;
                      const city_Name =
                        data.data.createCity.data.attributes.name;

                      setCityValue({
                        name: city_Name,
                        id: city_Id,
                      });
                      const newOption = { name: city_Name, id: city_Id };
                      setNewCities([...newCities, newOption]);
                    });
                  } else {
                    setCityValue(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.name
                  );
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      name: `Add "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id='city'
                options={
                  filteredCity?.length > 0
                    ? filteredCity
                    : [...cities, ...newCities]
                }
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.name;
                }}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )}
                size='small'
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={company_?.city || cityValue}
                    placeholder='Ex: Praia'
                  />
                )}
              />
              {cityError && !currentID && (
                <Typography
                  variant='body2'
                  color='gray'
                  style={{
                    fontSize: '12px',
                    marginTop: '5.5px',
                    marginLeft: '14px',
                  }}
                >
                  {cityError}
                </Typography>
              )}
            </FormControl>
          </Grid>
        </Stack>
        <Stack direction='row'>
          <Grid container margin={2}>
            <InputLabel id='demo-simple-select-label'>Phone</InputLabel>
            <TextField
              required
              autoFocus
              type='text'
              defaultValue={company_?.phone}
              fullWidth
              name='phone'
              id='phone'
              placeholder='Ex: Afrikan Coders'
              onChange={(e) =>
                setFormData({ ...formData, phone: e.target.value })
              }
              {...register('phone')}
              helperText={errors.phone?.message}
            />
          </Grid>
        </Stack>

        <Grid margin={2}>
          <InputLabel>Adicione uma foto de Perfil</InputLabel>
          <Box>
            <input
              type='file'
              id='file'
              onChange={(e) => setImageFile(e.target.files[0])}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
            <Button
              fullWidth
              startIcon={<DriveFolderUploadIcon />}
              variant='contained'
              color='primary'
              onClick={handleButtonClick}
            >
              Adicionar
            </Button>
            {error && (
              <Typography variant='body2' color='error'>
                {error}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid
          container
          spacing={3}
          item
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
          marginTop={5}
          paddingBottom={2}
        >
          <Grid item>
            <Button
              // disabled={!imageFile }
              variant='contained'
              type='submit'
              color='primary'
              onClick={() => {
                if (cityValue === null) {
                  setCityError('Mandatory Field');
                }
              }}
            >
              {!currentID
                ? i18n.t('button.create', {
                    lng: selectedLanguage,
                  })
                : i18n.t('button.update', {
                    lng: selectedLanguage,
                  })}
            </Button>
          </Grid>
        </Grid>
        <Snackbar
          style={{ backgroundColor: 'teal', color: 'coral' }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openAlertOK}
          autoHideDuration={5000}
          onClose={handleCloseAlert}
        >
          <Alert severity='success' sx={{ border: 1 }}>
            <AlertTitle
              sx={{
                width: 500,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              Successo{' '}
              <CloseIcon
                color='secondary'
                onClick={handleCloseAlert}
                sx={{ cursor: 'pointer' }}
              />{' '}
            </AlertTitle>
            {successMessage}
          </Alert>
        </Snackbar>
      </Paper>
    </form>
  );
};

export default CreateDistributor;
