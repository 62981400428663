import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_COLLABORATORS, GET_FURNISHER_BY_ID } from "./query.gql";
import { GET_DISTRIBUTTOR_BY_USER_PERMISSION, GET_FURN_COLLABS, GET_FURNISHERS_BY_USER_PERMISSION } from "../../Collaborator/Collaborator_Creation_Page/query.gql";
import { useFurnisherIdByUserId } from "../../../components/TopBar/helpers";
import { GET_FURNISHER_COLLABORATORS_IDs } from "../../../services/distributors/gql/query";


export const useFurnisherCollaborators = (furnisherId) => {
  const jwt = localStorage.getItem("jwtToken");

  const [retrieveData, setRetrieveData] = useState([]);

  const [getContractedFurnisher] = useLazyQuery(GET_FURNISHERS_BY_USER_PERMISSION);

  useEffect(() => {
    if (!furnisherId) return;

    getContractedFurnisher({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      fetchPolicy: "no-cache",
      variables: {
        userId: furnisherId,
      },
    })
      .then((result) => {
        console.log("Dados recebidos1111:", result?.data);
        setRetrieveData(result?.data?.furnisher?.data?.attributes?.collaborators.data || []);
      })
      .catch((err) => {
        console.error("Erro ao buscar os dados:", err);
      });
  }, [jwt, furnisherId]);

  return retrieveData;
};



export const useFurnisherCollabs = () => {
  const jwt = localStorage.getItem("jwtToken");
  const furnisherId = useFurnisherIdByUserId(); // Certifique-se de que retorna um ID válido

  const [retrieveData, setRetrieveData] = useState({ collaboratorsss: [], furnishersss: [], collabs: [] });

  const [getContractedFurnisher] = useLazyQuery(GET_FURNISHER_COLLABORATORS_IDs);

  useEffect(() => {
    if (!furnisherId || !jwt) return;

    getContractedFurnisher({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      fetchPolicy: "no-cache",
    })
      .then((result) => {
        if (!result?.data?.furnishercollabortors?.data) {
          console.error("Dados não encontrados ou inválidos.");
          return;
        }

        console.log("Dados recebidos:", result?.data?.furnishercollabortors);

        const collabIds = result?.data?.furnishercollabortors?.data
          ?.flatMap((item) => item?.attributes?.collaborators?.data?.map((c) => c.id) || [])
          ?.filter(Boolean); // Remove valores falsy como undefined

        const ccol = result?.data?.furnishercollabortors?.data;

        const furnshIds = result?.data?.furnishercollabortors?.data
          ?.flatMap((item) => item?.attributes?.furnishers?.data?.map((f) => f.id) || [])
          ?.filter(Boolean);

        setRetrieveData({ collaboratorsss: collabIds, furnishersss: furnshIds, collabs: ccol });
      })
      .catch((err) => {
        console.error("Erro ao buscar os dados:", err);
      });
  }, [jwt, furnisherId]);

  return retrieveData;
};

export const useFurnisherCollabs_ = () => {
  const jwt = localStorage.getItem("jwtToken");
  const userId = localStorage.getItem("userId");

  const [retrieveData, setRetrieveData] = useState([]);

  const [getContractedFurnisher] = useLazyQuery(GET_FURNISHER_COLLABORATORS_IDs);

  useEffect(() => {
    if (!jwt) return;

    getContractedFurnisher({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      fetchPolicy: "no-cache",
      variables: {
        userId,
      },
    })
      .then((result) => {
        const ids = result?.data?.furnishers?.data?.[0]?.attributes?.collaborators?.data.map((item) => item.id || '');
        setRetrieveData(ids);
      })
      .catch((err) => {
        console.error("Erro ao buscar os dados:", err);
      });
  }, [jwt]);

  return retrieveData;
};



export const useDistributorCollaborators = (furnisherId) => {
  const jwt = localStorage.getItem("jwtToken");
  const userId = localStorage.getItem("userId");

  const [retrieveData, setRetrieveData] = useState([]);

  const [getContractedFurnisher] = useLazyQuery(GET_DISTRIBUTTOR_BY_USER_PERMISSION);

  useEffect(() => {
    if (!furnisherId) return;

    getContractedFurnisher({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
      fetchPolicy: "no-cache",
      variables: {
        userId: userId,
      },
    })
      .then((result) => {
        console.log("Dados recebidos11111:", result?.data?.distributors?.data?.[0]?.attributes?.collaborators?.data);
        setRetrieveData(result?.data?.distributors?.data?.[0]?.attributes?.collaborators?.data || []);
      })
      .catch((err) => {
        console.error("Erro ao buscar os dados:", err);
      });
  }, [jwt, furnisherId]);

  return retrieveData;
};

export const useDistCollabsCount = (uIdDist) => {
  const [getCollaborators] = useLazyQuery(GET_COLLABORATORS);
  const [total, setTotal] = useState(0);
  const jwt = typeof window !== "undefined" ? localStorage.getItem("jwtToken") : null;

  useEffect(() => {
    if (!uIdDist || !jwt) return; // Verifica se o uIdDist e o jwt existem

    getCollaborators({
      context: {
        headers: {
          authorization: `Bearer ${jwt}`,
        },
      },
    }).then((res) => {
      const collaborators = res?.data?.collaborators?.data || [];

      if (Array.isArray(collaborators)) {
        const filteredCollabs = collaborators.filter(
          (item) => item?.attributes?.distributors?.data?.[0]?.id === uIdDist
        );

        setTotal(filteredCollabs?.length);
        console.log("Total de colaboradores:", filteredCollabs?.length);
      }
    }).catch((error) => {
      console.error("Erro ao buscar colaboradores:", error);
    });

  }, [uIdDist, getCollaborators]); // Inclui getCollaborators na lista de dependências

  return total;
};