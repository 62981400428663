
import { Hero } from "./sections/hero";
import { Intro } from "./sections/intro";
import { Expertise } from "./sections/expertise";
import { Skills } from "./sections/skills";
import { Experience } from "./sections/experience";
import { Education } from "./sections/education";
import { Profiles } from "./sections/profiles";
import { Awards } from "./sections/awards";
import { Portfolio } from "./sections/portfolio";
import { Clients } from "./sections/clients";
import { Contact } from "./sections/contact";
import { useEffect, useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import DeleteStep from "../Collaborator_Creation_Page/steps/Delete_Step";
import CreationStep from "../Collaborator_Creation_Page/steps/Create_Step";
import MainCard from "../../../components/MainCard/main-card.component";
import { Payment } from "./sections/payment";
import { useParams } from "react-router-dom";

const CollaboratorView = ({ colaborador, setColaborador, colaboradorEdit, setColaboradorEdit, setCollaborators, collaborators }) => {

  console.log("COLLABS: ", colaborador);

  const { disttributorId } = useParams();

  const jwt = localStorage.getItem("jwtToken");
  const [collaboratorID, setCollaboratorID] = useState(colaborador?.id);
  const [collaborator, setCollaborator] = useState(colaborador);
  const [expertises, setExpertises] = useState(colaborador?.expertise)
  const [skills, setSkills] = useState(colaborador?.skills)
  const [experiences, setExperiences] = useState(colaborador?.experiences)
  const [educations, setEducations] = useState(colaborador?.educations)
  const [profiles, setProfiles] = useState(colaborador?.profiles)
  const [awards, setAwards] = useState(colaborador?.awards)
  const [portfolios, setPortfolios] = useState(colaborador?.portfolios)
  const [intro, setIntro] = useState({ id: colaborador?.id, intro: colaborador?.intro })
  const [clients, setClients] = useState(colaborador?.client)
  const [payment, setPayment] = useState({ id: colaborador?.id, payment: null, empId: null })
  const [collaboratorGrid, setCollaboratorGrid] = useState(false);

  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(null);
  const removeStep = step?.includes("Delete") || step?.includes("Clear");
  const addStep = step?.includes("Add") || step?.includes("Edit");
  const editFalse = colaboradorEdit === false
  const editTrue = colaboradorEdit === true

  const [stepID, setStepID] = useState(null);
  function handleClose() {
    setOpen(false);
  };
  function handleOpen(step) {
    setOpen(true);
    setStep(step)
  };

  useEffect(() => {
    if (disttributorId) {
      const emp = colaborador?.employmentcolabdistributors?.[0]?.attributes;
      const item = emp?.distributors?.data?.[0]?.id == disttributorId ? emp?.payment : colaborador?.payment;
      const iddd = emp?.distributors?.data?.[0]?.id == disttributorId ? colaborador?.employmentcolabdistributors?.[0]?.id : null;
      setPayment({ id: colaborador?.id, payment: item, empId: iddd });
    } else {
      setPayment({ id: colaborador?.id, payment: colaborador?.payment });
    }
  }, [disttributorId]);


  return (
    <>
      <MainCard title="Detalhes do Colaborador">
        {/* Section Hero */}
        <Hero
          setStepID={setStepID}
          handleOpen={handleOpen}
          collaborator={collaborator}
          profiles={profiles}
          colaboradorEdit={colaboradorEdit}
        />

        {/* Section Intro */}
        <Intro
          setStepID={setStepID}
          handleOpen={handleOpen}
          intro={intro}
          colaboradorEdit={colaboradorEdit}
        />

        {/* Section Payment */}
        {
          editFalse && payment?.length > 0 && (
            <Payment
              payment={payment}
              colaboradorEdit={colaboradorEdit}
            />
          )
        }

        {/* Section Expertise */}
        {
          editFalse && expertises?.length > 0 && (
            <Expertise
              expertise={expertises}
              colaboradorEdit={colaboradorEdit}
            />
          )
        }

        {/* Section Skills */}
        {
          editFalse && skills?.length > 0 && (
            <Skills
              skills={skills}
              colaboradorEdit={colaboradorEdit}
            />)
        }

        {/* Section Experience */}
        {
          editFalse && experiences?.length > 0 && (
            <Experience
              experience={experiences}
              colaboradorEdit={colaboradorEdit}
            />)
        }

        {/* Section Education */}
        {
          editFalse && educations?.length > 0 && (
            <Education
              education={educations}
              colaboradorEdit={colaboradorEdit}
            />
          )
        }

        {/* Section Profiles */}
        {
          editFalse && profiles?.length > 0 && (
            <Profiles
              profiles={profiles}
              colaboradorEdit={colaboradorEdit}
            />
          )
        }

        {/* Section Awards */}
        {
          editFalse && awards?.length > 0 && (
            <Awards
              awards={awards}
              colaboradorEdit={colaboradorEdit}
            />
          )
        }

        {/* Section Portfolio */}
        {
          editFalse && portfolios?.length > 0 && (
            <Portfolio
              portfolio={portfolios}
              colaboradorEdit={colaboradorEdit}
            />
          )
        }

        {/* Section Clients */}
        {
          editFalse && clients?.length > 0 &&
          (<Clients
            collaborator={collaborator}
            clients={clients}
            colaboradorEdit={colaboradorEdit}
          />
          )
        }

        {/* Section Expertise */}
        {
          editTrue && (
            <Payment
              setStepID={setStepID}
              handleOpen={handleOpen}
              payment={payment}
              colaboradorEdit={colaboradorEdit} />
          )
        }

        {
          editTrue && (
            <Expertise
              setStepID={setStepID}
              handleOpen={handleOpen}
              expertise={expertises}
              colaboradorEdit={colaboradorEdit} />
          )
        }

        {/* Section Skills */}
        {
          editTrue && (
            <Skills
              setStepID={setStepID}
              handleOpen={handleOpen}
              skills={skills}
              colaboradorEdit={colaboradorEdit} />
          )
        }

        {/* Section Experience */}
        {
          editTrue && (
            <Experience
              setStepID={setStepID}
              handleOpen={handleOpen}
              experience={experiences}
              colaboradorEdit={colaboradorEdit} />
          )
        }

        {/* Section Education */}
        {
          editTrue && (
            <Education
              setStepID={setStepID}
              handleOpen={handleOpen}
              education={educations}
              colaboradorEdit={colaboradorEdit}
            />
          )
        }

        {/* Section Profiles */}
        {
          editTrue && (
            <Profiles
              setStepID={setStepID}
              handleOpen={handleOpen}
              profiles={profiles}
              colaboradorEdit={colaboradorEdit} />
          )
        }

        {/* Section Awards */}
        {
          editTrue && (
            <Awards
              setStepID={setStepID}
              handleOpen={handleOpen}
              awards={awards}
              colaboradorEdit={colaboradorEdit} />
          )
        }

        {/* Section Portfolio */}
        {
          editTrue && (
            <Portfolio
              setStepID={setStepID}
              handleOpen={handleOpen}
              portfolio={portfolios}
              colaboradorEdit={colaboradorEdit} />
          )
        }

        {/* Section Clients */}
        {
          editTrue && (
            <Clients
              setStepID={setStepID}
              handleOpen={handleOpen}
              profiles={profiles}
              clients={clients}
              colaboradorEdit={colaboradorEdit}
            />
          )
        }

        {/* Section Contact */}
        <Contact
          setStepID={setStepID}
          handleOpen={handleOpen}
          collaborator={collaborator}
          profiles={profiles}
          colaboradorEdit={colaboradorEdit}
        />
      </MainCard>


      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center" alignItems="center">
          {step}
        </DialogTitle>
        <DialogContent>
          {addStep && <CreationStep
            handleClose={handleClose}
            step={step} stepID={stepID}
            portfolio={portfolios} setPortfolio={setPortfolios}
            profiles={profiles} setProfiles={setProfiles}
            awards={awards} setAwards={setAwards}
            clients={clients} setClients={setClients}
            educations={educations} setEducations={setEducations}
            experiences={experiences} setExperiences={setExperiences}
            expertises={expertises} setExpertises={setExpertises}
            setSkills={setSkills} skills={skills}
            collaborator={collaborator} setCollaborator={setCollaborator}
            collaborators={collaborators} setCollaborators={setCollaborators}
            collaboratorID={collaboratorID} intro={intro} setIntro={setIntro} payments={payment} setPayments={setPayment}
          />}

          {removeStep && <DeleteStep
            handleClose={handleClose}
            step={step} stepID={stepID}
            portfolio={portfolios} setPortfolio={setPortfolios}
            profiles={profiles} setProfiles={setProfiles}
            awards={awards} setAwards={setAwards}
            clients={clients} setClients={setClients}
            experiences={experiences} setExperiences={setExperiences}
            educations={educations} setEducations={setEducations}
            skills={skills} setSkills={setSkills}
            expertises={expertises} setExpertises={setExpertises}
            collaborator={collaborator} setCollaborator={setCollaborator}
            collaborators={collaborators} setCollaborators={setCollaborators}
          />}
        </DialogContent>


      </Dialog>

    </>
  );
};

export default CollaboratorView;
