import { useLazyQuery } from "@apollo/client";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import "./StyleNotification.css";
import { useState, useEffect, useRef, useCallback } from 'react';
import CollaboratorToCompany from '../Collaborator/Collaborator_Creation_Page/CollaboratorToCompany';
import { GET_COMPANIES } from "../CompanyPage/Company_Creation_Page/query.gql";
import { toast } from "react-toastify";
import SvgIcon from "./SvgIcon";
import { GET_DISTRIBUTTOR_BY_USER_PERMISSION } from "../Collaborator/Collaborator_Creation_Page/query.gql";

const PopupNotification = ({ isOpen, handleOpen, notificationsData }) => {
  const jwt = localStorage.getItem("jwtToken");
  const userRole = localStorage.getItem("userRole") ?? null;
  const [openDialog, setOpenDialog] = useState(false);
  const [compId, setCompId] = useState(null);
  const [collId, setCollId] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [notId, setNotId] = useState(null);
  const [openOptionId, setOpenOptionId] = useState(null);
  const [collaboName, setCollaboName] = useState(null);
  const [collabPayment, setCollabPayment] = useState(null);
  const [dialogAction, setDialogAction] = useState(0);
  const distIddD = localStorage.getItem("distId") ?? null;
  const [isDist, setIsDist] = useState(userRole === 'distributor');
  const [distNotifications, setDistNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const menuRef = useRef(null);

  const [getComp] = useLazyQuery(GET_COMPANIES, {
    context: {
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      setCompanies(response?.companies?.data ?? []);
      setLoading(false);
    },
    onError: (error) => {
      toast.error("Erro ao buscar dados das empresas");
      setLoading(false);
    },
  });

  /*const [getDist] = useLazyQuery(GET_DISTRIBUTTOR_BY_USER_PERMISSION, {
    context: {
      headers: {
        authorization: `Bearer ${jwt}`,
      },
    },
    variables: { userId: localStorage.getItem("userId") },
    onCompleted: (response) => {
      const distID = response?.distributors?.data?.[0]?.id ?? null;
      console.log("MYMYSDISIT", notificationsData);
      setDistNotifications(
        notificationsData?.filter((item) => item?.attributes?.collaborator?.data?.attributes?.distributors?.data?.[0]?.id === distID)
      );
    },
    onError: () => {
      toast.error("Erro ao buscar notificações de distribuidores");
    },
  });*/

  const getDist = () => {
    setDistNotifications(
      notificationsData?.filter(
        (item) =>
          item?.attributes?.collaborator?.data?.attributes?.employmentcolabdistributors?.data?.[0]?.attributes?.distributors?.data?.[0]?.id === distIddD &&
          item?.attributes?.Company?.data?.attributes?.distributors?.data?.[0]?.id === distIddD
      )
    );
    console.log("notfffdata", notificationsData);
    // alert(distIddD);
  };

  useEffect(() => {
    if (isOpen) getComp();
    if (isDist) getDist();
  }, [isOpen, isDist]);




  const handleDialog = useCallback(() => setOpenDialog(!openDialog), [openDialog]);

  const associarCollab = useCallback(
    (companyId, collabId, notifId, collabName, actionNotification, payment) => {
      const companyStatus = companies.find(company => company.id === companyId)?.attributes?.status;

      if (companyStatus !== "Active") {
        toast.warning("Impossível contratar, a empresa está pendente");
        return;
      }

      setCompId(companyId);
      setCollId(collabId);
      setNotId(notifId);
      setCollaboName(collabName);
      setCollabPayment(payment);
      setDialogAction(actionNotification === 'fire' ? 0 : 1);
      handleOpen();
      handleDialog();
    },
    [companies, handleDialog, handleOpen]
  );

  const notificationItemContent = useCallback(
    ({ notification }) => (
      <>
        <div
          key={notification.id}
          className="notification-item group border-b pb-2 relative px-2"
          style={{ padding: '1.2rem 0.5rem' }}
        >
          <button
            type="button"
            onClick={() => associarCollab(notification?.attributes?.Company?.data?.id, notification?.attributes?.collaborator?.data?.id, notification.id, notification?.attributes?.collaborator?.data?.attributes?.name, notification?.attributes?.action, notification?.attributes?.collaborator?.data?.attributes?.employmentcolabdistributors?.data?.[0]?.attributes?.payment)}
            style={{
              fontSize: '13px',
              textTransform: 'uppercase',
              border: '1px solid rgba(0,0,0,0.1)',
              color: '#fff',
              backgroundColor: 'white',
              borderRadius: '50px',
              padding: '0.35rem 0.5rem',
              // display: 'inline-block',
              // textAlign: 'center',
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              right: '3.5rem',
              transition: 'opacity 0.15s ease-in-out',
              zIndex: '99999999999999',
              width: '40px',
              height: '40px',
              aspectRatios: '1/1',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}
            className="opacity-0 group-hover:opacity-100"
          >
            <SvgIcon />
            {/* Contratar */}
          </button>

          <button
            onClick={() =>
              setOpenOptionId(openOptionId === notification.id ? null : notification.id)
            }
            type="button"
            className="item-circle outline-none border cursor-pointer"
            style={{
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              aspectRatios: '1/1',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <MoreHorizIcon />
          </button>

          <div className="flex" style={{ alignItems: 'flex-start', gap: '30px' }}>
            <span
              className="item-circle bg-[#F7931E] text-white font-bold"
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              {notification?.attributes?.Company?.data?.attributes?.name?.[0]}
            </span>

            <div className="relative">
              <span className="block font-bold">
                {notification?.attributes?.Company?.data?.attributes?.name}
              </span>
              <p
                style={{
                  fontSize: '13px',
                  color: 'rgba(0,0,0,0.7)',
                  lineHeight: '1.25',
                  display: 'block',
                }}
              >
                A empresa solicitou interesse no colaborador{' '}
                {notification?.attributes?.collaborator?.data?.attributes?.name}
              </p>
            </div>
            {openOptionId === notification.id && (
              <div
                ref={menuRef}
                className="flex bg-white shadow-md flex-col border w-[55%] rounded-lg"
                style={{
                  position: 'absolute',
                  top: '60px',
                  right: '0.75rem',
                  padding: '1rem',
                  zIndex: '11111',
                  opacity: '1',
                  pointerEvents: 'all',
                  transition: 'opacity 0.25 ease-in-out',
                }}
              >
                <button
                  type="button"
                  style={{
                    display: 'block',
                    textAlign: 'left',
                    gap: '4px',
                    fontSize: '13px',
                    width: '100%',
                  }}
                  className="hover:opacity-70"
                >
                  <PlaylistAddCheckIcon />
                  <span style={{ marginLeft: '2.5px' }}>Marcar como lido</span>
                </button>
                <button
                  type="button"
                  style={{
                    display: 'block',
                    textAlign: 'left',
                    gap: '4px',
                    fontSize: '13px',
                    marginTop: '6px',
                    width: '100%',
                  }}
                  className="hover:opacity-70"
                >
                  <DeleteForeverIcon />
                  <span style={{ marginLeft: '2.5px' }}>Remover</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </>
    ));

  return (
    <>
      <div className={`notification-container ${isOpen ? 'active' : ''}`}>
        <div className="notification-content">
          <div className="notification-top text-black">
            <span>Notifications</span>
            <button
              onClick={handleOpen}
              type="button"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 'auto',
                width: '40px',
                height: '40px',
                aspectRatio: '1/1',
                cursor: 'pointer',
              }}
            >
              <ArrowBackIcon />
            </button>
          </div>
          <div className="notification-body flex flex-col text-black">
            {loading ? (
              <div className="loading-message">Carregando notificações...</div>
            ) : isDist ? (
              distNotifications.map(notification => notificationItemContent({ notification }))
            ) : (
              notificationsData.map(notification => notificationItemContent({ notification }))
            )}
          </div>
        </div>
      </div>

      <CollaboratorToCompany
        open={openDialog}
        handleClose={handleDialog}
        idCollaborador={collId}
        action={dialogAction}
        idCompany={compId}
        notificationId={notId}
        nameCollaborador={collaboName}
        paymentCollaborador={collabPayment}
      />
    </>
  );
};

export default PopupNotification;
