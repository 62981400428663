import { useParams } from "react-router-dom";
import { GET_EVALUATION_METHODS_BY_COMPANY_ID } from "../../evaluation/mutation.gql";
import { Box, Card, CardContent, CircularProgress, Grid, Typography } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

export default function MethodsMain() {
  const { companyId } = useParams();

  const [getMethodByIdCompany, { data, loading, error }] = useLazyQuery(GET_EVALUATION_METHODS_BY_COMPANY_ID);


  useEffect(() => {
    if (companyId) {
      getMethodByIdCompany({ variables: { companyId } });
    }
  }, [companyId, getMethodByIdCompany]);

  if (loading) {
    return (
      <>
        <div className="px-12 py-8 flex items-center gap-2">
          <CircularProgress size={18} />
          <span>Carregando...</span>
        </div>
      </>
    )
  }

  if (error) {
    return <Typography color="error">Erro ao carregar os métodos.</Typography>;
  }

  return (
    <Box sx={{ padding: "16px" }}>
      <div className="p-6 border-b flex items-center">
        <Typography variant="h6" gutterBottom>
          Meus Métodos de Avaliação
        </Typography>
        <a href="/evaluations" className="ml-auto inline-block text-center bg-[#F89220] text-white py-[1rem] px-[1rem] rounded-[.25rem]">Criar método</a>
      </div>

      <div className="p-6">
        <Grid container spacing={3}>
          {
            data?.evaluationMethods?.data?.length == 0 ?
              <>
                <div className="flex items-center gap-8">
                  <span>Nenhum Método Criado</span>
                </div>
              </>
              :

              data?.evaluationMethods?.data?.map((method) => (
                <Grid item xs={12} sm={6} md={4} key={method.id}>
                  <Card>
                    <CardContent>
                      {/* Nome do método */}
                      <Typography variant="h6" fontWeight="bold" gutterBottom>
                        {method.attributes.name}
                      </Typography>

                      {/* Critérios do método */}
                      <Box sx={{ marginTop: "16px" }}>
                        {method.attributes?.criteria?.data?.map((criterion) => (
                          <Box key={criterion.id} sx={{ marginBottom: "12px", display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="body1" fontWeight="medium">
                              {criterion.attributes.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {criterion.attributes.percentage}%
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
        </Grid>
      </div>
    </Box>
  );
};