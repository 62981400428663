import { GitHub, Instagram, LinkedIn } from "@mui/icons-material";


// Mapeamento de plataformas para ícones
export const platformIcons = {
  github: <GitHub sx={{ fontSize: 18 }} />,
  linkedin: <LinkedIn sx={{ fontSize: 18 }} />,
  instagram: <Instagram sx={{ fontSize: 18 }} />
  // Adicione outros ícones conforme necessário
  // Outro exemplo:
  // 'outro': <OtherIcon />,
};