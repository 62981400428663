import {
    Autocomplete,
    Button, FormControl, Grid, InputLabel, TextField, Typography, createFilterOptions
} from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_COUNTRY_STATE_CITY, GET_EXP_ENTITIES, GET_POSTROLE } from "../query.gql";
import { CREATE_EXPERIENCE, CREATE_EXP_ENTITY, UPDATE_EXPERIENCE } from "../mutation.gql";
import i18n from "../../../../translate/i18n";
import { LanguageContext } from "../../../../translate/languages/LanguageContext";
import { useContext } from "react";


const ExperienceStep = ({
    handleClose,
    collaboratorID,
    collaborator,
    setCollaborator,
    collaborators,
    setCollaborators,
    ObjectList,
    setObjectList,
    step,
    stepID,
    setExperience,
    setIsGridVisible

}) => {
    const { selectedLanguage } = useContext(LanguageContext);
    const addStep = step?.includes("Add");
    const editStep = step?.includes("Edit");
    const createStep = step?.includes("Create");
    const filter = createFilterOptions();
    const jwt = localStorage.getItem("jwtToken");
    const [getExpEntitiesName] = useLazyQuery(GET_EXP_ENTITIES);
    const [new_experience] = useMutation(CREATE_EXPERIENCE);
    const [update_exp] = useMutation(UPDATE_EXPERIENCE);
    const [new_expEntity] = useMutation(CREATE_EXP_ENTITY);
    //const [experience, setExperience] = useState([])
    const [expEntityValue, setExpEntityValue] = useState(null);
    const [expEntities, setExpEntities] = useState([]);
    const [newExpEntities, setNewExpEntities] = useState([]);
    const [expError, setExpError] = useState(null);
    const [countryError, setCountryError] = useState(null);
    const [postRoleError, setRoleError] = useState(null);
    const [getCountries] = useLazyQuery((GET_COUNTRY_STATE_CITY));
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState(null);
    const [getPostRole] = useLazyQuery(GET_POSTROLE);
    const [postRole, setPostRole] = useState(null);
    const [postRoles, setPostRoles] = useState([]);
    const [startDate, setStartDate] = useState("");
    const handleStartChangeDate = e => { setStartDate(e.target.value) }
    const [endDate, setEndDate] = useState("");
    const handleEndChangeDate = e => { setEndDate(e.target.value) }

    const [description, setDescription] = useState('');
    useEffect(() => {
        setExpEntities([]);
        getExpEntitiesName({
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
            //fetchPolicy: "network-only",
        }).then((data) => {
            const expEntitiesData = data.data.expEntities.data.map((item) => {
                return {
                    id: item.id,
                    name: item.attributes.name,
                };
            });

            setExpEntities(expEntitiesData);

        });
        const fetchData = async () => {
            try {
                const countriesData = await getCountries({
                    fetchPolicy: "cache-first", variables: {
                        pagination: {
                            limit: 1000,
                        },
                    },
                });
                setCountries(countriesData?.data?.countries?.data?.map((item) => {
                    return {
                        id: item.id,
                        name: item.attributes?.name,
                        cities: item.attributes?.cities?.data?.map((item) => {
                            return {
                                id: item.id,
                                name: item.attributes?.name,
                            };
                        })
                    };
                }));
                const postRoleData = await getPostRole({
                    variables: {
                        pagination: {
                            limit: 300,
                        },
                    },
                    context: {
                        headers: {
                            authorization: `Bearer ${jwt}`,
                        },
                    },
                    fetchPolicy: "cache-first",
                })
                setPostRoles(postRoleData.data.postRoles.data);
            } catch (error) {
                toast.error("Ocorreu um erro ao carregar a lista de Países");
            }
        };

        fetchData();
        if (step?.includes("Edit")) {
            const foundExpEntity = ObjectList.find((object) => object.id === stepID);
            setExpEntityValue({ id: foundExpEntity.expEntityID, name: foundExpEntity.expEntity })
            setCountry({ id: foundExpEntity.countryID, name: foundExpEntity.country })
            setPostRole({ id: foundExpEntity.postRoleID, attributes: { postRole: foundExpEntity.postRole, description: foundExpEntity.postRoleDescription } })
            setDescription(foundExpEntity.description)
            setStartDate(foundExpEntity.startDate)
            setEndDate(foundExpEntity.endDate)
        }
    }, []);
    const handleInputChange = (event, setVariable) => {
        setVariable(event.target.value);
    };
    const handleAdd = () => {
        if (expEntityValue === null) {
            setExpError(i18n.t('collaboratorStep.error.entitySelect', { lng: selectedLanguage }));
        }
        if (country === null) {
            setCountryError(i18n.t('collaboratorStep.error.countrySelect', { lng: selectedLanguage }));
        }
        if (postRole === null) {
            setRoleError(i18n.t('collaboratorStep.error.roleSelect', { lng: selectedLanguage }));
        }
        else {
            new_experience({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    data: {
                        description: description,
                        startDate: startDate,
                        endDate: endDate,
                        postRole: postRole.id,
                        expEntity: expEntityValue.id,
                        country: country.id,
                        collaborator: collaboratorID,
                    },
                },
            }).then((data) => {
                const newExperience = data.data.createExperience.data
                const updatedData = {
                    id: newExperience.id,
                    description: newExperience.attributes?.description,
                    startDate: newExperience.attributes?.startDate,
                    endDate: newExperience.attributes?.endDate,
                    postRole: newExperience.attributes?.postRole?.data?.attributes?.postRole,
                    postRoleID: newExperience.attributes?.postRole?.data?.id,
                    postRoleDescription: newExperience.attributes?.postRole?.data?.attributes?.description,
                    expEntity: newExperience.attributes?.expEntity?.data?.attributes?.name,
                    expEntityID: newExperience.attributes?.expEntity?.data?.id,
                    country: newExperience.attributes?.country?.data?.attributes?.name,
                    countryID: newExperience.attributes?.country?.data?.id
                }
                const updatedMainData = collaborators.map(obj => {
                    if (obj.id === collaborator.id) {
                        return { ...obj, experiences: [...obj.experiences, updatedData] }; // Add the updatedData value to the expertise array
                    }
                    return obj; // Keep the original object
                });
                const newList = [...ObjectList, updatedData]
                setObjectList(newList)
                setCollaborators(updatedMainData)
                handleClose()
                // setItToolValue(null)
                //setPercent("")
                toast.success("Experience Done!!")
            })


        }

    };
    const handleEdit = () => {
        if (expEntityValue === null) {
            setExpError(i18n.t('collaboratorStep.error.entitySelect', { lng: selectedLanguage }));
        }
        if (country === null) {
            setCountryError(i18n.t('collaboratorStep.error.countrySelect', { lng: selectedLanguage }));
        }
        if (postRole === null) {
            setRoleError(i18n.t('collaboratorStep.error.roleSelect', { lng: selectedLanguage }));
        }
        else {
            update_exp({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: stepID,
                    data: {
                        description: description,
                        startDate: startDate,
                        endDate: endDate,
                        postRole: postRole.id,
                        expEntity: expEntityValue.id,
                        country: country.id,
                        collaborator: collaboratorID,
                    },
                },
            }).then((data) => {

                const foundExpertise =
                {
                    id: stepID,
                    description: description,
                    startDate: startDate,
                    endDate: endDate,
                    postRole: postRole.attributes.postRole,
                    postRoleID: postRole.id,
                    postRoleDescription: postRole.attributes.description,
                    expEntity: expEntityValue.name,
                    expEntityID: expEntityValue.id,
                    country: country.name,
                    countryID: country.id
                }
                const foundExpertiseIndex = ObjectList.findIndex(expertise => expertise.id === stepID);

                if (foundExpertiseIndex !== -1) {
                    ObjectList[foundExpertiseIndex] = {
                        ...foundExpertise,
                    };
                }
                handleClose()
                setExpEntityValue(null)
                setStartDate(null)
                setEndDate(null)
                setDescription("")
                setPostRole(null)
                toast.success("Skill Updated!!")
            })


        }

    };
    const handleCreate = () => {
        if (expEntityValue === null) {
            setExpError(i18n.t('collaboratorStep.error.entitySelect', { lng: selectedLanguage }));
        }
        if (country === null) {
            setCountryError(i18n.t('collaboratorStep.error.countrySelect', { lng: selectedLanguage }));
        }
        if (postRole === null) {
            setRoleError(i18n.t('collaboratorStep.error.roleSelect', { lng: selectedLanguage }));
        }
        else {
            const newExperience = {
                name: expEntityValue.name,
                id: expEntityValue.id,
                description: description,
                startDate: startDate,
                endDate: endDate,
                countryName: country.name,
                countryID: country.id,
                postRole: postRole.attributes.description,
                postRoleID: postRole.id,
            };
            setExperience(prevState => [...prevState, newExperience]);
            setIsGridVisible(false);
        }

    };

    console.log("startDate", startDate)

    return (


        <>
            <Grid container item xs={12} spacing={3} id="expGrid">
                <Grid item xs={12} md={6}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.entity', { lng: selectedLanguage })}</InputLabel>
                    <Autocomplete
                        value={expEntityValue}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                const filteredExpEntity = expEntities.filter(item =>
                                    item.name?.toLowerCase() === newValue?.toLowerCase()
                                );

                                if (filteredExpEntity?.length > 0) {
                                    setExpEntityValue({
                                        name: filteredExpEntity[0].name,
                                        id: filteredExpEntity[0].id
                                    });
                                    setExpError(null)
                                } else {
                                    new_expEntity({
                                        context: {
                                            headers: {
                                                authorization: `Bearer ${jwt}`,
                                            },
                                        },
                                        //fetchPolicy: "network-only",
                                        variables: {
                                            data: {
                                                name: newValue.inputValue
                                            },
                                        },
                                    }).then((data) => {
                                        const expEntityId = data.data.createExpEntity.data.id;
                                        const expEntityName = data.data.createExpEntity.data.attributes.name;

                                        setExpEntityValue({
                                            name: expEntityName, id: expEntityId
                                        });
                                        setExpError(null)
                                        const newOption = { name: expEntityName, id: expEntityId }

                                        setNewExpEntities([...newExpEntities, newOption]);
                                    })
                                }

                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                new_expEntity({
                                    context: {
                                        headers: {
                                            authorization: `Bearer ${jwt}`,
                                        },
                                    },
                                    // fetchPolicy: "network-only",
                                    variables: {
                                        data: {
                                            name: newValue.inputValue
                                        },
                                    },
                                }).then((data) => {
                                    const expEntityId = data.data.createExpEntity.data.id;
                                    const expEntityName = data.data.createExpEntity.data.attributes.name;

                                    setExpEntityValue({
                                        name: expEntityName, id: expEntityId
                                    });
                                    setExpError(null)
                                    const newOption = { name: expEntityName, id: expEntityId }

                                    setNewExpEntities([...newExpEntities, newOption]);
                                })
                            } else {
                                setExpEntityValue(newValue);
                                setExpError(null)
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.name);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    name: `Add "${inputValue}"`,
                                });
                            }

                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="expEntity"
                        options={[...expEntities, ...newExpEntities]}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.name;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        size="small"
                        freeSolo
                        renderInput={(params) => (
                            <TextField {...params} />
                        )}
                    />
                    {expError && <Typography
                        variant="body2"
                        color="gray"
                        style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                        {expError}
                    </Typography>}
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel id="select-postRoles">{i18n.t('collaboratorStep.collaborator.role', { lng: selectedLanguage })}</InputLabel>
                    <FormControl fullWidth>
                        <Autocomplete
                            size="small"
                            options={postRoles}
                            value={postRole}
                            getOptionLabel={(country) => country.attributes.description}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                                setPostRole(newValue);
                                setRoleError(null)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={i18n.t('collaboratorStep.collaborator.roleBody', { lng: selectedLanguage })}
                                    required


                                />
                            )}
                        />
                        {postRoleError && <Typography
                            variant="body2"
                            color="gray"
                            style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                            {postRoleError}
                        </Typography>}
                    </FormControl>
                </Grid>


                <Grid item xs={12} md={6}>
                    <InputLabel id="select-countries">{i18n.t('collaboratorStep.collaborator.country', { lng: selectedLanguage })}</InputLabel>
                    <FormControl fullWidth>
                        <Autocomplete
                            size="small"
                            options={countries}
                            value={country}
                            getOptionLabel={(country) => country.name}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                                setCountry(newValue)
                                setCountryError(null);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={i18n.t('collaboratorStep.collaborator.countryBody', { lng: selectedLanguage })}
                                    required
                                />
                            )}
                        />
                        {countryError && <Typography
                            variant="body2"
                            color="gray"
                            style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                            {countryError}
                        </Typography>}
                    </FormControl>

                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.description', { lng: selectedLanguage })}</InputLabel>
                    <TextField size="small"
                        required
                        autoFocus
                        multiline
                        type="text"
                        value={description}
                        onChange={(event) => handleInputChange(event, setDescription)}
                        fullWidth
                        name="description"
                        id="description"
                        placeholder={i18n.t('collaboratorStep.collaborator.descriptionBody', { lng: selectedLanguage })}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.start', { lng: selectedLanguage })}</InputLabel>
                    <TextField
                        required
                        fullWidth
                        type="date"
                        value={startDate}
                        onChange={handleStartChangeDate}
                        name="start_date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.end', { lng: selectedLanguage })}</InputLabel>
                    <TextField
                        required
                        fullWidth
                        type="date"
                        value={endDate}
                        onChange={handleEndChangeDate}
                        name="start_date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

            </Grid>
            <Grid container spacing={2} marginTop={1}>
                <Grid container item xs={12} spacing={3} marginLeft={0.5} >
                    {createStep &&
                        <Grid item>
                            <Button
                                type="button"
                                color="primary"
                                onClick={handleCreate}
                            >
                                {i18n.t('collaboratorStep.button.experience', { lng: selectedLanguage })}
                            </Button>
                        </Grid>
                    }
                </Grid>
                <Grid container spacing={3} item xs={12} display="flex" justifyContent="space-around" alignItems="center">
                    {!createStep && 
                    <Grid item>
                        <Button variant="outlined" size="small" onClick={handleClose}>{i18n.t('collaboratorStep.button.cancel', { lng: selectedLanguage })}</Button>
                    </Grid>
                    }
                    <Grid item>

                        {editStep && <Button
                            variant="contained"
                            size="small"
                            onClick={handleEdit}
                        >{i18n.t('collaboratorStep.button.edit', { lng: selectedLanguage })}</Button>}
                        {addStep && <Button
                            variant="contained"
                            size="small"
                            onClick={handleAdd}
                        >{i18n.t('collaboratorStep.button.create', { lng: selectedLanguage })}</Button>}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );

};

export default ExperienceStep;