import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Print } from "@mui/icons-material";
import { LanguageContext } from "../../../translate/languages/LanguageContext";
import i18n from "../../../translate/i18n";
import "../curriculum.css";
import SectionHeader from "./sections/Header";
import Summary from "../details/sections/Summary";
import Experience from "../details/sections/Experience";
import Skills from "../details/Skiils";
import Languages from "../details/Languages";
import HeaderAlt from "../details/sections/HeaderAlt";
import SectionContact from "./sections/Contact";
import AvatarCv from "./AvatarCv";
import html2canvas from "html2canvas";
import Education from "../details/sections/Eduction";
import { PDFDocument, rgb } from "pdf-lib";
import { toast } from "react-toastify";

const DetailsCvAlternate = () => {
  const { REACT_APP_API_URL } = process.env;
  const { selectedLanguage } = useContext(LanguageContext);

  const location = useLocation();
  const { data, currentItems } = location.state || {};

  const handlePrint = () => {
    //generatePDF();
    generatePDF2();
    //generateWithPdfLib()
  };

  const enlargePaddingTop = () => {
    if (!data.avatar) {
      return '';
    }
    else if (data.avatar?.length > 0) {
      console.log(data.avatar);
      return 'pt-16';
    }
    return '';
  };

  const generatePDF = async () => {
    const layoutDoc = document.querySelector('.layoutDoc');
    if (!layoutDoc) return;

    // Ajusta o tamanho do elemento para A4
    layoutDoc.style.width = '210mm';
    layoutDoc.style.minHeight = '297mm';
    layoutDoc.style.margin = '0';

    // Captura a imagem usando html2canvas
    const canvas = await html2canvas(layoutDoc, {
      scale: 3,
      useCORS: true,
      logging: false,
      scrollX: 0,
      scrollY: 0,
    });

    const imgData = canvas.toDataURL('image/jpeg', 1.0);

    // Cria um novo documento PDF
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([595, 842]); // Tamanho A4 em pontos (595 x 842)

    const imgBytes = await fetch(imgData).then(res => res.arrayBuffer());
    const image = await pdfDoc.embedJpg(imgBytes);

    const { width, height } = page.getSize();
    const imgWidth = width;
    const imgHeight = (canvas.height * width) / canvas.width;

    let yPosition = 0;
    while (yPosition < imgHeight) {
      page.drawImage(image, {
        x: 0,
        y: height - imgHeight - yPosition,
        width: imgWidth,
        height: imgHeight,
      });
      yPosition += height;
      if (yPosition < imgHeight) {
        page = pdfDoc.addPage([595, 842]);
      }
    }

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `cv_${data?.name || 'document'}.pdf`;
    link.click();
  };

  const generatePDF2 = async () => {
    const layoutDoc = document.querySelector('.layoutDoc');
    const augTop = document.querySelectorAll('.augmented-top');
    if (!layoutDoc) return;

    augTop.forEach(item => item.style.marginTop = '1rem');
    // Ajusta o tamanho do elemento para A4
    layoutDoc.style.width = '210mm';
    layoutDoc.style.minHeight = '297mm';
    layoutDoc.style.margin = '0 auto';

    // Captura a imagem usando html2canvas
    const canvas = await html2canvas(layoutDoc, {
      scale: 3, // Aumenta a resolução
      useCORS: true,
      logging: false,
      scrollX: 0,
      scrollY: 0,
    });

    const imgData = canvas.toDataURL('image/jpeg', 1.0);

    // Cria um novo documento PDF
    let pdfDoc = await PDFDocument.create();
    let page = pdfDoc.addPage([595, 842]); // Tamanho A4 em pontos (595 x 842)

    // Converte a imagem base64 em um arrayBuffer
    const imgBytes = await fetch(imgData).then((res) => res.arrayBuffer());
    const image = await pdfDoc.embedJpg(imgBytes);

    const { width, height } = page.getSize();
    const imgWidth = width;
    const imgHeight = (canvas.height * width) / canvas.width;

    let yPosition = 0;
    while (yPosition < imgHeight) {
      // Adiciona a imagem na página
      page.drawImage(image, {
        x: 0,
        y: height - imgHeight - yPosition,
        width: imgWidth,
        height: imgHeight,
      });

      yPosition += height;
      if (yPosition < imgHeight) {
        // Adiciona uma nova página e desenha a imagem
        page = pdfDoc.addPage([595, 842]); // Tamanho A4
      }
    }

    // Gera o PDF e oferece para download
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `cv_${data?.name || 'document'}.pdf`;
    link.click();

    toast.success('Succefully!!!');
    setTimeout(() => {
      window.location.reload();
    }, 1000)
  };

  console.log(data);


  return (
    <>
      <div className="controls-bar">
        <div className="flex items-center ml-auto">
          <a
            href="/"
            className='border border-[#F89220] text-[#F89220] py-3.5 px-6 hover:opacity-60 rounded-[0.25rem] mr-2'>
            Voltar
          </a>
          <button
            onClick={() => handlePrint()}
            className='flex items-center py-3.5 px-6 border border-transparent bg-[#F89220] text-white rounded-[0.25rem] hover:bg-white hover:border-[#F89220] hover:text-[#F89220] gap-2 font-onest'
          >
            <Print sx={{ fontSize: 18 }} />
            {i18n.t('cv.print', { lng: selectedLanguage })}
          </button>
        </div>
      </div>

      <div className="layoutDoc">
        <div className="layoutDoc-header w-full">
          <HeaderAlt owner={data} lng={selectedLanguage} />
        </div>
        <div className="layoutDoc-body flex relative mt-2">
          <div className={`w-[30%] h-full relative p-6 self-start`}>
            <Skills skills={data.skills ?? []} lng={selectedLanguage} />
            <Languages language={data.languages ?? []} lng={selectedLanguage} />
          </div>
          <div className="w-[70%]  relative bg-white p-6 self-stretch">
            <Summary intro={data.intro ?? ''} />
            <Education edu={data.educations ?? []} lng={selectedLanguage} />
            <Experience exp={data.experiences ?? []} lng={selectedLanguage} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsCvAlternate;
