import {
    Autocomplete,
    Button, Grid, InputLabel, TextField, Typography, createFilterOptions
} from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_PLATFORMS } from "../query.gql";
import { CREATE_PROFILE, CREATE_PROFILE_PLATFORM, UPDATE_PROFILE } from "../mutation.gql";
import i18n from "../../../../translate/i18n";
import { LanguageContext } from "../../../../translate/languages/LanguageContext";
import { useContext } from "react";

const ProfileStep = ({
    handleClose,
    collaboratorID,
    collaborator,
    setCollaborator,
    collaborators,
    setCollaborators,
    ObjectList,
    setObjectList,
    step,
    stepID,
    setProfile,
    setIsGridVisible

}) => {
    const { selectedLanguage } = useContext(LanguageContext);
    const addStep = step?.includes("Add");
    const editStep = step?.includes("Edit");
    const createStep = step?.includes("Create");
    const filter = createFilterOptions();
    const jwt = localStorage.getItem("jwtToken");
    const [getProfilePlatforms] = useLazyQuery(GET_PLATFORMS);
    const [new_profile] = useMutation(CREATE_PROFILE);
    const [updated_profile] = useMutation(UPDATE_PROFILE);
    const [new_platform] = useMutation(CREATE_PROFILE_PLATFORM);
    //const [profile, setProfile] = useState([])
    const [platforms, setPlatforms] = useState([]);
    const [newPlatforms, setNewPlatforms] = useState([]);
    const [platformValue, setPlatformValue] = useState(null);
    const [platformError, setPlatformError] = useState(null);
    const [urlError, setUrlError] = useState(null);
    const [description, setDescription] = useState('');
    const [urlProfile, setUrlProfile] = useState('');
    useEffect(() => {
        setPlatforms([]);
        getProfilePlatforms({
            context: {
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            },
            //fetchPolicy: "network-only",
        }).then((data) => {
            const platformData = data.data.profilePlatforms.data.map((item) => {
                return {
                    id: item.id,
                    name: item.attributes.name,
                };
            });

            setPlatforms(platformData);

        });

        if (step?.includes("Edit")) {
            const foundProfile = ObjectList.find((object) => object.id === stepID);
            setPlatformValue({ id: foundProfile.platformID, name: foundProfile.platform })
            setDescription(foundProfile.description)
            setUrlProfile(foundProfile.urlProfile)
        }
    }, []);
    const handleInputChange = (event, setVariable) => {
        setVariable(event.target.value);
    };
    const handleAdd = () => {
        if (platformValue === null) {
            setPlatformError(i18n.t('collaboratorStep.error.profileSelect', { lng: selectedLanguage }));
        }
        if (urlProfile === "") {
            setUrlError(i18n.t('collaboratorStep.error.urlSelect', { lng: selectedLanguage }));
        }
        else {
            new_profile({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    data: {
                        platform: platformValue.id,
                        urlProfile: urlProfile,
                        description: description,
                        collaborator: collaboratorID,
                    },

                },
            }).then((data) => {
                const newProfile = data.data.createProfile.data
                const updatedData = {
                    id: newProfile.id,
                    urlProfile: newProfile.attributes?.urlProfile,
                    description: newProfile.attributes?.description,
                    platform: newProfile.attributes?.platform?.data?.attributes?.name,
                    platformID: newProfile.attributes?.platform?.data?.id,
                    icon: newProfile.attributes?.platform?.data?.attributes?.icon?.data?.attributes?.url
                }
                const updatedMainData = collaborators.map(obj => {
                    if (obj.id === collaborator.id) {
                        return { ...obj, profiles: [...obj.profiles, updatedData] }; // Add the updatedData value to the expertise array
                    }
                    return obj; // Keep the original object
                });
                const newList = [...ObjectList, updatedData]
                setObjectList(newList)
                setCollaborators(updatedMainData)
                handleClose()
                toast.success("Profile Done!!")
            })


        }

    };
    const handleEdit = () => {
        if (platformValue === null) {
            setPlatformError(i18n.t('collaboratorStep.error.profileSelect', { lng: selectedLanguage }));
        }
        if (urlProfile === "") {
            setUrlError(i18n.t('collaboratorStep.error.urlSelect', { lng: selectedLanguage }));
        }
        else {
            updated_profile({
                context: {
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                },
                //fetchPolicy: "network-only",
                variables: {
                    id: stepID,
                    data: {
                        platform: platformValue.id,
                        urlProfile: urlProfile,
                        description: description,
                        collaborator: collaboratorID,
                    },
                },
            }).then((data) => {
                const newProfile = data.data.updateProfile.data
                const foundExpertise =
                {
                    id: stepID,
                    urlProfile: urlProfile,
                    description: description,
                    platform: platformValue.name,
                    platformID: platformValue.id,
                    icon: newProfile.attributes?.platform?.data?.attributes?.icon?.data?.attributes?.url
                }
                const foundExpertiseIndex = ObjectList.findIndex(expertise => expertise.id === stepID);

                if (foundExpertiseIndex !== -1) {
                    ObjectList[foundExpertiseIndex] = {
                        ...foundExpertise,
                    };
                }
                handleClose()
                toast.success("Profile Updated!!")
            })


        }
    };
    const handleCreate = () => {
        if (platformValue === null) {
            setPlatformError(i18n.t('collaboratorStep.error.profileSelect', { lng: selectedLanguage }));
        }
        if (urlProfile === "") {
            setUrlError(i18n.t('collaboratorStep.error.urlSelect', { lng: selectedLanguage }));
        }
        else {
            const newPlatform = {
                platformName: platformValue.name,
                platformID: platformValue.id,
                urlProfile: urlProfile,
                description: description
            };
            setProfile(prevState => [...prevState, newPlatform]);
            setIsGridVisible(false);
        }

    };


    return (


        <>

            <Grid container item xs={12} spacing={3} id="expGrid">
                <Grid item xs={12} md={4}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.nameProfile', { lng: selectedLanguage })}</InputLabel>
                    <Autocomplete
                        value={platformValue}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                const filteredPlatforms = platforms.filter(item =>
                                    item.name?.toLowerCase() === newValue?.toLowerCase()
                                );

                                if (filteredPlatforms?.length > 0) {
                                    setPlatformValue({
                                        name: filteredPlatforms[0].name,
                                        id: filteredPlatforms[0].id
                                    });
                                } else {
                                    new_platform({
                                        context: {
                                            headers: {
                                                authorization: `Bearer ${jwt}`,
                                            },
                                        },
                                        //fetchPolicy: "network-only",
                                        variables: {
                                            data: {
                                                name: newValue.inputValue
                                            },
                                        },
                                    }).then((data) => {
                                        const platformID = data.data.createProfilePlatform.data.id;
                                        const platformName = data.data.createProfilePlatform.data.attributes.name;

                                        setPlatformValue({
                                            name: platformName, id: platformID
                                        });
                                        const newOption = { name: platformName, id: platformID }

                                        setNewPlatforms([...newPlatforms, newOption]);
                                    })
                                }

                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                new_platform({
                                    context: {
                                        headers: {
                                            authorization: `Bearer ${jwt}`,
                                        },
                                    },
                                    //fetchPolicy: "network-only",
                                    variables: {
                                        data: {
                                            name: newValue.inputValue
                                        },
                                    },
                                }).then((data) => {
                                    const platformID = data.data.createProfilePlatform.data.id;
                                    const platformName = data.data.createProfilePlatform.data.attributes.name;

                                    setPlatformValue({
                                        name: platformName, id: platformID
                                    });
                                    const newOption = { name: platformName, id: platformID }

                                    setNewPlatforms([...newPlatforms, newOption]);
                                })
                            } else {
                                setPlatformValue(newValue);
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.name);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    name: `Add "${inputValue}"`,
                                });
                            }

                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="eduFaculty"
                        options={[...platforms, ...newPlatforms]}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.name;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        size="small"
                        freeSolo
                        renderInput={(params) => (
                            <TextField placeholder="LinkedIn"{...params} />
                        )}
                    />
                    {platformError && <Typography
                        variant="body2"
                        color="gray"
                        style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                        {platformError}
                    </Typography>}
                </Grid>

                <Grid item xs={12} md={8}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.profileUrl', { lng: selectedLanguage })}</InputLabel>
                    <TextField size="small"
                        required
                        autoFocus
                        type="text"
                        value={urlProfile}
                        onChange={(event) => handleInputChange(event, setUrlProfile)}
                        fullWidth
                        name="urlProfile"
                        id="urlProfile"
                        placeholder="https://www.linkedin.com/company/afrikancoders/"
                    />
                    {urlError && <Typography
                        variant="body2"
                        color="gray"
                        style={{ fontSize: '12px', marginTop: '5.5px', marginLeft: '14px' }}>
                        {urlError}
                    </Typography>}
                </Grid>
                <Grid item xs={12} md={12}>
                    <InputLabel >{i18n.t('collaboratorStep.collaborator.description', { lng: selectedLanguage })}</InputLabel>
                    <TextField size="medium"
                        required
                        autoFocus
                        multiline
                        type="text"
                        value={description}
                        onChange={(event) => handleInputChange(event, setDescription)}
                        fullWidth
                        name="description"
                        id="description"
                        placeholder={i18n.t('collaboratorStep.collaborator.descriptionProfile', { lng: selectedLanguage })}
                    />
                </Grid>


            </Grid>
            <Grid container spacing={2} marginTop={1}>
                <Grid container item xs={12} spacing={3} marginLeft={0.5} >
                    {createStep &&
                        <Grid item>
                            <Button
                                type="button"
                                color="primary"
                                onClick={handleCreate}
                            >
                               {i18n.t('collaboratorStep.button.profile', { lng: selectedLanguage })}
                            </Button>
                        </Grid>
                    }
                </Grid>
                <Grid container spacing={3} item xs={12} display="flex" justifyContent="space-around" alignItems="center">
                    {!createStep && <Grid item>
                        <Button variant="outlined" size="small" onClick={handleClose}>{i18n.t('collaboratorStep.button.cancel', { lng: selectedLanguage })}</Button>
                    </Grid>}
                    <Grid item>

                        {editStep && <Button
                            variant="contained"
                            size="small"
                            onClick={handleEdit}
                        >{i18n.t('collaboratorStep.button.edit', { lng: selectedLanguage })}</Button>}
                        {addStep && <Button
                            variant="contained"
                            size="small"
                            onClick={handleAdd}
                        >{i18n.t('collaboratorStep.button.create', { lng: selectedLanguage })}</Button>}
                    </Grid>
                </Grid>
            </Grid>

        </>
    );

};

export default ProfileStep;