const messages = {
    pt: {
        translations: {
            cv: {
                summary: 'Resumo',
                education: 'Formação Acadêmica',
                experience: 'Experiência',
                skill: 'Habilidades',
                language: 'Idiomas',
                achievment: 'Conquistas',
                print: 'Imprimir',
                msg: 'Pdf gerado com sucesso!',
                info: 'Informações Pessoais',
                datanasc: 'Data de Nascimento',
                nationality: 'Nacionalidade',
                sex: 'Sexo',
                contact: 'Contactos',
                level: {
                    beginner: 'Iniciante',
                    advanced: 'Avançado',
                    native: 'Nativo',
                    intermediate: 'Intermediário',
                },
            },
            login: {
                login: {
                    access: 'Acessar',
                    signUp: 'Registe',
                    signMessage: 'Ainda não tem uma conta?',
                    welcome: 'Bem-vindo',
                },

                register: {
                    title: 'Criar uma conta como empresa',
                    userName: 'Nome de Usuário',
                    companyName: 'Nome da Empresa',
                    branchActivity: 'Ramo de Atividade',
                    email: 'E-mail',
                    emailConfirm: 'Confirmar E-mail',
                    password: 'Senha',
                    passwordConfirm: 'Confirmar Senha',
                },
                error: {
                    countrySelect: 'Por favor, selecione um país!',
                    citySelect: 'Por favor, selecione uma cidade!',
                    countryFetch: 'Ocorreu um erro ao carregar os países!',
                    userFetch: 'Ocorreu um erro ao carregar os dados!',
                    userCreation: 'Ocorreu um erro ao criar o usuário!',
                    username: 'Nome de usuário já está em uso!',
                    name: 'Já existe uma empresa registrada com esse nome!',
                    usernameBody: 'Por favor, selecione um novo nome de usuário',
                    usernameError: 'Nome de usuário é obrigatório',
                    emailSelect: 'O e-mail já está em uso!',
                    usernameMinLength: 'Digite pelo menos 3 caracteres',
                },
                button: {
                    login: 'Já tem uma conta? Faça login!',
                    toRegister: 'Sem uma conta? Registra Sua Empresa.',
                    register: 'Registrar',
                    back: '<< Voltar',
                    access: 'Acessar',
                },
            },
            loading: {
                loading: 'Carregando...',
            },
            form: {
                duplicated: 'já existe!',
                duplicatedError: 'Por favor, altere!',
                mandatory: 'Não pode ficar vazio!',
            },
            error: {
                createPeriod: 'Verifique os dados e tente novamente!',
                updatePeriod: 'Algo deu errado! Atualize e tente novamente!',
                deletePeriod: 'Algo deu errado durante a Eliminação!',
            },
            success: {
                create: ' criado com sucesso!',
                update: ' atualizado com sucesso!',
                delete: ' eliminado com sucesso!',
                createPeriod: 'Novo Período de Avaliação criado com sucesso!',
                updatePeriod: 'Período de Avaliação atualizado com sucesso!',
                deletePeriod: 'Período de Avaliação eliminado com sucesso!',
                createRole: 'Novo Cargo criado com sucesso!',
                updateRole: 'Cargo atualizado com sucesso!',
                deleteRole: 'Cargo eliminado com sucesso!',
                createDimension: 'Nova Dimensão criado com sucesso!',
                updateDimension: 'Dimensão atualizada com sucesso!',
                deleteDimension: 'Dimensão eliminada com sucesso!',
            },
            alert: {
                error: 'Erro',
                success: 'Sucesso',
                info: 'Informação',
                warning: 'Aviso',
            },
            pathway: {
                collaborator: 'Colaborador',
                distributor: 'Distribuidor',
                create: 'Criar',
                role: 'Cargo',
                companies: 'Empresas',
                evaluation: 'Avaliação',
                period: 'Período',
                dimension: 'Dimensão',
                indicator: 'Indicador',
                question: 'Questões',
                quiz: 'Questionário',
            },
            title: {
                indicator: 'Novo Indicador',
                editIndicator: 'Editar Indicador',
                viewIndicator: 'Ver Indicador',
                confirm: 'Confirmar operação',
                question: 'Nova Questão',
                quiz: 'Nova Pergunta',
                role: 'Novo Cargo',
                editRole: 'Editar Cargo',
                viewRole: 'Ver Cargo',
                dimension: 'Nova Dimensão',
                distributor: 'Novo Distribuidor',
                furnisher: 'Novo Fornecedor',
                editDimension: 'Editar Dimensão',
                viewDimension: 'Ver Dimensão',
                collaborator: 'Novo Colaborador',
                period: 'Novo Período de Avaliação',
                editPeriod: 'Editar Período de Avaliação',
                viewPeriod: 'Ver Período de Avaliação',
                company: 'Nova Empresa',
                delete: 'Tem certeza que pretende eliminar?',
            },
            tittles: {
                quiz: 'Teste',
                quizTeste: 'Fazer Teste Agora',
                quizResult: 'Ver Resultados',
                dashboard: 'Painel',
                company: 'Empresas',
                collaborator: 'Colaborador',
                distributor: 'Distribuidor',
                furnisher: 'Fornecedores',
                evaluation: 'Avaliação',
                evaluationManager: 'Gestão de Avaliações',
                parameterization: 'Parametrização',
                role: 'Cargo',
                dimension: 'Dimensão',
                priod: 'Periodo',
                indicator: 'Indicador',
                questao: 'Questões',
                quiz: 'Questionário',
                company_col: 'Todos Colaboradores',
                company_my_cols: 'Meus Colaboradores',
                company_my_criterys: 'Meus Métodos'

            },
            options: {
                verPerfil: 'Ver Perfil',
                mudarPalavraPasse: 'Mudar Palavra-Passe',
                sair: 'Sair',
                submit: 'Submeter',
                view: 'Ver',
                edit: 'Editar',
                delete: 'Eliminar',
                enable: 'Ativar',
                disable: 'Desativar',
                active: 'Ativo',
                inactive: 'Inativo',
                evaluate: 'Avalie',
            },
            dashboard: {
                welcome: 'Seja Bem Vindo, {{name}}',
                review: 'Análises',
                graph: 'Gráfico de Avaliação',
                history: 'Histórico de Avaliação',
                last: 'Ultimas Avaliações',
                questions: 'Responda as seguintes Questões',
                firstWelcome: 'Afrikan Coders entrará em contato.',
                error: 'Algo deu Errado, Atualize e tente Novamente!',
                evalWelcome: 'Seja Bem-vindo à plataforma',
                findDev: 'Encontre o seu Dev agora!'

            },
            month: {
                jan: 'Jan',
                feb: 'Fev',
                mar: 'Mar',
                apr: 'Abr',
                may: 'Mai',
                jun: 'Jun',
                jul: 'Jul',
                aug: 'Ago',
                sep: 'Set',
                oct: 'Out',
                nov: 'Nov',
                dec: 'Dez',
            },
            table: {
                name: 'Nome',
                location: 'Localização',
                email: 'Email',
                phone: 'Telefone',
                company: 'Empresa',
                state: 'Estado',
                action: 'Acção',
                description: 'Descrição',
                startDate: 'Data de Inicio',
                endDate: 'Data de Fim',
                dimension: 'Dimenção',
                question: 'Questão',
                questionType: 'Tipo de Pergunta',
                indicator: 'Indicador',
                branch: 'Ramo de atividade',
                status: 'Status',
                role: 'Cargo',
                yearsofExperience: 'Anos de experiência',
                speciality: 'Especialidade',
                 evaluation: 'Avaliação'
            },
            button: {
                evaluation: 'Avaliações',
                performed: 'Efetuado',
                pending: 'Pendente',
                new: 'Novo',
                filter: 'Filtros',
                companyProfile: 'Perfil Empresa',
                questionsandanswers: 'Perguntas e Respostas',
                next: 'Seguinte',
                cancel: 'Cancelar',
                save: 'Guardar',
                confirm: 'Confirmar',
                collaboratorFilter: 'Filtrar',
            },
            collaboratorFilter: {
                error: 'Preencha pelo menos um dos campos',
            },
            collaborator: {
                role: 'Cargo',
                experienceYears: 'Anos de Experiênia',
                education: 'Formação Acadêmica',
                skills: 'Habilidades',
                paymentPerHour: 'Pagamento/Hora',

                dashboard: {
                    title: 'Transforme seu negócio com os melhores talentos da tecnologia africana na Afrikan Coders',
                    subtitle: 'Na Afrikan Coders, conectamos empresas visionárias com profissionais freelancers altamente qualificados de todo o continente africano. Nossa plataforma é o hub perfeito para encontrar desenvolvedores, designers, analistas e outros talentos tecnológicos que unem inovação, diversidade cultural e excelência técnica.',
                    tittles: {
                        all_category: 'All category',
                        GRAFIC: 'Grafic Design',
                        AI: 'AI Services',
                        SOTWARE: 'Software Development',
                        DESIGN: 'UI/UX Design',
                        "DATA ANALYST": "Analista de Dados"
                    }
                },

                alert: {
                    creationSuccess: 'Colaborador criado com sucesso!',
                    creationEmailWarning: 'E-mail já está em uso!',
                    creationError: 'Verifique os dados e tente novamente!',
                },
                title: {
                    collaborator: 'Colaborador',
                    experience: 'Experiência',
                    education: 'Educação',
                    expertise: 'Especialidade',
                    expertiseArea: 'Área de atuação',
                    skill: 'Habilidade',
                    profile: 'Perfil',
                    award: 'Prêmio',
                    language: 'Idioma',
                    portfolio: 'Portfólio',
                },
                stepper: {
                    area: 'Área de Actuação',
                    collaborator: 'Dados Pessoais',
                    experience: 'Experiência',
                    education: 'Educação',
                    language: 'Idioma',
                    expertise: 'Especialidade',
                    skill: 'Habilidade',
                    profile: 'Perfil',
                    award: 'Prêmio',
                    portfolio: 'Portfólio',
                },
                subtitle: {
                    collaborator: 'Criar Colaborador',
                    experience: 'Criar Experiência',
                    education: 'Criar Educação',
                    expertise: 'Criar Especialidade',
                    expertiseArea: 'Criar Área de atuação',
                    skill: 'Criar Habilidade',
                    profile: 'Criar Perfil',
                    award: 'Criar Prêmio',
                    portfolio: 'Criar Portfólio',
                    language: 'Criar Idioma',
                },
                button: {
                    prev: 'Anterior',
                    next: 'Próximo',
                    end: 'Terminar',
                },
            },
            companyFilter: {
                nameComapny: 'Nome da empresa',
                address: 'Endereço',
                phone: 'Telefone',
                status: 'Estado',
            },
            collaboratorStep: {
                collaborator: {
                    level: 'Seleciona o teu Nível',
                    nameLanguage: 'Idioma',
                    payment: 'Pagamento',
                    nameExpertiseArea: 'Área de Actuação',
                    levelBody: 'Qual é o teu Nivel',
                    especiality: 'Especialidade',
                    yearsOfExperience: 'Anos de experiência',
                    name: 'Nome',
                    nameCollaborator: 'Nome do Colaborador',
                    nameProfile: 'Nome da Plataforma',
                    nameSkill: 'Nome da Habilidade',
                    nameExpertise: 'Nome da Especialidade',
                    nameFaculty: 'Nome da Faculdade',
                    address: 'Endereço',
                    country: 'País',
                    course: 'Curso',
                    countryBody: 'Ex: Cabo Verde',
                    city: 'Cidade',
                    cityBody: 'Ex: Praia',
                    company: 'Empresa',
                    description: 'Descrição',
                    descriptionBody: 'Como foi a sua Experiência?',
                    descriptionExpertise: 'Detalhes da sua especialidade!',
                    descriptionProfile: 'O que iremos encontrar?',
                    descriptionAward: 'Detalhes sobre o seu Prêmio',
                    descriptionportfolio: 'Detalhes do seu trabalho',
                    role: 'Cargo',
                    roleBody: 'Ex: Programador',
                    phone: 'Telefone',
                    web: 'Website',
                    email: 'Email',
                    introduction: 'Introdução',
                    introductionBody: 'Ex: Eu sou a tempestade que se aproxima...',
                    paymentIntro: 'Valor Pago por Hora',
                    upload: 'Foto do Colaborador',
                    newUpload: 'Alterar Imagem',
                    uploadPortfolio: 'Adicione uma Imagem',
                    entity: 'Nome da Entidade',
                    start: 'Data do Início',
                    end: 'Data do Fim',
                    profileUrl: 'Url do Perfil',
                    percentage: 'Percentagem',
                    percentageBody: 'Adicione um Número de 0 a 100',
                },
                button: {
                    upload: 'Carregar',
                    language: 'Adicionar Idioma',
                    collaborator: 'Adicionar Colaborador',
                    experience: 'Adicionar Experiência',
                    education: 'Adicionar Educação',
                    expertise: 'Adicionar Especialidade',
                    skill: 'Adicionar Habilidade',
                    profile: 'Adicionar Perfil',
                    award: 'Adicionar Prêmio',
                    portfolio: 'Adicionar Portfólio',
                    cancel: 'Cancelar',
                    edit: 'Editar',
                    create: 'Criar',
                    add: 'Adicionar',
                    update: 'Atualizar',
                },
                error: {
                    collaborator: 'Uma conta de colaborador deve ser criada primeiro!',
                    uploadFile:
                        'Por favor, selecione um arquivo de imagem válido (JPEG, PNG ou GIF)',
                    uploadReady: ' - está pronto para ser enviado!',
                    nameSelect: 'Nome é obrigatório',
                    yearsSelect: 'Anos de expperiência é obrigatório',
                    especialitySelect: 'Especialidade é obrigatório',
                    entitySelect: 'Entidade é obrigatória',
                    countrySelect: 'País é obrigatório',
                    citySelect: 'Cidade é obrigatória',
                    roleSelect: 'Cargo é obrigatório',
                    portfolioSelect: 'Portfólio é obrigatório',
                    profileSelect: 'Plataforma é obrigatória',
                    urlSelect: 'Url é obrigatório',
                    awardSelect: 'Prêmio é obrigatório',
                    skillSelect: 'Habilidade é obrigatória',
                    expertiseSelect: 'Especialidade é obrigatória',
                    facultySelect: 'Faculdade é obrigatória',
                    courseSelect: 'Curso é obrigatório',
                },
            },
            button: {
                collaborator: 'Adicionar Colaborador',
                experience: 'Adicionar Experiência',
                education: 'Adicionar Educação',
                expertise: 'Adicionar Especialidade',
                skill: 'Adicionar Habilidade',
                profile: 'Adicionar Perfil',
                award: 'Adicionar Prêmio',
                portfolio: 'Adicionar Portfólio',
                upload: 'Carregar',
                cancel: 'Cancelar',
                edit: 'Editar',
                create: 'Criar',
                add: 'Adicionar',
                update: 'Atualizar',
                new: 'Novo',
                back: 'Voltar',
            },
        },
    },
};

export { messages };
