import React, { useState } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import AddLinkIcon from "@mui/icons-material/AddLink";
import { Autocomplete, InputLabel, TextField, createFilterOptions } from "@mui/material";

function AutoCompleted(
  { autoValue,
    autoEntities,
    setAutoValue,
    newAutoEntities,
    setNewAutoEntities,
    placeholder,
    title }
) {
  const filter = createFilterOptions();

  return (
    <>

      <InputLabel >{title}</InputLabel>
      <Autocomplete
        value={autoValue}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            const filteredExpEntity = autoEntities.filter(item =>
              item.name?.toLowerCase() === newValue?.toLowerCase()
            );

            if (filteredExpEntity?.length > 0) {
              setAutoValue({
                name: filteredExpEntity[0].name,
                id: filteredExpEntity[0].id
              });
            } else {
              const newOption = { name: newValue.inputValue, id: null }
              setAutoValue(newOption)
              setNewAutoEntities([...newAutoEntities, newOption]);
            }

          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input

            const newOption = { name: newValue.inputValue, id: null }
            setAutoValue(newOption)
            setNewAutoEntities([...newAutoEntities, newOption]);
          } else {
            setAutoValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.name);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              name: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={[...autoEntities, ...newAutoEntities]}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.name;
        }}
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        size="small"
        freeSolo
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder}/>
        )}
      />

    </>
  );
}
export default AutoCompleted;